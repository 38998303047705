import { useDispatch, useSelector } from "react-redux"
import {
	createUser,
	createUserPermissions,
	fetchAll,
	fetchAllDropdown,
	fetchAllDropdownTemplate,
	invalidateCache,
	removeUser,
	updateUser,
	updateUserPermissions,
} from "../reducers/users"

export default function useUsers() {
	const dispatch = useDispatch()
	const data = useSelector((state) => state.users.data)
	const loading = useSelector((state) => state.users.loading)
	const message = useSelector((state) => state.users.message)
	const dropdownData = useSelector((state) => state.users.dropdownData)
	const templateDropdownData = useSelector((state) => state.users.templateDropdownData)

	const create = async (data) => {
		return dispatch(createUser(data))
	}
	const cleanCache = async (data) => {
		return dispatch(invalidateCache(data))
	}

	const remove = async (id) => {
		return dispatch(removeUser(id))
	}

	const update = async (data) => {
		return dispatch(updateUser(data))
	}

	const fetch = () => {
		dispatch(fetchAll())
	}

	const fetchDropdown = () => {
		dispatch(fetchAllDropdown())
	}

	const fetchDropdownTemplate = () => {
		dispatch(fetchAllDropdownTemplate())
	}

	const updatePermissions = (payload) => {
		return dispatch(updateUserPermissions(payload))
	}

	const createPermissions = (payload) => {
		return dispatch(createUserPermissions(payload))
	}

	return {
		fetch,
		data,
		loading,
		message,
		dropdownData,
		fetchDropdown,
		create,
		remove,
		update,
		cleanCache,
		updatePermissions,
		createPermissions,
		templateDropdownData,
		fetchDropdownTemplate,
	}
}
