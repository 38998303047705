import { useDisclosure } from "@chakra-ui/hooks"
import { useEffect, useState } from "react"
import Select from "react-select"
import styled from "styled-components"
import { ReactComponent as AddButton } from "../assets/add-button.svg"
import { customStylesSelect } from "../components/CustomSelectStyles"
import { makeDropdownOptions, makeIdsArray } from "../components/forms/calcHelper"
import IndicationApprovalForm from "../components/forms/indications/IndicationApprovalForm"
import Loading from "../components/frames/Loading"
import PriceTableFrame from "../components/frames/PriceTableFrame"
import Layout from "../components/layout/Layout"
import CustomModal from "../components/modals/CustomModal"
import generateToast from "../components/notifications/ToastGenerator"
import IndicationApprovalTable from "../components/tables/IndicationApprovalTable"
import Pagination from "../components/tables/Pagination"
import { country } from "../redux/api_urls"
import useDrugIndication from "../redux/hooks/useDrugIndication"
import useIndicationApproval from "../redux/hooks/useIndicationApproval"
import useMasterData from "../redux/hooks/useMasterData"
import useOrganization from "../redux/hooks/useOrganization"
import usePackage from "../redux/hooks/usePackage"
import useReference from "../redux/hooks/useReference"
import useUser from "../redux/hooks/useUser"
import { defaultPageNumber, superAdminAccess } from "../utils/constants"
import { checkWriteCondition, handleOrganizationPayload, handleResponse } from "../utils/functions"

const IndicationApprovalPage = () => {
	const { isOpen, onOpen, onClose } = useDisclosure()

	const hook = useIndicationApproval()
	const hookIndicationDrugs = useDrugIndication()
	const hookMasterData = useMasterData()
	const hookPackage = usePackage()
	const hookReference = useReference()
	const hookOrg = useOrganization()
	const role = useUser().role
	const parentData = useUser().useParentData
	const organizationId = useUser().organizationId

	const [selectedDrugIndication, setSelectedDrugIndication] = useState(null)
	const [selectedCountry, setSelectedCountry] = useState(null)
	const [sortBy, setSortBy] = useState(null)
	const [filter, setFilter] = useState(null)
	const [countries, setCountries] = useState([])
	const [indications, setIndications] = useState([])
	const [packages, setPackages] = useState([])
	const [references, setReferences] = useState([])
	const [organizations, setOrganizations] = useState([])

	useEffect(() => {
		hook.fetch(1, hook.defaultSize)
		hookMasterData.fetch(country)
		hookIndicationDrugs.fetchDropdownData()
		hookPackage.fetchDropdown(true)
		hookReference.fetchDropdown()
		if (superAdminAccess.includes(role)) {
			hookOrg.fetchDropdown()
		}
	}, []) // eslint-disable-line

	useEffect(() => {
		if (hook.message) {
			generateToast(hook.message)
		}
	}, [hook.message])

	useEffect(() => {
		setPackages(makeDropdownOptions(hookPackage.dropdownData, "description"))
		setIndications(makeDropdownOptions(hookIndicationDrugs.dropdownData, "indication_value"))
		setCountries(makeDropdownOptions(hookMasterData.countries))
		setReferences(makeDropdownOptions(hookReference.dropdownData, "value_field"))
		setOrganizations(makeDropdownOptions(hookOrg.dropdownData, "name"))
	}, [
		hookPackage.dropdownData,
		hookMasterData.countries,
		hookIndicationDrugs.dropdownData,
		hookReference.dropdownData,
		hookOrg.dropdownData,
	])

	const sortByOptions = [
		{ value: "date", label: "Date" },
		{ value: "country", label: "Country" },
		{ value: "drug_indication", label: "Drug indication" },
		{ value: "ongoing", label: "Status" },
		{ value: "pipeline", label: "Pipeline" },
	]

	const handleDrugIndicationSelect = (val) => {
		const filterData = {}
		if (!val) {
			setSelectedDrugIndication(null)
		} else {
			setSelectedDrugIndication(val)
			filterData["drugIndication"] = val.value
		}
		if (selectedCountry) {
			filterData["country"] = selectedCountry.value
		}
		if (sortBy) {
			filterData["sortBy"] = sortBy.value
		}
		hook.fetch(defaultPageNumber, hook.defaultSize, filterData)
		setFilter(filterData)
	}

	const handleCountrySelect = (val) => {
		const filterData = {}
		if (!val) {
			setSelectedCountry(null)
		} else {
			setSelectedCountry(val)
			filterData["country"] = val.value
		}
		if (selectedDrugIndication) {
			filterData["drugIndication"] = selectedDrugIndication.value
		}
		if (sortBy) {
			filterData["sortBy"] = sortBy.value
		}
		hook.fetch(defaultPageNumber, hook.defaultSize, filterData)
		setFilter(filterData)
	}

	const handleSortBySelect = (val) => {
		const filterData = {}
		if (!val) {
			setSortBy(null)
		} else {
			setSortBy(val)
			filterData["sortBy"] = val.value
		}

		if (selectedDrugIndication) {
			filterData["drugIndication"] = selectedDrugIndication.value
		}
		if (selectedCountry) {
			filterData["country"] = selectedCountry.value
		}
		hook.fetch(defaultPageNumber, hook.defaultSize, filterData)
		setFilter(filterData)
	}

	const submit = (data) => {
		let payload = {
			country: data.country.value,
			date: new Date(data.approvalDate).toISOString(),
			drug_indication: data.indication.value,
			ongoing: data.ongoing,
			pipeline: data.pipeline,
		}

		if (data.reference_dose) {
			payload["reference_dose"] = makeIdsArray(data.reference_dose)
		} else {
			payload["reference_dose"] = []
		}

		handleOrganizationPayload(payload, role, organizationId, data.organization)

		const handleSuccess = () => {
			onClose()
			setTimeout(() => {
				hook.fetch(1, hook.defaultSize)
			}, 1000)()
		}

		hook.create(payload).then((res) => handleResponse(res, handleSuccess))
	}

	const selectData = {
		countries: countries,
		indications: indications,
		packages: packages,
		references: references,
		organizations: organizations,
	}

	return (
		<Layout>
			<PageContainer>
				<FiltersDiv>
					<div>
						<Select
							name="drug_indication_filter"
							placeholder="Select drug indication..."
							className="react-select-container-indication"
							classNamePrefix="react-select"
							styles={customStylesSelect}
							options={indications}
							onChange={(val) => handleDrugIndicationSelect(val)}
							isClearable={true}
							value={selectedDrugIndication}
						/>
					</div>
					<div>
						<Select
							name="country_filter"
							placeholder="Select country..."
							className="react-select-container"
							classNamePrefix="react-select"
							styles={customStylesSelect}
							options={countries}
							onChange={(val) => handleCountrySelect(val)}
							isClearable={true}
							value={selectedCountry}
						/>
					</div>
					<div>
						<Select
							name="sort_by_filter"
							placeholder="Sort by..."
							className="react-select-container"
							classNamePrefix="react-select"
							styles={customStylesSelect}
							options={sortByOptions}
							onChange={(val) => handleSortBySelect(val)}
							isClearable={true}
							value={sortBy}
						/>
					</div>
				</FiltersDiv>
				<PriceTableFrame>
					<IndicationApprovalTable
						data={hook.data}
						selectData={selectData}
						role={role}
						parentData={parentData}
						organizationId={organizationId}
					/>
					{hook.loading && <Loading />}
				</PriceTableFrame>
				{checkWriteCondition(role, parentData) && <AddButton className="addBtn" onClick={() => onOpen()} />}
				{hook.pagination && (
					<Pagination
						totalCount={hook.pagination.total_count}
						totalPages={hook.pagination.total_pages}
						currentPage={hook.pagination.current_page}
						pageSize={hook.defaultSize}
						fetchPage={hook.fetch}
						setSize={hook.changeSize}
						pageSizesArray={[100, 250]}
						filter={filter}
					/>
				)}
			</PageContainer>
			<CustomModal isOpen={isOpen} onClose={onClose} size="5xl">
				<IndicationApprovalForm onSubmit={submit} selectData={selectData} cancel={onClose} role={role} />
			</CustomModal>
		</Layout>
	)
}

export default IndicationApprovalPage

const PageContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: hidden;
	background: var(--white);
`

const FiltersDiv = styled.div`
	width: 96%;
	display: flex;
	flex-direction: row;
	column-gap: 10px;
	align-items: stretch;
	margin-top: 1%;

	.react-select-container-indication {
		width: 453px;
		height: 40px;
		margin: 0 auto;
		text-align: left;
	}

	.react-select-container {
		width: 283px;
		height: 40px;
		margin: 0 auto;
		text-align: left;
	}
`
