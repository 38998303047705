import { useDisclosure } from "@chakra-ui/hooks"
import { Center } from "@chakra-ui/layout"
import React, { useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import { ReactComponent as TrueIcon } from "../../assets/check.svg"
import { ReactComponent as FalseIcon } from "../../assets/delete.svg"
import useIndicationApproval from "../../redux/hooks/useIndicationApproval"
import { superAdminAccess } from "../../utils/constants"
import {
	checkWriteCondition,
	findDefaultOptionByValue,
	formatDate,
	handleOrganizationPayload,
	handleResponse,
	makeStringOutOfArray,
} from "../../utils/functions"
import CustomButton from "../buttons/CustomButton"
import { makeDropdownOptions, makeIdsArray } from "../forms/calcHelper"
import IndicationApprovalForm from "../forms/indications/IndicationApprovalForm"
import CustomModal from "../modals/CustomModal"
import DeleteModal from "../modals/DeleteModal"
import { TablesStyles } from "./TableStyles"

const IndicationApprovalTable = ({ data, selectData, doNotExpandRows, role, parentData, organizationId }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()

	const hook = useIndicationApproval()

	const [updateData, setUpdateData] = useState(null)

	const indexedData = data.map((d, i) => {
		return {
			num: i + 1,
			id: d.id,
			drug_indication: d.drug_indication,
			indication_value: d.indication_value,
			country: d.country,
			country_name: d.country_name,
			date: d.date,
			ongoing: d.ongoing,
			pipeline: d.pipeline,
			reference_dose: d.reference_dose,
			organization: d.organization,
			organization_name: d.organization_name,
		}
	})

	const columns = [
		{
			dataField: "num",
			text: "No.",
			headerStyle: () => {
				return { width: "0.5%" }
			},
			formatter: (cell) => {
				return `${cell}.`
			},
		},
		{
			dataField: "indication_value",
			text: "Drug indication",
			headerStyle: () => {
				return { width: "13%" }
			},
		},
		{
			dataField: "country_name",
			text: "Approval country",
			headerStyle: () => {
				return { width: "2%" }
			},
		},
		{
			dataField: "date",
			text: "Date",
			headerStyle: () => {
				return { width: "2%" }
			},
			formatter: (cell) => {
				return cell ? formatDate(cell) : ""
			},
		},
		{
			dataField: "ongoing",
			text: "Status",
			headerStyle: () => {
				return { width: "1%" }
			},
			formatter: (cell) => <Center>{cell ? <FalseIcon /> : <TrueIcon />}</Center>,
		},
		{
			dataField: "pipeline",
			text: "Pipeline",
			headerStyle: () => {
				return { width: "1%" }
			},
			formatter: (cell) => <Center>{cell ? <TrueIcon /> : <FalseIcon />}</Center>,
		},
		{
			dataField: "reference_dose",
			text: "Reference doses",
			headerStyle: () => {
				return { width: "9%" }
			},
			formatter: (cell) => {
				return makeStringOutOfArray(cell, "value_field")
			},
		},
	]

	if (superAdminAccess.includes(role)) {
		columns.splice(1, 0, {
			dataField: "organization_name",
			text: "Organization",
			headerStyle: () => {
				return { width: "3%" }
			},
		})
	}

	const handleSuccess = () => {
		onClose()
		setTimeout(() => {
			hook.fetch(1, hook.defaultSize)
		}, 1000)
	}

	const handleDelete = (id) => {
		hook.remove(id).then((res) => handleResponse(res, handleSuccess))
	}

	const expandRow = {
		renderer: (row) =>
			doNotExpandRows ? (
				""
			) : (
				<div className="btns-container">
					<CustomButton type="update" onClick={() => handleOpenUpdate(row)} />
					<DeleteModal remove={() => handleDelete(row.id)} />
				</div>
			),
		className: "expandedRow",
		parentClassName: "parentExpandedRow",
	}

	const handleOpenUpdate = (row) => {
		let defaultValues = {
			id: row.id,
			defaultPackages: makeDropdownOptions(row.reference_dosing, "pack_info"),
			defaultCountry: findDefaultOptionByValue(selectData.countries, row.country),
			defaultIndication: findDefaultOptionByValue(selectData.indications, row.drug_indication),
			defaultDate: new Date(row.date),
			ongoing: row.ongoing,
			pipeline: row.pipeline,
			defaultReferenceDoses: makeDropdownOptions(row.reference_dose, "value_field"),
		}

		if (superAdminAccess.includes(role)) {
			defaultValues["organization"] = selectData.organizations.find((ele) => ele.value === Number(row.organization))
		}

		setUpdateData(defaultValues)
		onOpen()
	}

	const submit = (data) => {
		let newDate = null
		if (data.approvalDate) {
			newDate = new Date(data.approvalDate).toISOString()
		}

		let payload = {
			id: updateData.id,
			drug_indication: data.indication.value,
			country: data.country.value,
			ongoing: data.ongoing,
			pipeline: data.pipeline,
			date: newDate,
		}

		if (data.reference_dose) {
			payload["reference_dose"] = makeIdsArray(data.reference_dose)
		} else {
			payload["reference_dose"] = []
		}

		handleOrganizationPayload(payload, role, organizationId, data.organization)

		hook.update(payload).then((res) => handleResponse(res, handleSuccess))
	}

	return (
		<>
			<TablesStyles minWidth={"3300px"}>
				<BootstrapTable
					data={indexedData}
					columns={columns}
					keyField="id"
					classes="TablesStyles"
					expandRow={checkWriteCondition(role, parentData) && expandRow}
					bordered
					bootstrap4
				/>
			</TablesStyles>
			<CustomModal isOpen={isOpen} onClose={onClose} size="5xl">
				<IndicationApprovalForm onSubmit={submit} defaultValues={updateData} selectData={selectData} cancel={onClose} role={role} />
			</CustomModal>
		</>
	)
}

export default IndicationApprovalTable
