import { Center } from "@chakra-ui/layout"
import { ReactComponent as TrueIcon } from "../../assets/check.svg"
import { ReactComponent as FalseIcon } from "../../assets/delete.svg"

export const trueOrFalse = (value) => {
	return <Center>{value ? <TrueIcon /> : <FalseIcon />}</Center>
}

export const header = (width) => {
	if (width) {
		return { width: width }
	} else {
		return { width: "1%" }
	}
}
