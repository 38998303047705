import { Tooltip } from "@chakra-ui/react"

const RadioButtonGroup = ({ firstLabel, secondLabel, regName, defaultCheckedFirst, defaultCheckedSecond, register, title, tooltip }) => {
	const RadioButtons = () => {
		return (
			<div className="radio-button-group">
				<label className="radio-button label-inputs">
					{firstLabel}
					<input type="radio" name={regName} defaultChecked={defaultCheckedFirst} value={true} ref={register} />
					<span className="checkmark"></span>
				</label>
				<label className="radio-button label-inputs">
					{secondLabel}
					<input type="radio" name={regName} defaultChecked={defaultCheckedSecond} value={false} ref={register} />
					<span className="checkmark"></span>
				</label>
			</div>
		)
	}

	if (title) {
		return (
			<div className="radio-button-group-with-title">
				<Tooltip label={tooltip} hasArrow arrowSize={10} px="12px" py="8px">
					<label className="radio-title">{title}</label>
				</Tooltip>
				<RadioButtons />
			</div>
		)
	} else {
		return <RadioButtons />
	}
}

export default RadioButtonGroup
