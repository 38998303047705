import { useDisclosure } from "@chakra-ui/hooks"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { ReactComponent as AddButton } from "../assets/add-button.svg"
import { makeDropdownOptions } from "../components/forms/calcHelper"
import ReferenceForm from "../components/forms/ReferenceForm"
import Loading from "../components/frames/Loading"
import TableFrame from "../components/frames/TableFrame"
import Layout from "../components/layout/Layout"
import CustomModal from "../components/modals/CustomModal"
import generateToast from "../components/notifications/ToastGenerator"
import Pagination from "../components/tables/Pagination"
import ReferenceTable from "../components/tables/ReferenceTable"
import { productName } from "../redux/api_urls"
import useMasterData from "../redux/hooks/useMasterData"
import useOrganization from "../redux/hooks/useOrganization"
import useReference from "../redux/hooks/useReference"
import useUser from "../redux/hooks/useUser"
import { superAdminAccess } from "../utils/constants"
import { checkWriteCondition, handleOrganizationPayload, handleResponse } from "../utils/functions"

const ReferencesPage = () => {
	const { isOpen, onOpen, onClose } = useDisclosure()

	const hook = useReference()
	const hookMd = useMasterData()
	const hookOrg = useOrganization()
	const role = useUser().role
	const parentData = useUser().useParentData
	const organizationId = useUser().organizationId

	const [productNames, setProductNames] = useState([])
	const [organizations, setOrganizations] = useState([])

	useEffect(() => {
		hook.fetch(1, hook.defaultSize)
		hookMd.fetch(productName)
		if (superAdminAccess.includes(role)) {
			hookOrg.fetchDropdown()
		}
	}, []) // eslint-disable-line

	useEffect(() => {
		setProductNames(makeDropdownOptions(hookMd.productNames))
		setOrganizations(makeDropdownOptions(hookOrg.dropdownData, "name"))
	}, [hookMd.productNames, hookOrg.dropdownData])

	useEffect(() => {
		if (hook.message) {
			generateToast(hook.message)
		}
	}, [hook.message])

	const handleSuccess = () => {
		hook.fetch(1, hook.defaultSize)
		onClose()
	}

	const submit = (data) => {
		let payload = {
			pack_strength: data.pack_strength,
			drug: data.drug.value,
		}

		handleOrganizationPayload(payload, role, organizationId, data.organizationId)

		hook.create(payload).then((res) => handleResponse(res, handleSuccess))
	}

	const selectData = {
		productNames: productNames,
		organizations: organizations,
	}

	return (
		<Layout>
			<PageContainer>
				<TableFrame>
					<ReferenceTable selectData={selectData} role={role} parentData={parentData} organizationId={organizationId} />
					{hook.loading && <Loading />}
				</TableFrame>
				{checkWriteCondition(role, parentData) && <AddButton className="addBtn" onClick={() => onOpen()} />}

				{hook.pagination && (
					<Pagination
						totalCount={hook.pagination.total_count}
						totalPages={hook.pagination.total_pages}
						currentPage={hook.pagination.current_page}
						pageSize={hook.defaultSize}
						fetchPage={hook.fetch}
						setSize={hook.changeSize}
						pageSizesArray={[100, 250, 500]}
					/>
				)}
			</PageContainer>
			<CustomModal isOpen={isOpen} onClose={onClose} size="3xl">
				<ReferenceForm onSubmit={submit} selectData={selectData} cancel={onClose} role={role} />
			</CustomModal>
		</Layout>
	)
}

export default ReferencesPage

const PageContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: hidden;
	background: var(--white);
`
