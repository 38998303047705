import { createAction, createReducer } from "@reduxjs/toolkit"
import axios from "axios"
import { serverErrorMessage } from "../../utils/constants"
import { drugPackageURL, packageRemoveURL, packagePagesURL, dropdownPackageURL } from "../api_urls"

const START_REQUEST = createAction("PACKAGE/START_REQUEST")
const FETCH_SUCCESS = createAction("PACKAGE/FETCH_SUCCESS")
const REQUEST_SUCCESS = createAction("PACKAGE/REQUEST_SUCCESS")
const REQUEST_FAIL = createAction("PACKAGE/REQUEST_FAIL")
const CLEAN_MESSAGE = createAction("PACKAGE/CLEAN_MESSAGE")
const FETCH_DROPDOWN_DATA = createAction("PACKAGE/FETCH_DROPDOWN_DATA")
const CHANGE_SIZE = createAction("PACKAGE/CHANGE_SIZE")

export const fetchPage = (pageNumber, pageSize, filterData) => async (dispatch) => {
	let sort = "id"
	let filterUrl = packagePagesURL(pageNumber, pageSize, sort)
	if (filterData) {
		if (filterData["product_name"]) {
			filterUrl += "&drug=" + filterData["product_name"]
		}
		if (filterData.route) {
			filterUrl += "&route_of_administration=" + filterData.route
		}
	}

	dispatch(START_REQUEST())
	return axios.get(filterUrl).then((res) => handleResponse(res, dispatch, FETCH_SUCCESS, REQUEST_FAIL))
}

export const fetchDropdownData = (indicationApproval) => async (dispatch) => {
	dispatch(START_REQUEST())

	let url = dropdownPackageURL
	if (indicationApproval) {
		url += "&entity=IndicationApproval"
	}

	return axios.get(url).then((res) => handleResponse(res, dispatch, FETCH_DROPDOWN_DATA, REQUEST_FAIL))
}

export const fetchPackageSchema = (id) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.get(drugPackageURL`?schema_id=${id}`).then((res) => handleResponse(res, dispatch, FETCH_SUCCESS, REQUEST_FAIL))
}

export const createPackage = (data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(drugPackageURL, data).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const removePackage = (id) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.delete(packageRemoveURL(id)).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const updatePackage = (data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.put(drugPackageURL, data).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const setDefaultSize = (size) => async (dispatch) => {
	dispatch(CHANGE_SIZE(size))
}

export const cleanMessage = () => async (dispatch) => {
	dispatch(CLEAN_MESSAGE())
}

const handleResponse = (res, dispatch, success, fail) => {
	if (res !== undefined) {
		if (res.status >= 200 && res.status <= 299) {
			if (success === REQUEST_SUCCESS) {
				dispatch(success(res.data.message))
				dispatch(CLEAN_MESSAGE())
			} else {
				dispatch(success(res.data))
			}
			return res
		} else if ((res.response !== undefined && res.response.status === 400) || 401) {
			dispatch(fail(res.response.data.message))
			dispatch(CLEAN_MESSAGE())
		} else {
			dispatch(fail(serverErrorMessage))
			dispatch(CLEAN_MESSAGE())
		}
	} else {
		dispatch(fail(serverErrorMessage))
		dispatch(CLEAN_MESSAGE())
	}
}

const initState = {
	data: [],
	dropdownData: [],
	pagination: null,
	defaultSize: 200,
	loading: false,
	message: null,
}

export const packageReducer = createReducer(initState, (builder) => {
	builder
		.addCase(START_REQUEST, (state, action) => {
			state.loading = true
			state.message = null
		})
		.addCase(FETCH_SUCCESS, (state, action) => {
			state.loading = false
			state.data = action.payload.packages
			state.pagination = action.payload.pagination
		})
		.addCase(FETCH_DROPDOWN_DATA, (state, action) => {
			state.loading = false
			state.dropdownData = action.payload
		})
		.addCase(REQUEST_SUCCESS, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(REQUEST_FAIL, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(CLEAN_MESSAGE, (state, action) => {
			state.message = null
		})
		.addCase(CHANGE_SIZE, (state, action) => {
			state.defaultSize = action.payload
		})
})
