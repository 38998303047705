import { useDisclosure } from "@chakra-ui/react"
import { useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import { handleResponse } from "../../utils/functions"
import CustomButton from "../buttons/CustomButton"
import CustomModal from "../modals/CustomModal"
import DeleteModal from "../modals/DeleteModal"
import { TablesStyles } from "./TableStyles"
import useBsaConfig from "../../redux/hooks/useBsaConfig"
import BsaForm from "../forms/BsaForm"

const BsaConfigTable = ({ selectData }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()

	const hook = useBsaConfig()

	const [updateData, setUpdateData] = useState(null)

	let data = hook.bsa

	const indexedData = data.map((d, i) => {
		return {
			num: i + 1,
			id: d.id,
			weight: d.weight,
			bsa: d.bsa,
			country_name: d.country_name,
		}
	})

	const columns = [
		{
			dataField: "num",
			text: "No.",
			headerStyle: () => {
				return { width: "3%" }
			},
			formatter: (cell) => `${cell}.`,
		},
		{
			dataField: "country_name",
			text: "Country",
		},
		{
			dataField: "weight",
			text: "Weight",
		},
		{
			dataField: "bsa",
			text: "BSA",
		},
	]

	const handleSuccess = () => {
		hook.getBsaConfig()
		onClose()
	}

	const handleDelete = (id) => {
		hook.removeBsa(id).then((res) => handleResponse(res, handleSuccess))
	}

	const expandRow = {
		renderer: (row) => (
			<div className="btns-container">
				<CustomButton type="update" onClick={() => handleOpenUpdate(row)} />
				<DeleteModal remove={() => handleDelete(row.id)} />
			</div>
		),
		className: "expandedRow",
		parentClassName: "parentExpandedRow",
	}

	const handleOpenUpdate = (row) => {
		const defaultValues = {
			id: row.id,
			defaultCountry: selectData.countries.find((option) => option.label === row.country_name),
			weight: row.weight,
			bsa: row.bsa,
		}

		setUpdateData(defaultValues)
		onOpen()
	}

	const submit = (data) => {
		const payload = {
			id: updateData.id,
			country: data.country.value,
			weight: data.weight,
			bsa: data.bsa,
		}

		hook.updateBsa(payload).then((res) => handleResponse(res, handleSuccess))
	}

	return (
		<>
			<TablesStyles>
				<BootstrapTable
					data={indexedData}
					columns={columns}
					keyField="id"
					classes="TablesStyles"
					expandRow={expandRow}
					bordered
					bootstrap4
				/>
			</TablesStyles>

			<CustomModal isOpen={isOpen} onClose={onClose} size="3xl">
				<BsaForm defaultValues={updateData} submit={submit} cancel={onClose} selectData={selectData} />
			</CustomModal>
		</>
	)
}

export default BsaConfigTable
