import { createAction, createReducer } from "@reduxjs/toolkit"
import axios from "axios"
import { serverErrorMessage } from "../../utils/constants"
import { cacheURL, usersConfigURL, usersDropdownURL, usersRemoveURL, usersTemplateDropdownURL, usersURL } from "../api_urls"

const START_REQUEST = createAction("USERS/START_REQUEST")
const FETCH_SUCCESS = createAction("USERS/FETCH_SUCCESS")
const FETCH_SUCCESS_DROPDOWN = createAction("USERS/FETCH_SUCCESS_DROPDOWN")
const FETCH_SUCCESS_TEMPLATE = createAction("USERS/FETCH_SUCCESS_TEMPLATE")
const REQUEST_SUCCESS = createAction("USERS/REQUEST_SUCCESS")
const REQUEST_FAIL = createAction("USERS/REQUEST_FAIL")
const CLEAN_MESSAGE = createAction("USERS/CLEAN_MESSAGE")

export const fetchAll = () => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.get(usersURL).then((res) => handleResponse(res, dispatch, FETCH_SUCCESS, REQUEST_FAIL))
}

export const fetchAllDropdown = () => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.get(usersDropdownURL).then((res) => handleResponse(res, dispatch, FETCH_SUCCESS_DROPDOWN, REQUEST_FAIL))
}

export const fetchAllDropdownTemplate = () => async (dispatch) => {
	return axios.get(usersTemplateDropdownURL).then((res) => handleResponse(res, dispatch, FETCH_SUCCESS_TEMPLATE, REQUEST_FAIL))
}

export const createUser = (data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(usersURL, data).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}
export const invalidateCache = (data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(cacheURL, data).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const removeUser = (id) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.delete(usersRemoveURL(id)).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const updateUser = (data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.put(usersURL, data).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const updateUserPermissions = (data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.put(usersConfigURL, data).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const createUserPermissions = (data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(usersConfigURL, data).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

const handleResponse = (res, dispatch, success, fail, type) => {
	if (res !== undefined) {
		if (res.status >= 200 && res.status <= 299) {
			if (success === REQUEST_SUCCESS) {
				dispatch(success(res.data.message))
				dispatch(CLEAN_MESSAGE())
			} else {
				dispatch(success(res.data))
			}
			return res
		} else if (res.response !== undefined && res.response.status === 400) {
			dispatch(fail(res.response.data.message))
			dispatch(CLEAN_MESSAGE())
		} else {
			dispatch(fail(serverErrorMessage))
			dispatch(CLEAN_MESSAGE())
		}
	} else {
		dispatch(fail(serverErrorMessage))
		dispatch(CLEAN_MESSAGE())
	}
}

const initState = {
	data: [],
	dropdownData: [],
	templateDropdownData: [],
	loading: false,
	message: null,
}

export const usersReducer = createReducer(initState, (builder) => {
	builder
		.addCase(START_REQUEST, (state, action) => {
			state.loading = true
			state.message = null
		})
		.addCase(FETCH_SUCCESS, (state, action) => {
			state.loading = false
			state.data = action.payload
		})
		.addCase(FETCH_SUCCESS_DROPDOWN, (state, action) => {
			state.loading = false
			state.dropdownData = action.payload
		})
		.addCase(FETCH_SUCCESS_TEMPLATE, (state, action) => {
			state.templateDropdownData = action.payload
		})
		.addCase(REQUEST_SUCCESS, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(REQUEST_FAIL, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(CLEAN_MESSAGE, (state, action) => {
			state.message = null
		})
})
