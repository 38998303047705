import { FormLabel } from "@chakra-ui/react"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import Select from "react-select"
import styled from "styled-components"
import CustomButton from "../../buttons/CustomButton"
import { customStylesSelect } from "../../CustomSelectStyles"

const DrugIndicationForm = ({ defaultValues, onSubmit, selectData, cancel }) => {
	const { register, handleSubmit, control } = useForm()

	return (
		<FormWrapper onSubmit={handleSubmit(onSubmit)}>
			<div className="row-section">
				<div className="treatment-line">
					<label>Treatment line</label>
					<Controller
						control={control}
						options={selectData.treatmentLines}
						as={Select}
						defaultValue={defaultValues && defaultValues.defaultTreatmentLine}
						name="treatment_line"
						placeholder="Select treatment line..."
						className="react-select-container"
						classNamePrefix="react-select"
						styles={customStylesSelect}
						ref={register({ required: true })}
						rules={{ required: true, message: "This field is required" }}
					/>
				</div>
				<div className="combination-code">
					<label>Combination code</label>
					<input
						name="combination_code"
						ref={register({ required: true })}
						defaultValue={defaultValues && defaultValues.combinationCode}
						placeholder="Insert combination code..."
						autoComplete="off"
					/>
				</div>
			</div>
			<div className="row-section">
				<div className="drugs">
					<label>Product names</label>
					<Controller
						isMulti
						control={control}
						options={selectData.productNames}
						as={Select}
						defaultValue={defaultValues && defaultValues.defaultProductNameValues}
						name="product_names"
						ref={register({ required: true })}
						placeholder="Select product names..."
						className="react-select-container"
						classNamePrefix="react-select"
						styles={customStylesSelect}
						rules={{ required: true, message: "This field is required" }}
					/>
				</div>
			</div>
			<div className="indication">
				<label>Indication</label>
				<Controller
					control={control}
					options={selectData.indications}
					as={Select}
					defaultValue={defaultValues && defaultValues.defaultIndication}
					name="indication"
					placeholder="Select indication..."
					className="react-select-container"
					classNamePrefix="react-select"
					styles={customStylesSelect}
					ref={register({ required: true })}
					rules={{ required: true, message: "This field is required" }}
				/>
			</div>
			<div className="indication">
				<FormLabel textAlign="center">Organization</FormLabel>
				<Controller
					control={control}
					options={selectData.organizations}
					as={Select}
					defaultValue={defaultValues && defaultValues.organization}
					name="organization"
					placeholder="Select organization..."
					className="react-select-container"
					classNamePrefix="react-select"
					styles={customStylesSelect}
					isClearable={true}
				/>
			</div>
			<div className="btns-wrapper">
				<CustomButton type="save" />
				<CustomButton type="cancel" onClick={() => cancel()} />
			</div>
		</FormWrapper>
	)
}

const FormWrapper = styled.form`
	text-align: center;
	width: 843px;
	height: 550px;
	padding: 10px 20px;

	input {
		padding: 15px;
		color: var(--black);
		height: 40px;
		width: 100%;
	}

	.react-select-container {
		width: 100%;
		height: 40px;
		margin: 0 auto;
		text-align: left;

		.react-select__control {
			border-radius: 4px;
			background: var(--white);
		}

		.react-select__value-container {
			max-height: 70px;
			overflow-y: auto;
		}
		.react-select__multi-value__remove {
			color: var(--blue);
		}
		.react-select__input {
			height: 40px;
		}
		.react-select__placeholder {
			color: var(--blue);
			font-weight: 400;
		}
		.react-select__menu-list {
			height: 160px;
		}
		.react-select__single-value {
			color: var(--black);
		}
	}
	.row-section {
		display: flex;
		column-gap: 20px;
		margin-bottom: 20px;
		justify-content: center;
		width: 100%;

		.treatment-line,
		.combination-code {
			width: 50%;
		}

		.drugs {
			width: 100%;
		}
	}
	.indication {
		margin-bottom: 20px;
	}
	label {
		display: block;
		padding: 8px 0 5px 0;
		color: var(--white);
		font-size: 16px;
	}
	/* button {
        margin: 30px 0 20px 30px;
    }
    .cancelBtn {
        margin-left: 155px;
        margin-right: 30px;
    } */
	.btns-wrapper {
		display: flex;
		width: 100%;
		height: 130px;
		justify-content: center;
		align-items: flex-end;
		grid-gap: 20px;
	}
`
export default DrugIndicationForm
