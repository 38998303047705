import axios from "axios"
import update from "immutability-helper"
import {
	fetchOneTreatmentURL,
	fetchTreatmentTablesData,
	schemasURL,
	subPosologiesURL,
	treatmentRemoveURL,
	treatmentsFilterByDrugIndicationURL,
	treatmentsPagesURL,
	treatmentsURL,
} from "../api_urls"

// export const fetchTreatments = (token) => async (dispatch) => {
//   dispatch({ type: "TREATMENT/START_REQUEST" });
//   try {
//     const response = await axios.get(treatmentsURL(), authHeader(token));
//     dispatch({
//       type: "TREATMENT/FETCH_SUCCESS",
//       payload: response.data.treatments,
//     });
//     dispatch({ type: "TREATMENT/CLEAN_MESSAGE" });
//   } catch (error) {
//     dispatch({ type: "REQUEST/REQUEST_FAIL", error });
//     dispatch({ type: "TREATMENT/CLEAN_MESSAGE" });
//   }
// };

export const fetchPage = (pageNumber, pageSize, filterData) => async (dispatch) => {
	let filterUrl = treatmentsPagesURL(pageNumber, pageSize)
	if (filterData) {
		if (filterData["product_name"]) {
			filterUrl += "&drug=" + filterData["product_name"]
		}
		if (filterData.drugIndication) {
			filterUrl += "&drug_indication=" + filterData.drugIndication
		}
		if (filterData.indication) {
			filterUrl += "&indication=" + filterData.indication
		}
	}

	dispatch({ type: "TREATMENT/START_REQUEST" })
	try {
		const response = await axios.get(filterUrl)
		dispatch({
			type: "TREATMENT/FETCH_SUCCESS",
			payload: response.data,
		})
	} catch (error) {
		dispatch({ type: "TREATMENT/REQUEST_FAIL", error })
		dispatch({ type: "TREATMENT/CLEAN_MESSAGE" })
	}
}

export const fetchFilteredPage = (pageNumber, pageSize, drugIndication) => async (dispatch) => {
	dispatch({ type: "TREATMENT/START_REQUEST" })
	try {
		const response = await axios.get(treatmentsFilterByDrugIndicationURL(pageNumber, pageSize, drugIndication))
		dispatch({
			type: "TREATMENT/FILTERED/FETCH_SUCCESS",
			payload: response.data,
		})
	} catch (error) {
		dispatch({ type: "TREATMENT/REQUEST_FAIL", error })
		dispatch({ type: "TREATMENT/CLEAN_MESSAGE" })
	}
}

export const fetchSubPosologies = () => async (dispatch) => {
	dispatch({ type: "TREATMENT/START_REQUEST" })
	try {
		const response = await axios.get(subPosologiesURL)
		dispatch({
			type: "TREATMENT/SUB_POSOLOGIES/FETCH_SUCCESS",
			payload: response.data,
		})
	} catch (error) {
		dispatch({ type: "TREATMENT/REQUEST_FAIL", error })
		dispatch({ type: "TREATMENT/CLEAN_MESSAGE" })
	}
}

export const setDefaultSize = (size) => async (dispatch) => {
	dispatch({ type: "TREATMENT/CHANGE_SIZE", payload: size })
}

export const fetchTreatment = (id) => async (dispatch) => {
	dispatch({ type: "TREATMENT/START_REQUEST" })
	try {
		const response = await axios.get(fetchOneTreatmentURL(id))
		dispatch({
			type: "TREATMENT/FETCH_ONE_SUCCESS",
			payload: response.data,
		})
		dispatch({ type: "TREATMENT/CLEAN_MESSAGE" })
	} catch (error) {
		dispatch({ type: "TREATMENT/REQUEST_FAIL", error })
		dispatch({ type: "TREATMENT/CLEAN_MESSAGE" })
	}
}

export const createTreatment = (data) => async (dispatch) => {
	dispatch({ type: "TREATMENT/START_REQUEST" })
	try {
		const response = await axios.post(treatmentsURL, data)
		dispatch({
			type: "TREATMENT/REQUEST_SUCCESS",
			payload: response.data.message,
		})
		dispatch({ type: "TREATMENT/CLEAN_MESSAGE" })
	} catch (error) {
		dispatch({ type: "TREATMENT/REQUEST_FAIL", error })
		dispatch({ type: "TREATMENT/CLEAN_MESSAGE" })
	}
}

export const removeTreatment = (id) => async (dispatch) => {
	dispatch({ type: "TREATMENT/START_REQUEST" })
	try {
		const response = await axios.delete(treatmentRemoveURL(id))
		dispatch({
			type: "TREATMENT/REQUEST_SUCCESS",
			payload: response.data.message,
		})
		dispatch({ type: "TREATMENT/CLEAN_MESSAGE" })
	} catch (error) {
		dispatch({ type: "TREATMENT/REQUEST_FAIL", error })
		dispatch({ type: "TREATMENT/CLEAN_MESSAGE" })
	}
}

export const updateTreatmentScheme = (data) => async (dispatch) => {
	dispatch({ type: "TREATMENT/START_REQUEST" })
	try {
		const response = await axios.put(schemasURL, data)
		dispatch({
			type: "TREATMENT/REQUEST_SUCCESS",
			payload: response.data.message,
		})
		dispatch({ type: "TREATMENT/CLEAN_MESSAGE" })
		return response
	} catch (error) {
		dispatch({ type: "TREATMENT/REQUEST_FAIL", error })
		dispatch({ type: "TREATMENT/CLEAN_MESSAGE" })
	}
}

export const updateTreatmentCycles = (data) => async (dispatch) => {
	dispatch({ type: "TREATMENT/START_REQUEST" })
	try {
		const response = await axios.put(treatmentsURL, data)
		dispatch({
			type: "TREATMENT/REQUEST_SUCCESS",
			payload: response.data.message,
		})
		dispatch({ type: "TREATMENT/CLEAN_MESSAGE" })
	} catch (error) {
		dispatch({ type: "TREATMENT/REQUEST_FAIL", error })
		dispatch({ type: "TREATMENT/CLEAN_MESSAGE" })
	}
}

export const fetchTreatmentTableData = (id) => async (dispatch) => {
	dispatch({ type: "TREATMENT/START_REQUEST" })
	try {
		const response = await axios.get(fetchTreatmentTablesData(id))
		dispatch({
			type: "TREATMENT/FETCH_TABLES_SUCCESS",
			payload: response.data,
		})
		dispatch({ type: "TREATMENT/CLEAN_MESSAGE" })
		return response
	} catch (error) {
		dispatch({ type: "TREATMENT/REQUEST_FAIL", error })
		dispatch({ type: "TREATMENT/CLEAN_MESSAGE" })
	}
}

export const setFormWeekDoses = (formIndex, weekDoses) => async (dispatch) => {
	dispatch({
		type: "TREATMENT/SET_WEEK_DOSES",
		weekDoses: weekDoses,
		formIndex: formIndex,
	})
}

export const addNewForm = (payload) => async (dispatch) => {
	dispatch({ type: "TREATMENT/ADD_FORM", payload: payload })
}

export const deleteForm = (formIndex) => async (dispatch) => {
	dispatch({ type: "TREATMENT/DELETE_FORM", formIndex: formIndex })
}

export const resetCycleForms = () => async (dispatch) => {
	dispatch({ type: "TREATMENT/RESET_FORMS" })
}

export const setCurrentId = (number) => async (dispatch) => {
	dispatch({ type: "TREATMENT/SET_CURRENT_ID", payload: number })
}

export const setWeeksPerCycle = (number) => async (dispatch) => {
	dispatch({ type: "TREATMENT/SET_WEEKS_PER_CYCLE", payload: number })
}

export const updateCurrentWeekInput = (formIndex, weekInputIndex, value) => async (dispatch) => {
	dispatch({
		type: "TREATMENT/UPDATE_CURRENT_WEEK_INPUT",
		formIndex: formIndex,
		weekInputIndex: weekInputIndex,
		value: value,
	})
}

export const updateCurrentDosagePattern = (formIndex, value) => async (dispatch) => {
	dispatch({
		type: "TREATMENT/UPDATE_CURRENT_DOSAGE_PATTERN",
		formIndex: formIndex,
		value: value,
	})
}

export const updateCurrentInfinite = (formIndex, value) => async (dispatch) => {
	dispatch({
		type: "TREATMENT/UPDATE_CURRENT_INFINITE",
		formIndex: formIndex,
		value: Boolean(value),
	})
}

export const cleanDataByDrugIndication = () => async (dispatch) => {
	dispatch({
		type: "TREATMENT/CLEAN_DRUG_INDICATION_DATA",
	})
}

const initState = {
	data: [],
	subPosologies: [],
	dataByDrugIndication: [],
	currentTreatment: null,
	tables: [],
	forms: [{ id: 0, weekDoses: [], isInfinite: false, dosagePattern: "" }],
	currentId: 0,
	weeksPerCycle: 0,
	pagination: null,
	defaultSize: 200,
	message: null,
	loading: false,
}

export const treatmentsReducer = (state = initState, action) => {
	switch (action.type) {
		case "TREATMENT/START_REQUEST":
			return {
				...state,
				loading: true,
				message: null,
			}
		case "TREATMENT/FETCH_SUCCESS":
			return {
				...state,
				loading: false,
				data: action.payload.treatments,
				pagination: action.payload.pagination,
			}
		case "TREATMENT/FILTERED/FETCH_SUCCESS":
			return {
				...state,
				loading: false,
				dataByDrugIndication: action.payload.treatments,
			}
		case "TREATMENT/FETCH_ONE_SUCCESS":
			return {
				...state,
				loading: false,
				currentTreatment: action.payload.treatment,
			}

		case "TREATMENT/SUB_POSOLOGIES/FETCH_SUCCESS":
			return {
				...state,
				subPosologies: action.payload,
			}
		case "TREATMENT/FETCH_TABLES_SUCCESS":
			return {
				...state,
				loading: false,
				tables: action.payload,
			}
		case "TREATMENT/REQUEST_SUCCESS":
			return {
				...state,
				loading: false,
				message: action.payload,
			}

		case "TREATMENT/REQUEST_FAIL":
			if (action.error.response !== undefined) {
				if (action.error.response.data.message === undefined) {
					const serverError = { text: "Server error!", type: "error" }
					return {
						...state,
						message: serverError,
						loading: false,
					}
				} else {
					return {
						...state,
						message: action.error.response.data.message,
						loading: false,
					}
				}
			} else {
				const serverError = { text: "Server error!", type: "error" }
				return {
					...state,
					message: serverError,
					loading: false,
				}
			}
		case "TREATMENT/CLEAN_MESSAGE":
			return {
				...state,
				message: null,
			}
		case "TREATMENT/CLEAN_DRUG_INDICATION_DATA":
			return {
				...state,
				dataByDrugIndication: [],
			}
		case "TREATMENT/RESET_TREATMENT":
			return {
				...state,
				currentTreatment: null,
			}

		case "TREATMENT/RESET_FORMS":
			return {
				...state,
				forms: [{ id: 0, weekDoses: [], isInfinite: false, dosagePattern: "" }],
			}
		case "TREATMENT/SET_WEEK_DOSES":
			return update(state, {
				forms: {
					[action.formIndex]: {
						weekDoses: { $set: action.weekDoses },
					},
				},
			})

		case "TREATMENT/CHANGE_SIZE":
			return {
				...state,
				defaultSize: action.payload,
			}
		case "TREATMENT/ADD_FORM":
			return {
				...state,
				forms: action.payload,
			}
		case "TREATMENT/DELETE_FORM":
			return {
				...state,
				forms: [...state.forms.slice(0, action.formIndex), ...state.forms.slice(action.formIndex + 1)],
			}
		case "TREATMENT/SET_CURRENT_ID":
			return {
				...state,
				currentId: parseInt(action.payload),
			}
		case "TREATMENT/SET_WEEKS_PER_CYCLE":
			return {
				...state,
				weeksPerCycle: parseInt(action.payload),
			}
		case "TREATMENT/UPDATE_CURRENT_WEEK_INPUT":
			return update(state, {
				forms: {
					[action.formIndex]: {
						weekDoses: {
							[action.weekInputIndex]: {
								value: { $set: action.value },
							},
						},
					},
				},
			})
		case "TREATMENT/UPDATE_CURRENT_DOSAGE_PATTERN":
			return update(state, {
				forms: {
					[action.formIndex]: {
						dosagePattern: { $set: action.value },
					},
				},
			})
		case "TREATMENT/UPDATE_CURRENT_INFINITE":
			return update(state, {
				forms: {
					[action.formIndex]: {
						isInfinite: { $set: action.value },
					},
				},
			})
		default:
			return state
	}
}
