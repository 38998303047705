import { Button } from "@chakra-ui/button"
import { useDisclosure } from "@chakra-ui/hooks"
import { useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import { productName } from "../../redux/api_urls"
import useMasterData from "../../redux/hooks/useMasterData"
import { superAdminAccess } from "../../utils/constants"
import { checkWriteCondition, handleOrganizationPayload, handleResponse, makeStringOutOfArray } from "../../utils/functions"
import { makeDropdownOptions, makeIdsArray } from "../forms/calcHelper"
import ProductNameForm from "../forms/ProductNameForm"
import CustomModal from "../modals/CustomModal"
import DeleteModal from "../modals/DeleteModal"
import { trueOrFalse } from "./tableHelper"
import { TablesStyles } from "./TableStyles"

const ProductNameTable = ({ selectData, role, parentData, organizationId }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()

	const hook = useMasterData()

	const [updateData, setUpdateData] = useState(null)

	let data = hook.productNames

	const indexedData = data.map((d, i) => {
		return {
			num: i + 1,
			id: d.id,
			code: d.code,
			name: d.name,
			color: d.color,
			secondary_code: d.secondary_code,
			cluster_drugs: d.cluster_drugs,
			is_cluster: d.is_cluster,
			cluster_drugs_values: d.cluster_drugs_values,
			is_monotherapy: d.is_monotherapy,
			monotherapy_exists: d.monotherapy_exists,
			organization: d.organization,
			organization_name: d.organization_name,
		}
	})

	const columns = [
		{
			dataField: "num",
			text: "No.",
			headerStyle: () => {
				return { width: "3%" }
			},
			formatter: (cell) => {
				return `${cell}.`
			},
		},
		{
			dataField: "code",
			text: "Code",
			headerStyle: () => {
				return { width: "8%" }
			},
		},
		{
			dataField: "secondary_code",
			text: "Secondary code",
		},
		{
			dataField: "name",
			text: "Name",
		},

		{
			dataField: "color",
			text: "Color",
		},
		{
			dataField: "cluster_drugs_values",
			text: "Cluster drugs",
			formatter: (cell) => makeStringOutOfArray(cell),
		},
		{
			dataField: "is_cluster",
			text: "Cluster",
			headerStyle: () => {
				return { width: "7%" }
			},
			formatter: (cell) => trueOrFalse(cell),
		},
		{
			dataField: "is_monotherapy",
			text: "Monotherapy",
			headerStyle: () => {
				return { width: "7%" }
			},
			formatter: (cell) => trueOrFalse(cell),
		},
		{
			dataField: "monotherapy_exists",
			text: "Monotherapy exists",
			headerStyle: () => {
				return { width: "7%" }
			},
			formatter: (cell) => trueOrFalse(cell),
		},
	]

	if (superAdminAccess.includes(role)) {
		columns.splice(1, 0, {
			dataField: "organization_name",
			text: "Organization",
		})
	}

	const handleSuccess = () => {
		hook.fetch(productName)
		onClose()
	}

	const handleDelete = (id) => {
		hook.remove(productName, id).then((res) => handleResponse(res, handleSuccess))
	}

	const expandRow = {
		renderer: (row) => (
			<div className="btns-container">
				<Button variant="orange" onClick={() => handleOpenUpdate(row)}>
					Update
				</Button>
				<DeleteModal remove={() => handleDelete(row.id)} />
			</div>
		),
		className: "expandedRow",
		parentClassName: "parentExpandedRow",
	}

	const handleOpenUpdate = (row) => {
		let defaultValues = {
			id: row.id,
			code: row.code,
			secondary_code: row.secondary_code,
			name: row.name,
			color: row.color,
			cluster_drugs: makeDropdownOptions(row.cluster_drugs_values),
			is_cluster: row.is_cluster,
			is_monotherapy: row.is_monotherapy,
			monotherapy_exists: row.monotherapy_exists,
		}

		if (superAdminAccess.includes(role)) {
			defaultValues["organization"] = selectData.organizations.find((ele) => ele.value === Number(row.organization))
		}

		setUpdateData(defaultValues)
		onOpen()
	}

	const submit = (data) => {
		let clusterDrugs = []
		if (data.cluster_drugs) {
			clusterDrugs = makeIdsArray(data.cluster_drugs)
		}

		let payload = {
			id: updateData.id,
			code: data.code,
			name: data.name,
			secondary_code: data.secondary_code,
			color: data.color,
			cluster_drugs: clusterDrugs,
			is_cluster: data.is_cluster,
			is_monotherapy: data.is_monotherapy,
			monotherapy_exists: data.monotherapy_exists,
		}

		handleOrganizationPayload(payload, role, organizationId, data.organization)

		hook.update(productName, payload).then((res) => handleResponse(res, handleSuccess))
	}

	return (
		<>
			<TablesStyles minWidth={"2000px"}>
				<BootstrapTable
					data={indexedData}
					columns={columns}
					keyField="id"
					classes="TablesStyles"
					expandRow={checkWriteCondition(role, parentData) && expandRow}
					bordered
					bootstrap4
				/>
			</TablesStyles>

			<CustomModal isOpen={isOpen} onClose={onClose} size="4xl">
				<ProductNameForm defaultValues={updateData} selectData={selectData} onSubmit={submit} cancel={onClose} role={role} />
			</CustomModal>
		</>
	)
}

export default ProductNameTable
