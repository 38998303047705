import {
	Button,
	Flex,
	FormControl,
	FormLabel,
	Input,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Stack,
} from "@chakra-ui/react"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import Select from "react-select"
import styled from "styled-components"
import useTreatment from "../../redux/hooks/useTreatment"
import { superAdminAccess } from "../../utils/constants"
import { customStylesSelect } from "../CustomSelectStyles"
import PositiveDecimalInput from "../inputs/PositiveDecimalInput"

const TreatmentForm = ({ submit, selectData, defaultValues, cancel, role }) => {
	const hook = useTreatment()

	const history = useHistory()
	const { register, handleSubmit, control } = useForm()

	const {
		dosage,
		dosage_per_kg,
		dosage_per_bsa,
		weeks_per_cycle,
		helper_code,
		defaultGenericName,
		defaultProductName,
		defaultIndication,
		defaultRoutes,
		defaultSubPosologies,
		id,
	} = defaultValues

	const handleEditCycles = (e) => {
		e.preventDefault()
		hook.fetchOne(id).then(() => {
			setTimeout(() => {
				history.push(`/posologies/update/${id}`)
			}, 1000)
		})
	}

	const generateTables = () => {
		if (Object.keys(hook.tables).length !== 0) {
			const infiniteCyclesArray = [hook.tables.metadata.infinite_cycle_numbers][0]
			const headerTable = hook.tables.table_header.map((row, i) => {
				return (
					<tr key={i}>
						{row.map((td, i) => (
							<td key={i} className={infiniteCyclesArray.includes(i) ? "infinite-msg" : ""}>
								{td}
							</td>
						))}
					</tr>
				)
			})

			const table = hook.tables.table.rows.map((row, i) => {
				return (
					<tr key={i}>
						{row.map((td, i) => (
							<td key={i} className={infiniteCyclesArray.includes(i) ? "infinite-msg" : ""}>
								{td}
							</td>
						))}
					</tr>
				)
			})
			return (
				<InfoSection>
					<div className="doses"></div>
					<div className="table-Frame">
						<table>
							<tbody>
								{headerTable}
								{table}
							</tbody>
						</table>
					</div>

					<p className="infinite-msg">* Infinite cycles are marked red</p>
				</InfoSection>
			)
		} else {
			return null
		}
	}

	return (
		<TreatmentsForm onSubmit={handleSubmit(submit)}>
			<Stack spacing={8} mb={12}>
				<Flex gridGap="20px" direction={["column", "row"]}>
					<Flex w="100%" gridGap="20px" direction={["column", "column", "row"]}>
						<FormControl>
							<FormLabel color="white">Dosage</FormLabel>
							<Controller
								control={control}
								as={PositiveDecimalInput}
								name={"dosage"}
								defaultValue={!dosage ? undefined : dosage}
								placeholder="Insert dosage..."
							/>
						</FormControl>
						<FormControl>
							<FormLabel color="white">Dosage per kilogram</FormLabel>
							<Controller
								control={control}
								as={PositiveDecimalInput}
								name={"dosage_per_kg"}
								defaultValue={!dosage_per_kg ? undefined : dosage_per_kg}
								placeholder="Insert dosage..."
							/>
						</FormControl>
					</Flex>
					<Flex w="100%" gridGap="20px" direction={["column", "column", "row"]}>
						<FormControl>
							<FormLabel color="white">Dosage per BSA</FormLabel>
							<Controller
								control={control}
								as={PositiveDecimalInput}
								name={"dosage_per_bsa"}
								defaultValue={!dosage_per_bsa ? undefined : dosage_per_bsa}
								placeholder="Insert dosage..."
							/>
						</FormControl>
						<FormControl>
							<FormLabel color="white">Weeks per cycle</FormLabel>
							<NumberInput
								w="100%"
								bg="white"
								_placeholder={{ color: "black" }}
								color="black"
								borderRadius={4}
								type="number"
								defaultValue={weeks_per_cycle}
								name="weeks_per_cycle"
								placeholder="Insert weeks..."
							>
								<NumberInputField ref={register({ required: true })} />
								<NumberInputStepper>
									<NumberIncrementStepper />
									<NumberDecrementStepper />
								</NumberInputStepper>
							</NumberInput>
						</FormControl>
					</Flex>
				</Flex>
				<Flex gridGap="20px">
					<Flex w="100%" gridGap="20px" direction={["column", "column", "row"]}>
						<FormControl>
							<FormLabel color="white">Product name</FormLabel>
							<Controller
								control={control}
								as={Select}
								name="product_name"
								defaultValue={defaultProductName}
								options={selectData.productNames}
								placeholder="Select product name..."
								className="react-select-container"
								classNamePrefix="react-select"
								styles={customStylesSelect}
							/>
						</FormControl>
						<FormControl>
							<FormLabel color="white">Generic name</FormLabel>
							<Controller
								control={control}
								as={Select}
								name="generic_name"
								defaultValue={defaultGenericName}
								options={selectData.genericNames}
								placeholder="Select generic name..."
								className="react-select-container"
								classNamePrefix="react-select"
								styles={customStylesSelect}
							/>
						</FormControl>
					</Flex>
					<Flex w="100%" gridGap="20px" direction={["column", "column", "row"]}>
						<FormControl>
							<FormLabel color="white">Sub posologies</FormLabel>
							<Controller
								isMulti
								control={control}
								as={Select}
								name="sub_posologies"
								options={selectData.subPosologies}
								placeholder="Select sub posologies..."
								className="react-select-container"
								classNamePrefix="react-select"
								styles={customStylesSelect}
								defaultValue={defaultSubPosologies}
							/>
						</FormControl>
						<FormControl>
							<FormLabel color="white">Helper code</FormLabel>
							<Input
								w="100%"
								bg="white"
								color="black"
								borderRadius={4}
								_placeholder={{ color: "black" }}
								type="text"
								name="helper_code"
								ref={register}
								defaultValue={helper_code}
							/>
						</FormControl>
					</Flex>
				</Flex>
				<Flex gridGap="20px" direction={["column", "column", "row"]}>
					<FormControl>
						<FormLabel color="white">Indication</FormLabel>
						<Controller
							control={control}
							as={Select}
							name="indication"
							defaultValue={defaultIndication}
							options={selectData.drugIndications}
							placeholder="Select indication..."
							className="react-select-container"
							classNamePrefix="react-select"
							styles={customStylesSelect}
						/>
					</FormControl>
					<FormControl>
						<FormLabel color="white">Routes of administration</FormLabel>
						<Controller
							isMulti
							control={control}
							as={Select}
							name="allowed_routes"
							options={selectData.routes}
							placeholder="Select routes of administration..."
							className="react-select-container"
							classNamePrefix="react-select"
							styles={customStylesSelect}
							defaultValue={defaultRoutes}
						/>
					</FormControl>
				</Flex>
				{superAdminAccess.includes(role) && (
					<Flex width={"50%"} direction={["column", "column", "row"]}>
						<FormControl>
							<FormLabel textAlign="center" color="white">
								Organization
							</FormLabel>
							<Controller
								control={control}
								options={selectData.organizations}
								as={Select}
								defaultValue={defaultValues && defaultValues.organization}
								name="organization"
								placeholder="Select organization..."
								className="react-select-container"
								classNamePrefix="react-select"
								styles={customStylesSelect}
								isClearable={true}
							/>
						</FormControl>
					</Flex>
				)}
			</Stack>

			{generateTables()}
			<Flex w="100%" justify="space-between" mt={8}>
				<Flex gridGap="20px">
					<Button variant="green" type="submit">
						Save
					</Button>
					<Button variant="orange" type="button" onClick={(e) => handleEditCycles(e)}>
						Edit cycles
					</Button>
				</Flex>
				<Button type="button" onClick={() => cancel()}>
					Close
				</Button>
			</Flex>
		</TreatmentsForm>
	)
}

const InfoSection = styled.div`
	color: var(--white);

	table {
		display: block;
		table-layout: fixed;

		border-collapse: collapse;
		overflow-y: auto;
		white-space: nowrap;
		&::-webkit-scrollbar-track {
			background: var(--blue);
			width: 100%;
		}
		&::-webkit-scrollbar-thumb {
			background: var(--white);
		}
	}
	tbody {
		display: table;
		width: 99%;
	}

	td {
		border: 1px solid var(--white);
		font-size: 14px;
		text-align: center;
		padding: 5px;
		min-width: 50px;
	}
	tr td:first-child {
		width: 150px !important;
		font-size: 16px;
	}
	.infinite-msg {
		color: var(--red) !important;
		margin-top: 5px;
	}
`

const TreatmentsForm = styled.form`
	width: 1400px;
	margin: 60px 0;

	.react-select-container {
		.react-select__control {
			color: var(--black);
			background: var(--white);
		}
		.react-select__placeholder {
			color: var(--black);
		}

		.react-select__single-value {
			color: var(--black);
		}
	}
`

export default TreatmentForm
