import { Button } from "@chakra-ui/button"
import { useDisclosure } from "@chakra-ui/hooks"
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
} from "@chakra-ui/modal"
import React, { useRef } from "react"
import CustomButton from "../buttons/CustomButton"

const DeleteModal = ({ remove, user }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const cancelRef = useRef()

	const handleDelete = () => {
		remove()
		onClose()
	}
	return (
		<>
			<Button variant="red" onClick={onOpen}>
				Delete
			</Button>
			<AlertDialog motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered>
				<AlertDialogOverlay />

				<AlertDialogContent bgColor={"blue"}>
					<AlertDialogHeader color="white" alignSelf="center">
						Are you sure?
					</AlertDialogHeader>
					<AlertDialogBody color={"white"} alignSelf="center" textAlign="center">
						If you delete this {user ? `user` : `item`},
						<br /> you won't be able to restore data!
					</AlertDialogBody>
					<AlertDialogFooter alignSelf="center" gridGap="50px">
						<CustomButton type="cancel" onClick={onClose} />
						<CustomButton type="delete" onClick={() => handleDelete()} />
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</>
	)
}

export default DeleteModal
