import { useDisclosure } from "@chakra-ui/hooks"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { ReactComponent as AddButton } from "../assets/add-button.svg"
import { makeDropdownOptions, makeIdsArray } from "../components/forms/calcHelper"
import GenericNameForm from "../components/forms/GenericNameForm"
import Loading from "../components/frames/Loading"
import TableFrame from "../components/frames/TableFrame"
import Layout from "../components/layout/Layout"
import CustomModal from "../components/modals/CustomModal"
import generateToast from "../components/notifications/ToastGenerator"
import GenericNameTable from "../components/tables/GenericNameTable"
import { genericName } from "../redux/api_urls"
import useMasterData from "../redux/hooks/useMasterData"
import useOrganization from "../redux/hooks/useOrganization"
import useUser from "../redux/hooks/useUser"
import { superAdminAccess } from "../utils/constants"
import { checkWriteCondition, handleOrganizationPayload, handleResponse } from "../utils/functions"

const GenericNamePage = () => {
	const { isOpen, onOpen, onClose } = useDisclosure()

	const hook = useMasterData()
	const hookOrg = useOrganization()
	const role = useUser().role
	const parentData = useUser().useParentData
	const organizationId = useUser().organizationId

	const [genericNames, setGenericNames] = useState([])
	const [organizations, setOrganizations] = useState([])

	useEffect(() => {
		hook.fetch(genericName)
		if (superAdminAccess.includes(role)) {
			hookOrg.fetchDropdown()
		}
	}, []) // eslint-disable-line

	useEffect(() => {
		if (hook.message) {
			generateToast(hook.message)
		}
	}, [hook.message])

	useEffect(() => {
		setGenericNames(makeDropdownOptions(hook.genericNames))
		setOrganizations(makeDropdownOptions(hookOrg.dropdownData, "name"))
	}, [hook.genericNames, hookOrg.dropdownData])

	const submit = (data) => {
		let clusterGenerics = []
		if (data.cluster_generics) {
			clusterGenerics = makeIdsArray(data.cluster_generics)
		}
		let payload = {
			code: data.code,
			name: data.name,
			color: data.color,
			cluster_generics: clusterGenerics,
			is_cluster: data.is_cluster,
			is_monotherapy: data.is_monotherapy,
			monotherapy_exists: data.monotherapy_exists,
		}

		handleOrganizationPayload(payload, role, organizationId, data.organization)

		const handleSuccess = () => {
			hook.fetch(genericName)
			onClose()
		}

		hook.create(genericName, payload).then((res) => handleResponse(res, handleSuccess))
	}

	const selectData = {
		genericNames: genericNames,
		organizations: organizations,
	}

	return (
		<Layout>
			<PageContainer>
				<TableFrame>
					<GenericNameTable selectData={selectData} role={role} parentData={parentData} organizationId={organizationId} />
					{hook.loading && <Loading />}
				</TableFrame>
				{checkWriteCondition(role, parentData) && <AddButton className="addBtn" onClick={() => onOpen()} />}
			</PageContainer>
			<CustomModal isOpen={isOpen} onClose={onClose} size="3xl">
				<GenericNameForm selectData={selectData} onSubmit={submit} cancel={onClose} role={role} />
			</CustomModal>
		</Layout>
	)
}

export default GenericNamePage

const PageContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: hidden;
	background: var(--white);
`
