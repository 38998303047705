import ApiKeyPage from "../pages/ApiKeyPage"
import BsaConfigPage from "../pages/BsaConfigPage"
import CreateTreatmentPage from "../pages/CreateTreatmentPage"
import CurrencyConfigPage from "../pages/CurrencyConfigPage"
import DrugIndicationPage from "../pages/DrugIndicationPage"
import EditTreatmentPage from "../pages/EditTreatmentPage"
import GenericNamePage from "../pages/GenericNamePage"
import IndicationApprovalPage from "../pages/IndicationApprovalPage"
import LoginPage from "../pages/Login"
import MasterDataPage from "../pages/MasterDataPage"
import NotFoundPage from "../pages/NotFound"
import OrganizationPage from "../pages/OrganizationPage"
import PackagePage from "../pages/PackagePage"
import PricePage from "../pages/PricePage"
import ProductNamePage from "../pages/ProductNamePage"
import ProfilePage from "../pages/ProfilePage"
import ReferencesPage from "../pages/ReferencesPage"
import TreatmentPage from "../pages/TreatmentPage"
import UserPage from "../pages/UserPage"
import { company, country, currency, dosagePattern, indicationMd, route, treatmentLineMd } from "../redux/api_urls"

const CountryPage = () => {
	return <MasterDataPage type={country} />
}

const CurrencyPage = () => {
	return <MasterDataPage type={currency} />
}

const DosagePatternPage = () => {
	return <MasterDataPage type={dosagePattern} />
}

const CompanyPage = () => {
	return <MasterDataPage type={company} />
}

const RoutePage = () => {
	return <MasterDataPage type={route} />
}

const TreatmentLinePage = () => {
	return <MasterDataPage type={treatmentLineMd} />
}

const IndicationPage = () => {
	return <MasterDataPage type={indicationMd} />
}

const CurrencyDynamic = () => {
	return <CurrencyConfigPage staticPage={false} />
}

const CurrencyStatic = () => {
	return <CurrencyConfigPage staticPage={true} />
}

const routes = [
	{
		path: "/",
		component: TreatmentPage,
		// isPrivate: true,
		exact: true,
	},
	{
		path: "/posologies",
		component: TreatmentPage,
		isPrivate: true,
	},
	{
		path: "/posologies/add",
		component: CreateTreatmentPage,
		isPrivate: true,
		isWriteMode: true,
	},
	{
		path: "/posologies/update/:id",
		component: EditTreatmentPage,
		isPrivate: true,
		isWriteMode: true,
	},
	{
		path: "/login",
		component: LoginPage,
		isPrivate: false,
	},
	{
		path: "/organizations",
		component: OrganizationPage,
		isPrivate: true,
		isSuperAdmin: true,
	},
	{
		path: "/api-keys",
		component: ApiKeyPage,
		isPrivate: true,
		isSuperAdmin: true,
	},
	{
		path: "/config/bsa-weight",
		component: BsaConfigPage,
		isPrivate: true,
		exact: true,
		isSuperAdmin: true,
	},
	{
		path: "/config/currency-rates/static",
		component: CurrencyStatic,
		isPrivate: true,
		exact: true,
	},
	{
		path: "/config/currency-rates/dynamic",
		component: CurrencyDynamic,
		isPrivate: true,
		exact: true,
	},
	{
		path: "/master-data/product-names",
		component: ProductNamePage,
		isPrivate: true,
	},
	{
		path: "/master-data/generic-names",
		component: GenericNamePage,
		isPrivate: true,
	},
	{
		path: "/master-data/route-of-administration",
		component: RoutePage,
		isPrivate: true,
		isSuperAdmin: true,
	},
	{
		path: "/master-data/country",
		component: CountryPage,
		isPrivate: true,
		isSuperAdmin: true,
	},
	{
		path: "/master-data/company",
		component: CompanyPage,
		isPrivate: true,
		isSuperAdmin: true,
	},
	{
		path: "/master-data/dosage-pattern",
		component: DosagePatternPage,
		isPrivate: true,
		isSuperAdmin: true,
	},
	{
		path: "/master-data/currency",
		component: CurrencyPage,
		isPrivate: true,
		isSuperAdmin: true,
	},
	{
		path: "/master-data/treatment-line",
		component: TreatmentLinePage,
		isPrivate: true,
	},
	{
		path: "/master-data/indication",
		component: IndicationPage,
		isPrivate: true,
	},
	{
		path: "/indication/drug",
		component: DrugIndicationPage,
		isPrivate: true,
	},
	{
		path: "/indication/approval",
		component: IndicationApprovalPage,
		isPrivate: true,
	},
	{
		path: "/drug/package",
		component: PackagePage,
		isPrivate: true,
	},
	{
		path: "/drug/price",
		component: PricePage,
		isPrivate: true,
	},
	{
		path: "/drug/reference",
		component: ReferencesPage,
		isPrivate: true,
	},
	{
		path: "/users",
		component: UserPage,
		isPrivate: true,
		isAdmin: true,
	},
	{
		path: "/profile",
		component: ProfilePage,
		isPrivate: true,
		isAdmin: false,
	},
	{
		path: "/*",
		component: NotFoundPage,
		isPrivate: false,
	},
]

export default routes
