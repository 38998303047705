import { Input } from "@chakra-ui/input"
import { Flex } from "@chakra-ui/layout"
import { Text } from "@chakra-ui/layout"
import { Box } from "@chakra-ui/layout"
import React, { useEffect } from "react"
import { useDropzone } from "react-dropzone"

const UploadFile = ({ onChange, format, files, setFiles, text }) => {
	function sizeConverter(a) {
		//eslint-disable-line
		let b = 0,
			c = parseInt(a, 10) || 0
		for (; 1024 <= c && ++b; ) c /= 1024
		return c.toFixed(10 > c && 0 < b ? 1 : 0) + " " + ["bytes", "KB", "MB"][b]
	}

	useEffect(() => {
		if (files.length > 0) {
			onChange(files[0])
		}
	}, [files]) //eslint-disable-line

	const { getRootProps, getInputProps } = useDropzone({
		maxSize: "32721205",
		multiple: false,
		// accept: "image/jpeg, image/png",
		accept: format,
		onDrop: (acceptedFiles) => {
			setFiles(
				acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			)
		},
	})
	//   const acceptedFileItems = acceptedFiles.map((file) => (
	//     <Text key={file.path}>{file.path}</Text>
	//   ));
	const file = files.map((file) => (
		<Flex key={file.name} gridGap="10px" h="60px" w="100%" overflow="hidden" alignItems="center" p="3px">
			<Text color="mc_bg" fontSize="14px" whiteSpace="nowrap">
				{/* {sizeConverter(file.size)} */}
			</Text>
		</Flex>
	))

	return (
		<Flex w="240px" h="40px" bg="#354f99" rounded="md" _focus={{ outline: "yellow" }} align="center" justify="center">
			<div {...getRootProps()}>
				<Input {...getInputProps()} />
				<Flex
					alignItems="center"
					gridGap="10px"
					justify="center"
					mb="5px"
					cursor="pointer"
					_focus={{ outline: "none", border: "none" }}
				>
					<Text color="#fff">{text}</Text>
					<Box>{file}</Box>
				</Flex>
			</div>
		</Flex>
	)
}

export default UploadFile
