import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { pricePagesURL } from "../api_urls"
import { createPrice, fetchPage, importPrices, removePrice, setDefaultSize, updatePrice } from "../reducers/price"

export default function usePrice() {
	const dispatch = useDispatch()
	const data = useSelector((state) => state.prices.data)
	const pagination = useSelector((state) => state.prices.pagination)
	const defaultSize = useSelector((state) => state.prices.defaultSize)
	const message = useSelector((state) => state.prices.message)
	const loading = useSelector((state) => state.prices.loading)
	const [modal, setModal] = useState(false)

	const create = async (data) => {
		return dispatch(createPrice(data))
	}

	const remove = async (id) => {
		return dispatch(removePrice(id))
	}

	const update = async (payload) => {
		return dispatch(updatePrice(payload))
	}

	const fetch = (pageNumber, pageSize, filterData) => {
		let sort = "id"
		if (filterData !== undefined && filterData && filterData.sortBy) {
			sort = filterData.sortBy
		}
		let filterUrl = pricePagesURL(pageNumber, pageSize, sort)
		if (filterData) {
			if (filterData.package) {
				filterUrl += "&drug_package=" + filterData.package
			}
			if (filterData.country) {
				filterUrl += "&country=" + filterData.country
			}
		}

		dispatch(fetchPage(filterUrl))
	}

	const changeSize = async (size) => {
		dispatch(setDefaultSize(size))
	}

	const importFile = async (file, exception) => {
		let formData = new FormData()
		formData.append("prices_file", file, file.name)
		formData.append("raise_no_drug_ex", exception)
		return dispatch(importPrices(formData))
	}

	return { fetch, create, importFile, remove, update, changeSize, data, loading, pagination, defaultSize, message, modal, setModal }
}
