import React from "react"
import Select from "react-select"

const CustomSelectAll = (props) => {
	const handleChange = (selected) => {
		if (selected !== null && selected.length > 0 && selected[selected.length - 1].value === props.labelValueObject.value) {
			return props.onChange(props.options)
		}
		return props.onChange(selected)
	}

	if (props.allowSelectAll) {
		return <Select {...props} options={[props.labelValueObject, ...props.options]} onChange={handleChange} />
	}

	return <Select {...props} />
}
CustomSelectAll.defaultProps = {
	labelValueObject: {
		value: "*",
		label: "SELECT ALL",
	},
}
export default CustomSelectAll
