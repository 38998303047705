import { Modal, ModalBody, ModalContent, ModalOverlay, ModalCloseButton } from "@chakra-ui/modal"
import { Flex } from "@chakra-ui/react"
import React from "react"

const CustomModal = ({ children, isOpen, onClose, size, modalCustomWidth, overlayClick, color, modalCloseButton }) => {
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size={size}
			closeOnOverlayClick={overlayClick}
			closeOnEsc={false}
			scrollBehavior={"inside"}
			isCentered
		>
			<ModalOverlay />
			<ModalContent bg={color ? color : "blue"} w={modalCustomWidth}>
				{modalCloseButton && <ModalCloseButton />}
				<ModalBody p={["10px", "24px"]}>
					<Flex w="100%" justify="center">
						{children}
					</Flex>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default CustomModal
