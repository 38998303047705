import React from "react"
import styled from "styled-components"
const TableFrame = ({ children }) => {
	return (
		<ContainerStyles>
			<div className="layer">
				<FrameTableStyles
					style={{
						height: "90vh",
					}}
				>
					{children}
				</FrameTableStyles>
			</div>
		</ContainerStyles>
	)
}
const ContainerStyles = styled.div`
	width: 100%;
	height: 93vh;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	.layer {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		position: relative;
		margin: 0 2%;
	}
`
const FrameTableStyles = styled.div`
	display: flex;
	direction: column;
	width: 100%;
	height: 85vh;
	border: 4px solid var(--blue);
	border-radius: 4px;
	overflow-y: auto;
`
export default TableFrame
