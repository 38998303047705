import { toast } from "react-toastify"

export const generateToast = (message) => {
	if (message.type === "warning") {
		return toast.warning(message.text, { autoClose: 5000 })
	} else if (message.type === "success") {
		return toast.success(message.text, { autoClose: 5000 })
	} else if (message.type === "error") {
		return toast.error(message.text, { autoClose: 5000 })
	}
}

export default generateToast
