import styled from "styled-components"

const Checkbox = ({ id, name, checked, onChange, ...props }) => {
	return (
		<CheckboxWrapper>
			<input id={id} name={name} type="checkbox" checked={checked} onChange={onChange} {...props} />
			<label htmlFor={id}></label>
		</CheckboxWrapper>
	)
}

const CheckboxWrapper = styled.div`
	width: 20px;
	height: 40px;
	position: relative;
	label {
		width: 20px;
		height: 20px;
		cursor: pointer;
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
		left: 0;
		background: #fcfff4;
		background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
		border-radius: 4px;
		box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
		&:after {
			content: "";
			width: 10px;
			height: 6px;
			position: absolute;
			top: 6px;
			left: 5.5px;
			border: 3px solid #333;
			border-top: none;
			border-right: none;
			background: transparent;
			opacity: 0;
			transform: rotate(-45deg);
		}
		&:hover::after {
			opacity: 0.5;
		}
	}
	input[type="checkbox"] {
		margin-bottom: 0;
		visibility: hidden;
		width: 20px;
		&:checked + label:after {
			opacity: 1;
		}
	}
`
export default Checkbox
