import { Button } from "@chakra-ui/react"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import styled from "styled-components"
import { ReactComponent as HidePassword } from "../../assets/hide-password.svg"
import { ReactComponent as ShowPassword } from "../../assets/show-password.svg"
import useUser from "../../redux/hooks/useUser"

const UpdateProfileForm = () => {
	const { register, handleSubmit } = useForm()
	const hook = useUser()
	const [currentPassShown, setCurrentPassShown] = useState(false)
	const [newPassShown, setNewPassShown] = useState(false)
	const [repeatPassShown, setRepeatPassShown] = useState(false)

	const toggleCurrentPassVisiblity = () => {
		setCurrentPassShown(currentPassShown ? false : true)
	}

	const toggleNewPassVisiblity = () => {
		setNewPassShown(newPassShown ? false : true)
	}

	const toggleRepeatPassVisiblity = () => {
		setRepeatPassShown(repeatPassShown ? false : true)
	}

	const submit = (data) => {
		if (data.new_password !== data.new_password_repeat) {
			toast.error("Passwords do not match")
		} else {
			const payload = {}
			payload["username"] = hook.username
			if (data.new_password) {
				payload["password"] = data.new_password
				payload["repeated_password"] = data.new_password_repeat
				payload["current_password"] = data.current_password
			}

			if (data.is_static) {
				let isStatic
				if (data.is_static === "false") {
					isStatic = false
				} else {
					isStatic = true
				}
				payload["is_static"] = isStatic
			}
			hook.change(payload)
		}
	}

	return (
		<FormWrapper>
			<form onSubmit={handleSubmit(submit)}>
				<h2 className="title">Update profile</h2>
				<Box>
					<div className="column-section">
						<label>Current password</label>
						<div className="pas-input-wrapper">
							<input
								name="current_password"
								ref={register}
								placeholder="Insert current password..."
								type={currentPassShown ? "text" : "password"}
							/>
							{currentPassShown ? (
								<HidePassword className="visibility-icon" onClick={toggleCurrentPassVisiblity} />
							) : (
								<ShowPassword className="visibility-icon" onClick={toggleCurrentPassVisiblity} />
							)}
						</div>
						<label>New password</label>
						<div className="pas-input-wrapper">
							<input
								name="new_password"
								ref={register}
								placeholder="Insert new password..."
								type={newPassShown ? "text" : "password"}
							/>
							{newPassShown ? (
								<HidePassword className="visibility-icon" onClick={toggleNewPassVisiblity} />
							) : (
								<ShowPassword className="visibility-icon" onClick={toggleNewPassVisiblity} />
							)}
						</div>
						<label>Repeat password</label>{" "}
						<div className="pas-input-wrapper">
							<input
								name="new_password_repeat"
								placeholder="Insert new password again..."
								type={repeatPassShown ? "text" : "password"}
								ref={register}
							/>
							{repeatPassShown ? (
								<HidePassword className="visibility-icon" onClick={toggleRepeatPassVisiblity} />
							) : (
								<ShowPassword className="visibility-icon" onClick={toggleRepeatPassVisiblity} />
							)}
						</div>
					</div>
					<div className="column-section">
						<label>First name</label>
						<input disabled type="text" defaultValue={hook.firstName} />
						<label>Last name</label>
						<input disabled type="text" defaultValue={hook.lastName} />
					</div>
				</Box>
				<Button type="submit">Save</Button>
			</form>
		</FormWrapper>
	)
}

const FormWrapper = styled.div`
	width: clamp(320px, 80vw, 900px);
	text-align: center;
	margin-bottom: 150px;
	.pas-input-wrapper {
		position: relative;
	}
	.pas-input {
		padding-right: 50px;
	}
	.visibility-icon {
		position: absolute;
		top: 15%;
		right: 4%;
		path {
			fill: var(--white);
		}
	}
	.title {
		margin-bottom: 70px;
		font-size: 36px;
	}

	label {
		font-weight: bold;
		padding-bottom: 5px;
	}

	input {
		width: 100%;
		height: 40px;
		padding-left: 10px;
		margin-bottom: 12px;
		color: var(--white);
		background: var(--blue);
		::placeholder {
			color: var(--white);
		}
	}

	button {
		/* display: block; */
		margin: 24px 0 0 auto;
		background-color: var(--green);
		color: var(--white);
		font-size: 15px;
	}
	@media (max-width: 678px) {
		margin-bottom: 0px;
		.title {
			font-size: 24px;
		}
	}
`

const Box = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	column-gap: 30px;
	.column-section {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	@media (max-width: 678px) {
		flex-direction: column;
		.title {
			font-size: 24px;
		}
	}
`

export default UpdateProfileForm
