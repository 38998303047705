import React, { useState } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import { ReactComponent as HidePassword } from "../../assets/hide-password.svg"
import { ReactComponent as ShowPassword } from "../../assets/show-password.svg"
import useUser from "../../redux/hooks/useUser"

const LoginForm = () => {
	const { register, handleSubmit } = useForm()
	const hook = useUser()
	const [passwordShown, setPasswordShown] = useState(false)
	const togglePasswordVisiblity = () => {
		setPasswordShown(passwordShown ? false : true)
	}

	const handleLogin = (data) => {
		hook.signin(data)
	}

	return (
		<LoginFormStyles>
			<form onSubmit={handleSubmit(handleLogin)}>
				<input name="username" ref={register({ required: true })} placeholder="Username" type="text" />
				<div className="pas-input-wrapper">
					<input
						className="pas-input"
						name="password"
						ref={register({ required: true })}
						placeholder="Password"
						type={passwordShown ? "text" : "password"}
					/>
					{passwordShown ? (
						<HidePassword className="visibility-icon" onClick={togglePasswordVisiblity} />
					) : (
						<ShowPassword className="visibility-icon" onClick={togglePasswordVisiblity} />
					)}
				</div>

				<button>Log in</button>
			</form>
		</LoginFormStyles>
	)
}

const LoginFormStyles = styled.div`
	width: 281px;
	height: 190px;
	text-align: center;
	.pas-input-wrapper {
		position: relative;
	}
	.pas-input {
		padding-right: 50px;
	}
	.visibility-icon {
		position: absolute;
		top: 14%;
		right: 4%;
	}
	input {
		height: 40px;
		background: var(--white);
		border-radius: 4px;
		border: none;
		width: 281px;
		margin-bottom: 20px;
		padding: 0 10px 0 10px;
		color: var(--blue);
		font-weight: bold;
		font-size: 16px;
		&::placeholder {
			color: var(--blue);
			font-size: 16px;
			font-weight: 400;
		}
	}
	button {
		margin: 0 auto;
		background-color: var(--blue);
		color: var(--white);
		border: 1.5px solid var(--white);
		width: 140px;
		height: 50px;
		font-weight: bold;
		line-height: 20px;
		text-align: center;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		&:hover {
			background: var(--green);
			border: none;
			transition: 0.3s;
		}
		&:active {
			box-shadow: none;
			transform: translateY(1px);
		}
	}
`

export default LoginForm
