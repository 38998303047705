import { useDisclosure } from "@chakra-ui/react"
import { useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import useCurrencyConfig from "../../redux/hooks/useCurrencyConfig"
import { superAdminAccess } from "../../utils/constants"
import { handleResponse } from "../../utils/functions"
import CustomButton from "../buttons/CustomButton"
import CurrencyRateForm from "../forms/CurrencyRateForm"
import CustomModal from "../modals/CustomModal"
import DeleteModal from "../modals/DeleteModal"
import { TablesStyles } from "./TableStyles"

const CurrencyConfigTable = ({ selectData, staticPage, role, useParentData, organizationId }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()

	const hook = useCurrencyConfig()

	const [updateData, setUpdateData] = useState(null)

	let data = hook.currency

	const indexedData = data.map((d, i) => {
		return {
			num: i + 1,
			id: d.id,
			rate: d.rate,
			currency_code: d.currency_code,
			currency: d.currency,
			is_static: d.is_static,
			organization_name: d.organization_name,
		}
	})

	const columns = [
		{
			dataField: "num",
			text: "No.",
			headerStyle: () => {
				return { width: "3%" }
			},
			formatter: (cell) => `${cell}.`,
		},
		{
			dataField: "currency_code",
			text: "Code",
		},
		{
			dataField: "rate",
			text: "Rate",
		},
	]

	if (superAdminAccess.includes(role) && staticPage) {
		columns.splice(1, 0, {
			dataField: "organization_name",
			text: "Organization",
		})
	}

	const handleSuccess = () => {
		hook.getCurrencyConfig(true)
		onClose()
	}

	const handleDelete = (id) => {
		hook.removeCurrency(id).then((res) => handleResponse(res, handleSuccess))
	}

	const expandRow = {
		renderer: (row) => (
			<div className="btns-container">
				<CustomButton type="update" onClick={() => handleOpenUpdate(row)} />
				<DeleteModal remove={() => handleDelete(row.id)} />
			</div>
		),
		className: "expandedRow",
		parentClassName: "parentExpandedRow",
	}

	const handleOpenUpdate = (row) => {
		const defaultValues = {
			id: row.id,
			defaultCurrency: selectData.currencies.find((option) => option.value === row.currency),
			defaultOrganization: selectData.organizations.find((option) => option.value === row.organization),
			rate: row.rate,
		}

		setUpdateData(defaultValues)
		onOpen()
	}

	const submit = (data) => {
		let payload = {
			id: updateData.id,
			currency: data.currency_code.value,
			rate: data.rate,
		}

		if (superAdminAccess.includes(role)) {
			payload["organization"] = data.organization.value
		} else {
			payload["organization"] = organizationId
		}

		hook.updateCurrency(payload).then((res) => handleResponse(res, handleSuccess))
	}

	return (
		<>
			<TablesStyles>
				<BootstrapTable
					data={indexedData}
					columns={columns}
					keyField="id"
					classes="TablesStyles"
					expandRow={staticPage && !useParentData && expandRow}
					bordered
					bootstrap4
				/>
			</TablesStyles>

			<CustomModal isOpen={isOpen} onClose={onClose} size="lg">
				<CurrencyRateForm defaultValues={updateData} onSubmit={submit} cancel={onClose} selectData={selectData} role={role} />
			</CustomModal>
		</>
	)
}

export default CurrencyConfigTable
