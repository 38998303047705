import { Icon } from "@chakra-ui/react"

export const AddIcon = (props) => (
	<Icon viewBox="0 0 26 27" {...props}>
		<path
			fill="currentColor"
			d="M1.595 14.96c-.44 0-.825-.147-1.155-.44-.293-.33-.44-.733-.44-1.21 0-.44.147-.788.44-1.045.33-.293.715-.44 1.155-.44H24.31c.44 0 .807.147 1.1.44.33.293.495.678.495 1.155 0 .403-.165.77-.495 1.1-.293.293-.66.44-1.1.44H1.595zm11.33 11.88c-.513 0-.935-.165-1.265-.495-.33-.33-.495-.752-.495-1.265V1.815c0-.55.165-.99.495-1.32.367-.33.788-.495 1.265-.495.55 0 .972.165 1.265.495.33.33.495.752.495 1.265v23.32c0 .513-.165.935-.495 1.265-.33.33-.752.495-1.265.495z"
		></path>
	</Icon>
)
