import React from "react"
import { Controller, useForm } from "react-hook-form"
import Select from "react-select"
import styled from "styled-components"
import { superAdminAccess } from "../../utils/constants"
import CustomButton from "../buttons/CustomButton"
import { customStylesSelect } from "../CustomSelectStyles"
import FormCheckbox from "./formComponents/FormCheckbox"

const ProductNameForm = ({ defaultValues, onSubmit, cancel, selectData, role }) => {
	const { register, handleSubmit, control } = useForm()

	console.log(selectData, "data")
	return (
		<FormStyles>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="input-horizontal-group">
					<div className="input-horizontal-group-individual">
						<label>Code</label>
						<input
							type="text"
							name="code"
							ref={register({ required: true })}
							defaultValue={defaultValues && defaultValues.code}
						/>
					</div>
					<div className="input-horizontal-group-individual">
						<label>Secondary code</label>
						<input
							type="text"
							name="secondary_code"
							ref={register}
							defaultValue={defaultValues && defaultValues.secondary_code}
						/>
					</div>
				</div>
				<div className="input-horizontal-group">
					<div className="input-horizontal-group-individual">
						<label>Name</label>
						<input
							type="text"
							name="name"
							ref={register({ required: true })}
							defaultValue={defaultValues && defaultValues.name}
						/>
					</div>
					<div className="input-horizontal-group-individual">
						<label>Color</label>
						<input
							type="text"
							name="color"
							ref={register({ required: true })}
							defaultValue={defaultValues && defaultValues.color}
						/>
					</div>
				</div>

				<div className="checkbox-section-mono">
					<FormCheckbox className="checkbox-wrapper">
						<label className="checkbox-label">Cluster</label>
						<div className="checkbox">
							<input
								id="checkbox"
								name="is_cluster"
								type="checkbox"
								ref={register}
								defaultChecked={defaultValues && defaultValues.is_cluster}
							/>
							<label htmlFor="checkbox"></label>
						</div>
					</FormCheckbox>
					<FormCheckbox className="checkbox-wrapper">
						<label className="checkbox-label">Monotherapy</label>
						<div className="checkbox">
							<input
								id="checkboxMono"
								name="is_monotherapy"
								type="checkbox"
								ref={register}
								defaultChecked={defaultValues && defaultValues.is_monotherapy}
							/>
							<label htmlFor="checkboxMono"></label>
						</div>
					</FormCheckbox>
					<FormCheckbox className="checkbox-wrapper">
						<label className="checkbox-label">Monotherapy exists</label>
						<div className="checkbox">
							<input
								id="checkboxMonoExists"
								name="monotherapy_exists"
								type="checkbox"
								ref={register}
								defaultChecked={defaultValues && defaultValues.monotherapy_exists}
							/>
							<label htmlFor="checkboxMonoExists"></label>
						</div>
					</FormCheckbox>
				</div>

				<div className="input-horizontal-group">
					<div className="cluster-drugs">
						<label>Cluster drugs</label>
						<Controller
							isMulti
							control={control}
							options={selectData.productNames}
							as={Select}
							defaultValue={defaultValues && defaultValues.cluster_drugs}
							name="cluster_drugs"
							ref={register({ required: true })}
							placeholder="Select cluster drugs..."
							className="react-select-container"
							classNamePrefix="react-select"
							styles={customStylesSelect}
						/>
					</div>
				</div>
				{superAdminAccess.includes(role) && (
					<div className="input-horizontal-group">
						<div className="cluster-drugs">
							<label>Organization</label>
							<Controller
								control={control}
								options={selectData.organizations}
								as={Select}
								defaultValue={defaultValues && defaultValues.organization}
								name="organization"
								placeholder="Select organization..."
								className="react-select-container"
								classNamePrefix="react-select"
								styles={customStylesSelect}
								isClearable={true}
							/>
						</div>
					</div>
				)}
				<div className="btns-wrapper">
					<CustomButton type="save" />
					<CustomButton type="cancel" onClick={() => cancel()} />
				</div>
			</form>
		</FormStyles>
	)
}
const FormStyles = styled.div`
	text-align: center;
	width: 100%;
	/* height: 600px; */

	.input-horizontal-group {
		display: flex;
		column-gap: 20px;
		width: 100%;

		&-individual {
			width: 50%;
		}
		label {
			white-space: nowrap;
		}
	}

	.react-select-container {
		width: 100%;
		height: 40px;
		margin: 0 auto;
		text-align: left;

		.react-select__control {
			border-radius: 4px;
			background: var(--white);
		}

		.react-select__value-container {
			color: var(--blue);
			max-height: 70px;
			overflow-y: auto;
		}
		.react-select__multi-value__remove {
			color: var(--blue);
		}
		.react-select__input {
			height: 40px;
		}
		.react-select__placeholder {
			color: var(--blue);
			font-weight: 400;
		}
		.react-select__menu-list {
			color: var(--black);
			height: 160px;
		}
		.react-select__single-value {
			color: var(--black);
		}
	}

	.checkbox-section-mono {
		display: flex;
		justify-content: center;

		column-gap: 50px;
		.checkbox-wrapper {
			width: 146px;
			white-space: nowrap;
			@media (max-width: 768px) {
				width: 130px;
			}
		}
		.checkbox {
			margin: auto;
		}
		input {
			height: 40px;
		}
		label {
			margin-top: 15px;
			top: -10px;
			padding-top: 10px;
		}
		@media (max-width: 480px) {
			column-gap: 10px;
			.checkbox-label {
				width: 100%;
				font-size: 14px;
				height: 62px;
			}
		}
	}

	.cluster-drugs {
		width: 100%;
	}
	input {
		width: 100%;
		height: 40px;
		padding: 0 15px;
		color: var(--blue);
	}
	label {
		display: block;
		padding: 20px 0 10px 0;
		color: var(--white);
	}
	.btns-wrapper {
		/* margin-top: 50px; */
		width: 100%;
		height: 200px;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		column-gap: 20px;
	}
	button {
		/* margin: 50px 0 30px 30px; */
	}

	.cancelBtn {
		/* margin-left: 155px;
        margin-right: 30px; */
		&:hover {
			color: var(--red);
			transition: 0.3 ease;
		}
	}
`
export default ProductNameForm
