import React from "react"
import { Redirect, Route } from "react-router-dom"
import useUser from "../../redux/hooks/useUser"
import { accountTabAccess, writeMode } from "../../utils/constants"

const AppRoute = ({ component: Component, path, isPrivate, isAdmin, isSuperAdmin, isWriteMode, ...rest }) => {
	const hook = useUser()
	const token = hook.token
	const role = hook.role
	const parentData = hook.useParentData

	return (
		<Route
			path={path}
			render={(props) => {
				if (isPrivate && !Boolean(token)) {
					return <Redirect to={{ pathname: "/login" }} />
					// } else if (path === "/" && token) {
					// 	if (accountTabAccess.includes(role)) {
					// 		return <Redirect to={{ pathname: "/users" }} />
					// 	} else {
					// 		return <Redirect to={{ pathname: "/posologies" }} />
					// 	}
				} else if (isSuperAdmin && role !== 4) {
					return <Redirect to={{ pathname: "/404" }} />
				} else if (isAdmin && !accountTabAccess.includes(role)) {
					return <Redirect to={{ pathname: "/404" }} />
				} else if (isWriteMode && !writeMode.includes(role)) {
					return <Redirect to={{ pathname: "/404" }} />
				} else if (isWriteMode && parentData) {
					return <Redirect to={{ pathname: "/404" }} />
				} else {
					return <Component {...props} />
				}
			}}
			{...rest}
		/>
	)
}

export default AppRoute
