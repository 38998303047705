import styled from "styled-components"

export const TablesStyles = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	min-width: ${(props) => props.minWidth};
	/* overflow-x: auto; */
	// height: fit-content;

	table.table-bordered.TablesStyles {
		width: 100%;
		max-height: 95vh;
		min-width: 1200px;
		font-size: 15px;
		color: var(--black);
		border-radius: 40px;
		border-collapse: collapse;
	}
	table.table-bordered.TablesStyles th {
		border: 2px solid var(--blue);
		&:first-of-type {
			border-left: 0;
		}
		&:last-of-type {
			border-right: 0;
		}
		height: 40px;
		min-width: 120px !important;
		border-bottom: 1px solid red;
	}
	thead {
		position: -webkit-sticky;
		position: sticky;
		top: 0px;
		z-index: 20;
		background-color: var(--white);
		margin-bottom: 4px;
		box-shadow: 16px 16px 11px -18px rgba(0, 0, 0, 0.75);
	}

	table.table-bordered.TablesStyles tbody tr {
		max-height: 50px !important;
		-webkit-line-clamp: 3;
		height: 35px;
		-webkit-box-orient: vertical;
		:hover {
			color: var(--white);
			background: var(--blue);
			cursor: pointer;
		}
	}
	table.table-bordered.TablesStyles td {
		border: 2px solid var(--blue);
		line-height: 1;
		padding: 5px 0;
		text-align: center;
	}
	.table tr:first-of-type th {
		border-top: 0;
	}
	.table tr td:first-of-type,
	table tr th:first-of-type {
		border-left: 0;
	}
	.table tr td:last-of-type,
	table tr th:last-of-type {
		border-right: 0;
	}
	td {
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.expandedRow {
		background: var(--blue);
		border-bottom: 1px solid var(--blue) !important;
		height: 50px;
	}
	.parentExpandedRow {
		cursor: pointer;
		background: var(--blue);
		color: var(--white);
		white-space: wrap !important;
		height: 50px;
	}
	.btns-container {
		display: flex;
		margin-left: 20px;
		column-gap: 20px;
		align-items: center;
	}
`
