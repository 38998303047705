import { createAction, createReducer } from "@reduxjs/toolkit"
import axios from "axios"
import {
	configCurrencyURL,
	configCurrencyRemoveURL,
	configCurrencyResetURL,
	configCurrencyCrawlURL,
	currencyConfigImportURL,
} from "../api_urls"

const START_REQUEST = createAction("CONFIG/CURRENCY/START_REQUEST")
const FETCH_SUCCESS = createAction("CONFIG/CURRENCY/FETCH_SUCCESS")
const REQUEST_SUCCESS = createAction("CONFIG/CURRENCY/REQUEST_SUCCESS")
const REQUEST_FAIL = createAction("CONFIG/CURRENCY/REQUEST_FAIL")
const CLEAN_MESSAGE = createAction("CONFIG/CURRENCY/CLEAN_MESSAGE")

export const fetchCurrencyConfig = (staticPage) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.get(configCurrencyURL(staticPage)).then((res) => handleSuccess(res, dispatch, FETCH_SUCCESS, REQUEST_FAIL))
	// .catch(function (error) {
	// 	handleError(error, dispatch, REQUEST_FAIL)
	// })
}

export const crawlCurrencyConfig = () => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.get(configCurrencyCrawlURL).then((res) => handleSuccess(res, dispatch, FETCH_SUCCESS, REQUEST_FAIL))
	// .catch(function (error) {
	// 	handleError(error, dispatch, REQUEST_FAIL)
	// })
}

export const resetCurrencyConfig = () => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.get(configCurrencyResetURL).then((res) => handleSuccess(res, dispatch, FETCH_SUCCESS, REQUEST_FAIL))
	// .catch(function (error) {
	// 	handleError(error, dispatch, REQUEST_FAIL)
	// })
}

export const createCurrencyItem = (data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(configCurrencyURL(true), data).then((res) => handleSuccess(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
	// .catch(function (error) {
	// 	handleError(error, dispatch, REQUEST_FAIL)
	// })
}

export const removeCurrencyItem = (id) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.delete(configCurrencyRemoveURL(id)).then((res) => handleSuccess(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
	// .catch(function (error) {
	// 	handleError(error, dispatch, REQUEST_FAIL)
	// })
}

export const updateCurrencyItem = (data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.put(configCurrencyURL(true), data).then((res) => handleSuccess(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
	// .catch(function (error) {
	// 	handleError(error, dispatch, REQUEST_FAIL)
	// })
}

export const importCurrencyRates = (data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios
		.post(currencyConfigImportURL, data, { headers: { "content-type": "multipart/form-data" } })
		.then((res) => handleSuccess(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
	// .catch(error => {
	// 	handleError(error, dispatch, REQUEST_FAIL)
	// })
}

export const cleanMessage = () => async (dispatch) => {
	return dispatch(CLEAN_MESSAGE())
}

const handleSuccess = (res, dispatch, success, fail) => {
	if (res !== undefined) {
		if (res.status === 200) {
			dispatch(success(res.data))
		} else if (res.response !== undefined && res.response.status === 400) {
			dispatch(fail(res.response.data.message))
			dispatch(CLEAN_MESSAGE())
		} else {
			dispatch(fail({ text: "Server error!", type: "error" }))
			dispatch(CLEAN_MESSAGE())
		}
	} else {
		dispatch(fail({ text: "Server error!", type: "error" }))
		dispatch(CLEAN_MESSAGE())
	}

	return res
}

const initState = {
	currency: [],
	loading: false,
	message: null,
}

export const currencyConfigReducer = createReducer(initState, (builder) => {
	builder
		.addCase(START_REQUEST, (state, action) => {
			state.loading = true
			state.message = null
		})
		.addCase(FETCH_SUCCESS, (state, action) => {
			state.loading = false
			state.currency = action.payload
		})
		.addCase(REQUEST_SUCCESS, (state, action) => {
			state.loading = false
			state.message = action.payload.message
		})
		.addCase(REQUEST_FAIL, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(CLEAN_MESSAGE, (state, action) => {
			state.message = null
		})
})
