import { useDispatch, useSelector } from "react-redux"
import {
	fetchCurrencyConfig,
	resetCurrencyConfig,
	removeCurrencyItem,
	createCurrencyItem,
	updateCurrencyItem,
	crawlCurrencyConfig,
	importCurrencyRates,
} from "../reducers/currencyConfig"

export default function useCurrencyConfig() {
	const dispatch = useDispatch()
	const currency = useSelector((state) => state.currencyConfig.currency)
	const message = useSelector((state) => state.currencyConfig.message)
	const loading = useSelector((state) => state.currencyConfig.loading)

	const getCurrencyConfig = async (staticPage) => {
		return dispatch(fetchCurrencyConfig(staticPage))
	}

	const crawlConfig = async () => {
		dispatch(crawlCurrencyConfig())
	}

	const resetCurrency = async () => {
		dispatch(resetCurrencyConfig())
	}

	const createCurrency = async (data) => {
		return dispatch(createCurrencyItem(data))
	}

	const removeCurrency = async (id) => {
		return dispatch(removeCurrencyItem(id))
	}

	const updateCurrency = async (data) => {
		return dispatch(updateCurrencyItem(data))
	}

	const importFile = async (file) => {
		let formData = new FormData()
		formData.append("file", file, file.name)
		return dispatch(importCurrencyRates(formData))
	}

	return {
		currency,
		importFile,
		getCurrencyConfig,
		crawlConfig,
		resetCurrency,
		createCurrency,
		removeCurrency,
		updateCurrency,
		loading,
		message,
	}
}
