import { NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from "@chakra-ui/react"
import React from "react"

const PositiveDecimalInput = ({ name, defaultValue, register, placeholder, ...props }) => {
	return (
		<NumberInput
			bg="white"
			w="100%"
			_placeholder={{ color: "black" }}
			type="number"
			name={name}
			// ref={register}
			placeholder={placeholder}
			defaultValue={defaultValue}
			step={0.01}
			min={0}
			{...props}
			borderRadius={4}
		>
			<NumberInputField />
			<NumberInputStepper>
				<NumberIncrementStepper />
				<NumberDecrementStepper />
			</NumberInputStepper>
		</NumberInput>
	)
}

export default PositiveDecimalInput
