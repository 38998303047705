import { Box, HStack, Stack, Tooltip } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import Select from "react-select"

import styled from "styled-components"
import { InfoIcon } from "../../assets/InfoIcon"

import CustomButton from "../buttons/CustomButton"
import { customStylesSelect } from "../CustomSelectStyles"

const OrganizationForm = ({ defaultValues, onSubmit, selectData, cancel }) => {
	const { register, handleSubmit, control, watch } = useForm()

	const [showTemplate, setShowTemplate] = useState(false)
	const [showParent, setShowParent] = useState(false)

	useEffect(() => {
		if (watch("use_template")) {
			setShowTemplate(true)
		} else {
			setShowTemplate(false)
		}

		if (watch("use_parent_data")) {
			setShowParent(true)
		} else {
			setShowParent(false)
		}
	}, [watch])

	return (
		<FormWrapper onSubmit={handleSubmit(onSubmit)}>
			<Stack spacing={8} minH="800px">
				<div>
					<label>Name</label>
					<input
						name="name"
						ref={register({ required: true })}
						defaultValue={defaultValues && defaultValues.name}
						placeholder="Insert name..."
					/>
				</div>

				<div>
					<label>Users</label>
					<Controller
						isMulti
						control={control}
						options={selectData.users}
						as={Select}
						defaultValue={defaultValues && defaultValues.defaultUsers}
						name="users"
						placeholder="Select users..."
						className="react-select-container"
						classNamePrefix="react-select"
						styles={customStylesSelect}
					/>
				</div>
				<Tooltip
					hasArrow
					placement="auto-start"
					label="All organization users will use template permissions instead of personal setup"
				>
					<HStack align="center" sx={{ label: { p: 0 } }} w="fit-content">
						<InfoIcon color="white" boxSize={7} />
						<Box display={"flex"} flexDir="row" columnGap={"10px"} h="20px">
							<label>Use template</label>

							<div className="checkbox">
								<input
									type="checkbox"
									id="use_template"
									name="use_template"
									ref={register}
									defaultChecked={defaultValues && defaultValues.use_template}
								/>
								<label htmlFor="use_template"></label>
							</div>
						</Box>
					</HStack>
				</Tooltip>

				{showTemplate && (
					<Box>
						<label>Template</label>
						<Controller
							control={control}
							options={selectData.templateUsers}
							as={Select}
							defaultValue={defaultValues && defaultValues.defaultTemplate}
							name="template"
							placeholder="Select template for users..."
							className="react-select-container"
							classNamePrefix="react-select"
							styles={customStylesSelect}
							isClearable={true}
						/>
					</Box>
				)}
				<Tooltip
					hasArrow
					placement="auto-start"
					label="All organization users will use parent organization data. All organization roles can see data, but won't be able to edit it"
				>
					<HStack align="center" sx={{ label: { p: 0 } }} w="fit-content">
						<InfoIcon color="white" boxSize={7} />
						<Box display={"flex"} flexDir="row" columnGap={"10px"} h="20px">
							<label>Use parent data</label>

							<div className="checkbox">
								<input
									type="checkbox"
									id="use_parent_data"
									name="use_parent_data"
									ref={register}
									defaultChecked={defaultValues && defaultValues.use_parent_data}
								/>
								<label htmlFor="use_parent_data"></label>
							</div>
						</Box>
					</HStack>
				</Tooltip>
				{showParent && (
					<div>
						<label>Parent organization</label>
						<Controller
							control={control}
							options={selectData.parentOrganizations}
							as={Select}
							defaultValue={defaultValues && defaultValues.defaultParentOrganization}
							name="parent_organization"
							placeholder="Select parent organization..."
							className="react-select-container"
							classNamePrefix="react-select"
							styles={customStylesSelect}
							isClearable={true}
						/>
					</div>
				)}
				{defaultValues && (
					<>
						<div>
							<label>API token</label>
							<input
								disabled={true}
								// name="name"
								// ref={register({ required: true })}
								defaultValue={defaultValues && defaultValues.apiToken}
							/>
						</div>
						<Box display={"flex"} flexDir="row" columnGap={"10px"} h="20px">
							<label>Change API token</label>

							<div className="checkbox">
								<input
									type="checkbox"
									id="change_api_token"
									name="change_api_token"
									ref={register}
									defaultChecked={defaultValues && defaultValues.change_api_token}
								/>
								<label htmlFor="change_api_token"></label>
							</div>
						</Box>
					</>
				)}
			</Stack>
			<div className="btns-wrapper">
				<CustomButton type="save" />
				<CustomButton type="cancel" onClick={() => cancel()} />
			</div>
		</FormWrapper>
	)
}

const FormWrapper = styled.form`
	text-align: center;
	width: 743px;
	min-height: 100vh - 100px;
	display: flex;
	flex-direction: column;
	row-gap: 40px;
	justify-content: space-between;

	input {
		padding: 15px;
		color: var(--black);
		height: 40px;
		width: 100%;
	}

	.react-select-container {
		width: 100%;
		height: 40px;
		margin: 0 auto;
		text-align: left;

		.react-select__control {
			border-radius: 4px;
			background: var(--white);
		}

		.react-select__value-container {
			max-height: 70px;
			overflow-y: auto;
		}
		.react-select__multi-value__remove {
			color: var(--blue);
		}
		.react-select__input {
			height: 40px;
		}
		.react-select__placeholder {
			color: var(--blue);
			font-weight: 400;
		}
		.react-select__menu-list {
			height: 160px;
		}
		.react-select__single-value {
			color: var(--black);
		}
	}

	label {
		display: block;
		padding: 8px 0 5px 0;
		color: var(--white);
		font-size: 16px;
	}

	.btns-wrapper {
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: flex-end;
		grid-gap: 20px;
	}

	.checkbox {
		margin: 0;
		width: 20px;
		margin-left: 5px;
		& input {
			height: 20px;
		}
	}
`
export default OrganizationForm
