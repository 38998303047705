import { useDisclosure } from "@chakra-ui/react"
import { useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import useReference from "../../redux/hooks/useReference"
import { superAdminAccess } from "../../utils/constants"
import { checkWriteCondition, handleOrganizationPayload, handleResponse } from "../../utils/functions"
import CustomButton from "../buttons/CustomButton"
import ReferenceForm from "../forms/ReferenceForm"
import CustomModal from "../modals/CustomModal"
import DeleteModal from "../modals/DeleteModal"
import { TablesStyles } from "./TableStyles"

const ReferenceTable = ({ selectData, role, parentData, organizationId }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()

	const hook = useReference()

	const [updateData, setUpdateData] = useState(null)

	let data = hook.data

	const columns = [
		{
			dataField: "drug_name",
			text: "Product name",
		},
		{
			dataField: "pack_strength",
			text: "Pack strength",
		},
	]

	if (superAdminAccess.includes(role)) {
		columns.splice(0, 0, {
			dataField: "organization_name",
			text: "Organization",
		})
	}

	const handleSuccess = () => {
		hook.fetch(1, hook.defaultSize)
		onClose()
	}

	const handleDelete = (id) => {
		hook.remove(id).then((res) => handleResponse(res, handleSuccess))
	}

	const expandRow = {
		renderer: (row) => (
			<div className="btns-container">
				<CustomButton type="update" onClick={() => handleOpenUpdate(row)} />
				<DeleteModal remove={() => handleDelete(row.id)} />
			</div>
		),
		className: "expandedRow",
		parentClassName: "parentExpandedRow",
	}

	const handleOpenUpdate = (row) => {
		const defaultValues = {
			id: row.id,
			defaultProductName: selectData.productNames.find((option) => option.value === row.drug),
			packStrength: row.pack_strength,
		}

		if (superAdminAccess.includes(role)) {
			defaultValues["organization"] = selectData.organizations.find((ele) => ele.value === Number(row.organization))
		}

		setUpdateData(defaultValues)
		onOpen()
	}

	const submit = (data) => {
		let payload = {
			id: updateData.id,
			drug: data.drug.value,
			pack_strength: data.pack_strength,
		}

		handleOrganizationPayload(payload, role, organizationId, data.organization)

		hook.update(payload).then((res) => handleResponse(res, handleSuccess))
	}

	const handleCancel = () => {
		onClose()
	}

	return (
		<>
			<TablesStyles>
				<BootstrapTable
					data={data}
					columns={columns}
					keyField="id"
					classes="TablesStyles"
					expandRow={checkWriteCondition(role, parentData) && expandRow}
					bordered
					bootstrap4
				/>
			</TablesStyles>

			<CustomModal isOpen={isOpen} onClose={onClose} size="6xl">
				<ReferenceForm defaultValues={updateData} selectData={selectData} onSubmit={submit} cancel={handleCancel} role={role} />
			</CustomModal>
		</>
	)
}

export default ReferenceTable
