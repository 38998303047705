import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import LoginForm from "../components/forms/LoginForm"
import generateToast from "../components/notifications/ToastGenerator"
import useUser from "../redux/hooks/useUser"
import { accountTabAccess } from "../utils/constants"

const LoginPage = () => {
	const hook = useUser()

	useEffect(() => {
		// hook.signout()
		// console.log(typeof process.env.REACT_APP_USE_OIDC_AUTH)
		if (process.env.REACT_APP_USE_OIDC_AUTH === "true") {
			hook.loginOidc().then((res) => {
				if (res !== undefined && res.status === 200) {
					window.location.replace(res.data.uri)
				}
			})
		}
		// 	console.log("not")
		// }
	}, []) //eslint-disable-line

	useEffect(() => {
		if (hook.message) {
			generateToast(hook.message)
		}
	}, [hook.message])

	const history = useHistory()

	useEffect(() => {
		if (hook.role) {
			// if (accountTabAccess.includes(hook.role)) {
			// 	history.push("/users")
			// } else {

			if (process.env.REACT_APP_USE_OIDC_AUTH === "true") {
				history.push("/")
			} else {
				if (accountTabAccess.includes(hook.role)) {
					history.push("/users")
				} else {
					history.push("/posologies")
				}
			}
			// }
		}
	}, [hook.role])

	if (process.env.REACT_APP_USE_OIDC_AUTH === "true") {
		return <></>
	} else {
		return (
			<ContainerStyles>
				<TitleSectionStyles>
					<div className="titleContainer">
						<h1>
							Hi!
							<br /> Please log in
							<br /> to continue.
						</h1>
						<div className="underLine"></div>
					</div>
				</TitleSectionStyles>
				<LoginSectionStyles>
					<h2>Posology Panel</h2>
					<LoginForm />
				</LoginSectionStyles>
			</ContainerStyles>
		)
	}
}

const ContainerStyles = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
`
const TitleSectionStyles = styled.div`
	width: 50%;
	background: var(--white);
	display: flex;
	justify-content: center;
	align-items: center;

	.titleContainer {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 320px;
		padding-left: 87px;
	}
	h1 {
		font-size: clamp(50px, 4vw, 75px);
		color: var(--blue);
		margin-bottom: 26px;
	}
	.underLine {
		width: 100%;
		height: 6px;
		background: var(--blue);
	}
	@media (max-width: 900px) {
		.titleContainer {
			padding-left: 20px;
		}
	}
	@media (max-width: 768px) {
		width: 30%;
		.titleContainer {
			justify-content: center;
			align-items: center;
			padding-left: 10px;
		}
		h1 {
			font-size: 20px;
		}
	}
	@media (max-width: 600px) {
		display: none;
	}
`
const LoginSectionStyles = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;
	background: var(--blue);
	justify-content: center;
	align-items: center;
	img,
	h2 {
		padding-bottom: 20px;
	}
	h2 {
		font-size: 25px;
		color: var(--white);
		font-weight: 500;
	}
	@media (max-width: 768px) {
		width: 70%;
	}
	@media (max-width: 600px) {
		width: 100%;
	}
`

export default LoginPage
