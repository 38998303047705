import React from "react"
import styled from "styled-components"

const PriceTableFrame = ({ children }) => {
	return (
		<ContainerStyles>
			<div className="layer">
				<FrameTableStyles>{children}</FrameTableStyles>
			</div>
		</ContainerStyles>
	)
}
const ContainerStyles = styled.div`
	width: 100%;
	height: 90vh;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	background: var(--white);
	.layer {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		position: relative;
		margin: 0 2%;
	}
`
const FrameTableStyles = styled.div`
	width: 100%;
	min-width: 670px;
	height: 85vh;
	border: 4px solid var(--blue);
	border-radius: 4px;
	overflow-y: auto;
	background: var(--white);
`
export default PriceTableFrame
