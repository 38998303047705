import { Flex } from "@chakra-ui/layout"
import { NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from "@chakra-ui/number-input"
import { Box, Button, Collapse, Tooltip, useDisclosure } from "@chakra-ui/react"
import { TagLabel } from "@chakra-ui/tag"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import styled from "styled-components"
import { ReactComponent as HidePassword } from "../../assets/hide-password.svg"
import { ReactComponent as CollapseArrow } from "../../assets/select-arrow.svg"
import { ReactComponent as ShowPassword } from "../../assets/show-password.svg"
import useUsers from "../../redux/hooks/useUsers"
import {
	activeIngredient,
	allDrugsDifferent,
	allDrugsSame,
	cheapestAll,
	cheapestPerStrength,
	chooseAnyPackage,
	countryGraph,
	defaultExtendedTreatmentLine,
	defaultProtocol,
	euMarketAccess,
	evolution,
	exactNumber,
	extendedTreatmentLine,
	gender,
	her2,
	hosp,
	hr,
	listed,
	menopause,
	mnf,
	nearestInt,
	node,
	orphanDesignation,
	pik3ca,
	ppeVat,
	ppiVat,
	ppp,
	protocol,
	roundDown,
	roundUp,
	stacked,
	superAdminAccess,
	symptomaticVisceralInvolvement,
	tables,
	transplantEligibility,
} from "../../utils/constants"
import { checkDefaultValueRadio, handleResponse } from "../../utils/functions"
import CustomSelectAll from "../CustomSelectAll"
import { customStylesSelect } from "../CustomSelectStyles"
import FormCheckbox from "./formComponents/FormCheckbox"
import RadioButtonGroup from "./formComponents/RadioButtonGroup"
import Switch from "./formComponents/Switch"

const UserForm = ({ defaultValues, onSubmit, cancel, update, selectData, showConfirmDialog, role }) => {
	const [passwordShown, setPasswordShown] = useState(false)
	const [repeatPasswordShown, setrepeatPasswordShown] = useState(false)
	const [singleYear, setSingleYear] = useState(false)
	const [option28Days, setOption28Days] = useState(false)
	const [selectedUser, setSelectedUser] = useState(null)
	const [copyDisabled, setCopyDisabled] = useState(false)
	const { isOpen, onToggle } = useDisclosure()

	const hook = useUsers()

	useEffect(() => {
		if (!update) {
			onToggle()
		}
	}, [])

	useEffect(() => {
		if (defaultValues) {
			if (defaultValues.default_interval) {
				setSingleYear(true)
			} else if (defaultValues.default_interval === false) {
				setOption28Days(true)
			}
		}
	}, [defaultValues])

	const togglePasswordVisiblity = () => {
		setPasswordShown(passwordShown ? false : true)
	}

	const toggleRepeatPasswordVisiblity = () => {
		setrepeatPasswordShown(repeatPasswordShown ? false : true)
	}

	const { register, handleSubmit, control, watch } = useForm()

	const checkPricePermission = (priceOption) => {
		if (Object.keys(defaultValues.allowed_prices).length !== 0) {
			if (Object.keys(defaultValues.allowed_prices).includes(priceOption)) {
				return true
			} else return false
		} else return false
	}

	const handleSingleYear = () => {
		if (!singleYear) {
			setSingleYear(true)
			setOption28Days(false)
		} else {
			setSingleYear(false)
		}
	}
	const handle28Days = () => {
		if (!option28Days) {
			setSingleYear(false)
			setOption28Days(true)
		} else {
			setOption28Days(false)
		}
	}

	const handleUserPreferences = () => {
		const handleSuccess = () => {
			hook.fetch()
			cancel()
		}

		const handleError = () => {
			setCopyDisabled(false)
		}

		if (update) {
			const payload = {
				username_from: selectedUser.label,
				username_to: defaultValues.username,
			}
			setCopyDisabled(true)
			hook.updatePermissions(payload).then((res) => handleResponse(res, handleSuccess, handleError))
		} else {
			if (
				!watch("username") ||
				!watch("password") ||
				!watch("repeated_password") ||
				!watch("first_name") ||
				!watch("last_name") ||
				!watch("email") ||
				!watch("role") ||
				!watch("organization")
			) {
				toast.error("Personal informations are required!")
			} else if (watch("password") !== watch("repeated_password")) {
				toast.error("Passwords do not match!")
			} else {
				const payload = {
					username: watch("username"),
					password: watch("password"),
					repeated_password: watch("repeated_password"),
					first_name: watch("first_name"),
					last_name: watch("last_name"),
					email: watch("email"),
					username_from: selectedUser.label,
					role: watch("role").value,
					organization: watch("organization").value,
				}

				setCopyDisabled(true)
				hook.createPermissions(payload).then((res) => handleResponse(res, handleSuccess, handleError))
			}
		}
	}

	return (
		<UserFormStyled onSubmit={handleSubmit(onSubmit)}>
			<Flex>
				<Button
					variant="white"
					rightIcon={<CollapseArrow className={isOpen ? "test" : ""} />}
					onClick={onToggle}
					h="40px"
					px="8px"
					// w="auto"
				>
					{isOpen ? "Close personal informations" : "Open personal informations"}
				</Button>
			</Flex>

			<Collapse in={isOpen} animateOpacity>
				<div className="personalData-section">
					<div className="sides">
						<div className="sides-inputGroup">
							<label className="label-inputs">Username</label>
							<input
								type="text"
								name="username"
								ref={register({ required: true })}
								defaultValue={defaultValues && defaultValues.username}
							/>
						</div>
						<div className="sides-inputGroup">
							<label className="label-inputs">Password</label>
							<div className="pas-input-wrapper">
								<input
									className="pas-input"
									name="password"
									ref={!update ? register({ required: true }) : register}
									defaultValue={defaultValues && defaultValues.password}
									type={passwordShown ? "text" : "password"}
								/>
								{passwordShown ? (
									<HidePassword className="visibility-icon" onClick={togglePasswordVisiblity} />
								) : (
									<ShowPassword className="visibility-icon" onClick={togglePasswordVisiblity} />
								)}
							</div>
						</div>
						<div className="sides-inputGroup">
							<label className="label-inputs">Repeat password</label>
							<div className="pas-input-wrapper">
								<input
									className="pas-input"
									name="repeated_password"
									ref={!update ? register({ required: true }) : register}
									type={repeatPasswordShown ? "text" : "password"}
								/>
								{repeatPasswordShown ? (
									<HidePassword className="visibility-icon" onClick={toggleRepeatPasswordVisiblity} />
								) : (
									<ShowPassword className="visibility-icon" onClick={toggleRepeatPasswordVisiblity} />
								)}
							</div>
						</div>
						{superAdminAccess.includes(role) && (
							<div className="sides-inputGroup">
								<label className="label-inputs">Organization</label>
								<Controller
									control={control}
									options={selectData.organizations}
									as={CustomSelectAll}
									defaultValue={defaultValues && defaultValues.defaultOrganization}
									name="organization"
									placeholder="Select user organization..."
									className="react-select-container select-user"
									classNamePrefix="react-select"
									styles={customStylesSelect}
								/>
							</div>
						)}
						<Flex pl={[0, 0, "117px"]} my="15px" w="100%" gridColumnGap="20px">
							<Box w="90%">
								<CustomSelectAll
									options={selectData.users}
									value={selectedUser}
									className="react-select-container select-user"
									classNamePrefix="react-select"
									styles={customStylesSelect}
									onChange={(val) => setSelectedUser(val)}
									placeholder="Select user..."
								/>
							</Box>
							<Button
								w="300px"
								variant="green"
								onClick={() => handleUserPreferences()}
								disabled={!selectedUser || copyDisabled}
							>
								Copy user preferences
							</Button>
						</Flex>
					</div>
					<div className="sides">
						<div className="sides-inputGroup">
							<label className="label-inputs">First name</label>
							<input
								type="text"
								name="first_name"
								ref={register({ required: true })}
								defaultValue={defaultValues && defaultValues.first_name}
							/>
						</div>
						<div className="sides-inputGroup">
							<label className="label-inputs">Last name</label>
							<input
								type="text"
								name="last_name"
								ref={register({ required: true })}
								defaultValue={defaultValues && defaultValues.last_name}
							/>
						</div>
						<div className="sides-inputGroup">
							<label className="label-inputs">Email</label>
							<input
								type="text"
								name="email"
								ref={register({ required: true })}
								defaultValue={defaultValues && defaultValues.email}
							/>
						</div>
						<div className="sides-inputGroup">
							<label className="label-inputs">Role</label>
							<Controller
								control={control}
								options={selectData.roles}
								as={CustomSelectAll}
								defaultValue={defaultValues ? defaultValues.defaultRole : selectData.roles[0]}
								name="role"
								placeholder="Select user role..."
								className="react-select-container select-user"
								classNamePrefix="react-select"
								styles={customStylesSelect}
							/>
						</div>
					</div>
				</div>
			</Collapse>

			<>
				<div className="permission-section">
					<div>
						<div className="dropdowns-container">
							<div className="dropdowns-container-countries">
								<label>Allowed countries</label>
								<Controller
									isMulti
									control={control}
									options={selectData.countries}
									as={CustomSelectAll}
									defaultValue={defaultValues && defaultValues.allowed_countries}
									name="allowed_countries"
									placeholder="Select countries..."
									className="react-select-container"
									classNamePrefix="react-select"
									styles={customStylesSelect}
									allowSelectAll={true}
								/>
							</div>

							<div className="dropdowns-container-countries">
								<label>Default countries</label>
								<Controller
									isMulti
									control={control}
									options={selectData.countries}
									as={CustomSelectAll}
									defaultValue={defaultValues && defaultValues.default_countries}
									name="default_countries"
									placeholder="Select countries..."
									className="react-select-container"
									classNamePrefix="react-select"
									styles={customStylesSelect}
									allowSelectAll={true}
								/>
							</div>
						</div>
						<div className="dropdowns-container">
							<div className="dropdowns-container-drugs">
								<label>Allowed product names</label>
								<Controller
									isMulti
									control={control}
									options={selectData.drugs}
									as={CustomSelectAll}
									defaultValue={defaultValues && defaultValues.allowed_drugs}
									name="allowed_drugs"
									placeholder="Select product names..."
									className="react-select-container"
									classNamePrefix="react-select"
									styles={customStylesSelect}
									allowSelectAll={true}
								/>
							</div>
							<div className="dropdowns-container-drugs">
								<label>Default product names</label>
								<Controller
									isMulti
									control={control}
									options={selectData.drugs}
									as={CustomSelectAll}
									defaultValue={defaultValues && defaultValues.default_drugs}
									name="default_drugs"
									placeholder="Select product names..."
									className="react-select-container"
									classNamePrefix="react-select"
									styles={customStylesSelect}
									allowSelectAll={true}
								/>
							</div>
						</div>
					</div>
					<div className="dropdowns-container">
						<div className="dropdowns-container-drugs">
							<label>Allowed generics</label>
							<Controller
								isMulti
								control={control}
								options={selectData.generics}
								as={CustomSelectAll}
								defaultValue={defaultValues && defaultValues.allowed_generics}
								name="allowed_generics"
								placeholder="Select generics..."
								className="react-select-container"
								classNamePrefix="react-select"
								styles={customStylesSelect}
								allowSelectAll={true}
							/>
						</div>
						<div className="dropdowns-container-drugs">
							<label>Default generics</label>
							<Controller
								isMulti
								control={control}
								options={selectData.generics}
								as={CustomSelectAll}
								defaultValue={defaultValues && defaultValues.default_generics}
								name="default_generics"
								placeholder="Select generics..."
								className="react-select-container"
								classNamePrefix="react-select"
								styles={customStylesSelect}
								allowSelectAll={true}
							/>
						</div>
					</div>
					<div className="dropdowns-container">
						<div className="dropdowns-container-drugs">
							<label>Allowed brand types</label>
							<Controller
								isMulti
								control={control}
								options={selectData.brandTypes}
								as={CustomSelectAll}
								defaultValue={defaultValues && defaultValues.allowed_brand_types}
								name="allowed_brand_types"
								placeholder="Select brand types..."
								className="react-select-container"
								classNamePrefix="react-select"
								styles={customStylesSelect}
								allowSelectAll={true}
							/>
						</div>
						<div className="dropdowns-container-drugs">
							<label>Default brand types</label>
							<Controller
								isMulti
								control={control}
								options={selectData.brandTypes}
								as={CustomSelectAll}
								defaultValue={defaultValues && defaultValues.default_brand_types}
								name="default_brand_types"
								placeholder="Select brand types..."
								className="react-select-container"
								classNamePrefix="react-select"
								styles={customStylesSelect}
								allowSelectAll={true}
							/>
						</div>
					</div>
					<div className="dropdowns-container">
						<div className="dropdowns-container-countries">
							<label>Default treatment lines</label>
							<Controller
								isMulti
								control={control}
								options={selectData.treatmentLines}
								as={CustomSelectAll}
								defaultValue={defaultValues && defaultValues.default_treatment_lines}
								name="default_treatment_lines"
								placeholder="Select treatment lines..."
								className="react-select-container"
								classNamePrefix="react-select"
								styles={customStylesSelect}
								allowSelectAll={true}
							/>
						</div>
					</div>

					<div className="radio-buttons-section">
						<RadioButtonGroup
							firstLabel={"Active"}
							secondLabel={"Inactive"}
							regName="is_active"
							defaultCheckedFirst={checkDefaultValueRadio(update, defaultValues && defaultValues.is_active)}
							defaultCheckedSecond={checkDefaultValueRadio(update, defaultValues && !defaultValues.is_active, true)}
							register={register}
							title={"User status"}
							tooltip={"Set active so user can access the platform, or inactive to temporary or permanently disallow access"}
						/>
						<RadioButtonGroup
							firstLabel={"Full text indication"}
							secondLabel={"Combination codes"}
							regName={"indication_text"}
							defaultCheckedFirst={checkDefaultValueRadio(update, defaultValues && defaultValues.indication_text)}
							defaultCheckedSecond={checkDefaultValueRadio(update, defaultValues && !defaultValues.indication_text)}
							register={register}
							title={"Treatment output visual"}
							tooltip={
								"If combination code checed everywhere on calculator output will be shown with shortcut combination code, otherwise we will have full text indication"
							}
						/>

						<RadioButtonGroup
							firstLabel={"Static"}
							secondLabel={"Dynamic"}
							regName={"is_static"}
							defaultCheckedFirst={checkDefaultValueRadio(update, defaultValues && defaultValues.is_static)}
							defaultCheckedSecond={checkDefaultValueRadio(update, defaultValues && !defaultValues.is_static)}
							register={register}
							title={"Currency rates usage"}
							tooltip={"Use static or dynamic currency rates for user"}
						/>

						<div className="radio-button-group-with-title" style={{ marginBottom: "-10px" }}>
							<Tooltip
								label={"Choose which value to be default, can be unchecked (no value will be selected)"}
								hasArrow
								arrowSize={10}
								px="12px"
								py="8px"
							>
								<label className="radio-title">Default interval</label>
							</Tooltip>
							<FormCheckbox>
								<div className="checkbox-group">
									<label className="checkbox-title">Annual</label>
									<div className="checkbox">
										<input
											id="checkbox"
											name="singleYear"
											type="checkbox"
											checked={singleYear}
											value={singleYear}
											onChange={() => handleSingleYear()}
											ref={register}
										/>
										<label htmlFor="checkbox"></label>
									</div>

									<label className="checkbox-title">28 days</label>
									<div className="checkbox">
										<input
											id="checkboxdays"
											name="option28days"
											type="checkbox"
											value={option28Days}
											checked={option28Days}
											onChange={() => handle28Days()}
											ref={register}
										/>
										<label htmlFor="checkboxdays"></label>
									</div>
								</div>
							</FormCheckbox>
						</div>
						<RadioButtonGroup
							title={"Calculator mode"}
							firstLabel={"Standard"}
							secondLabel={"Advanced"}
							regName={"calculator_mode"}
							defaultCheckedFirst={checkDefaultValueRadio(update, defaultValues && defaultValues.calculator_mode)}
							defaultCheckedSecond={checkDefaultValueRadio(update, defaultValues && !defaultValues.calculator_mode)}
							register={register}
							tooltip={"Choose between two calculator modes"}
						/>
						<RadioButtonGroup
							title="Default calculation type"
							firstLabel={"Cycles"}
							secondLabel={"Weeks"}
							regName={"default_calculation_type"}
							defaultCheckedFirst={checkDefaultValueRadio(update, defaultValues && defaultValues.default_calculation_type)}
							defaultCheckedSecond={checkDefaultValueRadio(update, defaultValues && !defaultValues.default_calculation_type)}
							register={register}
							tooltip={"Choose which type to be used as default for user"}
						/>
					</div>
					{/* <div className="dropdowns-container">
						<Box w="49%">
							<label>Role</label>
							<Controller
								control={control}
								options={selectData.roles}
								as={CustomSelectAll}
								defaultValue={defaultValues ? defaultValues.defaultRole : selectData.roles[0]}
								name="role"
								placeholder="Select user role..."
								className="react-select-container select-user"
								classNamePrefix="react-select"
								styles={customStylesSelect}
							/>
						</Box>
					</div> */}
					<div className="switch-section">
						<div className="switch-column-groups">
							<div className="switch-group">
								<label>Wastage permissions</label>
								<Switch
									label={"Wastage calculation"}
									regName={"wastage_calculation"}
									tooltip={"Visibility of wastage calculation option"}
									defaultChecked={update && defaultValues.wastage_calculation}
									register={register}
								/>

								<Switch
									label={"Default wastage calculation"}
									regName={"default_wastage_calc_option"}
									defaultChecked={update && defaultValues.default_wastage_calc_option}
									tooltip={
										"Default option for wastage calculation. Off we will include wastage in calculation and on the end we don't have wastage."
									}
									register={register}
								/>
							</div>
							<div className="switch-group">
								<label>Calculator permissions</label>

								<Switch
									label={"Auto calculate"}
									regName={"auto_calculate"}
									defaultChecked={update ? defaultValues.auto_calculate : true}
									register={register}
									tooltip={"Auto generate calculator output without any click"}
								/>

								<Switch
									label={"Select all indications"}
									regName={"select_all_indications"}
									defaultChecked={update ? defaultValues.select_all_indications : true}
									register={register}
									tooltip={"Select all indications automatically (works only on standard calculator mode)"}
								/>

								<Switch
									label={"Reference dosing"}
									regName={"reference_dosing"}
									defaultChecked={update && defaultValues.reference_dosing}
									register={register}
									tooltip={
										"When enabled for the user, the calculation of the price is made only on the packs corresponding to the reference dosing (check indication approval table)"
									}
								/>

								<Switch
									label={"Brand type filtering"}
									regName={"brand_type_filtering"}
									defaultChecked={update ? defaultValues.brand_type_filtering : false}
									register={register}
									tooltip={"Use brand type filtering"}
								/>

								<Switch
									label={"Caching"}
									regName={"caching_on"}
									defaultChecked={update ? defaultValues.caching_on : false}
									register={register}
									tooltip={"Turn on/off caching"}
								/>

								<Flex gridGap="10px" align="center" fontSize="14px">
									<Tooltip
										label={"Default number of drug placeholders on advanced calculator mode"}
										hasArrow
										arrowSize={10}
										px="12px"
										py="8px"
									>
										<TagLabel color="white">Default drug holders</TagLabel>
									</Tooltip>
									<NumberInput
										h="40px"
										w="60px"
										defaultValue={defaultValues ? defaultValues.adv_mode_drug_holders : 1}
										min={1}
										max={200}
										borderRadius="8px"
									>
										<NumberInputField name={"adv_mode_drug_holders"} ref={register({ required: true })} />
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
								</Flex>
							</div>

							<div className="switch-group">
								<label className="radio-title">Cycle rounding</label>

								<div className="cycle-rounding-radio">
									<Tooltip label={"Round cycles/weeks up"} hasArrow arrowSize={10} px="12px" py="8px">
										<p> Round up</p>
									</Tooltip>
									<label className="radio-button label-inputs">
										<input
											type="radio"
											name="cycle_rounding"
											defaultChecked={checkDefaultValueRadio(
												update,
												defaultValues && defaultValues.cycle_rounding === roundUp
											)}
											value={"round_up"}
											ref={register}
										/>

										<span className="checkmark"></span>
									</label>
								</div>
								<div className="cycle-rounding-radio">
									<Tooltip label={"Round cycles/weeks down"} hasArrow arrowSize={10} px="12px" py="8px">
										<p>Round down</p>
									</Tooltip>
									<label className="radio-button">
										<input
											type="radio"
											name="cycle_rounding"
											defaultChecked={checkDefaultValueRadio(
												update,
												defaultValues && defaultValues.cycle_rounding === roundDown
											)}
											value={"round_down"}
											ref={register}
										/>
										<span className="checkmark"></span>
									</label>
								</div>
								<div className="cycle-rounding-radio">
									<Tooltip
										label={"Get exact number (divide weeks/cycles to have exact output)"}
										hasArrow
										arrowSize={10}
										px="12px"
										py="8px"
									>
										<p>Exact number</p>
									</Tooltip>
									<label className="radio-button label-inputs">
										<input
											type="radio"
											name="cycle_rounding"
											defaultChecked={checkDefaultValueRadio(
												update,
												defaultValues && defaultValues.cycle_rounding === exactNumber
											)}
											value={"exact_number"}
											ref={register}
										/>
										<span className="checkmark"></span>
									</label>
								</div>
							</div>

							{update && (
								<div className="radio-button-group-with-title" style={{ marginTop: "10px" }}>
									<FormCheckbox>
										<div className="checkbox-group" style={{ justifyContent: "flex-end" }}>
											<Tooltip
												label={
													"Indication to show if user have default indications set (works on standard calculator mode)"
												}
												hasArrow
												arrowSize={10}
												px="12px"
												py="8px"
											>
												<label
													className="checkbox-title"
													style={{
														color: "var(--white)",
														fontSize: "14px",
													}}
												>
													Default indications
												</label>
											</Tooltip>
											<div className="checkbox">
												<input
													type="checkbox"
													id="default_indications"
													disabled={true}
													checked={defaultValues && defaultValues.default_indications_exists}
												/>
												<label htmlFor="default_indications"></label>
											</div>
										</div>
										<div
											className="checkbox-group"
											style={{
												justifyContent: "flex-end",
												marginTop: "6px",
											}}
										>
											<Tooltip
												label={
													"Indication to show if user have all packages automatically set (works on standard calculator mode, also auto calculate need this checked to be able to work)"
												}
												hasArrow
												arrowSize={10}
												px="12px"
												py="8px"
											>
												<label
													className="checkbox-title"
													style={{
														color: "var(--white)",
														fontSize: "14px",
													}}
												>
													Default packages
												</label>
											</Tooltip>
											<div className="checkbox">
												<input
													type="checkbox"
													id="default_packages"
													disabled={true}
													checked={defaultValues && defaultValues.default_packages_exists}
												/>
												<label htmlFor="default_packages"></label>
											</div>
										</div>
									</FormCheckbox>
								</div>
							)}
						</div>
						<div className="switch-column-groups">
							<div className="switch-group">
								<label>Visualization permissions</label>

								<Switch
									label={"Tables"}
									regName={tables}
									defaultChecked={checkDefaultValueRadio(
										update,
										defaultValues && defaultValues.allowed_graphs.includes(tables)
									)}
									register={register}
									tooltip={"Show user posology tables"}
								/>

								<Switch
									label={"Country"}
									regName={countryGraph}
									defaultChecked={checkDefaultValueRadio(
										update,
										defaultValues && defaultValues.allowed_graphs.includes(countryGraph)
									)}
									register={register}
									tooltip={"Show user basic country graph"}
								/>

								<Switch
									label={"Stacked"}
									regName={stacked}
									defaultChecked={checkDefaultValueRadio(
										update,
										defaultValues && defaultValues.allowed_graphs.includes(stacked)
									)}
									register={register}
									tooltip={"Show user country graph stacked"}
								/>

								<Switch
									label={"Evolution"}
									regName={evolution}
									defaultChecked={checkDefaultValueRadio(
										update,
										defaultValues && defaultValues.allowed_graphs.includes(evolution)
									)}
									register={register}
									tooltip={"Show user evolution graph"}
								/>

								<Switch
									label={"Show pipeline"}
									regName={"show_pipeline"}
									defaultChecked={update && defaultValues.show_pipeline}
									register={register}
									tooltip={"Show pipeline data in country graph stacked"}
								/>

								<Switch
									label={"Show treatment line"}
									regName={"show_treatment_line"}
									defaultChecked={checkDefaultValueRadio(update, defaultValues && defaultValues.show_treatment_line)}
									register={register}
									tooltip={"Show treatment line above country graph stacked"}
								/>

								<Switch
									label={"Flexible drug order"}
									regName={"flexible_drug_order"}
									defaultChecked={update && defaultValues.flexible_drug_order}
									register={register}
									tooltip={
										"Turn off to have flexible order (app sort all bars by treatment line and bar stack on every bar to have same order inside bar stack) - Used on standard calculator mode"
									}
								/>
							</div>
							<div className="switch-group">
								<label>Package option allowed/default</label>
								<Switch
									label={"Cheapest per strength"}
									regName={cheapestPerStrength}
									defaultChecked={checkDefaultValueRadio(
										update,
										defaultValues && defaultValues.package_option.includes(cheapestPerStrength)
									)}
									register={register}
									radio={true}
									radioRegName={"default_package_option"}
									radioDefaultChecked={checkDefaultValueRadio(
										update,
										defaultValues && defaultValues.default_package_option === cheapestPerStrength
									)}
									tooltip={
										"Check to have default select, off/on to remove it from user calculator (User can choose cheapest package per strength)"
									}
								/>

								<Switch
									label={"Cheapest (all)"}
									regName={cheapestAll}
									defaultChecked={checkDefaultValueRadio(
										update,
										defaultValues && defaultValues.package_option.includes(cheapestAll)
									)}
									register={register}
									radio={true}
									radioRegName={"default_package_option"}
									radioDefaultChecked={checkDefaultValueRadio(
										update,
										defaultValues && defaultValues.default_package_option === cheapestAll
									)}
									tooltip={
										"Check to have default select, off/on to remove it from user calculator (Calculator calculate all possible combinations)"
									}
								/>

								<Switch
									label={"Choose any package"}
									regName={chooseAnyPackage}
									defaultChecked={checkDefaultValueRadio(
										update,
										defaultValues && defaultValues.package_option.includes(chooseAnyPackage)
									)}
									register={register}
									radio={true}
									radioRegName={"default_package_option"}
									radioDefaultChecked={checkDefaultValueRadio(
										update,
										defaultValues && defaultValues.default_package_option === chooseAnyPackage
									)}
									tooltip={
										"Check to have default select, off/on to remove it from user calculator (User can choose any package)"
									}
								/>
							</div>

							<div className="switch-group">
								<label className="radio-title">Cluster bar coloring type</label>
								<div className="cycle-rounding-radio">
									<Tooltip
										label={
											"Related only to cluster coloring, if checked all drugs from cluster will take color from cluster row in product name table"
										}
										hasArrow
										arrowSize={10}
										px="12px"
										py="8px"
									>
										<p>All drugs same</p>
									</Tooltip>
									<label className="radio-button label-inputs">
										<input
											type="radio"
											name="cluster_bar_coloring_type"
											defaultChecked={checkDefaultValueRadio(
												update,
												defaultValues && defaultValues.cluster_bar_coloring_type === allDrugsSame
											)}
											value={"all_drugs_same"}
											ref={register}
										/>

										<span className="checkmark"></span>
									</label>
								</div>
								<div className="cycle-rounding-radio">
									<Tooltip
										label={
											"Related only to cluster coloring, if checked all drugs from cluster will take their color from color row in product name table"
										}
										hasArrow
										arrowSize={10}
										px="12px"
										py="8px"
									>
										<p>All drugs different</p>
									</Tooltip>
									<label className="radio-button">
										<input
											type="radio"
											name="cluster_bar_coloring_type"
											defaultChecked={checkDefaultValueRadio(
												update,
												defaultValues && defaultValues.cluster_bar_coloring_type === allDrugsDifferent
											)}
											value={"all_drugs_different"}
											ref={register}
										/>
										<span className="checkmark"></span>
									</label>
								</div>
							</div>
						</div>
						<div className="switch-column-groups">
							<div className="switch-group">
								<label>Price allowed/default</label>

								<Switch
									label={"HOSP"}
									regName={"hosp"}
									defaultChecked={update && checkPricePermission(hosp)}
									register={register}
									price={true}
									priceInputDefaultValue={
										defaultValues && defaultValues.allowed_prices["hosp"]
											? defaultValues.allowed_prices["hosp"]
											: "HOSP"
									}
									priceDefaultChecked={update && defaultValues.hosp_checkbox}
									tooltip={
										"Toggle to show/hide, write show value inside inpu field, and check to be default set on user calculator"
									}
								/>

								<Switch
									label={"MNF"}
									regName={"mnf"}
									defaultChecked={update ? checkPricePermission(mnf) : true}
									register={register}
									price={true}
									priceInputDefaultValue={
										defaultValues && defaultValues.allowed_prices["mnf"] ? defaultValues.allowed_prices["mnf"] : "P1"
									}
									priceDefaultChecked={update && defaultValues.mnf_checkbox}
									tooltip={
										"Toggle to show/hide, write show value inside inpu field, and check to be default set on user calculator"
									}
								/>

								<Switch
									label={"PPP"}
									regName={"ppp"}
									defaultChecked={update && checkPricePermission(ppp)}
									register={register}
									price={true}
									priceInputDefaultValue={
										defaultValues && defaultValues.allowed_prices["ppp"] ? defaultValues.allowed_prices["ppp"] : "PPP"
									}
									priceDefaultChecked={update && defaultValues.ppp_checkbox}
									tooltip={
										"Toggle to show/hide, write show value inside inpu field, and check to be default set on user calculator"
									}
								/>

								<Switch
									label={"PPE VAT"}
									regName={"ppeVat"}
									defaultChecked={update ? checkPricePermission(ppeVat) : true}
									register={register}
									price={true}
									priceInputDefaultValue={
										defaultValues && defaultValues.allowed_prices["ppe_vat"]
											? defaultValues.allowed_prices["ppe_vat"]
											: "P3"
									}
									priceDefaultChecked={update && defaultValues.ppeVat_checkbox}
									tooltip={
										"Toggle to show/hide, write show value inside inpu field, and check to be default set on user calculator"
									}
								/>

								<Switch
									label={"PPI VAT"}
									regName={"ppiVat"}
									defaultChecked={update ? checkPricePermission(ppiVat) : true}
									register={register}
									price={true}
									priceInputDefaultValue={
										defaultValues && defaultValues.allowed_prices["ppi_vat"]
											? defaultValues.allowed_prices["ppi_vat"]
											: "P4"
									}
									priceDefaultChecked={update && defaultValues.ppiVat_checkbox}
									tooltip={
										"Toggle to show/hide, write show value inside inpu field, and check to be default set on user calculator"
									}
								/>

								<Switch
									label={"LISTED"}
									regName={"listed"}
									defaultChecked={update ? checkPricePermission(listed) : true}
									register={register}
									price={true}
									priceInputDefaultValue={
										defaultValues && defaultValues.allowed_prices["listed"]
											? defaultValues.allowed_prices["listed"]
											: "LISTED"
									}
									priceDefaultChecked={update && defaultValues.listed_checkbox}
									tooltip={
										"Toggle to show/hide, write show value inside inpu field, and check to be default set on user calculator"
									}
								/>
							</div>
							<div className="switch-group">
								<label>Price rounding</label>
								<div className="cycle-rounding-radio">
									<Tooltip label={"Price will be round up"} hasArrow arrowSize={10} px="12px" py="8px">
										<p> Round up</p>
									</Tooltip>
									<label className="radio-button label-inputs">
										<input
											type="radio"
											name="drug_rounding"
											defaultChecked={checkDefaultValueRadio(
												update,
												defaultValues && defaultValues.drug_rounding === roundUp
											)}
											value={roundUp}
											ref={register}
										/>

										<span className="checkmark"></span>
									</label>
								</div>
								<div className="cycle-rounding-radio">
									<Tooltip label={"Price will be round down"} hasArrow arrowSize={10} px="12px" py="8px">
										<p>Round down</p>
									</Tooltip>
									<label className="radio-button">
										<input
											type="radio"
											name="drug_rounding"
											defaultChecked={checkDefaultValueRadio(
												update,
												defaultValues && defaultValues.drug_rounding === roundDown
											)}
											value={roundDown}
											ref={register}
										/>
										<span className="checkmark"></span>
									</label>
								</div>
								<div className="cycle-rounding-radio">
									<Tooltip
										label={"If price is more or equal to 0.5: round up else round down"}
										hasArrow
										arrowSize={10}
										px="12px"
										py="8px"
									>
										<p>Nearest int</p>
									</Tooltip>
									<label className="radio-button label-inputs">
										<input
											type="radio"
											name="drug_rounding"
											defaultChecked={checkDefaultValueRadio(
												update,
												defaultValues && defaultValues.drug_rounding === nearestInt
											)}
											value={nearestInt}
											ref={register}
										/>
										<span className="checkmark"></span>
									</label>
								</div>
							</div>
							<div className="switch-group">
								<label>Week permissions</label>
								<Flex gridGap="10px" align="center" fontSize="14px">
									<Tooltip
										label={"Start of week/cycles on user calculator (default set)"}
										hasArrow
										arrowSize={10}
										px="12px"
										py="8px"
									>
										<TagLabel color="white">Start</TagLabel>
									</Tooltip>
									<NumberInput
										h="40px"
										w="90px"
										defaultValue={defaultValues ? defaultValues.week_start : 1}
										min={1}
										max={200}
										borderRadius="8px"
									>
										<NumberInputField name={"week_start"} ref={register({ required: true })} />
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
								</Flex>
								<Flex gridGap="10px" align="center" fontSize="14px">
									<Tooltip
										label={"End of week/cycles on user calculator (default set)"}
										hasArrow
										arrowSize={10}
										px="12px"
										py="8px"
									>
										<TagLabel color="white">End</TagLabel>
									</Tooltip>
									<NumberInput
										h="40px"
										w="90px"
										defaultValue={defaultValues ? defaultValues.week_end : 52}
										min={1}
										max={200}
										borderRadius="8px"
									>
										<NumberInputField name={"week_end"} ref={register({ required: true })} />
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
								</Flex>
								<Switch
									label={"Show start"}
									regName={"show_start_number"}
									defaultChecked={update ? defaultValues.show_start_number : true}
									register={register}
									// tooltip={"Show/hide column"}
								/>
								<Switch
									label={"Show end"}
									regName={"show_end_number"}
									defaultChecked={update ? defaultValues.show_end_number : true}
									register={register}
									// tooltip={"Show/hide column"}
								/>
							</div>
						</div>
						<div className="switch-column">
							<div className="switch-group">
								<label>Navigation page permissions</label>

								<Switch
									label={"Bsa weight page access"}
									regName={"show_bsa_weight_page"}
									defaultChecked={checkDefaultValueRadio(update, defaultValues && defaultValues.show_bsa_weight_page)}
									register={register}
									tooltip={"User have access to bsa/weight page"}
								/>

								<Switch
									label={"Currency rate page access"}
									regName={"show_currency_rate_page"}
									defaultChecked={checkDefaultValueRadio(update, defaultValues && defaultValues.show_currency_rate_page)}
									register={register}
									tooltip={"User have access to currency page"}
								/>

								<Switch
									label={"Dosage pattern page access"}
									regName={"show_dosage_pattern_page"}
									defaultChecked={checkDefaultValueRadio(update, defaultValues && defaultValues.show_dosage_pattern_page)}
									register={register}
									tooltip={"User have access to dosage pattern page"}
								/>

								<Switch
									label={"HTA/Reimbursement access"}
									regName={"hta_reimbursement_access"}
									defaultChecked={checkDefaultValueRadio(update, defaultValues && defaultValues.hta_reimbursement_access)}
									register={register}
									tooltip={"User have access to dashboard page"}
								/>
							</div>
							<div className="switch-group">
								<label>Field permissions</label>

								<Switch
									label={"Annual access"}
									regName={"show_single_year_radio"}
									defaultChecked={checkDefaultValueRadio(update, defaultValues && defaultValues.show_single_year_radio)}
									register={register}
									tooltip={"Show/hide annual checkbox"}
								/>

								<Switch
									label={"28 days access"}
									regName={"show_single_month_radio"}
									defaultChecked={checkDefaultValueRadio(update, defaultValues && defaultValues.show_single_month_radio)}
									register={register}
									tooltip={"Show/hide 28 days checkbox"}
								/>

								<Switch
									label={"Calculate type cycles access"}
									regName={"allow_calc_type_cycles"}
									defaultChecked={checkDefaultValueRadio(update, defaultValues && defaultValues.allow_calc_type_cycles)}
									register={register}
									tooltip={"Show/hide cycles radio"}
								/>

								<Switch
									label={"Calculate type weeks access"}
									regName={"allow_calc_type_weeks"}
									defaultChecked={checkDefaultValueRadio(update, defaultValues && defaultValues.allow_calc_type_weeks)}
									register={register}
									tooltip={"Show/hide weeks radio"}
								/>

								<Switch
									label={"Treatment line access"}
									regName={"show_filter_by_treatment_line"}
									defaultChecked={checkDefaultValueRadio(
										update,
										defaultValues && defaultValues.show_filter_by_treatment_line
									)}
									register={register}
									tooltip={"Show/hide treatment line dropdown"}
								/>

								<Switch
									label={"Brand type access"}
									regName={"allow_brand_type"}
									defaultChecked={checkDefaultValueRadio(update, defaultValues && defaultValues.allow_brand_type)}
									register={register}
									tooltip={"Show/hide brand type dropdown"}
								/>
							</div>
							<div className="switch-group">
								<label>General permissions</label>

								<Switch
									label={"Template"}
									regName={"is_template"}
									defaultChecked={checkDefaultValueRadio(update, defaultValues && defaultValues.is_template)}
									register={register}
									tooltip={"Set checked if account is template"}
								/>
							</div>
						</div>
						<div>
							<div className="switch-group">
								<div className="switch-group">
									<label className="radio-title">Dashboard permissions</label>
									<Switch
										label={"EU Market access"}
										regName={euMarketAccess}
										defaultChecked={update ? defaultValues.eu_market_access : true}
										register={register}
										tooltip={"Allow EU data"}
									/>

									<Switch
										label={"Protocol"}
										regName={protocol}
										defaultChecked={update ? defaultValues.allow_protocol : true}
										register={register}
										tooltip={"Show/hide column"}
									/>

									<Switch
										label={"Default protocol"}
										regName={defaultProtocol}
										defaultChecked={update ? defaultValues.default_protocol : true}
										register={register}
										tooltip={"Show/hide column"}
									/>

									<Switch
										label={"Transplant eligibility"}
										regName={transplantEligibility}
										defaultChecked={update ? defaultValues.allow_transplant_eligibility : true}
										register={register}
										tooltip={"Show/hide column"}
									/>

									<Switch
										label={"Extended treatment line"}
										regName={extendedTreatmentLine}
										defaultChecked={update ? defaultValues.allow_extended_treatment_line : true}
										register={register}
										tooltip={"Show/hide column"}
									/>

									<Switch
										label={"Default extended treatment line"}
										regName={defaultExtendedTreatmentLine}
										defaultChecked={update ? defaultValues.default_extended_treatment_line : true}
										register={register}
										tooltip={"Show/hide column"}
									/>

									<Switch
										label={"Gender"}
										regName={gender}
										defaultChecked={update ? defaultValues.allow_gender : true}
										register={register}
										tooltip={"Show/hide column"}
									/>

									<Switch
										label={"HER2"}
										regName={her2}
										defaultChecked={update ? defaultValues.allow_her2 : true}
										register={register}
										tooltip={"Show/hide column"}
									/>

									<Switch
										label={"HR"}
										regName={hr}
										defaultChecked={update ? defaultValues.allow_hr : true}
										register={register}
										tooltip={"Show/hide column"}
									/>

									<Switch
										label={"PIK3CA"}
										regName={pik3ca}
										defaultChecked={update ? defaultValues.allow_pik3ca : true}
										register={register}
										tooltip={"Show/hide column"}
									/>

									<Switch
										label={"Menopause"}
										regName={menopause}
										defaultChecked={update ? defaultValues.allow_menopause : true}
										register={register}
										tooltip={"Show/hide column"}
									/>

									<Switch
										label={"Node"}
										regName={node}
										defaultChecked={update ? defaultValues.allow_node : true}
										register={register}
										tooltip={"Show/hide column"}
									/>

									<Switch
										label={"Symptomatic visceral involvement"}
										regName={symptomaticVisceralInvolvement}
										defaultChecked={update ? defaultValues.allow_symptomatic_visceral_involvement : true}
										register={register}
										tooltip={"Show/hide column"}
									/>

									<Switch
										label={"Active ingredient"}
										regName={activeIngredient}
										defaultChecked={update ? defaultValues.allow_active_ingredient : true}
										register={register}
										tooltip={"Show/hide column"}
									/>

									<Switch
										label={"Orphan designation"}
										regName={orphanDesignation}
										defaultChecked={update ? defaultValues.allow_orphan_designation : true}
										register={register}
										tooltip={"Show/hide column"}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
			<div className="btns-wrapper">
				<div>
					<Button variant="white" size="xl" onClick={() => showConfirmDialog()}>
						Invalidate cache
					</Button>
				</div>

				<div>
					<Button type="submit" variant="green" size="xl">
						Save
					</Button>
					<Button variant="cancel" size="xl" onClick={() => cancel()} ml="20px">
						Cancel
					</Button>
				</div>
				<div></div>
			</div>
		</UserFormStyled>
	)
}

const UserFormStyled = styled.form`
	display: flex;
	flex-direction: column;
	row-gap: 20px;
	min-width: 80%;
	width: 1550px;
	height: 90vh;
	text-align: center;
	padding: 15px 20px;
	position: relative;
	.test {
		transform: rotate(180deg);
	}
	.select-user {
		.react-select__placeholder {
			color: var(--black);
			font-weight: 400;
		}
		.react-select__single-value {
			color: var(--black);
			font-weight: bold;
		}
	}
	input {
		height: 40px;
		background: var(--white);
		border-radius: 4px;
		border: none;
		width: 281px;
		margin-bottom: 20px;
		padding: 0 10px 0 10px;
		color: var(--black);
		font-weight: bold;
		font-size: 16px;
		&::placeholder {
			color: var(--black);
			font-size: 16px;
			font-weight: 400;
		}
	}

	.personalData-section {
		justify-content: center;
		column-gap: 80px;
		width: 100%;
		display: flex;

		.sides {
			width: 100%;
			gap: 10px;
			&-inputGroup {
				display: flex;
				margin-bottom: 15px;
				align-items: center;
				gap: 15px;
			}
			.label-inputs {
				display: block;
				width: 120px;
				flex-wrap: nowrap;
				text-align: right;
				color: var(--white);
			}
			.pas-input-wrapper {
				width: 100%;
				position: relative;
			}
		}
	}
	.pas-input {
		padding-right: 50px;
	}
	.visibility-icon {
		position: absolute;
		right: 16px;
		top: 8px;
	}
	input {
		width: 100%;
		margin-bottom: 0;
	}

	.switch-column {
		display: flex;
		flex-direction: column;
		row-gap: 20px;
	}

	.permission-section {
		width: 100%;
		padding-top: 20px;
		padding-bottom: 20px;
		border-top: 1px solid var(--grey);
		border-bottom: 1px solid var(--grey);
		overflow-y: scroll;
		padding-right: 4px;
		&::-webkit-scrollbar-thumb {
			background-color: var(--white);
		}
		&::-webkit-scrollbar-track {
			background: var(--blue);
			width: 100%;
		}

		.radio-buttons-section {
			display: flex;
			flex-wrap: wrap;
			row-gap: 20px;
			column-gap: 10px;
			/* justify-content: space-between; */
			align-items: flex-start;
			margin-bottom: 20px;
			margin-top: 20px;
			color: var(--white);
			width: 100%;
			.radio-button-group-with-title {
				min-width: 280px;
				height: 90px;
				border-radius: 2px;
				border: 0.5px solid var(--white);
				padding: 2px;
			}
			.radio-title {
				display: inline-block;
				margin-bottom: 10px;
				border-bottom: 1px solid var(--white);
			}
			.radio-button-group {
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0 auto;
				width: 24%;

				&-with-title {
					width: 24%;
				}
				.radio-button {
					display: block;
					position: relative;
					padding: 0;
					padding-left: 35px;
					:first-child {
						margin-right: 20px;
					}
					cursor: pointer;
					font-size: 16px;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
				}
				.radio-button input {
					position: absolute;
					opacity: 0;
					cursor: pointer;
					display: none;
				}
				.checkmark {
					position: absolute;
					top: 0;
					left: 0;
					height: 25px;
					width: 25px;
					background-color: var(--blue);
					border: 2px solid var(--white);
					border-radius: 50%;
				}
				.radio-button:hover input ~ .checkmark {
					background-color: var(--blue);
				}
				.radio-button input:checked ~ .checkmark {
					background-color: var(--blue);
				}
				.checkmark:after {
					content: "";
					position: absolute;
					display: none;
				}
				.radio-button input:checked ~ .checkmark:after {
					display: block;
				}

				.radio-button .checkmark:after {
					width: 13px;
					height: 13px;
					border-radius: 50%;
					background: var(--white);
					transform: translate(4px, 4px);
				}
			}
			.checkbox-group {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 10px;
				.checkbox-title {
					display: block;
					padding-bottom: 10px;
					padding-left: 10px;
				}
				input {
					height: 20px;
				}
			}
		}
		.dropdowns-container {
			width: 100%;
			display: flex;
			column-gap: 20px;
			height: 120px;

			&-drugs,
			&-countries {
				width: 50%;
			}
			label {
				display: block;
				margin-bottom: 10px !important;
				color: var(--white);
			}
		}
		.switch-section {
			display: flex;
			justify-content: space-between;
			width: 100%;
			padding-top: 20px;
			.switch-column-groups {
				display: flex;
				flex-direction: column;
				grid-gap: 35px;
			}
			.switch-group {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				row-gap: 15px;
				&-partial {
					display: flex;
					p {
						font-size: 14px;
						color: var(--white);
					}
					&-input {
						height: 22px;
						width: 130px;
						font-size: 14px;
						margin-left: 10px;
					}
				}

				label {
					color: var(--white);
					display: block;
					margin: 0 auto;
					border-bottom: 1px solid var(--white);
				}
				.cycle-rounding-radio {
					display: flex;
					column-gap: 10px;
					label {
						border: none;
					}
					p {
						font-size: 14px;
						color: var(--white);
					}
				}
			}
			.checkbox {
				margin: 0;
				width: 20px;
				margin-left: 5px;
			}
			.packageOption-radio-wrapper {
				width: "25px";
				height: "20px";
				margin-left: 10px;
				label {
					border-bottom: none;
				}
			}
			.radio-button {
				display: block;
				position: relative;
				padding: 0;
				padding-left: 30px;
				border-bottom: none;
				cursor: pointer;
				font-size: 16px;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			.radio-button input {
				position: absolute;
				opacity: 0;
				cursor: pointer;
				display: none;
			}
			.checkmark {
				position: absolute;
				top: 0;
				left: 0;
				height: 25px;
				width: 25px;
				background-color: var(--blue);
				border: 2px solid var(--white);
				border-radius: 50%;
				transform: translate(0, -2px);
			}
			.radio-button:hover input ~ .checkmark {
				background-color: var(--blue);
			}
			.radio-button input:checked ~ .checkmark {
				background-color: var(--blue);
			}
			.checkmark:after {
				content: "";
				position: absolute;
				display: none;
			}
			.radio-button input:checked ~ .checkmark:after {
				display: block;
			}

			.radio-button .checkmark:after {
				width: 13px;
				height: 13px;
				border-radius: 50%;
				background: var(--white);
				transform: translate(4px, 4px);
			}

			.checkbox-group {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 10px;
				.checkbox-title {
					display: block;
					padding-bottom: 10px;
					padding-left: 10px;
				}
				input {
					height: 20px;
				}
			}
		}
	}
	.btns-wrapper {
		display: grid;
		width: 100%;
		// margin-top: 30px;
		/* height:100px; */
		padding-top: 20px;
		grid-template-columns: repeat(3, 1fr);
		align-items: center;

		.cancelBtn {
			margin-left: 20px;

			&:hover {
				color: var(--red);
				transition: 0.3 ease;
			}
		}
	}
	.react-select-container {
		.react-select__control {
			border-radius: 4px;
			background: var(--white);
			max-height: 120px;
			color: var(--black);
		}

		.react-select__value-container {
			max-height: 80px;
			overflow-y: auto;
		}
		.react-select__multi-value__remove {
			color: var(--blue);
		}

		.react-select__placeholder {
			color: var(--black);
			font-weight: 400;
		}
		.react-select__menu {
			/* max-height: 200px; */
			margin-bottom: 20px;
			overflow-y: hidden;
		}
	}
`

export default UserForm
