import { Box, FormLabel } from "@chakra-ui/react"
import React from "react"
import DatePicker from "react-date-picker"
import { Controller, useForm } from "react-hook-form"
import Select from "react-select"
import styled from "styled-components"
import { superAdminAccess } from "../../../utils/constants"
import CustomButton from "../../buttons/CustomButton"
import { customStylesSelect } from "../../CustomSelectStyles"

const IndicationApprovalForm = ({ defaultValues, onSubmit, selectData, cancel, role }) => {
	const { handleSubmit, control, register } = useForm()

	return (
		<FormWrapper onSubmit={handleSubmit(onSubmit)}>
			<div className="dropdown-wrapper">
				<label>Drug indication</label>
				<Controller
					control={control}
					options={selectData.indications}
					as={Select}
					defaultValue={defaultValues && defaultValues.defaultIndication}
					name="indication"
					placeholder="Select indication..."
					className="react-select-container"
					classNamePrefix="react-select"
					styles={customStylesSelect}
					rules={{ required: true, message: "This field is required" }}
				/>
			</div>
			<div className="dropdown-wrapper">
				<label>Reference doses</label>
				<Controller
					isMulti
					control={control}
					options={selectData.references}
					as={Select}
					defaultValue={defaultValues && defaultValues.defaultReferenceDoses}
					name="reference_dose"
					placeholder="Select reference doses..."
					className="react-select-container"
					classNamePrefix="react-select"
					styles={customStylesSelect}
				/>
			</div>

			<div className="row-section">
				<div className="country">
					<label>Country</label>
					<Controller
						control={control}
						options={selectData.countries}
						as={Select}
						defaultValue={(defaultValues && defaultValues.defaultCountry) || null}
						name="country"
						placeholder="Select country..."
						className="react-select-container"
						classNamePrefix="react-select"
						styles={customStylesSelect}
						rules={{ required: true, message: "This field is required" }}
					/>
				</div>
				<div className="date">
					<label>Date</label>
					<Controller
						control={control}
						as={<DatePicker locale="en-GB" />}
						defaultValue={defaultValues && defaultValues.defaultDate}
						name="approvalDate"
						placeholder="dd/MM/yyyy"
						className="date-picker"
					/>
				</div>
				<div>
					<label>Ongoing</label>
					<div className="checkbox">
						<input
							id="ongoing"
							name="ongoing"
							type="checkbox"
							ref={register}
							defaultChecked={defaultValues && defaultValues.ongoing}
						/>
						<label htmlFor="ongoing"></label>
					</div>
				</div>
				<div>
					<label>Pipeline</label>
					<div className="checkbox">
						<input
							id="pipeline"
							name="pipeline"
							type="checkbox"
							ref={register}
							defaultChecked={defaultValues && defaultValues.pipeline}
						/>
						<label htmlFor="pipeline"></label>
					</div>
				</div>
			</div>
			{superAdminAccess.includes(role) && (
				<Box>
					<FormLabel textAlign="center">Organization</FormLabel>
					<Controller
						control={control}
						options={selectData.organizations}
						as={Select}
						defaultValue={defaultValues && defaultValues.organization}
						name="organization"
						placeholder="Select organization..."
						className="react-select-container"
						classNamePrefix="react-select"
						styles={customStylesSelect}
						isClearable={true}
					/>
				</Box>
			)}

			<div className="btns-wrapper">
				<CustomButton type="save" />
				<CustomButton type="cancel" onClick={() => cancel()} />
			</div>
		</FormWrapper>
	)
}

const FormWrapper = styled.form`
	text-align: center;
	width: 743px;
	height: 540px;
	padding: 10px 20px;
	.dropdown-wrapper {
		margin: 10px 0;
	}
	.row-section {
		display: flex;
		width: 100%;
		column-gap: 20px;
		margin: 20px 0;

		.country,
		.date {
			width: 40%;
		}

		.checkbox-section {
			display: flex;
			flex-direction: column;
			width: 16%;
			input {
				all: unset;
			}
		}
		.checkbox {
			width: 20px;
			position: relative;
			margin: 7px auto;

			label {
				width: 22px;
				height: 22px;
				cursor: pointer;
				position: absolute;
				top: 0;
				left: 0;
				background: #fcfff4;
				background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
				border-radius: 4px;
				box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
				&:after {
					content: "";
					width: 10px;
					height: 6px;
					position: absolute;
					top: 7px;
					left: 6px;
					border: 3px solid #333;
					border-top: none;
					border-right: none;
					background: transparent;
					opacity: 0;
					transform: rotate(-45deg);
				}
				&:hover::after {
					opacity: 0.5;
				}
			}
			input[type="checkbox"] {
				margin-bottom: 0;
				visibility: hidden;
				&:checked + label:after {
					opacity: 1;
				}
			}
		}
	}
	.btns-wrapper {
		display: flex;
		justify-content: center;
		height: 120px;
		align-items: flex-end;
		grid-gap: 160px;
	}
	.react-select-container {
		width: 100%;
		height: 40px;
		margin: 0 auto;
		text-align: left;

		.react-select__control {
			border-radius: 4px;
			background: var(--white);
		}

		.react-select__value-container {
			max-height: 70px;
			overflow-y: auto;
		}
		.react-select__multi-value__remove {
			color: var(--blue);
		}

		.react-select__placeholder {
			color: var(--black);
			font-weight: 400;
		}
		.react-select__menu-list {
			height: 160px;
		}
		.react-select__single-value {
			color: var(--black);
		}
	}

	label {
		display: block;
		padding: 8px 0 5px 0;
		color: var(--white);
		font-size: 16px;
	}
	button {
		margin: 30px 0 20px 30px;
	}
	.cancelBtn {
		margin-left: 155px;
		margin-right: 30px;
	}
	.react-date-picker {
		width: 100%;
		height: 40px;

		button {
			all: unset;
		}
		.react-date-picker__wrapper {
			border: none;
			border-radius: 4px;
			background: var(--white);
			color: var(--black);
			input {
				color: var(--black);
			}
		}
		.react-date-picker__clear-button {
			transform: translate(-10px, 0);
			&__icon {
				stroke: var(--black);
			}
		}
		.react-date-picker__calendar-button {
			transform: translate(-6px, 0);
			&__icon {
				stroke: var(--black);
			}
		}
		.react-calendar {
			padding: 15px;
			&__navigation__arrow {
				margin: 5px;
				padding: 5px;
			}
		}
	}
`
export default IndicationApprovalForm
