import { useDispatch, useSelector } from "react-redux"
import { createReference, fetchDropdownData, fetchPage, removeReference, setDefaultSize, updateReference } from "../reducers/reference"

export default function useReference() {
	const dispatch = useDispatch()
	const data = useSelector((state) => state.references.data)
	const dropdownData = useSelector((state) => state.references.dropdownData)
	const pagination = useSelector((state) => state.references.pagination)
	const defaultSize = useSelector((state) => state.references.defaultSize)
	const message = useSelector((state) => state.references.message)
	const loading = useSelector((state) => state.references.loading)

	const create = async (data) => {
		return dispatch(createReference(data))
	}
	const remove = async (id) => {
		return dispatch(removeReference(id))
	}

	const update = async (data) => {
		return dispatch(updateReference(data))
	}

	const fetch = (pageNumber, pageSize) => {
		dispatch(fetchPage(pageNumber, pageSize))
	}

	const fetchDropdown = () => {
		dispatch(fetchDropdownData())
	}

	const changeSize = async (size) => {
		dispatch(setDefaultSize(size))
	}

	return { fetch, create, remove, update, changeSize, data, loading, pagination, defaultSize, message, dropdownData, fetchDropdown }
}
