import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
	createTreatment,
	fetchPage,
	fetchFilteredPage,
	setDefaultSize,
	fetchTreatment,
	fetchTreatmentTableData,
	updateTreatmentScheme,
	updateTreatmentCycles,
	removeTreatment,
	setFormWeekDoses,
	resetCycleForms,
	setCurrentId,
	setWeeksPerCycle,
	cleanDataByDrugIndication,
	addNewForm,
	deleteForm,
	updateCurrentWeekInput,
	updateCurrentDosagePattern,
	updateCurrentInfinite,
	fetchSubPosologies,
} from "../reducers/treatment"

export default function useTreatment() {
	const dispatch = useDispatch()
	const data = useSelector((state) => state.treatments.data)
	const loading = useSelector((state) => state.treatments.loading)
	const subPosologies = useSelector((state) => state.treatments.subPosologies)
	const dataByDrugIndication = useSelector((state) => state.treatments.dataByDrugIndication)
	const currentTreatment = useSelector((state) => state.treatments.currentTreatment)
	const tables = useSelector((state) => state.treatments.tables)
	const message = useSelector((state) => state.treatments.message)
	const forms = useSelector((state) => state.treatments.forms)
	const currentId = useSelector((state) => state.treatments.currentId)
	const weeksPerCycle = useSelector((state) => state.treatments.weeksPerCycle)
	const pagination = useSelector((state) => state.treatments.pagination)
	const defaultSize = useSelector((state) => state.treatments.defaultSize)

	const [modal, setModal] = useState(false)

	const create = async (payload) => {
		try {
			await dispatch(createTreatment(payload))
		} catch (error) {}
	}

	const remove = async (id) => {
		try {
			setModal(false)
			await dispatch(removeTreatment(id)).then(() => fetch(pagination.current_page, defaultSize))
		} catch (error) {}
	}

	const fetch = (pageNumber, pageSize, filterData) => {
		dispatch(fetchPage(pageNumber, pageSize, filterData))
	}

	const fetchByDrugIndication = (pageNumber, pageSize, drugIndication) => {
		dispatch(fetchFilteredPage(pageNumber, pageSize, drugIndication))
	}

	const changeSize = async (size) => {
		dispatch(setDefaultSize(size))
	}

	const cleanDrugIndicationData = async () => {
		dispatch(cleanDataByDrugIndication())
	}

	const fetchOne = async (id) => {
		try {
			dispatch(fetchTreatment(id))
		} catch (error) {}
	}

	const fetchTables = async (id) => {
		return dispatch(fetchTreatmentTableData(id))
	}

	const updateScheme = async (payload) => {
		return dispatch(updateTreatmentScheme(payload))
	}

	const updateCycles = async (payload) => {
		try {
			setModal(false)
			await dispatch(updateTreatmentCycles(payload))
		} catch (error) {}
	}

	const setWeekDoses = async (formIndex, weekInputs) => {
		dispatch(setFormWeekDoses(formIndex, weekInputs))
	}

	const addForm = async (payload) => {
		dispatch(addNewForm(payload))
	}

	const removeForm = async (formIndex) => {
		dispatch(deleteForm(formIndex))
	}

	const resetForms = async () => {
		dispatch(resetCycleForms())
	}

	const setId = async (number) => {
		dispatch(setCurrentId(number))
	}

	const setWeeks = async (number) => {
		dispatch(setWeeksPerCycle(number))
	}

	const updateOneWeekInput = async (formIndex, weekInputIndex, value) => {
		dispatch(updateCurrentWeekInput(formIndex, weekInputIndex, value))
	}

	const updateDosagePattern = async (formIndex, value) => {
		dispatch(updateCurrentDosagePattern(formIndex, value))
	}

	const updateInfinite = async (formIndex, value) => {
		dispatch(updateCurrentInfinite(formIndex, value))
	}
	const fetchSubPosologiesData = () => {
		dispatch(fetchSubPosologies())
	}

	return {
		data,
		loading,
		message,
		currentTreatment,
		modal,
		tables,
		forms,
		currentId,
		weeksPerCycle,
		pagination,
		defaultSize,
		dataByDrugIndication,
		subPosologies,
		cleanDrugIndicationData,
		fetch,
		changeSize,
		fetchOne,
		create,
		remove,
		updateScheme,
		updateCycles,
		updateOneWeekInput,
		updateDosagePattern,
		updateInfinite,
		fetchByDrugIndication,
		setModal,
		fetchTables,
		setWeekDoses,
		resetForms,
		setId,
		setWeeks,
		addForm,
		removeForm,
		fetchSubPosologiesData,
	}
}
