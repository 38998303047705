import { createAction, createReducer } from "@reduxjs/toolkit"
import axios from "axios"
import { serverErrorMessage } from "../../utils/constants"
import { organizationRemoveURL, organizationURL } from "../api_urls"

const START_REQUEST = createAction("ORGANIZATION/START_REQUEST")
const FETCH_SUCCESS = createAction("ORGANIZATION/FETCH_SUCCESS")
const FETCH_SUCCESS_DROPDOWN = createAction("ORGANIZATION/FETCH_SUCCESS_DROPDOWN")
const REQUEST_SUCCESS = createAction("ORGANIZATION/REQUEST_SUCCESS")
const REQUEST_FAIL = createAction("ORGANIZATION/REQUEST_FAIL")
const CLEAN_MESSAGE = createAction("ORGANIZATION/CLEAN_MESSAGE")

export const fetchOrganizations = () => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.get(organizationURL).then((res) => handleResponse(res, dispatch, FETCH_SUCCESS, REQUEST_FAIL))
}

export const fetchDropdownData = () => async (dispatch) => {
	const url = organizationURL + "?dropdown_data=True"

	dispatch(START_REQUEST())
	return axios.get(url).then((res) => handleResponse(res, dispatch, FETCH_SUCCESS_DROPDOWN, REQUEST_FAIL))
}

export const createOrganization = (data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(organizationURL, data).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const removeOrganization = (id) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.delete(organizationRemoveURL(id)).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const updateOrganization = (data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.put(organizationURL, data).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const cleanMessage = () => async (dispatch) => {
	dispatch(CLEAN_MESSAGE())
}

const handleResponse = (res, dispatch, success, fail) => {
	if (res !== undefined) {
		if (res.status >= 200 && res.status <= 299) {
			if (success === REQUEST_SUCCESS) {
				dispatch(success(res.data.message))
				dispatch(CLEAN_MESSAGE())
			} else {
				dispatch(success(res.data))
			}
			return res
		} else if (res.response !== undefined && res.response.status === 400) {
			dispatch(fail(res.response.data.message))
			dispatch(CLEAN_MESSAGE())
		} else {
			dispatch(fail(serverErrorMessage))
			dispatch(CLEAN_MESSAGE())
		}
	} else {
		dispatch(fail(serverErrorMessage))
		dispatch(CLEAN_MESSAGE())
	}
}

const initState = {
	data: [],
	dropdownData: [],
	loading: false,
	message: null,
}

export const organizationReducer = createReducer(initState, (builder) => {
	builder
		.addCase(START_REQUEST, (state, action) => {
			state.loading = true
			state.message = null
		})
		.addCase(FETCH_SUCCESS, (state, action) => {
			state.loading = false
			state.data = action.payload
		})
		.addCase(FETCH_SUCCESS_DROPDOWN, (state, action) => {
			state.loading = false
			state.dropdownData = action.payload
		})
		.addCase(REQUEST_SUCCESS, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(REQUEST_FAIL, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(CLEAN_MESSAGE, (state, action) => {
			state.message = null
		})
})
