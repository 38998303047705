import { useDispatch, useSelector } from "react-redux"
import { fetchBsaConfig, removeBsaItem, createBsaItem, updateBsaItem } from "../reducers/bsaConfig"

export default function useBsaConfig() {
	const dispatch = useDispatch()
	const bsa = useSelector((state) => state.bsaConfig.bsa)
	const message = useSelector((state) => state.bsaConfig.message)
	const loading = useSelector((state) => state.bsaConfig.loading)

	const getBsaConfig = async () => {
		return dispatch(fetchBsaConfig())
	}

	const createBsa = async (data) => {
		return dispatch(createBsaItem(data))
	}

	const removeBsa = async (id) => {
		return dispatch(removeBsaItem(id))
	}

	const updateBsa = async (data) => {
		return dispatch(updateBsaItem(data))
	}

	return { bsa, getBsaConfig, createBsa, removeBsa, updateBsa, loading, message }
}
