import React from "react"
import styled from "styled-components"
import AdminSidebar from "./AdminSidebar"

const Layout = ({ children }) => {
	return (
		<Container>
			<SidebarStyles>
				<AdminSidebar className="sidebar-container" />
			</SidebarStyles>
			{children}
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	position: relative;
	width: 100%;

	.addBtn {
		position: absolute;
		right: 5px;
		bottom: 30px;
		cursor: pointer;
		width: 68px;
		height: 68px;
		z-index: 30;
		@media (max-width: 1024px) {
			right: 20px;
			bottom: 70px;
		}
		@media (max-width: 1400px) {
			width: 72px;
			height: 72px;
			right: 25px;
			bottom: 32px;
		}
	}

	.resetBtn {
		position: absolute;
		width: 130px;
		height: 30px;
		right: 80px;
		top: 15px;
		color: var(--white);
		background: var(--green);

		@media (max-width: 1400px) {
			right: 60px;
			top: 7px;
		}
		@media (max-width: 1024px) {
			right: 45px;
		}
	}
`
const SidebarStyles = styled.div`
	width: 17%;
	min-width: 160px;

	.sidebar-container {
		position: sticky;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		row-gap: 20px;
		width: 100%;
		min-width: 200px;
		height: 100vh;
		overflow-y: "auto";
		padding: 20px 10px;
		background: var(--blue);
		align-items: center;
		z-index: 10;

		.links-container {
			display: flex;
			flex-direction: column;
			row-gap: 10px;
			max-width: 80vh;
			overflow-y: auto;

			::-webkit-scrollbar {
			}
			::-webkit-scrollbar-thumb {
				background-color: #1f3161;
				border-radius: 4px;
			}
			::-webkit-scrollbar-track {
				margin-left: 2px;
				margin-right: 2px;
				border-radius: 4px;
			}
		}
		.title-box {
			display: flex;
			align-items: center;
		}
	}
	a {
		display: block;
		position: relative;
		// font-size: clamp(13px, 0.8vw, 20px);
		font-size: 13px;
		padding: 2px;
		padding-left: 35px;
		color: var(--white);
		font-weight: 500;
		&:hover {
			text-decoration-line: underline;
		}
	}
	.active {
		span {
			position: absolute;
			width: 5px;
			height: 5px;
			padding: 5px;
			left: 14px;
			bottom: 5px;
			border-radius: 50%;
			background: var(--green);
		}
	}

	h2 {
		color: var(--white);
		font-weight: 500;
		font-size: clamp(20px, 1.5vw, 27px);
		padding: 0 10px 2px 10px;
	}
	img {
		width: min(159px, 40%);
	}
	.linkSections {
		display: flex;
		flex-direction: column;
	}
	.logout-btn {
		background: var(--red);
		color: var(--white);
		margin-top: 5px;
		/* height: 26px; */
		/* max-width: 66px; */
		cursor: pointer;
		padding: 4px 10px;
	}
`

export default Layout
