export const base_url = process.env.REACT_APP_BASE_URL

//AUTH
export const loginURL = "/user/login"
export const oidcURL = "/auth/login"
export const refreshTokenURL = "/user/refresh"
export const updateProfileURL = "/user/profile"

//CONFIG
const reset = "/reset"
const config = "/core/config"
const crawl = "/crawl"
const bsa = "/weight_bsa_config"
const currencyConfig = "/currency"

export const currencyConfigImportURL = config + currencyConfig + "/import"

//fetch
export const configBsaURL = config + bsa
export const configBsaResetURL = config + bsa + reset
export const configCurrencyURL = (isStatic) => `${config}${currencyConfig}?is_static=${isStatic}`
export const configCurrencyResetURL = `${config}${currencyConfig}/reset`
export const configCurrencyCrawlURL = config + currencyConfig + crawl
//remove
export const configBsaRemoveURL = (id) => `${config}${bsa}?id=${id}`
export const configCurrencyRemoveURL = (id) => `${config}${currencyConfig}?id=${id}`

//USERS
const users = "/user"
export const usersDropdownURL = `${users}?dropdown_data=True`
export const usersTemplateDropdownURL = `${users}?template_data=True`
export const usersConfigURL = users + "/config"
export const usersURL = users
//remove
export const usersRemoveURL = (id) => `${users}?id=${id}`
//cashe
export const cacheURL = "/core/cache"

//TREATMENT
const treatments = "/core/cycles"
const schema = "/core/posology"
const subPosologies = "/core/subposologies"

//fetch
export const treatmentsURL = treatments
export const subPosologiesURL = subPosologies
export const schemasURL = schema
export const fetchOneTreatmentURL = (id) => `${treatments}?id=${id}`
export const fetchTreatmentTablesData = (id) => `${treatments}?id=${id}&table=true`

export const treatmentsPagesURL = (pageNumber, perPage) => `${treatments}?per_page=${perPage}&page_no=${pageNumber}&order_by=id`
export const treatmentsFilterByDrugIndicationURL = (pageNumber, perPage, drugIndication) =>
	`${treatments}?per_page=${perPage}&page_no=${pageNumber}&order_by=id&indication=${drugIndication}`
//remove
export const treatmentRemoveURL = (id) => `${treatments}?id=${id}`

//MASTER DATA
export const productName = `ProductName`
export const genericName = `GenericName`
export const brandType = `BrandType`
export const route = `RouteOfAdministration`
export const country = `Country`
export const dosagePattern = `DosagePattern`
export const currency = `Currency`
export const company = `Company`
export const treatmentLineMd = `TreatmentLine`
export const indicationMd = `Indication`
export const packUnit = `PackUnit`
export const fillUnit = `FillUnit`

export const masterDataURL = (type) => `/core/masterdata?entity=${type}`
export const masterDataDropdownURL = (type) => `/core/masterdata?entity=${type}&dropdown_data=True`
export const masterDataRemoveURL = (type, id) => `/core/masterdata?entity=${type}&id=${id}`

//DRUG...
const drugPackage = `/core/drug/package` //package is reserved word!!!
const price = `/core/drug/price`
const indication = `/core/drug/indication`
const reference = "/core/drug/references"
const dropdown = `?dropdown_data=True`
// find and create
export const drugPackageURL = drugPackage
export const drugPriceURL = price
export const drugReferencesURL = reference

export const dropdownIndicationURL = indication + dropdown
export const dropdownPackageURL = drugPackage + dropdown
export const dropdownReferencesURL = reference + dropdown
export const pricesImportURL = drugPriceURL + "/import"

export const pricePagesURL = (pageNumber, perPage, sortBy) => `${price}?per_page=${perPage}&page_no=${pageNumber}&order_by=${sortBy}`
export const packagePagesURL = (pageNumber, perPage, sortBy) =>
	`${drugPackage}?per_page=${perPage}&page_no=${pageNumber}&order_by=${sortBy}`
export const referencePagesURL = (pageNumber, perPage) => `${reference}?per_page=${perPage}&page_no=${pageNumber}&order_by=id`

//remove...
export const packageRemoveURL = (id) => `${drugPackage}?id=${id}`
export const priceRemoveURL = (id) => `${price}?id=${id}`
export const referenceRemoveURL = (id) => `${reference}?id=${id}`
export const drugIndicationByDrugURL = (id) => `${indication}?drug_id=${id}`

//INDICATION
export const drugIndicationURL = indication
export const drugIndicationPagesURL = (pageNumber, perPage) => `/core/drug/indication?per_page=${perPage}&page_no=${pageNumber}&order_by=id`
export const indicationApprovalURL = `/core/drug/indication/approval`
export const indicationApprovalPagesURL = (pageNumber, perPage, sortBy) =>
	`/core/drug/indication/approval?per_page=${perPage}&page_no=${pageNumber}&order_by=${sortBy}`
export const indicationApprovalFilterByDrugIndicationURL = (pageNumber, perPage, drugIndication) =>
	`/core/drug/indication/approval?per_page=${perPage}&page_no=${pageNumber}&order_by=id&drug_indication=${drugIndication}`

//remove
export const drugIndicationRemoveURL = (id) => `${indication}?id=${id}`
export const indicationApprovalRemoveURL = (id) => `/core/drug/indication/approval?id=${id}`

//ORGANIZATION
export const organizationURL = "/organization"
export const organizationRemoveURL = (id) => organizationURL + `?id=${id}`

//API KEY

export const apiKeyURL = "/api_key"
export const apiKeyRemoveURL = (id) => apiKeyURL + `?id=${id}`
