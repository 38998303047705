import { useDispatch, useSelector } from "react-redux"
import { login, loginAuth, loginUser, logout, refreshAccessToken, updateUserProfile } from "../reducers/user"

export default function useUser() {
	const dispatch = useDispatch()
	const username = useSelector((state) => state.user.username)
	const token = useSelector((state) => state.user.token)
	const refreshToken = useSelector((state) => state.user.refreshToken)
	const admin = useSelector((state) => state.user.admin)
	const isStatic = useSelector((state) => state.user.isStatic)
	const firstName = useSelector((state) => state.user.firstName)
	const lastName = useSelector((state) => state.user.lastName)
	const role = useSelector((state) => state.user.role)
	const useParentData = useSelector((state) => state.user.useParentData)
	const organizationId = useSelector((state) => state.user.organizationId)
	const organizationName = useSelector((state) => state.user.organizationName)
	const wastageCalculation = useSelector((state) => state.user.wastageCalculation)
	const defaultWastageCalcOption = useSelector((state) => state.user.defaultWastageCalcOption)
	const indicationText = useSelector((state) => state.user.indicationText)
	const packageOptions = useSelector((state) => state.user.packageOptions)
	const allowedGraphs = useSelector((state) => state.user.allowedGraphs)
	const htaReimbursement = useSelector((state) => state.user.htaReimbursement)
	const singleYear = useSelector((state) => state.user.singleYear)
	const option28Days = useSelector((state) => state.user.option28Days)
	const autoCalculate = useSelector((state) => state.user.autoCalculate)
	const selectAllIndications = useSelector((state) => state.user.selectAllIndications)
	const showBsaWeight = useSelector((state) => state.user.showBsaWeight)
	const showCurrencyRate = useSelector((state) => state.user.showCurrencyRate)
	const showDosagePattern = useSelector((state) => state.user.showDosagePattern)
	const allowedPrices = useSelector((state) => state.user.allowedPrices)
	const defaultPrices = useSelector((state) => state.user.defaultPrices)
	const defaultDrugs = useSelector((state) => state.user.defaultDrugs)
	const defaultCountries = useSelector((state) => state.user.defaultCountries)
	const defaultInterval = useSelector((state) => state.user.defaultInterval)
	const defaultPackageOption = useSelector((state) => state.user.defaultPackageOption)
	const defaultPackages = useSelector((state) => state.user.defaultPackages)
	const defaultIndications = useSelector((state) => state.user.defaultIndications)
	const showPipeline = useSelector((state) => state.user.showPipeline)
	const showTreatmentLine = useSelector((state) => state.user.showTreatmentLine)
	const allowTreatmentLines = useSelector((state) => state.user.allowTreatmentLines)
	const euMarketAccess = useSelector((state) => state.user.euMarketAccess)
	const allowCycles = useSelector((state) => state.user.allowCycles)
	const allowWeeks = useSelector((state) => state.user.allowWeeks)
	const showAnnual = useSelector((state) => state.user.showAnnual)
	const showMonth = useSelector((state) => state.user.showMonth)
	const showBrandTypes = useSelector((state) => state.user.showBrandTypes)
	const calculatorMode = useSelector((state) => state.user.calculatorMode)
	const showStartNumber = useSelector((state) => state.user.showStartNumber)
	const showEndNumber = useSelector((state) => state.user.showEndNumber)
	const message = useSelector((state) => state.user.message)

	const signin = async (data) => {
		return dispatch(process.env.REACT_APP_USE_OIDC_AUTH === "true" ? loginUser(data) : login(data))
	}

	const loginOidc = async () => {
		return dispatch(loginAuth())
	}

	const signout = async () => {
		dispatch(logout())
	}
	const change = async (data) => {
		return dispatch(updateUserProfile(data))
	}

	const refresh = async (oidcRefresh) => {
		return dispatch(refreshAccessToken(oidcRefresh ? oidcRefresh : refreshToken))
	}

	return {
		signin,
		loginOidc,
		signout,
		change,
		refresh,
		token,
		username,
		isStatic,
		refreshToken,
		organizationId,
		organizationName,
		wastageCalculation,
		defaultWastageCalcOption,
		indicationText,
		packageOptions,
		htaReimbursement,
		singleYear,
		option28Days,
		autoCalculate,
		selectAllIndications,
		allowTreatmentLines,
		showBsaWeight,
		showCurrencyRate,
		showDosagePattern,
		showTreatmentLine,
		allowedGraphs,
		allowedPrices,
		firstName,
		lastName,
		defaultPrices,
		defaultCountries,
		defaultDrugs,
		defaultInterval,
		defaultPackageOption,
		admin,
		message,
		showPipeline,
		defaultIndications,
		defaultPackages,
		euMarketAccess,
		allowCycles,
		allowWeeks,
		showAnnual,
		showMonth,
		showBrandTypes,
		calculatorMode,
		showStartNumber,
		showEndNumber,
		role,
		useParentData,
	}
}
