import { useDisclosure } from "@chakra-ui/hooks"
import React, { useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import styled from "styled-components"
import useDrugIndication from "../../redux/hooks/useDrugIndication"
import useIndicationApproval from "../../redux/hooks/useIndicationApproval"
import useTreatment from "../../redux/hooks/useTreatment"
import { superAdminAccess } from "../../utils/constants"
import {
	checkWriteCondition,
	findDefaultOptionByName,
	findDefaultOptionByValue,
	handleOrganizationPayload,
	handleResponse,
} from "../../utils/functions"
import CustomButton from "../buttons/CustomButton"
import { makeDropdownOptions, makeIdsArray } from "../forms/calcHelper"
import DrugIndicationForm from "../forms/indications/DrugIndicationForm"
import Loading from "../frames/Loading"
import ModalTableFrame from "../frames/ModalTableFrame"
import CustomModal from "../modals/CustomModal"
import DeleteModal from "../modals/DeleteModal"
import IndicationApprovalTable from "./IndicationApprovalTable"
import { TablesStyles } from "./TableStyles"
import TreatmentTable from "./TreatmentTable"

const DrugIndicationTable = ({ selectData, doNotExpandRows, role, parentData, organizationId }) => {
	const { isOpen: isOpenDrugIndication, onOpen: onOpenDrugIndication, onClose: onCloseDrugIndication } = useDisclosure()
	const { isOpen: isOpenApproval, onOpen: onOpenApproval, onClose: onCloseApproval } = useDisclosure()
	const { isOpen: isOpenPosology, onOpen: onOpenPosology, onClose: onClosePosology } = useDisclosure()

	const hook = useDrugIndication()
	const hookTreatment = useTreatment()
	const hookIndicationApproval = useIndicationApproval()

	const [updateData, setUpdateData] = useState(null)

	let data = hook.data

	const indexedData = data.map((d, i) => {
		return {
			num: i + 1,
			id: d.id,
			indication: d.indication,
			product_name_values: d.drug_value,
			product_names: d.drugs,
			treatment_line_name: d.treatment_line_name,
			indication_name: d.indication_name,
			combination_code: d.combination_code,
			organization: d.organization,
			organization_name: d.organization_name,
		}
	})

	const columns = [
		{
			dataField: "num",
			text: "No.",
			headerStyle: () => {
				return { width: "3%" }
			},
			formatter: (cell) => {
				return `${cell}.`
			},
		},
		{
			dataField: "indication_name",
			text: "Indication",
			headerStyle: () => {
				return { width: "70%" }
			},
		},
		{
			dataField: "treatment_line_name",
			text: "Treatment line",
			headerStyle: () => {
				return { width: "9%" }
			},
		},
		{
			dataField: "product_name_values",
			text: "Product names",

			headerStyle: () => {
				return { width: "22%" }
			},
		},
		{
			dataField: "combination_code",
			text: "Code",
			headerStyle: () => {
				return { width: "15%" }
			},
		},
	]

	if (superAdminAccess.includes(role)) {
		columns.splice(1, 0, {
			dataField: "organization_name",
			text: "Organization",
			headerStyle: () => {
				return { width: "13%" }
			},
		})
	}

	const handleSuccess = () => {
		hook.fetch(1, hook.defaultSize)
		onCloseDrugIndication()
	}

	const handleDelete = (id) => {
		hook.remove(id).then((res) => handleResponse(res, handleSuccess))
	}

	const handleApprovalsRedirection = (id) => {
		onOpenApproval()
		hookIndicationApproval.fetchByDrugIndication(1, hookIndicationApproval.defaultSize, id)
	}

	const handlePosologiesRedirection = (id) => {
		onOpenPosology()
		hookTreatment.fetchByDrugIndication(1, hookTreatment.defaultSize, id)
	}

	const handleApprovalClose = () => {
		hookIndicationApproval.cleanDrugIndicationData()
		onCloseApproval()
	}

	const handlePosologyClose = () => {
		hookTreatment.cleanDrugIndicationData()
		onClosePosology()
	}

	const expandRow = {
		renderer: (row) =>
			doNotExpandRows ? null : (
				<div className="btns-container">
					<CustomButton type="posology" onClick={() => handlePosologiesRedirection(row.id)} />
					<CustomButton type="approval" onClick={() => handleApprovalsRedirection(row.id)} />
					<CustomButton type="update" onClick={() => handleOpenUpdate(row)} />
					<DeleteModal remove={() => handleDelete(row.id)} />
				</div>
			),
		className: "expandedRow",
		parentClassName: "parentExpandedRow",
	}

	const handleOpenUpdate = (row) => {
		let defaultValues = {
			id: row.id,
			defaultTreatmentLine: findDefaultOptionByName(selectData.treatmentLines, row.treatment_line_name),
			defaultProductNameValues: makeDropdownOptions(row.product_names),
			defaultIndication: findDefaultOptionByValue(selectData.indications, row.indication),
			combinationCode: row.combination_code,
		}

		if (superAdminAccess.includes(role)) {
			defaultValues["organization"] = selectData.organizations.find((ele) => ele.value === Number(row.organization))
		}

		setUpdateData(defaultValues)
		onOpenDrugIndication()
	}

	const submit = (data) => {
		let payload = {
			id: updateData.id,
			drugs: makeIdsArray(data.product_names),
			indication: data.indication.value,
			treatment_line: data.treatment_line.value,
			combination_code: data.combination_code,
		}

		handleOrganizationPayload(payload, role, organizationId, data.organization)

		hook.update(payload).then((res) => handleResponse(res, handleSuccess))
	}

	return (
		<>
			<TablesStyles minWidth="2300px">
				<BootstrapTable
					data={indexedData}
					columns={columns}
					keyField="id"
					classes="TablesStyles"
					expandRow={checkWriteCondition(role, parentData) && expandRow}
					bordered
					bootstrap4
				/>
			</TablesStyles>

			<CustomModal isOpen={isOpenDrugIndication} onClose={onCloseDrugIndication} size="5xl">
				<DrugIndicationForm
					defaultValues={updateData}
					selectData={selectData}
					doNotExpandRows={true}
					onSubmit={submit}
					cancel={onCloseDrugIndication}
				/>
			</CustomModal>
			<CustomModal
				isOpen={isOpenApproval}
				onClose={handleApprovalClose}
				color={"white"}
				// modalCloseButton={true}
				size="7xl"
				modalCustomWidth="1550px"
			>
				<ModalStyle>
					<ModalTableFrame>
						<IndicationApprovalTable data={hookIndicationApproval.dataByDrugIndication} doNotExpandRows={true} />
						{hookIndicationApproval.loading && <Loading />}
					</ModalTableFrame>
				</ModalStyle>
			</CustomModal>
			<CustomModal
				isOpen={isOpenPosology}
				onClose={handlePosologyClose}
				color={"white"}
				// modalCloseButton={true}
				size="7xl"
				modalCustomWidth="1550px"
			>
				<ModalStyle>
					<ModalTableFrame>
						<TreatmentTable data={hookTreatment.dataByDrugIndication} doNotExpandRows={true} />
						{hookTreatment.loading && <Loading />}
					</ModalTableFrame>
				</ModalStyle>
			</CustomModal>
		</>
	)
}

export default DrugIndicationTable

const ModalStyle = styled.div`
	width: 1500px;
	/* height: 80vh; */
	background-color: var(--white);
`
