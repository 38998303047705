import { useDisclosure } from "@chakra-ui/hooks"
import React, { useEffect, useState } from "react"
import Select from "react-select"
import styled from "styled-components"
import { ReactComponent as AddButton } from "../assets/add-button.svg"
import { customStylesSelect } from "../components/CustomSelectStyles"
import { makeDropdownOptions } from "../components/forms/calcHelper"
import DrugIndicationForm from "../components/forms/indications/DrugIndicationForm"
import Loading from "../components/frames/Loading"
import PriceTableFrame from "../components/frames/PriceTableFrame"
import Layout from "../components/layout/Layout"
import CustomModal from "../components/modals/CustomModal"
import generateToast from "../components/notifications/ToastGenerator"
import DrugIndicationTable from "../components/tables/DrugIndicationTable"
import Pagination from "../components/tables/Pagination"
import { indicationMd, productName, treatmentLineMd } from "../redux/api_urls"
import useDrugIndication from "../redux/hooks/useDrugIndication"
import useMasterData from "../redux/hooks/useMasterData"
import useOrganization from "../redux/hooks/useOrganization"
import useUser from "../redux/hooks/useUser"
import { defaultPageNumber, superAdminAccess } from "../utils/constants"
import { checkWriteCondition, drugIndicationSubmit } from "../utils/functions"

const DrugIndicationPage = () => {
	const { isOpen, onOpen, onClose } = useDisclosure()

	const hook = useDrugIndication()
	const hookMd = useMasterData()
	const hookOrg = useOrganization()
	const role = useUser().role
	const parentData = useUser().useParentData
	const organizationId = useUser().organizationId

	const [selectedTreatmentLine, setSelectedTreatmentLine] = useState(null)
	const [selectedIndication, setSelectedIndication] = useState(null)
	const [filter, setFilter] = useState(null)
	const [indications, setIndications] = useState([])
	const [treatmentLines, setTreatmentLines] = useState([])
	const [productNames, setProductNames] = useState([])
	const [organizations, setOrganizations] = useState([])

	useEffect(() => {
		hook.fetch(1, hook.defaultSize)
		hookMd.fetch(productName, true)
		hookMd.fetch(treatmentLineMd, true)
		hookMd.fetch(indicationMd, true)
		if (superAdminAccess.includes(role)) {
			hookOrg.fetchDropdown()
		}
	}, []) // eslint-disable-line

	useEffect(() => {
		if (hook.message) {
			generateToast(hook.message)
		}
	}, [hook.message])

	useEffect(() => {
		setIndications(makeDropdownOptions(hookMd.indications))
		setTreatmentLines(makeDropdownOptions(hookMd.treatmentLines))
		setProductNames(makeDropdownOptions(hookMd.productNames))
		setOrganizations(makeDropdownOptions(hookOrg.dropdownData, "name"))
	}, [hookMd.treatmentLines, hookMd.productNames, hookMd.indications, hookOrg.dropdownData])

	const handleTreatmentLineSelect = (val) => {
		const filterData = {}
		if (!val) {
			setSelectedTreatmentLine(null)
		} else {
			setSelectedTreatmentLine(val)
			filterData["treatmentLine"] = val.value
		}
		if (selectedIndication) {
			filterData["indication"] = selectedIndication.value
		}
		hook.fetch(defaultPageNumber, hook.defaultSize, filterData)
		setFilter(filterData)
	}

	const handleIndicationSelect = (val) => {
		const filterData = {}
		if (!val) {
			setSelectedIndication(null)
		} else {
			setSelectedIndication(val)
			filterData["indication"] = val.value
		}

		if (selectedTreatmentLine) {
			filterData["treatmentLine"] = selectedTreatmentLine.value
		}
		hook.fetch(defaultPageNumber, hook.defaultSize, filterData)
		setFilter(filterData)
	}

	const selectData = {
		treatmentLines: treatmentLines,
		indications: indications,
		productNames: productNames,
		organizations: organizations,
	}

	const submit = (data) => {
		drugIndicationSubmit(data, hook.fetch, onClose, hook.create, role, organizationId, hook.defaultSize)
	}

	return (
		<Layout>
			<PageContainer>
				<FiltersDiv>
					<div>
						<Select
							name="treatment_line_filter"
							placeholder="Select treatment line..."
							className="react-select-container"
							classNamePrefix="react-select"
							styles={customStylesSelect}
							options={treatmentLines}
							onChange={(val) => handleTreatmentLineSelect(val)}
							isClearable={true}
							value={selectedTreatmentLine}
						/>
					</div>
					<div>
						<Select
							name="indication_filter"
							placeholder="Select indication..."
							className="react-select-container-indication"
							classNamePrefix="react-select"
							styles={customStylesSelect}
							options={indications}
							onChange={(val) => handleIndicationSelect(val)}
							isClearable={true}
							value={selectedIndication}
						/>
					</div>
				</FiltersDiv>
				<PriceTableFrame>
					<DrugIndicationTable selectData={selectData} role={role} parentData={parentData} organizationId={organizationId} />
					{hook.loading && <Loading />}
				</PriceTableFrame>
				{checkWriteCondition(role, parentData) && <AddButton className="addBtn" onClick={() => onOpen()} />}
				{hook.pagination && (
					<Pagination
						totalCount={hook.pagination.total_count}
						totalPages={hook.pagination.total_pages}
						currentPage={hook.pagination.current_page}
						pageSize={hook.defaultSize}
						fetchPage={hook.fetch}
						setSize={hook.changeSize}
						pageSizesArray={[200, 500]}
						filter={filter}
					/>
				)}
			</PageContainer>
			<CustomModal isOpen={isOpen} onClose={onClose} size="5xl">
				<DrugIndicationForm onSubmit={submit} selectData={selectData} cancel={onClose} role={role} />
			</CustomModal>
		</Layout>
	)
}

export default DrugIndicationPage

const PageContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: hidden;
	background: var(--white);
`

const FiltersDiv = styled.div`
	width: 96%;
	display: flex;
	flex-direction: row;
	column-gap: 10px;
	align-items: stretch;
	margin-top: 1%;

	.react-select-container {
		width: 223px;
		height: 40px;
		margin: 0 auto;
		text-align: left;
	}

	.react-select-container-indication {
		width: 383px;
		height: 40px;
		margin: 0 auto;
		text-align: left;
	}

	.react-select__single-value {
		color: var(--white);
	}

	.react-select__control {
		border-radius: 4px;
		background: var(--blue);
	}

	.react-select__value-container {
		max-height: 70px;
		overflow-y: auto;
	}
	.react-select__multi-value__remove {
		color: var(--blue);
	}
	.react-select__input {
		height: 40px;
		color: var(--white);
	}
	.react-select__placeholder {
		color: var(--white);
		font-weight: 400;
	}
	.react-select__indicator {
		color: var(--white);
	}
`
