import { useDisclosure } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import Select from "react-select"
import styled from "styled-components"
import { ReactComponent as AddButton } from "../assets/add-button.svg"
import { customStylesSelect } from "../components/CustomSelectStyles"
import { makeDropdownOptions } from "../components/forms/calcHelper"
import DrugPackageForm from "../components/forms/DrugPackageForm"
import Loading from "../components/frames/Loading"
import PriceTableFrame from "../components/frames/PriceTableFrame"
import Layout from "../components/layout/Layout"
import CustomModal from "../components/modals/CustomModal"
import generateToast from "../components/notifications/ToastGenerator"
import PackageTable from "../components/tables/PackageTable"
import Pagination from "../components/tables/Pagination"
import { fillUnit, packUnit, productName, route } from "../redux/api_urls"
import useMasterData from "../redux/hooks/useMasterData"
import useOrganization from "../redux/hooks/useOrganization"
import usePackage from "../redux/hooks/usePackage"
import useUser from "../redux/hooks/useUser"
import { defaultPageNumber, superAdminAccess } from "../utils/constants"
import { checkWriteCondition, handleOrganizationPayload, handleResponse } from "../utils/functions"

const PackagePage = () => {
	const hook = usePackage()
	const hookMasterData = useMasterData()
	const hookOrg = useOrganization()
	const role = useUser().role
	const parentData = useUser().useParentData
	const organizationId = useUser().organizationId
	const { isOpen, onOpen, onClose } = useDisclosure()

	const [selectedProductName, setSelectedProductName] = useState(null)
	const [selectedRoute, setSelectedRoute] = useState(null)
	const [filter, setFilter] = useState(null)
	const [productNames, setProductNames] = useState([])
	const [routes, setRoutes] = useState([])
	const [packUnits, setPackUnits] = useState([])
	const [fillUnits, setFillUnits] = useState([])
	const [organizations, setOrganizations] = useState([])

	useEffect(() => {
		hookMasterData.fetch(productName)
		hookMasterData.fetch(route)
		hookMasterData.fetch(packUnit)
		hookMasterData.fetch(fillUnit)
		hook.fetch(defaultPageNumber, hook.defaultSize)
		if (superAdminAccess.includes(role)) {
			hookOrg.fetchDropdown()
		}
	}, []) // eslint-disable-line

	useEffect(() => {
		if (hook.message) {
			generateToast(hook.message)
		}
	}, [hook.message])

	useEffect(() => {
		setProductNames(makeDropdownOptions(hookMasterData.productNames))
		setRoutes(makeDropdownOptions(hookMasterData.routes))
		setPackUnits(makeDropdownOptions(hookMasterData.packUnits))
		setFillUnits(makeDropdownOptions(hookMasterData.fillUnits))
		setOrganizations(makeDropdownOptions(hookOrg.dropdownData, "name"))
	}, [hookMasterData.productNames, hookMasterData.routes, hookMasterData.packUnits, hookMasterData.fillUnits, hookOrg.dropdownData])

	const handleProductNameSelect = (val) => {
		const filterData = {}
		if (!val) {
			setSelectedProductName(null)
		} else {
			setSelectedProductName(val)
			filterData["product_name"] = val.value
		}

		if (selectedRoute) {
			filterData["route"] = selectedRoute.value
		}
		hook.fetch(defaultPageNumber, hook.defaultSize, filterData)
		setFilter(filterData)
	}

	const handleRouteSelect = (val) => {
		const filterData = {}
		if (!val) {
			setSelectedRoute(null)
		} else {
			setSelectedRoute(val)
			filterData["route"] = val.value
		}

		if (selectedProductName) {
			filterData["product_name"] = selectedProductName.value
		}
		hook.fetch(defaultPageNumber, hook.defaultSize, filterData)
		setFilter(filterData)
	}

	const submit = (data) => {
		let payload = {
			drug: data.product_name.value,
			route_of_administration: data.route_of_administration.value,
			pack_info: data.pack_info,
			pack_strength: data.pack_strength,
			pack_unit: data.pack_unit.value,
			pack_size: data.pack_size,
			fill_unit: data.fill_unit.value,
			fill_size: data.fill_size,
			description: data.description,
		}

		handleOrganizationPayload(payload, role, organizationId, data.organization)

		const handleSuccess = () => {
			hook.fetch(defaultPageNumber, hook.defaultSize)
			onClose()
		}

		hook.create(payload).then((res) => handleResponse(res, handleSuccess))
	}

	const selectData = {
		productNames: productNames,
		routes: routes,
		fillUnits: fillUnits,
		packUnits: packUnits,
		organizations: organizations,
	}

	return (
		<Layout>
			<PageContainer>
				<FiltersDiv>
					<div>
						<Select
							name="drug_filter"
							placeholder="Select product name..."
							className="react-select-container"
							classNamePrefix="react-select"
							styles={customStylesSelect}
							options={productNames}
							onChange={(val) => handleProductNameSelect(val)}
							isClearable={true}
							value={selectedProductName}
						/>
					</div>
					<div>
						<Select
							name="route_filter"
							placeholder="Select route of administration..."
							className="react-select-container"
							classNamePrefix="react-select"
							styles={customStylesSelect}
							options={routes}
							onChange={(val) => handleRouteSelect(val)}
							isClearable={true}
							value={selectedRoute}
						/>
					</div>
				</FiltersDiv>
				<PriceTableFrame>
					<PackageTable selectData={selectData} role={role} parentData={parentData} organizationId={organizationId} />
					{hook.loading && <Loading />}
				</PriceTableFrame>
				{checkWriteCondition(role, parentData) && <AddButton className="addBtn" onClick={() => onOpen()} />}

				{hook.pagination && (
					<Pagination
						totalCount={hook.pagination.total_count}
						totalPages={hook.pagination.total_pages}
						currentPage={hook.pagination.current_page}
						pageSize={hook.defaultSize}
						fetchPage={hook.fetch}
						setSize={hook.changeSize}
						pageSizesArray={[200, 500]}
						filter={filter}
					/>
				)}
			</PageContainer>
			<CustomModal isOpen={isOpen} onClose={onClose} size="6xl">
				<DrugPackageForm onSubmit={submit} selectData={selectData} cancel={onClose} role={role} />
			</CustomModal>
		</Layout>
	)
}

export default PackagePage

const PageContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: hidden;
	background: var(--white);
`

const FiltersDiv = styled.div`
	width: 96%;
	display: flex;
	flex-direction: row;
	column-gap: 10px;
	align-items: stretch;
	margin-top: 1%;

	.react-select-container {
		width: 283px;

		margin: 0 auto;
		text-align: left;

		.react-select__single-value {
			color: var(--white);
		}

		.react-select__control {
			border-radius: 4px;
			background: var(--blue);
		}

		.react-select__value-container {
			max-height: 70px;
			overflow-y: auto;
		}
		.react-select__multi-value__remove {
			color: var(--blue);
		}
		.react-select__input {
			color: var(--white);
		}
		.react-select__placeholder {
			color: var(--white);
			font-weight: 400;
		}
		.react-select__indicator {
			color: var(--white);
		}
	}
`
