import { createAction, createReducer } from "@reduxjs/toolkit"
import axios from "axios"
import { drugPriceURL, priceRemoveURL, pricesImportURL } from "../api_urls"

const START_REQUEST = createAction("PRICE/START_REQUEST")
const FETCH_SUCCESS = createAction("PRICE/FETCH_SUCCESS")
const REQUEST_FAIL = createAction("PRICE/REQUEST_FAIL")
const REQUEST_SUCCESS = createAction("PRICE/REQUEST_SUCCESS")
const CHANGE_SIZE = createAction("PRICE/CHANGE_SIZE")
const CLEAN_MESSAGE = createAction("PRICE/CLEAN_MESSAGE")

export const fetchPage = (url) => async (dispatch) => {
	dispatch(START_REQUEST())

	return axios.get(url).then((res) => handleSuccess(res, dispatch, FETCH_SUCCESS, REQUEST_FAIL))
	// .catch(function (error) {
	// 	handleError(error, dispatch, REQUEST_FAIL)
	// })
}

export const createPrice = (data) => async (dispatch) => {
	dispatch(START_REQUEST())

	return axios.post(drugPriceURL, data).then((res) => handleSuccess(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
	// .catch(function (error) {
	// 	handleError(error, dispatch, REQUEST_FAIL)
	// })
}

export const removePrice = (id) => async (dispatch) => {
	dispatch(START_REQUEST())

	return axios.delete(priceRemoveURL(id)).then((res) => handleSuccess(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
	// .catch(function (error) {
	// 	handleError(error, dispatch, REQUEST_FAIL)
	// })
}

export const updatePrice = (data) => async (dispatch) => {
	dispatch(START_REQUEST())

	return axios.put(drugPriceURL, data).then((res) => handleSuccess(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
	// .catch(function (error) {
	// 	handleError(error, dispatch, REQUEST_FAIL)
	// })
}

export const setDefaultSize = (size) => async (dispatch) => {
	dispatch(CHANGE_SIZE(size))
}

export const importPrices = (payload) => async (dispatch) => {
	return axios
		.post(pricesImportURL, payload, { headers: { "content-type": "multipart/form-data" } })
		.then((res) => handleSuccess(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
	// .catch(function (error) {
	// 	handleError(error, dispatch, REQUEST_FAIL)
	// })
}

const handleSuccess = (res, dispatch, success, fail) => {
	if (res !== undefined) {
		if (res.status === 200) {
			dispatch(success(res.data))
		} else if (res.response !== undefined && res.response.status === 400) {
			dispatch(fail(res.response.data.message))
			dispatch(CLEAN_MESSAGE())
		} else {
			dispatch(fail({ text: "Server error!", type: "error" }))
			dispatch(CLEAN_MESSAGE())
		}
	} else {
		dispatch(fail({ text: "Server error!", type: "error" }))
		dispatch(CLEAN_MESSAGE())
	}

	return res
}

// const handleError = (error, dispatch, fail) => {
// 	if (error.response) {
// 		if (error.response.status === 500) {
// 			dispatch(fail({ text: "Server error!", type: "error"}))
// 		} else if(error.response.status === 400) {
// 			dispatch(fail(error.response.data.message))
// 		} else {
// 			dispatch(fail({ text: "Server error!", type: "error"}))
// 		}
// 	} else {
// 		dispatch(fail({ text: "Server error!", type: "error"}))
// 	}

// 	dispatch(CLEAN_MESSAGE())

// 	return error

// }

const initState = {
	data: [],
	loading: false,
	pagination: null,
	defaultSize: 50,
	message: null,
}

export const priceReducer = createReducer(initState, (builder) => {
	builder
		.addCase(START_REQUEST, (state, action) => {
			state.loading = true
			state.message = null
		})
		.addCase(FETCH_SUCCESS, (state, action) => {
			state.loading = false
			state.data = action.payload.prices
			state.pagination = action.payload.pagination
		})
		.addCase(REQUEST_FAIL, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(REQUEST_SUCCESS, (state, action) => {
			state.loading = false
			state.message = action.payload.message
		})
		.addCase(CLEAN_MESSAGE, (state, action) => {
			state.message = null
		})
		.addCase(CHANGE_SIZE, (state, action) => {
			state.defaultSize = action.payload
		})
})
