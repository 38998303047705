import { Button } from "@chakra-ui/button"
import React from "react"
import styled from "styled-components"

const ConfirmDialogForm = ({ confirm, cancel, children }) => {
	return (
		<ConfirmDialog>
			{children}
			<div className="btns-wrapper">
				<Button variant="red" onClick={() => confirm()}>
					Confirm
				</Button>
				<Button variant="cancel" onClick={() => cancel()}>
					Cancel
				</Button>
			</div>
		</ConfirmDialog>
	)
}

const ConfirmDialog = styled.div`
	width: 400px;
	height: 200px;
	background: var(--blue);
	color: var(--white);
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: column;
	border-radius: 4px;
	padding: 10px;

	.btns-wrapper {
		button {
			margin: 0 10px;
		}
		.btn-confirm {
			background: var(--red);
			color: var(--white);
		}
	}
`
export default ConfirmDialogForm
