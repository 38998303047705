import { useDispatch, useSelector } from "react-redux"
import {
	fetchPage,
	fetchFilteredPage,
	setDefaultSize,
	cleanDataByDrugIndication,
	createIndicationApproval,
	removeIndicationApproval,
	updateIndicationApproval,
} from "../reducers/indication_approval"

export default function useIndicationApproval() {
	const dispatch = useDispatch()
	const data = useSelector((state) => state.indicationApprovals.data)
	const loading = useSelector((state) => state.indicationApprovals.loading)
	const dataByDrugIndication = useSelector((state) => state.indicationApprovals.dataByDrugIndication)
	const pagination = useSelector((state) => state.indicationApprovals.pagination)
	const defaultSize = useSelector((state) => state.indicationApprovals.defaultSize)
	const message = useSelector((state) => state.indicationApprovals.message)

	const create = async (data) => {
		return dispatch(createIndicationApproval(data))
	}

	const remove = async (id) => {
		return dispatch(removeIndicationApproval(id))
	}

	const update = async (data) => {
		return dispatch(updateIndicationApproval(data))
	}

	const fetch = (pageNumber, pageSize, filterData) => {
		dispatch(fetchPage(pageNumber, pageSize, filterData))
	}

	const fetchByDrugIndication = (pageNumber, pageSize, drugIndication) => {
		dispatch(fetchFilteredPage(pageNumber, pageSize, drugIndication))
	}

	const changeSize = async (size) => {
		dispatch(setDefaultSize(size))
	}

	const cleanDrugIndicationData = async () => {
		dispatch(cleanDataByDrugIndication())
	}

	return {
		fetch,
		fetchByDrugIndication,
		cleanDrugIndicationData,
		data,
		loading,
		dataByDrugIndication,
		message,
		changeSize,
		pagination,
		defaultSize,
		create,
		remove,
		update,
	}
}
