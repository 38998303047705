import { useDisclosure } from "@chakra-ui/hooks"
import { useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import { company, country, currency, dosagePattern, indicationMd, route, treatmentLineMd } from "../../redux/api_urls"
import useMasterData from "../../redux/hooks/useMasterData"
import { masterDataWithOrg, superAdminAccess } from "../../utils/constants"
import { checkWriteCondition, handleOrganizationPayload, handleResponse } from "../../utils/functions"
import CustomButton from "../buttons/CustomButton"
import MasterDataForm from "../forms/MasterDataForm"
import CustomModal from "../modals/CustomModal"
import DeleteModal from "../modals/DeleteModal"
import { TablesStyles } from "./TableStyles"

const MasterdataTable = ({ type, selectData, role, parentData, organizationId }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()

	const hook = useMasterData()

	const [updateData, setUpdateData] = useState(null)

	let data = []
	if (type === currency) {
		data = hook.currencies
	} else if (type === country) {
		data = hook.countries
	} else if (type === dosagePattern) {
		data = hook.dosagePatterns
	} else if (type === company) {
		data = hook.companies
	} else if (type === route) {
		data = hook.routes
	} else if (type === treatmentLineMd) {
		data = hook.treatmentLines
	} else if (type === indicationMd) {
		data = hook.indications
	}

	const indexedData = data.map((d, i) => {
		return { num: i + 1, id: d.id, code: d.code, name: d.name, organization: d.organization, organization_name: d.organization_name }
	})

	const columns = [
		{
			dataField: "num",
			text: "No.",
			headerStyle: () => {
				return { width: "3%", textAlign: "center" }
			},
			formatter: (cell) => {
				return `${cell}.`
			},
		},
		{
			dataField: "code",
			text: "Code",
			headerStyle: () => {
				return { width: "20%", textAlign: "center" }
			},
		},
		{
			dataField: "name",
			text: "Value",
		},
	]

	if (masterDataWithOrg.includes(type) && superAdminAccess.includes(role)) {
		columns.splice(1, 0, {
			dataField: "organization_name",
			text: "Organization",
			headerStyle: () => {
				return { width: "20%", textAlign: "center" }
			},
		})
	}

	const handleSuccess = () => {
		hook.fetch(type)
		onClose()
	}

	const handleDelete = (id) => {
		hook.remove(type, id).then((res) => handleResponse(res, handleSuccess))
	}

	const expandRow = {
		renderer: (row) => (
			<div className="btns-container">
				<CustomButton type="update" onClick={() => handleOpenUpdate(row)} />
				<DeleteModal remove={() => handleDelete(row.id)} />
			</div>
		),
		className: "expandedRow",
		parentClassName: "parentExpandedRow",
	}

	const handleOpenUpdate = (row) => {
		let defaultValues = {
			id: row.id,
			code: row.code,
			name: row.name,
		}

		if (masterDataWithOrg.includes(type) && superAdminAccess.includes(role)) {
			defaultValues["defaultOrganization"] = selectData.organizations.find((ele) => ele.value === row.organization)
		}

		setUpdateData(defaultValues)
		onOpen()
	}

	const submit = (data) => {
		let payload = {
			id: updateData.id,
			code: data.code,
			name: data.name,
		}

		if (masterDataWithOrg.includes(type)) {
			handleOrganizationPayload(payload, role, organizationId, data.organization)
		}

		hook.update(type, payload).then((res) => handleResponse(res, handleSuccess))
	}

	return (
		<>
			<TablesStyles>
				<BootstrapTable
					data={indexedData}
					columns={columns}
					keyField="id"
					classes="TablesStyles"
					expandRow={checkWriteCondition(role, parentData) && expandRow}
					bordered
					bootstrap4
				/>
			</TablesStyles>

			<CustomModal isOpen={isOpen} onClose={onClose} size="md">
				<MasterDataForm
					defaultValues={updateData}
					onSubmit={submit}
					cancel={onClose}
					selectData={selectData}
					type={type}
					role={role}
				/>
			</CustomModal>
		</>
	)
}

export default MasterdataTable
