import { useDisclosure } from "@chakra-ui/react"
import { useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import { toast } from "react-toastify"
import useOrganization from "../../redux/hooks/useOrganization"
import { handleResponse, makeStringOutOfArray } from "../../utils/functions"
import CustomButton from "../buttons/CustomButton"
import { makeDropdownOptions, makeIdsArray } from "../forms/calcHelper"
import OrganizationForm from "../forms/OrganizationForm"
import CustomModal from "../modals/CustomModal"
import DeleteModal from "../modals/DeleteModal"
import { trueOrFalse } from "./tableHelper"
import { TablesStyles } from "./TableStyles"

const OrganizationTable = ({ selectData }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()

	const hook = useOrganization()

	const [updateData, setUpdateData] = useState(null)

	let data = hook.data

	const columns = [
		{
			dataField: "name",
			text: "Name",
			headerStyle: () => {
				return { width: "400px" }
			},
		},
		{
			dataField: "token",
			text: "Token",
			headerStyle: () => {
				return { width: "500px" }
			},
		},
		{
			dataField: "use_parent_data",
			text: "Use parent data",
			formatter: (cell) => trueOrFalse(cell),
			headerStyle: () => {
				return { width: "120px" }
			},
		},
		{
			dataField: "parent_organization_name",
			text: "Parent organization",
			headerStyle: () => {
				return { width: "250px" }
			},
		},
		{
			dataField: "use_template",
			text: "Use template",
			formatter: (cell) => trueOrFalse(cell),
			headerStyle: () => {
				return { width: "120px" }
			},
		},
		{
			dataField: "template_username",
			text: "Template",
			headerStyle: () => {
				return { width: "250px" }
			},
		},
		{
			dataField: "users_values",
			text: "Users",
			headerStyle: () => {
				return { width: "750px" }
			},
			formatter: (cell) => {
				return makeStringOutOfArray(cell, "username")
			},
		},
	]

	const handleSuccess = () => {
		hook.fetch()
		onClose()
	}

	const handleDelete = (id) => {
		hook.remove(id).then((res) => handleResponse(res, handleSuccess))
	}

	const expandRow = {
		renderer: (row) => (
			<div className="btns-container">
				<CustomButton type="update" onClick={() => handleOpenUpdate(row)} />
				<DeleteModal remove={() => handleDelete(row.id)} />
			</div>
		),
		className: "expandedRow",
		parentClassName: "parentExpandedRow",
	}

	const handleOpenUpdate = (row) => {
		const defaultValues = {
			id: row.id,
			name: row.name,
			defaultTemplate: selectData.templateUsers.find((option) => option.value === row.template),
			defaultUsers: makeDropdownOptions(row.users_values, "username"),
			defaultParentOrganization: selectData.parentOrganizations.find((option) => option.value === row.parent_organization),
			use_parent_data: row.use_parent_data,
			use_template: row.use_template,
			apiToken: row.token,
		}

		setUpdateData(defaultValues)
		onOpen()
	}

	const submit = (data) => {
		if (data.use_template && !data.template) {
			toast.error("Template is required!")
		} else if (data.use_parent_data && !data.parent_organization) {
			toast.error("Parent organization is required!")
		} else {
			const payload = {
				id: updateData.id,
				name: data.name,
				users: makeIdsArray(data.users),
				use_parent_data: data.use_parent_data,
				use_template: data.use_template,
				change_api_token: data.change_api_token,
			}

			if (data.template) {
				payload["template"] = data.template.value
			} else {
				payload["template"] = ""
			}

			if (data.parent_organization) {
				payload["parent_organization"] = data.parent_organization.value
			} else {
				payload["parent_organization"] = ""
			}

			hook.update(payload).then((res) => handleResponse(res, handleSuccess))
		}
	}

	const handleCancel = () => {
		onClose()
	}

	return (
		<>
			<TablesStyles minWidth={"2000px"}>
				<BootstrapTable
					data={data}
					columns={columns}
					keyField="id"
					classes="TablesStyles"
					expandRow={expandRow}
					bordered
					bootstrap4
				/>
			</TablesStyles>

			<CustomModal isOpen={isOpen} onClose={onClose} size="full">
				<OrganizationForm defaultValues={updateData} selectData={selectData} onSubmit={submit} cancel={handleCancel} />
			</CustomModal>
		</>
	)
}

export default OrganizationTable
