import { createAction, createReducer } from "@reduxjs/toolkit"
import axios from "axios"
import { configBsaURL, configBsaRemoveURL } from "../api_urls"

const START_REQUEST = createAction("CONFIG/BSA/START_REQUEST")
const FETCH_SUCCESS = createAction("CONFIG/BSA/FETCH_SUCCESS")
const REQUEST_SUCCESS = createAction("CONFIG/BSA/REQUEST_SUCCESS")
const REQUEST_FAIL = createAction("CONFIG/BSA/REQUEST_FAIL")
const CLEAN_MESSAGE = createAction("CONFIG/BSA/CLEAN_MESSAGE")

export const fetchBsaConfig = () => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.get(configBsaURL).then((res) => handleSuccess(res, dispatch, FETCH_SUCCESS, REQUEST_FAIL))
	// .catch(function (error) {
	// 	handleError(error, dispatch, REQUEST_FAIL)
	// })
}

export const createBsaItem = (data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(configBsaURL, data).then((res) => handleSuccess(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
	// .catch(function (error) {
	// 	handleError(error, dispatch, REQUEST_FAIL)
	// })
}

export const removeBsaItem = (id) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.delete(configBsaRemoveURL(id)).then((res) => handleSuccess(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
	// .catch(function (error) {
	// 	handleError(error, dispatch, REQUEST_FAIL)
	// })
}

export const updateBsaItem = (data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.put(configBsaURL, data).then((res) => handleSuccess(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
	// .catch(function (error) {
	// 	handleError(error, dispatch, REQUEST_FAIL)
	// })
}

export const cleanMessage = () => async (dispatch) => {
	return dispatch(CLEAN_MESSAGE())
}

const handleSuccess = (res, dispatch, success, fail) => {
	if (res !== undefined) {
		if (res.status === 200) {
			dispatch(success(res.data))
		} else if (res.response !== undefined && res.response.status === 400) {
			dispatch(fail(res.response.data.message))
			dispatch(CLEAN_MESSAGE())
		} else {
			dispatch(fail({ text: "Server error!", type: "error" }))
			dispatch(CLEAN_MESSAGE())
		}
	} else {
		dispatch(fail({ text: "Server error!", type: "error" }))
		dispatch(CLEAN_MESSAGE())
	}

	return res
}

const initState = {
	bsa: [],
	loading: false,
	message: null,
}

export const bsaConfigReducer = createReducer(initState, (builder) => {
	builder
		.addCase(START_REQUEST, (state, action) => {
			state.loading = true
			state.message = null
		})
		.addCase(FETCH_SUCCESS, (state, action) => {
			state.loading = false
			state.bsa = action.payload
		})
		.addCase(REQUEST_SUCCESS, (state, action) => {
			state.loading = false
			state.message = action.payload.message
		})
		.addCase(REQUEST_FAIL, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(CLEAN_MESSAGE, (state, action) => {
			state.message = null
		})
})
