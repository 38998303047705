import { Tooltip } from "@chakra-ui/react"
import styled from "styled-components"
import FormCheckbox from "./FormCheckbox"

const Switch = ({
	label,
	regName,
	defaultChecked,
	register,
	radio,
	radioRegName,
	radioDefaultChecked,
	price,
	priceInputDefaultValue,
	priceDefaultChecked,
	tooltip,
}) => {
	return (
		<div className="switch-group-partial">
			<Tooltip label={tooltip} hasArrow arrowSize={10} px="12px" py="8px">
				<p>{label}</p>
			</Tooltip>
			<ToggleSwitch>
				<input type="checkbox" id={regName} name={regName} defaultChecked={defaultChecked} ref={register} />
				<label htmlFor={regName}>Toggle</label>{" "}
			</ToggleSwitch>
			{radio && (
				<div className="packageOption-radio-wrapper">
					<label className="radio-button">
						<input
							type="radio"
							name={radioRegName}
							defaultChecked={radioDefaultChecked}
							value={regName}
							ref={register}
							style={{ height: "20px" }}
						/>
						<span className="checkmark"></span>
					</label>
				</div>
			)}
			{price && (
				<>
					<input
						className="switch-group-partial-input"
						type="text"
						name={`${regName}Name`}
						ref={register}
						defaultValue={priceInputDefaultValue}
					/>
					<div style={{ height: "20px" }}>
						<FormCheckbox className="price-checkbox">
							<div className="checkbox">
								<input
									className="price-checkbox-input"
									id={`${regName}_checkbox`}
									name={`${regName}_checkbox`}
									type="checkbox"
									ref={register}
									defaultChecked={priceDefaultChecked}
								/>
								<label htmlFor={`${regName}_checkbox`}></label>
							</div>
						</FormCheckbox>
					</div>
				</>
			)}
		</div>
	)
}

export default Switch

const ToggleSwitch = styled.div`
	display: flex;
	margin-left: 10px;
	input[type="checkbox"] {
		height: 0;
		width: 0;
		visibility: hidden;
	}
	label {
		cursor: pointer;
		text-indent: -9999px;
		width: 40px;
		height: 20px;
		background: var(--white);
		display: block;
		border: 1px solid var(--blue);
		border-radius: 100px;
		position: relative;
	}
	label:after {
		content: "";
		position: absolute;
		top: 3px;
		left: 5px;
		width: 13px;
		height: 13px;
		background: var(--red);
		border-radius: 50%;
		transition: 0.4s;
	}
	input:checked + label {
		background: var(--white);
	}
	input:checked + label:after {
		left: calc(100% - 5px);
		transform: translateX(-100%);
		background: var(--green);
	}
	label:active:after {
		width: 13px;
	}
`
