import { Box, FormLabel } from "@chakra-ui/react"
import React from "react"
import DatePicker from "react-date-picker"
import { Controller, useForm } from "react-hook-form"
import Select from "react-select"
import styled from "styled-components"
import { superAdminAccess } from "../../utils/constants"
import CustomButton from "../buttons/CustomButton"
import { customStylesSelect } from "../CustomSelectStyles"
import PositiveDecimalInput from "../inputs/PositiveDecimalInput"

const PriceForm = ({ onSubmit, cancel, defaultValues, selectData, role }) => {
	const { handleSubmit, control } = useForm()
	return (
		<FormWrapper onSubmit={handleSubmit(onSubmit)}>
			<OneRowStyle>
				<div className="inline-element">
					<label>Drug package</label>
					<Controller
						control={control}
						options={selectData.packages}
						defaultValue={defaultValues && defaultValues.defaultPackage}
						name="drug_package"
						as={Select}
						placeholder="Select drug package..."
						className="react-select-container"
						classNamePrefix="react-select"
						styles={customStylesSelect}
						rules={{ required: true, message: "This field is required" }}
					/>
				</div>
				<div className="inline-element">
					<label>Brand type</label>
					<Controller
						control={control}
						options={selectData.brandTypes}
						defaultValue={defaultValues && defaultValues.defaultBrandType}
						name="brand_type"
						as={Select}
						placeholder="Select brand type..."
						className="react-select-container"
						classNamePrefix="react-select"
						styles={customStylesSelect}
						rules={{ required: true, message: "This field is required" }}
					/>
				</div>
			</OneRowStyle>
			<OneRowStyle>
				<div className="inline-element">
					<label>Country</label>
					<Controller
						name="country"
						control={control}
						options={selectData.countries}
						defaultValue={defaultValues && defaultValues.defaultCountry}
						as={Select}
						placeholder="Select country..."
						className="react-select-container country-select"
						classNamePrefix="react-select"
						styles={customStylesSelect}
						rules={{ required: true, message: "This field is required" }}
					/>
				</div>
				<div className="inline-element">
					<label>Currency</label>
					<Controller
						name="currency"
						control={control}
						options={selectData.currencies}
						defaultValue={defaultValues && defaultValues.defaultCurrency}
						as={Select}
						placeholder="Select currency..."
						className="react-select-container currency-select"
						classNamePrefix="react-select"
						styles={customStylesSelect}
						rules={{ required: true, message: "This field is required" }}
					/>
				</div>
			</OneRowStyle>

			<OneRowStyle>
				<LabelInputStyle>
					<label>MNF</label>
					<Controller
						control={control}
						as={PositiveDecimalInput}
						placeholder={"Insert"}
						name="mnf"
						defaultValue={defaultValues && Number(defaultValues.mnf)}
					/>
				</LabelInputStyle>
				<LabelInputStyle>
					<label>HOSP</label>
					<Controller
						control={control}
						as={PositiveDecimalInput}
						placeholder={"Insert"}
						name="hosp"
						defaultValue={defaultValues && Number(defaultValues.hosp)}
					/>
				</LabelInputStyle>
				<LabelInputStyle>
					<label>PPP</label>
					<Controller
						control={control}
						as={PositiveDecimalInput}
						placeholder={"Insert"}
						name="ppp"
						defaultValue={defaultValues && Number(defaultValues.ppp)}
					/>
				</LabelInputStyle>
				<LabelInputStyle>
					<label>PPE VAT</label>
					<Controller
						control={control}
						as={PositiveDecimalInput}
						placeholder={"Insert"}
						name="ppe_vat"
						defaultValue={defaultValues && Number(defaultValues.ppeVat)}
					/>
				</LabelInputStyle>
				<LabelInputStyle>
					<label>PPI VAT</label>
					<Controller
						control={control}
						as={PositiveDecimalInput}
						placeholder={"Insert"}
						name="ppi_vat"
						defaultValue={defaultValues && Number(defaultValues.ppiVat)}
					/>
				</LabelInputStyle>
				<LabelInputStyle>
					<label>LISTED</label>
					<Controller
						control={control}
						as={PositiveDecimalInput}
						placeholder={"Insert"}
						name="listed"
						defaultValue={defaultValues && Number(defaultValues.listed)}
					/>
				</LabelInputStyle>
			</OneRowStyle>
			<OneRowStyle>
				<div className="inline-element">
					<label>Start date</label>
					<Controller
						control={control}
						as={<DatePicker locale="en-GB" />}
						defaultValue={(defaultValues && new Date(defaultValues.startDate)) || null}
						name="start_date"
						placeholder="dd/MM/yyyy"
						className="date-picker"
					/>
				</div>
				<div className="inline-element">
					<label>End date</label>
					<Controller
						control={control}
						as={<DatePicker locale="en-GB" />}
						name="end_date"
						defaultValue={(defaultValues && new Date(defaultValues.endDate)) || null}
						placeholder="dd/MM/yyyy"
						className="date-picker"
					/>
				</div>
			</OneRowStyle>
			{superAdminAccess.includes(role) && (
				<Box>
					<FormLabel textAlign="center">Organization</FormLabel>
					<Controller
						control={control}
						options={selectData.organizations}
						as={Select}
						defaultValue={defaultValues && defaultValues.organization}
						name="organization"
						placeholder="Select organization..."
						className="react-select-container"
						classNamePrefix="react-select"
						styles={customStylesSelect}
						isClearable={true}
					/>
				</Box>
			)}
			<ButtonsStyle>
				<CustomButton type="save" />
				<CustomButton type="cancel" onClick={cancel} />
			</ButtonsStyle>
		</FormWrapper>
	)
}
const FormWrapper = styled.form`
	display: flex;
	flex-direction: column;
	row-gap: 20px;
	text-align: center;
	width: 880px;
	padding: 15px;
	height: 620px;
	font-size: 18px;

	.inline-element {
		width: 50%;
	}
	label {
		display: block;
		padding-bottom: 5px;
		color: var(--white);
	}
	input {
		width: 100%;
		height: 40px;
		font-size: 16px;
		color: var(--black);
		font-weight: 400;
		padding-left: 10px;
		::placeholder {
			color: var(--black);
		}
	}
	.react-select-container {
		width: 100%;
		height: 40px;
		margin: 0 auto;
		text-align: left;

		.react-select__control {
			border-radius: 4px;
			background: var(--white);
		}

		.react-select__value-container {
			max-height: 70px;
			overflow-y: auto;
		}
		.react-select__multi-value__remove {
			color: var(--blue);
		}
		.react-select__input {
			height: 40px;
		}
		.react-select__placeholder {
			color: var(--blue);
			font-weight: 400;
		}
		.react-select__menu-list {
			height: 160px;
		}
		.react-select__single-value {
			color: var(--black);
		}
	}

	.date-picker {
		width: 100%;
		height: 40px;

		button {
			all: unset;
		}
		.react-date-picker__wrapper {
			border: none;
			border-radius: 4px;
			background: var(--white);
			color: var(--black);
			input {
				color: var(--black);
			}
		}
		.react-date-picker__clear-button {
			transform: translate(-10px, 0);
			&__icon {
				stroke: var(--black);
			}
		}
		.react-date-picker__calendar-button {
			transform: translate(-6px, 0);
			&__icon {
				stroke: var(--black);
			}
		}
		.react-calendar {
			padding: 15px;
			&__navigation__arrow {
				margin: 5px;
				padding: 5px;
			}
		}
	}
`

const OneRowStyle = styled.div`
	display: flex;
	column-gap: 20px;
	justify-content: center;
`

const LabelInputStyle = styled.div`
	display: flex;
	flex-direction: column;
	input {
		width: 100%;
	}

	select {
		width: 480px;
		margin-bottom: 0;
		height: 40px;
	}
`
const ButtonsStyle = styled.div`
	margin-top: 20px;
	button {
		margin: 0 10px;
		padding: 4px 10px;
	}
`

export default PriceForm
