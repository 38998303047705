import React from "react"
import styled from "styled-components"

const CustomButton = ({ type, onClick }) => {
	let buttonData = {}

	if (type === "update") {
		buttonData["color"] = "orange"
		buttonData["textColor"] = "white"
		buttonData["text"] = "Update"
		buttonData["onClick"] = onClick
		buttonData["type"] = "button"
	} else if (type === "delete") {
		buttonData["color"] = "red"
		buttonData["textColor"] = "white"
		buttonData["text"] = "Delete"
		buttonData["onClick"] = onClick
		buttonData["type"] = "button"
	} else if (type === "cancel") {
		buttonData["color"] = "white"
		buttonData["textColor"] = "red"
		buttonData["text"] = "Cancel"
		buttonData["onClick"] = onClick
		buttonData["type"] = "button"
	} else if (type === "save") {
		buttonData["color"] = "green"
		buttonData["textColor"] = "white"
		buttonData["text"] = "Save"
		buttonData["type"] = "submit"
	} else if (type === "approval" || "posology") {
		buttonData["color"] = "white"
		buttonData["textColor"] = "black"
		buttonData["text"] = type === "approval" ? "Approval" : "Posology"
		buttonData["type"] = "button"
		buttonData["onClick"] = onClick
	}

	return (
		<ButtonStyle
			color={buttonData.color}
			textColor={buttonData.textColor}
			onClick={() => type !== "save" && buttonData.onClick()}
			type={buttonData.type}
		>
			{buttonData.text}
		</ButtonStyle>
	)
}

const ButtonStyle = styled.button`
	background: ${(props) => `var(--${props.color})`};
	color: ${(props) => `var(--${props.textColor})`};
	min-height: 40px;
	padding: 0 12px;
	min-width: 130px;
`

export default CustomButton
