import { createAction, createReducer } from "@reduxjs/toolkit"
import axios from "axios"
import { serverErrorMessage } from "../../utils/constants"
import { drugIndicationURL, drugIndicationRemoveURL, dropdownIndicationURL, drugIndicationPagesURL } from "../api_urls"

const START_REQUEST = createAction("INDICATION/DRUG/START_REQUEST")
const FETCH_SUCCESS = createAction("INDICATION/DRUG/FETCH_SUCCESS")
const FETCH_DROPDOWN_SUCCESS = createAction("INDICATION/DRUG/FETCH_DROPDOWN_SUCCESS")
const REQUEST_SUCCESS = createAction("INDICATION/DRUG/REQUEST_SUCCESS")
const REQUEST_FAIL = createAction("INDICATION/DRUG/REQUEST_FAIL")
const CLEAN_MESSAGE = createAction("INDICATION/DRUG/CLEAN_MESSAGE")
const CHANGE_SIZE = createAction("INDICATION/DRUG/CHANGE_SIZE")

export const fetchPage = (pageNumber, pageSize, filterData) => async (dispatch) => {
	let filterUrl = drugIndicationPagesURL(pageNumber, pageSize, filterData)

	if (filterData) {
		if (filterData.treatmentLine) {
			filterUrl += "&treatment_line=" + filterData.treatmentLine
		}
		if (filterData.indication) {
			filterUrl += "&indication=" + filterData.indication
		}
	}

	dispatch(START_REQUEST())
	return axios.get(filterUrl).then((res) => handleResponse(res, dispatch, FETCH_SUCCESS, REQUEST_FAIL))
}

export const createDrugIndication = (data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(drugIndicationURL, data).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}
export const removeDrugIndication = (id) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.delete(drugIndicationRemoveURL(id)).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const updateDrugIndication = (data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.put(drugIndicationURL, data).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const fetchDropdownIndication = () => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.get(dropdownIndicationURL).then((res) => handleResponse(res, dispatch, FETCH_DROPDOWN_SUCCESS, REQUEST_FAIL))
}

export const setDefaultSize = (size) => async (dispatch) => {
	dispatch(CHANGE_SIZE(size))
}

const handleResponse = (res, dispatch, success, fail) => {
	if (res !== undefined) {
		if (res.status >= 200 && res.status <= 299) {
			if (success === REQUEST_SUCCESS) {
				dispatch(success(res.data.message))
				dispatch(CLEAN_MESSAGE())
			} else {
				dispatch(success(res.data))
			}
			return res
		} else if (res.response !== undefined && res.response.status === 400) {
			dispatch(fail(res.response.data.message))
			dispatch(CLEAN_MESSAGE())
		} else {
			dispatch(fail(serverErrorMessage))
			dispatch(CLEAN_MESSAGE())
		}
	} else {
		dispatch(fail(serverErrorMessage))
		dispatch(CLEAN_MESSAGE())
	}
}

const initState = {
	data: [],
	dropdownData: [],
	pagination: null,
	defaultSize: 200,
	combinationCode: "",
	loading: false,
	message: null,
}

export const drugIndicationReducer = createReducer(initState, (builder) => {
	builder
		.addCase(START_REQUEST, (state, action) => {
			state.loading = true
			state.message = null
		})
		.addCase(FETCH_SUCCESS, (state, action) => {
			state.loading = false
			state.data = action.payload.indications
			state.pagination = action.payload.pagination
		})
		.addCase(FETCH_DROPDOWN_SUCCESS, (state, action) => {
			state.loading = false
			state.dropdownData = action.payload
		})
		.addCase(REQUEST_SUCCESS, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(REQUEST_FAIL, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(CLEAN_MESSAGE, (state, action) => {
			state.message = null
		})
		.addCase(CHANGE_SIZE, (state, action) => {
			state.defaultSize = action.payload
		})
})
