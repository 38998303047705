import { useDispatch, useSelector } from "react-redux"
import { createOrganization, fetchDropdownData, fetchOrganizations, removeOrganization, updateOrganization } from "../reducers/organization"
import { cleanMessage } from "../reducers/package"

export default function useOrganization() {
	const dispatch = useDispatch()
	const data = useSelector((state) => state.organizations.data)
	const dropdownData = useSelector((state) => state.organizations.dropdownData)
	const message = useSelector((state) => state.organizations.message)
	const loading = useSelector((state) => state.organizations.loading)

	const create = async (data) => {
		return dispatch(createOrganization(data))
	}
	const remove = async (id) => {
		return dispatch(removeOrganization(id))
	}

	const update = async (data) => {
		return dispatch(updateOrganization(data))
	}

	const fetch = () => {
		dispatch(fetchOrganizations())
	}

	const fetchDropdown = () => {
		dispatch(fetchDropdownData())
	}

	const clean = async () => {
		dispatch(cleanMessage())
	}

	return {
		fetch,
		fetchDropdown,
		data,
		dropdownData,
		message,
		loading,
		create,
		remove,
		update,
		clean,
	}
}
