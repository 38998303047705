import React from "react"
import ReactPaginate from "react-paginate"
import styled from "styled-components"

const Pagination = ({ totalCount, totalPages, currentPage, fetchPage, setSize, pageSize, pageSizesArray, filter }) => {
	const handlePageChange = (item) => {
		fetchPage(item.selected + 1, pageSize, filter)
	}

	const handlePageSizeChange = (e) => {
		const val = e.target.value
		setSize(val)
		fetchPage(currentPage, val, filter)
	}

	return (
		<PaginationStyle>
			<select onChange={handlePageSizeChange}>
				{pageSizesArray.map((number, i) => {
					return (
						<option key={i} value={number}>
							{number} of {totalCount}
						</option>
					)
				})}
			</select>
			<ReactPaginate
				previousLabel={"<"}
				nextLabel={">"}
				breakLabel={"..."}
				pageCount={totalPages}
				forcePage={parseInt(currentPage - 1)}
				onPageChange={handlePageChange}
				containerClassName={"pagination-container"}
				activeClassName={"active"}
			/>
		</PaginationStyle>
	)
}

const PaginationStyle = styled.div`
	width: 96%;
	height: 40px;
	display: flex;
	flex-direction: row;
	background: var(--white);

	.pagination-container {
		display: flex;
		justify-content: center;
		min-width: 240px;
		width: 100%;
		max-width: 400px;
		margin: 0 auto;
	}

	select {
		width: 100px;
		height: 20px;
		font-size: 12px;
		border: 2px solid var(--blue);
		background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 21 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.5L10.5 11L20 1.5' stroke='%231E405F' stroke-width='2.5'/%3E%3C/svg%3E");
		padding-right: 0px;

		option[value=""][disabled] {
			display: none;
		}
	}

	ul {
		list-style-type: none;
		display: flex;
		flex-direction: row;
		column-gap: 10px;
		text-align: center;
		font-weight: bold;

		li {
			width: 10%;
			cursor: pointer;
			white-space: nowrap;
		}

		.active a {
			display: block;
			line-height: 1.2em;
			background-color: var(--blue);
			border-radius: 2px;
			color: var(--white);
			width: 100%;
			padding-top: 1px;
			padding-bottom: 1px;
		}
	}
`
export default Pagination
