import { Spinner } from "@chakra-ui/react"
import React from "react"
import styled from "styled-components"

const Loading = () => {
	return (
		<Overlay>
			<Spinner thickness="4px" speed="0.85s" emptyColor="gray.200" color="blue" size="xl" />
		</Overlay>
	)
}

const Overlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgb(61, 87, 161);
	background: linear-gradient(321deg, rgba(61, 87, 161, 1) 0%, rgba(53, 79, 153, 1) 50%, rgba(35, 61, 135, 1) 100%);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 20;
`

export default Loading
