import axios from "axios"
import { dropdownReferencesURL, drugReferencesURL, referencePagesURL, referenceRemoveURL } from "../api_urls"
import { createAction, createReducer } from "@reduxjs/toolkit"

const START_REQUEST = createAction("REFERENCE/START_REQUEST")
const FETCH_SUCCESS = createAction("REFERENCE/FETCH_SUCCESS")
const REQUEST_FAIL = createAction("REFERENCE/REQUEST_FAIL")
const REQUEST_SUCCESS = createAction("REFERENCE/REQUEST_SUCCESS")
const CHANGE_SIZE = createAction("REFERENCE/CHANGE_SIZE")
const CLEAN_MESSAGE = createAction("REFERENCE/CLEAN_MESSAGE")
const FETCH_SUCCESS_DROPDOWN = createAction("REFERENCE/FETCH_SUCCESS_DROPDOWN")

export const fetchPage = (pageNumber, pageSize) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.get(referencePagesURL(pageNumber, pageSize)).then((res) => handleSuccess(res, dispatch, FETCH_SUCCESS, REQUEST_FAIL))
}

export const fetchDropdownData = () => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.get(dropdownReferencesURL).then((res) => handleSuccess(res, dispatch, FETCH_SUCCESS_DROPDOWN, REQUEST_FAIL))
}

export const createReference = (data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(drugReferencesURL, data).then((res) => handleSuccess(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const removeReference = (id) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.delete(referenceRemoveURL(id)).then((res) => handleSuccess(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const updateReference = (data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.put(drugReferencesURL, data).then((res) => handleSuccess(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const setDefaultSize = (size) => async (dispatch) => {
	dispatch(CHANGE_SIZE(size))
}

const handleSuccess = (res, dispatch, success, fail) => {
	if (res !== undefined) {
		if (res.status === 200) {
			dispatch(success(res.data))
		} else if (res.response !== undefined && res.response.status === 400) {
			dispatch(fail(res.response.data.message))
			dispatch(CLEAN_MESSAGE())
		} else {
			dispatch(fail({ text: "Server error!", type: "error" }))
			dispatch(CLEAN_MESSAGE())
		}
	} else {
		dispatch(fail({ text: "Server error!", type: "error" }))
		dispatch(CLEAN_MESSAGE())
	}

	return res
}

const initState = {
	data: [],
	dropdownData: [],
	loading: false,
	pagination: null,
	defaultSize: 100,
	message: null,
}

export const referenceReducer = createReducer(initState, (builder) => {
	builder
		.addCase(START_REQUEST, (state, action) => {
			state.loading = true
			state.message = null
		})
		.addCase(FETCH_SUCCESS, (state, action) => {
			state.loading = false
			state.data = action.payload.references
			state.pagination = action.payload.pagination
		})
		.addCase(FETCH_SUCCESS_DROPDOWN, (state, action) => {
			state.loading = false
			state.dropdownData = action.payload
		})
		.addCase(REQUEST_FAIL, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(REQUEST_SUCCESS, (state, action) => {
			state.loading = false
			state.message = action.payload.message
		})
		.addCase(CLEAN_MESSAGE, (state, action) => {
			state.message = null
		})
		.addCase(CHANGE_SIZE, (state, action) => {
			state.defaultSize = action.payload
		})
})
