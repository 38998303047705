import { useDispatch, useSelector } from "react-redux"
import { fetchMasterData, createMasterData, removeMasterData, updateMasterData } from "../reducers/masterData"

export default function useMasterData() {
	const dispatch = useDispatch()
	const countries = useSelector((state) => state.masterData.countries)
	const loading = useSelector((state) => state.masterData.loading)
	const companies = useSelector((state) => state.masterData.companies)
	const currencies = useSelector((state) => state.masterData.currencies)
	const dosagePatterns = useSelector((state) => state.masterData.dosagePatterns)
	const productNames = useSelector((state) => state.masterData.productNames)
	const genericNames = useSelector((state) => state.masterData.genericNames)
	const brandTypes = useSelector((state) => state.masterData.brandTypes)
	const routes = useSelector((state) => state.masterData.routes)
	const treatmentLines = useSelector((state) => state.masterData.treatmentLines)
	const indications = useSelector((state) => state.masterData.indications)
	const packUnits = useSelector((state) => state.masterData.packUnits)
	const fillUnits = useSelector((state) => state.masterData.fillUnits)
	const message = useSelector((state) => state.masterData.message)

	const create = async (type, data) => {
		return dispatch(createMasterData(type, data))
	}
	const remove = async (type, id) => {
		return dispatch(removeMasterData(type, id))
	}

	const update = async (type, data) => {
		return dispatch(updateMasterData(type, data))
	}

	const fetch = (type, dropdown) => {
		return dispatch(fetchMasterData(type, dropdown))
	}

	return {
		create,
		remove,
		update,
		fetch,
		countries,
		companies,
		currencies,
		dosagePatterns,
		productNames,
		brandTypes,
		genericNames,
		routes,
		treatmentLines,
		indications,
		packUnits,
		fillUnits,
		message,
		loading,
	}
}
