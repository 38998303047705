import { createAction, createReducer } from "@reduxjs/toolkit"
import axios from "axios"
import { serverErrorMessage } from "../../utils/constants"
import {
	brandType,
	company,
	country,
	currency,
	dosagePattern,
	fillUnit,
	genericName,
	indicationMd,
	masterDataDropdownURL,
	masterDataRemoveURL,
	masterDataURL,
	packUnit,
	productName,
	route,
	treatmentLineMd,
} from "../api_urls"

const START_REQUEST = createAction("MD/START_REQUEST")
const FETCH_SUCCESS = createAction("MD/FETCH_SUCCESS")
const REQUEST_SUCCESS = createAction("MD/REQUEST_SUCCESS")
const REQUEST_FAIL = createAction("MD/REQUEST_FAIL")
const CLEAN_MESSAGE = createAction("MD/CLEAN_MESSAGE")

export const fetchMasterData = (type, dropdown) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios
		.get(dropdown ? masterDataDropdownURL(type) : masterDataURL(type))
		.then((res) => handleResponse(res, dispatch, FETCH_SUCCESS, REQUEST_FAIL, type))
}

export const createMasterData = (type, data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(masterDataURL(type), data).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL, type))
}

export const removeMasterData = (type, id) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.delete(masterDataRemoveURL(type, id)).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL, type))
}

export const updateMasterData = (type, data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.put(masterDataURL(type), data).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL, type))
}

const handleResponse = (res, dispatch, success, fail, type) => {
	if (res !== undefined) {
		if (res.status >= 200 && res.status <= 299) {
			if (success === REQUEST_SUCCESS) {
				dispatch(success(res.data.message))
				dispatch(CLEAN_MESSAGE())
			} else {
				dispatch(success({ data: res.data, type: type }))
			}
			return res
		} else if (res.response !== undefined && res.response.status === 400) {
			dispatch(fail(res.response.data.message))
			dispatch(CLEAN_MESSAGE())
		} else {
			dispatch(fail(serverErrorMessage))
			dispatch(CLEAN_MESSAGE())
		}
	} else {
		dispatch(fail(serverErrorMessage))
		dispatch(CLEAN_MESSAGE())
	}
}

const initState = {
	countries: [],
	companies: [],
	currencies: [],
	dosagePatterns: [],
	productNames: [],
	brandTypes: [],
	genericNames: [],
	routes: [],
	treatmentLines: [],
	indications: [],
	packUnits: [],
	fillUnits: [],
	loading: false,
	message: null,
}

export const masterDataReducer = createReducer(initState, (builder) => {
	builder
		.addCase(START_REQUEST, (state, action) => {
			state.loading = true
			state.message = null
		})
		.addCase(FETCH_SUCCESS, (state, action) => {
			state.loading = false
			if (action.payload.type === country) {
				state.countries = action.payload.data
			} else if (action.payload.type === company) {
				state.companies = action.payload.data
			} else if (action.payload.type === currency) {
				state.currencies = action.payload.data
			} else if (action.payload.type === dosagePattern) {
				state.dosagePatterns = action.payload.data
			} else if (action.payload.type === productName) {
				state.productNames = action.payload.data
			} else if (action.payload.type === brandType) {
				state.brandTypes = action.payload.data
			} else if (action.payload.type === genericName) {
				state.genericNames = action.payload.data
			} else if (action.payload.type === route) {
				state.routes = action.payload.data
			} else if (action.payload.type === treatmentLineMd) {
				state.treatmentLines = action.payload.data
			} else if (action.payload.type === indicationMd) {
				state.indications = action.payload.data
			} else if (action.payload.type === packUnit) {
				state.packUnits = action.payload.data
			} else if (action.payload.type === fillUnit) {
				state.fillUnits = action.payload.data
			}
		})
		.addCase(REQUEST_SUCCESS, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(REQUEST_FAIL, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(CLEAN_MESSAGE, (state, action) => {
			state.message = null
		})
})
