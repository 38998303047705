import React, { useEffect } from "react"
import styled from "styled-components"
import UpdateProfileForm from "../components/forms/UpdateProfileForm"
import Layout from "../components/layout/Layout"
import generateToast from "../components/notifications/ToastGenerator"
import useUser from "../redux/hooks/useUser"

const ProfilePage = () => {
	const hook = useUser()

	useEffect(() => {
		if (hook.message) {
			generateToast(hook.message)
		}
	}, [hook.message])

	return (
		<Layout>
			<Container>
				<UpdateProfileForm />
			</Container>
		</Layout>
	)
}

const Container = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	height: calc(100vh - 64px);
	width: 100%;
	background: var(--white);
`

export default ProfilePage
