import { useDispatch, useSelector } from "react-redux"
import {
	fetchPage,
	fetchDropdownData,
	createPackage,
	removePackage,
	updatePackage,
	fetchPackageSchema,
	setDefaultSize,
	cleanMessage,
} from "../reducers/package"

export default function usePackage() {
	const dispatch = useDispatch()
	const data = useSelector((state) => state.packages.data)
	const dropdownData = useSelector((state) => state.packages.dropdownData)
	const pagination = useSelector((state) => state.packages.pagination)
	const defaultSize = useSelector((state) => state.packages.defaultSize)
	const message = useSelector((state) => state.packages.message)
	const loading = useSelector((state) => state.packages.loading)

	const create = async (data) => {
		return dispatch(createPackage(data))
	}
	const remove = async (id) => {
		return dispatch(removePackage(id))
	}

	const update = async (data) => {
		return dispatch(updatePackage(data))
	}

	const fetch = (pageNumber, pageSize, filterData) => {
		dispatch(fetchPage(pageNumber, pageSize, filterData))
	}

	const fetchDropdown = (indicationApproval) => {
		dispatch(fetchDropdownData(indicationApproval))
	}

	const fetchSchema = (id) => {
		dispatch(fetchPackageSchema(id))
	}

	const changeSize = async (size) => {
		dispatch(setDefaultSize(size))
	}

	const clean = async (size) => {
		dispatch(cleanMessage())
	}

	return {
		fetch,
		fetchDropdown,
		changeSize,
		dropdownData,
		data,
		pagination,
		defaultSize,
		message,
		loading,
		create,
		remove,
		update,
		fetchSchema,
		clean,
	}
}
