import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import Select from "react-select"
import styled from "styled-components"
import { ReactComponent as AddButton } from "../assets/add-button.svg"
import { customStylesSelect } from "../components/CustomSelectStyles"
import { makeDropdownOptions } from "../components/forms/calcHelper"
import Loading from "../components/frames/Loading"
import TreatmentTableFrame from "../components/frames/TreatmentTableFrame"
import Layout from "../components/layout/Layout"
import generateToast from "../components/notifications/ToastGenerator"
import Pagination from "../components/tables/Pagination"
import TreatmentTable from "../components/tables/TreatmentTable"
import { genericName, indicationMd, productName, route } from "../redux/api_urls"
import useDrugIndication from "../redux/hooks/useDrugIndication"
import useMasterData from "../redux/hooks/useMasterData"
import useOrganization from "../redux/hooks/useOrganization"
import useTreatment from "../redux/hooks/useTreatment"
import useUser from "../redux/hooks/useUser"
import { defaultPageNumber, superAdminAccess } from "../utils/constants"
import { checkWriteCondition } from "../utils/functions"

const TreatmentPage = () => {
	const hook = useTreatment()
	const hookMasterData = useMasterData()
	const hookIndicationDrug = useDrugIndication()
	const hookOrg = useOrganization()
	const hookUser = useUser()
	const role = hookUser.role
	const parentData = hookUser.useParentData
	const organizationId = hookUser.organizationId
	const history = useHistory()

	const access = new URLSearchParams(window.location.search).get("access")
	const refresh = new URLSearchParams(window.location.search).get("refresh")

	const fetchAll = (loggedIn) => {
		if (loggedIn) {
			hookUser.refresh()
		}

		hook.fetch(defaultPageNumber, hook.defaultSize)
		hook.fetchSubPosologiesData() //subposologies selectData
		hookMasterData.fetch(productName)
		hookMasterData.fetch(route, true)
		hookMasterData.fetch(genericName)
		hookIndicationDrug.fetchDropdownData()
		hookMasterData.fetch(indicationMd)
		if (superAdminAccess.includes(role)) {
			hookOrg.fetchDropdown()
		}
	}

	useEffect(() => {
		if (access && refresh && process.env.REACT_APP_USE_OIDC_AUTH === "true") {
			hookUser.signin({ access: access, refresh: refresh }).then((res) => {
				fetchAll()
			})
		} else if (hookUser.token) {
			fetchAll(true)
		} else {
			history.push("/login")
		}
		// hookUser.refresh()
		// hook.fetch(defaultPageNumber, hook.defaultSize)
		// hook.fetchSubPosologiesData() //subposologies selectData
		// hookMasterData.fetch(productName)
		// hookMasterData.fetch(route, true)
		// hookMasterData.fetch(genericName)
		// hookIndicationDrug.fetchDropdownData()
		// hookMasterData.fetch(indicationMd)
		// if (superAdminAccess.includes(role)) {
		// 	hookOrg.fetchDropdown()
		// }
	}, []) // eslint-disable-line

	const [radioButtonValue, setRadioButtonValue] = useState(true)
	const [selectedProductName, setSelectedProductName] = useState(null)
	const [selectedDrugIndication, setSelectedDrugIndication] = useState(null)
	const [selectedIndication, setSelectedIndication] = useState(null)
	const [filter, setFilter] = useState(null)
	const [tableData, setTableData] = useState([])
	const [inputFilter, setInputFilter] = useState("")
	const [productNames, setProductNames] = useState([])
	const [genericNames, setGenericNames] = useState([])
	const [indications, setIndications] = useState([])
	const [drugIndications, setDrugIndications] = useState([])
	const [routes, setRoutes] = useState([])
	const [subPosologies, setSubPosologies] = useState([])
	const [organizations, setOrganizations] = useState([])

	useEffect(() => {
		if (hook.data.length > 0) {
			setTableData(hook.data)
		}
	}, [hook.data])

	useEffect(() => {
		setProductNames(makeDropdownOptions(hookMasterData.productNames))
		setGenericNames(makeDropdownOptions(hookMasterData.genericNames))
		setIndications(makeDropdownOptions(hookMasterData.indications))
		setDrugIndications(makeDropdownOptions(hookIndicationDrug.dropdownData, "indication_value"))
		setRoutes(makeDropdownOptions(hookMasterData.routes))
		setSubPosologies(makeDropdownOptions(hook.subPosologies, "helper_code"))
		setOrganizations(makeDropdownOptions(hookOrg.dropdownData, "name"))
	}, [
		hookMasterData.productNames,
		hookMasterData.genericNames,
		hookMasterData.indications,
		hookIndicationDrug.dropdownData,
		hookMasterData.routes,
		hook.subPosologies,
		hookOrg.dropdownData,
	])

	// const subPosologies = []
	// hook.subPosologies.map((element) =>
	// 	subPosologies.push({ value: element.id, label: element.helper_code })
	// )

	const handleProductNameSelect = (val) => {
		const filterData = {}
		setInputFilter("")
		if (!val) {
			setSelectedProductName(null)
		} else {
			filterData["product_name"] = val.value
			setSelectedProductName(val)
		}

		if (radioButtonValue && selectedDrugIndication) {
			filterData["drugIndication"] = selectedDrugIndication.value
		} else if (!radioButtonValue && selectedIndication) {
			filterData["indication"] = selectedIndication.value
		}

		hook.fetch(defaultPageNumber, hook.defaultSize, filterData)
		setFilter(filterData)
	}

	const handleRadioButton = (val) => {
		setRadioButtonValue(val)
		if (val) {
			setSelectedIndication(null)
		} else {
			setSelectedDrugIndication(null)
		}
	}

	const handleIndications = (val) => {
		setInputFilter("")
		const filterData = {}
		if (radioButtonValue && val) {
			setSelectedDrugIndication(val)
			filterData["drugIndication"] = val.value
		} else if (!radioButtonValue && val) {
			setSelectedIndication(val)
			filterData["indication"] = val.value
		}

		if (radioButtonValue && !val) {
			setSelectedDrugIndication(null)
		} else if (!radioButtonValue && !val) {
			setSelectedIndication(null)
		}

		if (selectedProductName) {
			filterData["product_name"] = selectedProductName.value
		}
		hook.fetch(defaultPageNumber, hook.defaultSize, filterData)
		setFilter(filterData)
	}

	const handleInputFilter = (val) => {
		setInputFilter(val)
		if (val !== "") {
			const filteredArray = hook.data.filter((obj) => obj.indication_drugs.includes(val.toUpperCase()))
			setTableData(filteredArray)
		} else {
			setTableData([...hook.data])
		}
	}

	useEffect(() => {
		if (hook.message) {
			generateToast(hook.message)
		}
	}, [hook.message])

	const selectData = {
		productNames: productNames,
		genericNames: genericNames,
		indications: indications,
		drugIndications: drugIndications,
		routes: routes,
		subPosologies: subPosologies,
		organizations: organizations,
	}

	return (
		<Layout>
			<PageContainer>
				<div className="top-section">
					<Select
						options={productNames}
						placeholder="Select product name..."
						onChange={(val) => handleProductNameSelect(val)}
						className="react-select-container"
						classNamePrefix="react-select"
						styles={customStylesSelect}
						isClearable={true}
					/>
					<div className="radio-select">
						<div className="radioButtons">
							<label className="radio-button">
								Drug Indication
								<input
									type="radio"
									name="indication"
									defaultChecked
									value={true}
									onChange={() => handleRadioButton(true)}
								/>
								<span className="checkmark"></span>
							</label>
							<label className="radio-button">
								Indication
								<input type="radio" name="indication" value={false} onChange={() => handleRadioButton(false)} />
								<span className="checkmark"></span>
							</label>
						</div>
						<Select
							options={radioButtonValue ? drugIndications : indications}
							placeholder={radioButtonValue ? "Select drug indication..." : "Select indication..."}
							className="react-select-container"
							classNamePrefix="react-select"
							styles={customStylesSelect}
							onChange={(val) => handleIndications(val)}
							isClearable={true}
							value={radioButtonValue ? selectedDrugIndication : selectedIndication}
						/>
					</div>
					<input
						className="front-filter"
						type="text"
						placeholder="Type indication drugs..."
						onChange={(e) => handleInputFilter(e.target.value)}
						value={inputFilter}
					/>
				</div>
				<TreatmentTableFrame>
					<TreatmentTable
						data={tableData}
						selectData={selectData}
						role={role}
						parentData={parentData}
						organizationId={organizationId}
					/>
					{hook.loading && <Loading />}
				</TreatmentTableFrame>
				{checkWriteCondition(role, parentData) && <AddButton onClick={() => history.push("/posologies/add")} className="addBtn" />}

				{hook.pagination && (
					<Pagination
						totalCount={hook.pagination.total_count}
						totalPages={hook.pagination.total_pages}
						currentPage={hook.pagination.current_page}
						pageSize={hook.defaultSize}
						fetchPage={hook.fetch}
						setSize={hook.changeSize}
						filter={filter}
						pageSizesArray={[200, 500]}
					/>
				)}
			</PageContainer>
		</Layout>
	)
}

const PageContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: hidden;
	background: var(--white);

	.top-section {
		display: flex;
		column-gap: 20px;
		height: 100px;
		width: 96%;
		margin-bottom: 12px;
		z-index: 3;
		align-items: flex-end;
		background: var(--white);
		.front-filter {
			background: var(--blue);
			width: 25%;
			height: 40px;
			margin-bottom: 0;
			color: var(--white);
			background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.4082 20.8155C13.4957 20.8151 15.5229 20.1163 17.1673 18.8304L22.3371 24L24 22.3372L18.8302 17.1676C20.1167 15.5232 20.816 13.4956 20.8165 11.4077C20.8165 6.22055 16.5957 2 11.4082 2C6.22077 2 2 6.22055 2 11.4077C2 16.5949 6.22077 20.8155 11.4082 20.8155ZM11.4082 4.35193C15.2997 4.35193 18.4644 7.51646 18.4644 11.4077C18.4644 15.299 15.2997 18.4635 11.4082 18.4635C7.51676 18.4635 4.35206 15.299 4.35206 11.4077C4.35206 7.51646 7.51676 4.35193 11.4082 4.35193Z' fill='%23F8F8F8'/%3E%3C/svg%3E");
			background-repeat: no-repeat;
			background-position: 96%;
			padding-right: 40px;
			padding-left: 10px;
			::placeholder {
				color: var(--white);
			}
		}
		.react-select-container {
			width: 25%;
			max-width: 450px;
			/* height: 40px; */
			text-align: left;
			.react-select__control {
				border-radius: 4px;
				background: var(--blue);
			}
			.react-select__value-container {
				max-height: 70px;
				overflow-y: auto;
			}
			.react-select__multi-value__remove {
				color: var(--white);
			}
			.react-select__input {
				/* height: 40px; */
				color: var(--white);
			}
			.react-select__placeholder {
				color: var(--white);
				font-weight: 400;
			}
			.react-select__single-value {
				color: var(--white);
				font-weight: 400;
			}
		}
		.radio-select {
			min-width: 150px;
			width: 25%;
			max-width: 450px;
			.react-select-container {
				width: 100%;

				.react-select__single-value {
					color: var(--white);
					font-weight: 400;
				}
			}
			.radioButtons {
				display: flex;
				width: 80%;
				min-width: 200px;
				margin: 0 auto 20px auto;
				justify-self: center;
				.radio-button {
					display: block;
					position: relative;
					padding: 0;
					padding-left: 35px;
					margin: 0 auto;
					cursor: pointer;
					font-size: 16px;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
				}
				.radio-button input {
					position: absolute;
					opacity: 0;
					cursor: pointer;
					display: none;
				}
				.checkmark {
					position: absolute;
					top: 0;
					left: 0;
					height: 25px;
					width: 25px;
					background-color: var(--white);
					border: 2px solid var(--blue);
					border-radius: 50%;
				}
				.radio-button:hover input ~ .checkmark {
					background-color: var(--white);
				}
				.radio-button input:checked ~ .checkmark {
					background-color: var(--white);
				}
				.checkmark:after {
					content: "";
					position: absolute;
					display: none;
				}
				.radio-button input:checked ~ .checkmark:after {
					display: block;
				}
				.radio-button .checkmark:after {
					width: 13px;
					height: 13px;
					border-radius: 50%;
					background: var(--blue);
					transform: translate(4px, 4px);
				}
			}
		}
	}
`

export default TreatmentPage
