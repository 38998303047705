import { indicationMd, treatmentLineMd } from "../redux/api_urls"

export const serverError = "Server error!"
export const serverErrorMessage = { text: serverError, type: "error" }
export const loginError = "No account found with given credentials!"

export const roundUp = "round_up"
export const roundDown = "round_down"
export const exactNumber = "exact_number"
export const nearestInt = "nearest_int"

export const allDrugsSame = "all_drugs_same"
export const allDrugsDifferent = "all_drugs_different"

//pagination
export const defaultPageNumber = 1

//constants
export const cheapestAll = "cheapest_all"
export const cheapestPerStrength = "cheapest_per_strength"
export const chooseAnyPackage = "choose_any_package"

export const tables = "tables"
export const countryGraph = "country"
export const stacked = "stacked"
export const evolution = "evolution"
export const pipeline = "pipeline"
export const treatmentLine = "treatment_line"

//dashboard
export const euMarketAccess = "eu_market_access"
export const protocol = "allow_protocol"
export const transplantEligibility = "allow_transplant_eligibility"
export const extendedTreatmentLine = "allow_extended_treatment_line"
export const gender = "allow_gender"
export const her2 = "allow_her2"
export const hr = "allow_hr"
export const pik3ca = "allow_pik3ca"
export const menopause = "allow_menopause"
export const node = "allow_node"
export const symptomaticVisceralInvolvement = "allow_symptomatic_visceral_involvement"
export const activeIngredient = "allow_active_ingredient"
export const orphanDesignation = "allow_orphan_designation"
export const defaultExtendedTreatmentLine = "default_extended_treatment_line"
export const defaultProtocol = "default_protocol"

export const hosp = "hosp"
export const mnf = "mnf"
export const ppp = "ppp"
export const ppeVat = "ppe_vat"
export const ppiVat = "ppi_vat"
export const listed = "listed"

export const invalidaAllAction = "invalidate_all"
export const invalidateAction = "invalidate"

export const masterDataWithOrg = [indicationMd, treatmentLineMd]

export const rolesOptions = [
	{ label: "Viewer", value: 1 },
	{ label: "Editor", value: 2 },
	{ label: "Admin", value: 3 },
	{ label: "Superadmin", value: 4 },
	{ label: "User", value: 5 },
]

export const superAdminAccess = [4]
export const accountTabAccess = [3, 4]
export const writeMode = [2, 3, 4]
export const posologyAppAccess = [1, 2, 3, 4]
