import { Box, Button, useDisclosure } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { ReactComponent as AddButton } from "../assets/add-button.svg"
import { makeDropdownOptions } from "../components/forms/calcHelper"
import CurrencyRateForm from "../components/forms/CurrencyRateForm"
import Loading from "../components/frames/Loading"
import TableFrame from "../components/frames/TableFrame"
import Layout from "../components/layout/Layout"
import CustomModal from "../components/modals/CustomModal"
import generateToast from "../components/notifications/ToastGenerator"
import CurrencyConfigTable from "../components/tables/CurrencyConfigTable"
import UploadFile from "../components/UploadFile"
import { currency } from "../redux/api_urls"
import useCurrencyConfig from "../redux/hooks/useCurrencyConfig"
import useMasterData from "../redux/hooks/useMasterData"
import useOrganization from "../redux/hooks/useOrganization"
import useUser from "../redux/hooks/useUser"
import { superAdminAccess, writeMode } from "../utils/constants"

const CurrencyConfigPage = ({ staticPage }) => {
	const hook = useCurrencyConfig()
	const hookMd = useMasterData()
	const hookOrg = useOrganization()
	const role = useUser().role
	const useParentData = useUser().useParentData
	const organizationId = useUser().organizationId

	const { isOpen, onOpen, onClose } = useDisclosure()

	const [files, setFiles] = useState([])
	const [currencies, setCurrencies] = useState([])
	const [organizations, setOrganizations] = useState([])

	useEffect(() => {
		hook.getCurrencyConfig(staticPage)
		hookMd.fetch(currency)
		if (superAdminAccess.includes(role)) {
			hookOrg.fetchDropdown()
		}
	}, []) // eslint-disable-line

	useEffect(() => {
		setCurrencies(makeDropdownOptions(hookMd.currencies))
		setOrganizations(makeDropdownOptions(hookOrg.dropdownData, "name"))
	}, [hookMd.currencies, hookOrg.dropdownData])

	useEffect(() => {
		if (hook.message) {
			generateToast(hook.message)
		}
	}, [hook.message])

	const onSubmit = (data) => {
		let payload = {
			currency: data.currency_code.value,
			rate: data.rate,
		}

		if (superAdminAccess.includes(role)) {
			payload["organization"] = data.organization.value
		} else {
			payload["organization"] = organizationId
		}

		hook.createCurrency(payload).then((res) => {
			if (res !== undefined && res.status === 200) {
				onClose()
				hook.getCurrencyConfig(true)
			}
		})
	}

	const submitImport = (file) => {
		hook.importFile(file).then((res) => {
			if (res !== undefined) {
				setFiles([])
			}
		})
	}

	const selectData = {
		currencies: currencies,
		organizations: organizations,
	}

	return (
		<Layout>
			<TableFrame>
				<CurrencyConfigTable
					selectData={selectData}
					staticPage={staticPage}
					role={role}
					organizationId={organizationId}
					useParentData={useParentData}
				/>
				{hook.loading && <Loading />}
			</TableFrame>
			{staticPage && !useParentData && writeMode.includes(role) ? (
				<>
					<AddButton className="addBtn" onClick={() => onOpen()} />

					<Box position="absolute" bottom="30px" width="20%" height="50px" left="20%">
						<UploadFile
							onChange={submitImport}
							// format={[".xlsx"]}
							files={files}
							setFiles={setFiles}
							text={"Import currency rates"}
						/>
					</Box>
				</>
			) : !staticPage && superAdminAccess.includes(role) ? (
				<Button variant="green" color="white" pos="absolute" bottom="38px" left="20%" onClick={() => hook.crawlConfig()}>
					Crawl rates
				</Button>
			) : (
				<></>
			)}

			<CustomModal isOpen={isOpen}>
				<CurrencyRateForm onSubmit={onSubmit} cancel={onClose} selectData={selectData} role={role} />
			</CustomModal>
		</Layout>
	)
}

export default CurrencyConfigPage
