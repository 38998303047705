import { makeIdsArray } from "../components/forms/calcHelper"
import { genericName, productName } from "../redux/api_urls"
import { masterDataWithOrg, superAdminAccess, writeMode } from "./constants"

export const subPosologiesText = (array) => {
	let string = ""
	if (array !== undefined && array.length > 0) {
		array.map((element, i) => {
			if (i === array.length - 1) {
				return (string = string + element.helper_code)
			} else {
				return (string = string + element.helper_code + ", ")
			}
		})
	}

	return string
}

export const makeSubPosologiesDropdownOptions = (array) => {
	const dropdownOptions = []
	array.map((element) => dropdownOptions.push({ value: element.id, label: element.helper_code }))
	return dropdownOptions
}

export const routesText = (array) => {
	let string = ""
	if (array !== undefined && array.length > 0) {
		array.map((element, i) => {
			if (i === array.length - 1) {
				return (string = string + element.name)
			} else {
				return (string = string + element.name + ", ")
			}
		})
	}

	return string
}

export const handleResponse = (res, handleSuccess, handleError) => {
	if (res !== undefined && res.status === 200) {
		if (res.status === 200) {
			handleSuccess()
		} else if (handleError) {
			handleError()
		}
	}
}

export const makeStringOutOfArray = (arr, type) => {
	let arrayString = ""
	if (arr !== undefined && arr && arr.length > 0 && type) {
		arr.forEach((item) => {
			if (item[type] === arr[arr.length - 1][type]) {
				arrayString = arrayString + item[type]
			} else {
				arrayString = arrayString + item[type] + ", "
			}
		})
	} else if (arr !== undefined && arr && arr.length > 0) {
		arr.forEach((item) => {
			if (item.name === arr[arr.length - 1].name) {
				arrayString = arrayString + item.name
			} else {
				arrayString = arrayString + item.name + ", "
			}
		})
	}

	return arrayString
}

export const makeStringOutOfStringArray = (arr, price) => {
	let list = ""
	let keyArray = arr
	if (price && arr) {
		keyArray = Object.keys(arr)
	}
	if (keyArray && arr && keyArray.length !== 0) {
		list = keyArray.map((item) => {
			if (item === keyArray[keyArray.length - 1]) {
				return item
			} else {
				return item.concat(", ")
			}
		})
	}

	return list
}

export const findDefaultOptionByName = (arr, val) => {
	let defaultOption = null

	if (arr !== undefined && arr.length > 0 && val) {
		defaultOption = arr.find((ele) => ele.label === val)
	}

	return defaultOption
}

export const findDefaultOptionByValue = (arr, val) => {
	let defaultOption = null

	if (arr !== undefined && arr.length > 0 && val) {
		defaultOption = arr.find((ele) => ele.value === val)
	}

	return defaultOption
}

export const formatDate = (date) => {
	return new Date(date).toLocaleDateString("en-GB")
}

export const checkDefaultValueRadio = (update, condition, returnValue) => {
	if (update) {
		if (condition) {
			return true
		} else {
			return false
		}
	} else {
		if (!returnValue) {
			return true
		} else {
			return false
		}
	}
}

export const checkWriteCondition = (role, parentData) => {
	if (parentData) {
		return false
	} else if (writeMode.includes(role)) {
		return true
	} else {
		return false
	}
}

export const handleOrganizationPayload = (payload, role, organizationId, organizationField) => {
	if (superAdminAccess.includes(role)) {
		if (organizationField) {
			payload["organization"] = organizationField.value
		} else {
			payload["organization"] = ""
		}
	} else {
		payload["organization"] = organizationId
	}
}

export const pricesForm = ["mnf", "hosp", "ppp", "ppe_vat", "ppi_vat", "listed"]

export const handlePricePayload = (data, payload, price) => {
	if (data[price] !== 0) {
		payload[price] = data[price]
	} else {
		payload[price] = ""
	}
}

export const getSelectData = (names, options) => {
	let selectData = {}
	names.map((name, i) => {
		options.map((option, index) => {
			if (i === index) {
				return (selectData[name] = option)
			}
		})
	})

	return selectData
}

export const drugIndicationSubmit = (data, fetcher, close, submitter, role, organizationId, defaultSize) => {
	const handleSuccess = () => {
		if (defaultSize) {
			fetcher(1, defaultSize)
		} else {
			fetcher()
		}
		close()
	}

	let payload = {
		treatment_line: data.treatment_line.value,
		indication: data.indication.value,
		combination_code: data.combination_code,
		drugs: makeIdsArray(data.product_names),
	}

	handleOrganizationPayload(payload, role, organizationId, data.organization)

	submitter(payload).then((res) => handleResponse(res, handleSuccess))
}

export const productNameSubmit = (data, fetcher, submitter, close, role, organizationId) => {
	let clusterDrugs = []
	if (data.cluster_drugs) {
		clusterDrugs = makeIdsArray(data.cluster_drugs)
	}
	let payload = {
		code: data.code,
		name: data.name,
		secondary_code: data.secondary_code,
		color: data.color,
		cluster_drugs: clusterDrugs,
		is_cluster: data.is_cluster,
		is_monotherapy: data.is_monotherapy,
		monotherapy_exists: data.monotherapy_exists,
	}

	handleOrganizationPayload(payload, role, organizationId, data.organization)

	const handleSuccess = () => {
		fetcher(productName)
		close()
	}

	submitter(productName, payload).then((res) => handleResponse(res, handleSuccess))
}

export const genericNameSubmit = (data, fetcher, submitter, close, role, organizationId) => {
	let clusterGenerics = []
	if (data.cluster_generics) {
		clusterGenerics = makeIdsArray(data.cluster_generics)
	}
	let payload = {
		code: data.code,
		name: data.name,
		color: data.color,
		cluster_generics: clusterGenerics,
		is_cluster: data.is_cluster,
		is_monotherapy: data.is_monotherapy,
		monotherapy_exists: data.monotherapy_exists,
	}

	handleOrganizationPayload(payload, role, organizationId, data.organization)

	const handleSuccess = () => {
		fetcher(genericName)
		close()
	}

	submitter(genericName, payload).then((res) => handleResponse(res, handleSuccess))
}

export const masterDataSubmit = (data, fetcher, submitter, close, type, role, organizationId) => {
	const handleSuccess = () => {
		fetcher(type)
		close()
	}

	let payload = {
		name: data.name,
		code: data.code,
	}

	if (masterDataWithOrg.includes(type)) {
		handleOrganizationPayload(payload, role, organizationId, data.organization)
	}

	submitter(type, payload).then((res) => handleResponse(res, handleSuccess))
}
