export const customStylesSelect = {
	control: (base) => ({
		...base,
		minHeight: "40px",
		outline: "none",
		border: "none",
		boxShadow: "none",
		background: "var(--blue)",
	}),
	container: (base) => ({
		...base,
		width: "100%",
	}),
	dropdownIndicator: (base) => ({
		...base,
		padding: 4,
	}),
	clearIndicator: (base) => ({
		...base,
		padding: 4,
	}),

	valueContainer: (base) => ({
		...base,
		padding: "0px 6px",
	}),
	input: (base) => ({
		...base,
		margin: 0,
		padding: 0,
	}),
	singleValue: (base) => {
		return {
			...base,
			color: "var(--white)",
			"@media only screen and (max-width: 480px)": {
				...base["@media only screen and (max-width: 768px)"],
				fontSize: "12px",
			},
		}
	},
	placeholder: (base) => {
		return {
			...base,
			color: "var(--white)",
		}
	},
	menu: (base) => {
		return {
			...base,
			zIndex: 100,
		}
	},
	multiValue: (base) => {
		return {
			...base,
			"@media only screen and (max-width: 480px)": {
				...base["@media only screen and (max-width: 768px)"],
				fontSize: "12px",
			},
		}
	},
	multiValueLabel: (base) => ({
		...base,
	}),
	multiValueRemove: (base) => ({
		...base,

		":hover": {},
	}),
}

export const customStylesSmallSelect = {
	control: (base) => ({
		...base,
		height: "30px",
		minHeight: "30px",
		outline: "none",
		border: "none",
		boxShadow: "none",
		background: "var(--blue)",
	}),
	container: (base) => ({
		...base,
		width: "100%",
		height: "30px",
	}),
	dropdownIndicator: (base) => ({
		...base,
		padding: 4,
	}),
	clearIndicator: (base) => ({
		...base,
		padding: 4,
	}),

	valueContainer: (base) => ({
		...base,
		padding: "0px 6px",
		height: "30px",
		fontSize: "14px",
	}),
	input: (base) => ({
		...base,
		margin: 0,
		padding: 0,
		color: "var(--white)",
	}),
	singleValue: (base) => {
		return {
			...base,
			color: "var(--white)",
		}
	},

	placeholder: (base) => {
		return {
			...base,
			color: "var(--white)",
		}
	},
}

export const customStylesCheckbox = {
	dropdownButton: (base) => ({
		...base,
		width: "100%",
		minHeight: "40px",
		minWidth: "280px",
		color: "var(--white)",
		background: "var(--blue)",
		fontSize: "16px",
		"@media only screen and (max-width: 480px)": {
			...base["@media only screen and (max-width: 768px)"],
			fontSize: "12px",
		},
	}),

	control: (base) => ({
		...base,
		height: 40,
		minHeight: "40px",
		outline: "none",
		border: 0,
		borderRadius: "4px",
		boxShadow: "none",
		color: "var(--white)",
		background: "var(--blue)",
		margin: "none",
	}),
	container: (base) => ({
		...base,
		width: "100%",
	}),
	valueContainer: (base) => ({
		...base,
		width: "100%",
		padding: "0px 6px",
	}),
	menuList: (base) => ({
		...base,
		color: "var(--white)",
		background: "var(--blue)",
		maxHeight: "300px",
		width: "100%",
	}),
	menu: (base) => ({
		...base,
		width: "100%",
		paddingBottom: "30px",
		backgroundColor: "var(--white)",
	}),
	option: (base) => ({
		...base,
		width: "100%",
		cursor: "pointer",
		fontSize: "16px",
		fontWeight: 300,
		border: "4px",
		"@media only screen and (max-width: 480px)": {
			...base["@media only screen and (max-width: 768px)"],
			fontSize: "12px",
		},
		":hover": {
			background: "#3e5aa5",
		},
	}),
	checkbox: (base) => ({
		...base,
		width: "18px",
		height: "18px",
		background: "yellow",
	}),

	input: (base) => ({
		...base,
		margin: 0,
		padding: 0,
		width: "100%",
		minWidth: "100%",
		color: "var(--white)",
	}),
}
