import { combineReducers, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { apiKeyReducer } from "./reducers/apiKey"
import { bsaConfigReducer } from "./reducers/bsaConfig"
import { currencyConfigReducer } from "./reducers/currencyConfig"
import { drugIndicationReducer } from "./reducers/drug_indication"
import { indicationApprovalReducer } from "./reducers/indication_approval"
import { masterDataReducer } from "./reducers/masterData"
import { organizationReducer } from "./reducers/organization"
import { packageReducer } from "./reducers/package"
import { priceReducer } from "./reducers/price"
import { referenceReducer } from "./reducers/reference"
import { treatmentsReducer } from "./reducers/treatment"
import { userReducer } from "./reducers/user"
import { usersReducer } from "./reducers/users"

const persistConfig = {
	key: "posology",
	version: 1,
	storage,
	whitelist: ["user"],
}

const reducer = combineReducers({
	packages: packageReducer,
	prices: priceReducer,
	indicationDrugs: drugIndicationReducer,
	indicationApprovals: indicationApprovalReducer,
	user: userReducer,
	treatments: treatmentsReducer,
	masterData: masterDataReducer,
	users: usersReducer,
	references: referenceReducer,
	currencyConfig: currencyConfigReducer,
	bsaConfig: bsaConfigReducer,
	organizations: organizationReducer,
	apiKeys: apiKeyReducer,
})

const rootReducer = (state, action) => {
	if (action.type === "USER/LOGOUT") {
		state = undefined
		localStorage.removeItem("persist:calculator")
	}
	return reducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
	reducer: persistedReducer,
	middleware: getDefaultMiddleware({
		serializableCheck: {
			ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
		},
	}),
	devTools: process.env.NODE_ENV !== "production",
})

export default store
