import { createAction, createReducer } from "@reduxjs/toolkit"
import axios from "axios"
import jwtDecode from "jwt-decode"
import { loginError, posologyAppAccess, serverError } from "../../utils/constants"
import { loginURL, oidcURL, refreshTokenURL, updateProfileURL } from "../api_urls"

const START_REQUEST = createAction("USER/START_REQUEST")
const LOGIN_SUCCESS = createAction("USER/LOGIN_SUCCESS")
const REQUEST_FAIL = createAction("USER/REQUEST_FAIL")
const REQUEST_SUCCESS = createAction("USER/UPDATE_PROFILE")
const REFRESH_SUCCESS = createAction("USER/REFRESH_SUCCESS")
const LOGOUT = createAction("USER/LOGOUT")
const CLEAN_MESSAGE = createAction("USER/CLEAN_MESSAGE")

export const login = (data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(loginURL, data).then((res) => handleResponse(res, dispatch, LOGIN_SUCCESS, REQUEST_FAIL))
}

export const loginUser = (data) => async (dispatch) => {
	dispatch(LOGIN_SUCCESS(data))
}

export const loginAuth = () => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.get(oidcURL)
}

export const logout = () => async (dispatch) => {
	dispatch(LOGOUT())
}

export const updateUserProfile = (data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(updateProfileURL, data).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const refreshAccessToken = (data) => async (dispatch) => {
	const payload = { refresh: data }
	dispatch(START_REQUEST())
	return axios.post(refreshTokenURL, payload).then((res) => handleResponse(res, dispatch, REFRESH_SUCCESS, REQUEST_FAIL))
}

const handleResponse = (res, dispatch, success, fail) => {
	if (res !== undefined) {
		if (res.status >= 200 && res.status <= 299) {
			if (success === REQUEST_SUCCESS) {
				dispatch(success(res.data.message))
				dispatch(CLEAN_MESSAGE())
			} else {
				const decoded = jwtDecode(res.data.access)
				if (posologyAppAccess.includes(decoded.role)) {
					dispatch(success(res.data))
				} else {
					dispatch(fail({ text: loginError, type: "error" }))
					dispatch(CLEAN_MESSAGE())
				}
			}
			return res
		} else if (res.response !== undefined) {
			if (res.response.status === 401) {
				dispatch(fail({ text: loginError, type: "error" }))
				dispatch(CLEAN_MESSAGE())
			} else if (res.response.status >= 400 && res.response.status <= 499) {
				dispatch(fail(res.response.data.message))
				dispatch(CLEAN_MESSAGE())
			}
			return res.response
		} else {
			dispatch(fail({ text: serverError, type: "error" }))
			dispatch(CLEAN_MESSAGE())
		}
	} else {
		dispatch(fail({ text: serverError, type: "error" }))
		dispatch(CLEAN_MESSAGE())
	}
}

const initState = {
	token: "",
	refreshToken: "",
	username: "",
	admin: false,
	firstName: "",
	lastName: "",
	role: "",
	useParentData: false,
	organizationId: "",
	organizationName: "",
	isStatic: false,
	wastageCalculation: false,
	defaultWastageCalcOption: false,
	indicationText: false,
	packageOptions: null,
	htaReimbursement: false,
	singleYear: false,
	option28Days: false,
	autoCalculate: false,
	selectAllIndications: false,
	showBsaWeight: false,
	showCurrencyRate: false,
	showDosagePattern: false,
	showPipeline: false,
	showTreatmentLine: false,
	allowTreatmentLines: false,
	allowedGraphs: null,
	allowedPrices: null,
	defaultPrices: null,
	defaultCountries: null,
	defaultDrugs: null,
	defaultInterval: null,
	defaultPackageOption: null,
	defaultIndications: null,
	defaultPackages: null,
	euMarketAccess: false,
	allowCycles: false,
	allowWeeks: false,
	showAnnual: false,
	showMonth: false,
	showBrandTypes: false,
	calculatorMode: true,
	showStartNumber: false,
	showEndNumber: false,
	loading: false,
	message: null,
}

export const userReducer = createReducer(initState, (builder) => {
	builder
		.addCase(START_REQUEST, (state, action) => {
			state.loading = true
			state.message = null
		})
		.addCase(LOGIN_SUCCESS, (state, action) => {
			const decoded = jwtDecode(action.payload.access)
			state.loading = false
			state.token = action.payload.access
			state.refreshToken = action.payload.refresh
			state.username = decoded.username
			state.admin = decoded.is_admin
			state.firstName = decoded.firstname
			state.lastName = decoded.lastname
			state.isStatic = decoded.is_static
			state.role = decoded.role
			if (decoded.organization) {
				state.useParentData = decoded.organization.use_parent_data
				state.organizationId = decoded.organization.id
				state.organizationName = decoded.organization.name
			}
			state.wastageCalculation = decoded.wastage_calculation
			state.defaultWastageCalcOption = decoded.default_wastage_calc_option
			state.indicationText = decoded.indication_text
			state.htaReimbursement = decoded.hta_reimbursement_access
			state.singleYear = decoded.show_single_year_radio
			state.option28Days = decoded.show_single_month_radio
			state.autoCalculate = decoded.auto_calculate
			state.selectAllIndications = decoded.select_all_indications
			state.showBsaWeight = decoded.show_bsa_weight_page
			state.showCurrencyRate = decoded.show_currency_rate_page
			state.showDosagePattern = decoded.show_dosage_pattern_page
			state.packageOptions = decoded.package_option
			state.allowedGraphs = decoded.allowed_graphs
			state.allowedPrices = decoded.allowed_prices
			state.defaultPrices = decoded.default_prices
			state.defaultCountries = decoded.default_countries
			state.defaultDrugs = decoded.default_drugs
			state.defaultInterval = decoded.default_interval
			state.defaultPackageOption = decoded.default_package_option
			state.defaultIndications = decoded.default_indications
			state.defaultPackages = decoded.default_packages
			state.showPipeline = decoded.show_pipeline
			state.showTreatmentLine = decoded.show_treatment_line
			state.euMarketAccess = decoded.eu_market_access
			state.allowTreatmentLines = decoded.show_filter_by_treatment_line
			state.allowCycles = decoded.allow_calc_type_cycles
			state.allowWeeks = decoded.allow_calc_type_weeks
			state.showMonth = decoded.show_single_month_radio
			state.showAnnual = decoded.show_single_year_radio
			state.showBrandTypes = decoded.allow_brand_type
			state.calculatorMode = decoded.calculator_mode
			state.showStartNumber = decoded.show_start_number
			state.showEndNumber = decoded.show_end_number
			state.message = action.payload.message
		})
		.addCase(REFRESH_SUCCESS, (state, action) => {
			const decoded = jwtDecode(action.payload.access)
			state.loading = false
			state.token = action.payload.access
			state.username = decoded.username
			state.admin = decoded.is_admin
			state.firstName = decoded.firstname
			state.lastName = decoded.lastname
			state.isStatic = decoded.is_static
			state.role = decoded.role
			if (decoded.organization) {
				state.useParentData = decoded.organization.use_parent_data
				state.organizationId = decoded.organization.id
			}
			state.wastageCalculation = decoded.wastage_calculation
			state.defaultWastageCalcOption = decoded.default_wastage_calc_option
			state.indicationText = decoded.indication_text
			state.htaReimbursement = decoded.hta_reimbursement_access
			state.singleYear = decoded.show_single_year_radio
			state.option28Days = decoded.show_single_month_radio
			state.autoCalculate = decoded.auto_calculate
			state.selectAllIndications = decoded.select_all_indications
			state.showBsaWeight = decoded.show_bsa_weight_page
			state.showCurrencyRate = decoded.show_currency_rate_page
			state.showDosagePattern = decoded.show_dosage_pattern_page
			state.packageOptions = decoded.package_option
			state.allowedGraphs = decoded.allowed_graphs
			state.allowedPrices = decoded.allowed_prices
			state.defaultPrices = decoded.default_prices
			state.defaultCountries = decoded.default_countries
			state.defaultDrugs = decoded.default_drugs
			state.defaultInterval = decoded.default_interval
			state.defaultPackageOption = decoded.default_package_option
			state.defaultIndications = decoded.default_indications
			state.defaultPackages = decoded.default_packages
			state.showPipeline = decoded.show_pipeline
			state.showTreatmentLine = decoded.show_treatment_line
			state.allowTreatmentLines = decoded.show_filter_by_treatment_line
			state.allowCycles = decoded.allow_calc_type_cycles
			state.allowWeeks = decoded.allow_calc_type_weeks
			state.showMonth = decoded.show_single_month_radio
			state.showAnnual = decoded.show_single_year_radio
			state.showBrandTypes = decoded.allow_brand_type
			state.calculatorMode = decoded.calculator_mode
			state.euMarketAccess = decoded.eu_market_access
			state.showStartNumber = decoded.show_start_number
			state.showEndNumber = decoded.show_end_number
		})
		.addCase(REQUEST_SUCCESS, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(REQUEST_FAIL, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(LOGOUT, (state, action) => {
			state.loading = false
		})
		.addCase(CLEAN_MESSAGE, (state, action) => {
			state.message = null
		})
})
