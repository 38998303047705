import React from "react"
import { Controller, useForm } from "react-hook-form"
import Select from "react-select"
import styled from "styled-components"
import { masterDataWithOrg, superAdminAccess } from "../../utils/constants"
import SaveBtn from "../buttons/SaveBtn"
import { customStylesSelect } from "../CustomSelectStyles"

const MasterDataForm = ({ defaultValues, onSubmit, cancel, selectData, type, role }) => {
	const { register, handleSubmit, control } = useForm()

	return (
		<FormStyles>
			<form onSubmit={handleSubmit(onSubmit)}>
				{masterDataWithOrg.includes(type) && superAdminAccess.includes(role) && (
					<>
						<label>Organization</label>
						<Controller
							control={control}
							options={selectData.organizations}
							as={Select}
							defaultValue={defaultValues && defaultValues.defaultOrganization}
							name="organization"
							placeholder="Select organization..."
							className="react-select-container"
							classNamePrefix="react-select"
							styles={customStylesSelect}
							isClearable={true}
						/>
					</>
				)}
				<label>Code</label>
				<input type="text" name="code" ref={register({ required: true })} defaultValue={defaultValues && defaultValues.code} />
				<label>Value</label>
				<textarea
					name="name"
					ref={register({ required: true })}
					cols="30"
					rows="10"
					spellCheck="false"
					defaultValue={defaultValues && defaultValues.name}
				/>

				<div className="btns-wrapper">
					<SaveBtn />
					<button className="cancelBtn" type="button" onClick={() => cancel()}>
						Close
					</button>
				</div>
			</form>
		</FormStyles>
	)
}
const FormStyles = styled.div`
	text-align: center;
	min-height: 505px;
	form {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	input {
		width: 100%;
		max-width: 383px;
		margin-bottom: 0;
		height: 40px;
		padding: 0 10px;
		color: var(--blue);
	}
	label {
		display: block;
		max-width: 383px;
		padding: 20px 0 10px 0;
		color: var(--white);
	}
	button {
		width: 130px;
		min-height: 40px;
	}

	textarea {
		width: 100%;
		max-width: 383px;
		height: 170px;
		overflow-y: scroll;
		border-radius: 4px;
		resize: none;
		font-size: 16px;
		color: var(--blue);
		/* font-weight: 700; */
		padding: 15px;
		display: block;
		margin: 0 auto;
	}
	.cancelBtn {
		width: 130px;
		min-height: 40px;

		&:hover {
			color: var(--red);
			transition: 0.3 ease;
		}
	}
	.btns-wrapper {
		margin-top: 100px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		column-gap: 60px;
	}

	.react-select-container {
		width: 100%;
		height: 40px;
		margin: 0 auto;
		text-align: left;

		.react-select__control {
			border-radius: 4px;
			background: var(--white);
		}

		.react-select__value-container {
			max-height: 70px;
			overflow-y: auto;
		}
		.react-select__multi-value__remove {
			color: var(--blue);
		}
		.react-select__input {
			height: 40px;
		}
		.react-select__placeholder {
			color: var(--blue);
			font-weight: 400;
		}
		.react-select__menu-list {
			height: 160px;
		}
		.react-select__single-value {
			color: var(--black);
		}
	}
`
export default MasterDataForm
