import { ChakraProvider } from "@chakra-ui/react"
import axios from "axios"
import React from "react"
import { Switch } from "react-router-dom"
import AppRoute from "./components/auth/AppRoute"
import { chakraTheme } from "./components/ChakraTheme"
import GlobalStyles from "./components/GlobalStyles"
import routes from "./config/routes"
import { base_url, loginURL } from "./redux/api_urls"
import useUser from "./redux/hooks/useUser"
import store from "./redux/store"

function App() {
	const hookUser = useUser()

	axios.defaults.baseURL = base_url

	axios.interceptors.request.use(function (config) {
		if (store.getState().user.token) config.headers.Authorization = `Bearer ${store.getState().user.token}`

		return config
	})

	axios.interceptors.response.use(
		(response) => {
			return response
		},
		(error) => {
			if (error.response !== undefined) {
				if (error.response.status === 401) {
					if (error.response.data.code === "token_not_valid") {
						hookUser.signout()
					} else if (error.response.config.url !== loginURL) {
						hookUser.refresh()
					}
				}
			}

			return error
		}
	)

	return (
		<div className="App">
			<GlobalStyles />
			<ChakraProvider theme={chakraTheme}>
				<Switch>
					{routes.map((route) => (
						<AppRoute
							key={route.path}
							path={route.path}
							component={route.component}
							isPrivate={route.isPrivate}
							isAdmin={route.isAdmin}
							isSuperAdmin={route.isSuperAdmin}
							isWriteMode={route.isWriteMode}
							exact={true}
						/>
					))}
				</Switch>
			</ChakraProvider>
		</div>
	)
}

export default App
