import React from "react"
import { Controller, useForm } from "react-hook-form"
import Select from "react-select"
import styled from "styled-components"
import { superAdminAccess } from "../../utils/constants"
import CustomButton from "../buttons/CustomButton"
import CustomSelectAll from "../CustomSelectAll"
import { customStylesSelect } from "../CustomSelectStyles"
import PositiveDecimalInput from "../inputs/PositiveDecimalInput"

const DrugPackageForm = ({ defaultValues, onSubmit, cancel, selectData, role }) => {
	const { handleSubmit, control, register } = useForm()

	return (
		<FormWrapper onSubmit={handleSubmit(onSubmit)}>
			<div className="form-section">
				<div className="side-column">
					<label>Product name</label>
					<Controller
						control={control}
						options={selectData.productNames}
						as={CustomSelectAll}
						defaultValue={defaultValues && defaultValues.defaultProductName}
						name="product_name"
						placeholder="Select product name..."
						className="react-select-container"
						classNamePrefix="react-select"
						styles={customStylesSelect}
						ref={register({ required: true })}
						rules={{ required: true }}
					/>
					<label>Route of administration</label>
					<Controller
						control={control}
						options={selectData.routes}
						as={Select}
						defaultValue={defaultValues && defaultValues.defaultRoute}
						name="route_of_administration"
						placeholder="Select route of administration..."
						className="react-select-container"
						classNamePrefix="react-select"
						styles={customStylesSelect}
						rules={{ required: true, message: "This field is required" }}
					/>
					<label>Pack info</label>
					<input
						name="pack_info"
						ref={register({ required: true })}
						defaultValue={defaultValues && defaultValues.packInfo}
						placeholder={"Insert pack info..."}
					/>
					<label>Pack strength</label>
					<Controller
						control={control}
						as={PositiveDecimalInput}
						name={"pack_strength"}
						defaultValue={defaultValues && defaultValues.packStrength}
						placeholder={"Insert pack strength..."}
					/>
					{superAdminAccess.includes(role) && (
						<>
							<label>Organization</label>
							<Controller
								control={control}
								options={selectData.organizations}
								as={Select}
								defaultValue={defaultValues && defaultValues.organization}
								name="organization"
								placeholder="Select organization..."
								className="react-select-container"
								classNamePrefix="react-select"
								styles={customStylesSelect}
								isClearable={true}
							/>
						</>
					)}
				</div>
				<div className="side-column">
					<label>Pack unit</label>
					<Controller
						control={control}
						options={selectData.packUnits}
						as={Select}
						defaultValue={defaultValues && defaultValues.defaultPackUnit}
						name="pack_unit"
						placeholder="Select pack unit..."
						className="react-select-container"
						classNamePrefix="react-select"
						styles={customStylesSelect}
						rules={{ required: true, message: "This field is required" }}
					/>
					<label>Pack size</label>
					<Controller
						control={control}
						as={PositiveDecimalInput}
						name={"pack_size"}
						defaultValue={defaultValues && defaultValues.packSize}
						placeholder="Insert pack size..."
					/>
					<label>Fill unit</label>
					<Controller
						control={control}
						options={selectData.fillUnits}
						as={Select}
						defaultValue={defaultValues && defaultValues.defaultFillUnit}
						name="fill_unit"
						placeholder="Select fill unit..."
						className="react-select-container"
						classNamePrefix="react-select"
						styles={customStylesSelect}
						rules={{ required: true, message: "This field is required" }}
					/>
					<label>Fill size</label>
					<Controller
						control={control}
						as={PositiveDecimalInput}
						name="fill_size"
						defaultValue={defaultValues && defaultValues.fillSize}
						placeholder={"Insert fill size..."}
					/>
					<label>Description</label>
					<input
						name="description"
						ref={register({ required: true })}
						defaultValue={defaultValues && defaultValues.description}
						placeholder="Insert description..."
						autoComplete="off"
					/>
				</div>
			</div>
			<ButtonsDiv>
				<CustomButton type="save" />
				<CustomButton type="cancel" onClick={cancel} />
			</ButtonsDiv>
		</FormWrapper>
	)
}

const FormWrapper = styled.form`
	width: 1000px;
	height: 670px;
	text-align: center;

	.form-section {
		display: flex;
		column-gap: 100px;
		justify-content: center;
		margin-bottom: 60px;
	}
	.side-column {
		width: 100%;
		display: flex;
		flex-direction: column;
		row-gap: 15px;
	}
	label {
		display: block;
		color: var(--white);
		padding-top: 10px;
	}

	input {
		width: 100%;
		height: 40px;
		padding-left: 10px;
		font-size: 16px;
		color: var(--black);
		font-weight: 400;
		::placeholder {
			color: var(--black);
		}
	}

	.react-select-container {
		width: 100%;
		height: 40px;
		margin: 0 auto;
		text-align: left;

		.react-select__control {
			border-radius: 4px;
			background: var(--white);
		}

		.react-select__value-container {
			max-height: 70px;
			overflow-y: auto;
		}
		.react-select__multi-value__remove {
			color: var(--blue);
		}
		.react-select__input {
			height: 40px;
		}
		.react-select__placeholder {
			color: var(--blue);
			font-weight: 400;
		}
		.react-select__menu-list {
			height: 160px;
		}
		.react-select__single-value {
			color: var(--black);
		}
	}
`

const ButtonsDiv = styled.div`
	margin: 30px auto;
	button {
		margin: 0 25px;
		padding: 4px 10px;
	}
`
export default DrugPackageForm
