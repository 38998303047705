import { extendTheme } from "@chakra-ui/react"

const config = {
	initialColorMode: "light",
	useSystemColorMode: false,
}

// --blue:;
//         --white:#f8f8f8;
//         --green:#47AD6D;;
//         --red:#D26060;
//         --orange:#D69636;
//         --black:#000000;

export const chakraTheme = extendTheme({
	config,
	colors: {
		white: "#f8f8f8",
		green: "#47AD6D",
		blue: "#354f99",
		yellow: "#D69636",
		red: "#D26060",
	},

	fonts: {
		heading: "'Rubik',sans-serif",
		body: "'Rubik',sans-serif",
	},
	fontSizes: {
		Heading: {
			h1: "36px",
			h2: "28px",
			h3: "18px",
		},
	},
	breakpoints: ["30em", "48em", "62em", "80em", "96em"],
	components: {
		Button: {
			sizes: {
				xl: {
					h: "40px",
					minW: "120px",
				},
			},
			variants: {
				white: (props) => ({
					bg: "white",
					color: "black",
					_hover: {
						bg: "white",
						opacity: ".86",
					},
				}),
				red: (props) => ({
					bg: "red",
					color: "white",
					_hover: {
						bg: "red",
						opacity: ".86",
					},
				}),
				green: (props) => ({
					bg: "green",
					color: "white",
					_hover: {
						bg: "green",
						opacity: ".86",
					},
				}),
				orange: (props) => ({
					bg: "orange",
					color: "white",
					_hover: {
						bg: "orange",
						opacity: ".86",
					},
				}),
				cancel: (props) => ({
					bg: "white",
					color: "red",
					_hover: {
						bg: "white",
						opacity: ".86",
					},
				}),
			},
			defaultProps: {
				size: "xl",
			},
		},
	},
})
