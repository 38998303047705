import React from "react"
import styled from "styled-components"

const ModalTableFrame = ({ children }) => {
	return (
		<ContainerStyles>
			<FrameTableStyles>{children}</FrameTableStyles>
		</ContainerStyles>
	)
}
const ContainerStyles = styled.div`
	width: 100%;
	/* height: 75vh; */
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
`
const FrameTableStyles = styled.div`
	min-width: 670px;
	min-height: 400px;
	max-height: 60vh;
	border: 4px solid var(--blue);
	border-radius: 4px;
	overflow-y: auto;
`
export default ModalTableFrame
