import { useDisclosure } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { ReactComponent as AddButton } from "../assets/add-button.svg"
import BsaForm from "../components/forms/BsaForm"
import { makeDropdownOptions } from "../components/forms/calcHelper"
import Loading from "../components/frames/Loading"
import TableFrame from "../components/frames/TableFrame"
import Layout from "../components/layout/Layout"
import CustomModal from "../components/modals/CustomModal"
import generateToast from "../components/notifications/ToastGenerator"
import BsaConfigTable from "../components/tables/BsaConfigTable"
import { country } from "../redux/api_urls"
import useBsaConfig from "../redux/hooks/useBsaConfig"
import useMasterData from "../redux/hooks/useMasterData"
import useUser from "../redux/hooks/useUser"
import { writeMode } from "../utils/constants"

const BsaConfigPage = () => {
	const hook = useBsaConfig()
	const hookMd = useMasterData()
	const role = useUser().role

	const { isOpen, onOpen, onClose } = useDisclosure()

	const [countries, setCountries] = useState([])

	useEffect(() => {
		hook.getBsaConfig()
		hookMd.fetch(country)
	}, []) // eslint-disable-line

	useEffect(() => {
		setCountries(makeDropdownOptions(hookMd.countries))
	}, [hookMd.countries])

	useEffect(() => {
		if (hook.message) {
			generateToast(hook.message)
		}
	}, [hook.message])

	const handleSubmit = (data) => {
		const payload = {
			country: data.country.value,
			weight: data.weight,
			bsa: data.bsa,
		}

		hook.createBsa(payload).then((res) => {
			if (res !== undefined && res.status === 200) {
				onClose()
				hook.getBsaConfig()
			}
		})
	}

	const selectData = {
		countries: countries,
	}

	return (
		<Layout>
			<TableFrame>
				<BsaConfigTable selectData={selectData} role={role} />
				{hook.loading && <Loading />}
			</TableFrame>
			{writeMode.includes(role) && (
				<>
					<AddButton className="addBtn" onClick={() => onOpen()} />

					<CustomModal isOpen={isOpen}>
						<BsaForm submit={handleSubmit} cancel={onClose} selectData={selectData} />
					</CustomModal>
				</>
			)}
		</Layout>
	)
}

export default BsaConfigPage
