import { useDisclosure } from "@chakra-ui/react"
import { useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import usePackage from "../../redux/hooks/usePackage"
import { superAdminAccess } from "../../utils/constants"
import { checkWriteCondition, handleOrganizationPayload, handleResponse } from "../../utils/functions"
import CustomButton from "../buttons/CustomButton"
import DrugPackageForm from "../forms/DrugPackageForm"
import CustomModal from "../modals/CustomModal"
import DeleteModal from "../modals/DeleteModal"
import { TablesStyles } from "./TableStyles"

const PackageTable = ({ selectData, role, parentData, organizationId }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()

	const hook = usePackage()

	const [updateData, setUpdateData] = useState(null)

	let data = hook.data

	const columns = [
		{
			dataField: "pack_info",
			text: "Pack info",
		},
		{
			dataField: "pack_strength",
			text: "Pack strength",
			headerStyle: () => {
				return { width: "4%" }
			},
		},
		{
			dataField: "drug_name",
			text: "Product name",
		},
		{
			dataField: "route_of_administration_code",
			text: "Route of administration",
			headerStyle: () => {
				return { width: "6%" }
			},
		},
		{
			dataField: "pack_unit_name",
			text: "Pack unit",
			headerStyle: () => {
				return { width: "12%" }
			},
		},
		{
			dataField: "pack_size",
			text: "Pack size",
			headerStyle: () => {
				return { width: "5%" }
			},
		},
		{
			dataField: "fill_unit_name",
			text: "Fill unit",
			headerStyle: () => {
				return { width: "8%" }
			},
		},
		{
			dataField: "fill_size",
			text: "Fill size",
			headerStyle: () => {
				return { width: "5%" }
			},
		},
		{
			dataField: "description",
			text: "Description",
		},
	]

	if (superAdminAccess.includes(role)) {
		columns.splice(0, 0, {
			dataField: "organization_name",
			text: "Organization",
		})
	}

	const handleSuccess = () => {
		hook.fetch(1, hook.defaultSize)
		onClose()
	}

	const handleDelete = (id) => {
		hook.remove(id).then((res) => handleResponse(res, handleSuccess))
	}

	const expandRow = {
		renderer: (row) => (
			<div className="btns-container">
				<CustomButton type="update" onClick={() => handleOpenUpdate(row)} />
				<DeleteModal remove={() => handleDelete(row.id)} />
			</div>
		),
		className: "expandedRow",
		parentClassName: "parentExpandedRow",
	}

	const handleOpenUpdate = (row) => {
		let defaultValues = {
			id: row.id,
			defaultProductName: selectData.productNames.find((option) => option.value === row.drug),
			defaultRoute: selectData.routes.find((option) => option.value === row.route_of_administration),
			packInfo: row.pack_info,
			packStrength: row.pack_strength,
			defaultPackUnit: selectData.packUnits.find((option) => option.value === row.pack_unit),
			defaultFillUnit: selectData.fillUnits.find((option) => option.value === row.fill_unit),
			fillSize: row.fill_size,
			description: row.description,
			packSize: row.pack_size,
		}

		if (superAdminAccess.includes(role)) {
			defaultValues["organization"] = selectData.organizations.find((ele) => ele.value === Number(row.organization))
		}

		setUpdateData(defaultValues)
		onOpen()
	}

	const submit = (data) => {
		let payload = {
			id: updateData.id,
			drug: data.product_name.value,
			route_of_administration: data.route_of_administration.value,
			pack_info: data.pack_info,
			pack_strength: data.pack_strength,
			pack_unit: data.pack_unit.value,
			pack_size: data.pack_size,
			fill_unit: data.fill_unit.value,
			fill_size: data.fill_size,
			description: data.description,
		}

		handleOrganizationPayload(payload, role, organizationId, data.organization)

		hook.update(payload).then((res) => handleResponse(res, handleSuccess))
	}

	const handleCancel = () => {
		onClose()
	}

	return (
		<>
			<TablesStyles minWidth="2000px">
				<BootstrapTable
					data={data}
					columns={columns}
					keyField="id"
					classes="TablesStyles"
					expandRow={checkWriteCondition(role, parentData) && expandRow}
					bordered
					bootstrap4
				/>
			</TablesStyles>

			<CustomModal isOpen={isOpen} onClose={onClose} size="6xl">
				<DrugPackageForm
					defaultValues={updateData}
					selectData={selectData}
					doNotExpandRows={true}
					onSubmit={submit}
					cancel={handleCancel}
					role={role}
				/>
			</CustomModal>
		</>
	)
}

export default PackageTable
