import { useDisclosure } from "@chakra-ui/react"
import { useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import usePrice from "../../redux/hooks/usePrice"
import { superAdminAccess } from "../../utils/constants"
import {
	checkWriteCondition,
	formatDate,
	handleOrganizationPayload,
	handlePricePayload,
	handleResponse,
	pricesForm,
} from "../../utils/functions"
import CustomButton from "../buttons/CustomButton"
import PriceForm from "../forms/PriceForm"
import CustomModal from "../modals/CustomModal"
import DeleteModal from "../modals/DeleteModal"
import { TablesStyles } from "./TableStyles"

const PriceTable = ({ selectData, role, parentData, organizationId }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()

	const hook = usePrice()

	const [updateData, setUpdateData] = useState(null)

	let data = hook.data

	const columns = [
		{
			dataField: "start_date",
			text: "Start date",
			formatter: (cell) => {
				return cell ? formatDate(cell) : ""
			},
		},
		{
			dataField: "end_date",
			text: "End date",
			formatter: (cell) => {
				return cell ? formatDate(cell) : ""
			},
		},
		{
			dataField: "drug_name",
			text: "Product name",
		},

		{
			dataField: "brand_type_name",
			text: "Brand type",
		},
		{
			dataField: "pack_info",
			text: "Pack info",
		},
		{
			dataField: "pack_description",
			text: "Pack description",
			headerStyle: () => {
				return { width: "13%" }
			},
		},
		{
			dataField: "pack_strength",
			text: "Pack strength",
		},
		{
			dataField: "route_of_administration_code",
			text: "Route of administration",
		},
		{
			dataField: "mnf",
			text: "MNF",
		},
		{
			dataField: "hosp",
			text: "HOSP",
		},
		{
			dataField: "ppp",
			text: "PPP",
		},
		{
			dataField: "ppe_vat",
			text: "PPE VAT",
		},
		{
			dataField: "ppi_vat",
			text: "PPI VAT",
		},
		{
			dataField: "listed",
			text: "LISTED",
		},
		{
			dataField: "currency_code",
			text: "CURRENCY",
		},
		{
			dataField: "country_name",
			text: "COUNTRY",
		},
	]

	if (superAdminAccess.includes(role)) {
		columns.splice(0, 0, {
			dataField: "organization_name",
			text: "Organization",
		})
	}

	const handleSuccess = () => {
		onClose()
		hook.fetch(1, hook.defaultSize)
	}

	const handleDelete = (id) => {
		hook.remove(id).then((res) => handleResponse(res, handleSuccess))
	}

	const expandRow = {
		renderer: (row) => (
			<div className="btns-container">
				<CustomButton type="update" onClick={() => handleOpenUpdate(row)} />
				<DeleteModal remove={() => handleDelete(row.id)} />
			</div>
		),
		className: "expandedRow",
		parentClassName: "parentExpandedRow",
	}

	const handleOpenUpdate = (row) => {
		const defaultValues = {
			id: row.id,
			defaultPackage: selectData.packages.find((option) => option.value === row.package_id),
			defaultBrandType: selectData.brandTypes.find((option) => option.value === row.brand_type),
			defaultCountry: selectData.countries.find((option) => option.label === row.country_name),
			defaultCurrency: selectData.currencies.find((option) => option.label === row.currency_code),
			mnf: row.mnf,
			hosp: row.hosp,
			ppp: row.ppp,
			ppeVat: row.ppe_vat,
			ppiVat: row.ppi_vat,
			listed: row.listed,
			startDate: row.start_date,
			endDate: row.end_date,
		}

		if (superAdminAccess.includes(role)) {
			defaultValues["organization"] = selectData.organizations.find((ele) => ele.value === Number(row.organization))
		}

		setUpdateData(defaultValues)
		onOpen()
	}

	const submit = (data) => {
		if (data.start_date) {
			var startDate = new Date(data.start_date)
		} else {
			startDate = new Date(updateData.startDate)
		}

		if (data.end_date) {
			var endDate = new Date(data.end_date)
		} else {
			endDate = new Date(updateData.endDate)
		}

		let payload = {
			id: updateData.id,
			country: data.country.value,
			drug_package: data.drug_package.value,
			currency: data.currency.value,
			start_date: startDate.toISOString(),
			end_date: endDate.toISOString(),
		}

		pricesForm.map((price, i) => handlePricePayload(data, payload, price))

		handleOrganizationPayload(payload, role, organizationId, data.organization)

		hook.update(payload).then((res) => handleResponse(res, handleSuccess))
	}

	return (
		<>
			<TablesStyles minWidth="3800px">
				<BootstrapTable
					data={data}
					columns={columns}
					keyField="id"
					classes="TablesStyles"
					expandRow={checkWriteCondition(role, parentData) && expandRow}
					bordered
					bootstrap4
				/>
			</TablesStyles>

			<CustomModal isOpen={isOpen} onClose={onClose} size="6xl">
				<PriceForm defaultValues={updateData} selectData={selectData} onSubmit={submit} cancel={onClose} role={role} />
			</CustomModal>
		</>
	)
}

export default PriceTable
