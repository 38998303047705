import { useDisclosure } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import Select from "react-select"
import styled from "styled-components"
import { ReactComponent as AddButton } from "../assets/add-button.svg"
import { customStylesSelect } from "../components/CustomSelectStyles"
import { makeDropdownOptions } from "../components/forms/calcHelper"
import PriceForm from "../components/forms/PriceForm"
import Loading from "../components/frames/Loading"
import PriceTableFrame from "../components/frames/PriceTableFrame"
import Checkbox from "../components/inputs/Checkbox"
import Layout from "../components/layout/Layout"
import CustomModal from "../components/modals/CustomModal"
import generateToast from "../components/notifications/ToastGenerator"
import Pagination from "../components/tables/Pagination"
import PriceTable from "../components/tables/PriceTable"
import UploadFile from "../components/UploadFile"
import { brandType, country, currency } from "../redux/api_urls"
import useMasterData from "../redux/hooks/useMasterData"
import useOrganization from "../redux/hooks/useOrganization"
import usePackage from "../redux/hooks/usePackage"
import usePrice from "../redux/hooks/usePrice"
import useUser from "../redux/hooks/useUser"
import { defaultPageNumber, superAdminAccess } from "../utils/constants"
import { checkWriteCondition, handleOrganizationPayload, handlePricePayload, handleResponse, pricesForm } from "../utils/functions"

const PricePage = () => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const hook = usePrice()
	const hookMasterData = useMasterData()
	const hookPackage = usePackage()
	const hookOrg = useOrganization()
	const role = useUser().role
	const parentData = useUser().useParentData
	const organizationId = useUser().organizationId

	const [selectedPackage, setSelectedPackage] = useState(null)
	const [selectedCountry, setSelectedCountry] = useState(null)
	const [filter, setFilter] = useState(null)
	const [sortBy, setSortBy] = useState(null)
	const [files, setFiles] = useState([])
	const [drugExceptionValue, setDrugExceptionValue] = useState(false)
	const [packages, setPackages] = useState([])
	const [countries, setCountries] = useState([])
	const [currencies, setCurrencies] = useState([])
	const [brandTypes, setBrandTypes] = useState([])
	const [organizations, setOrganizations] = useState([])

	useEffect(() => {
		hook.fetch(defaultPageNumber, hook.defaultSize)
		hookPackage.fetchDropdown()
		hookMasterData.fetch(country)
		hookMasterData.fetch(currency)
		hookMasterData.fetch(brandType)
		if (superAdminAccess.includes(role)) {
			hookOrg.fetchDropdown()
		}

		setFilter(null)
	}, []) // eslint-disable-line

	useEffect(() => {
		setPackages(makeDropdownOptions(hookPackage.dropdownData, "description"))
		setCountries(makeDropdownOptions(hookMasterData.countries))
		setCurrencies(makeDropdownOptions(hookMasterData.currencies, "code"))
		setBrandTypes(makeDropdownOptions(hookMasterData.brandTypes))
		setOrganizations(makeDropdownOptions(hookOrg.dropdownData, "name"))
	}, [hookPackage.dropdownData, hookMasterData.countries, hookMasterData.currencies, hookMasterData.brandTypes, hookOrg.dropdownData])

	const handleSuccess = () => {
		onClose()
		hook.fetch(1, hook.defaultSize)
	}

	const submit = (data) => {
		let startDate = new Date(data.start_date)
		let endDate = new Date(data.end_date)

		let payload = {
			country: data.country.value,
			drug_package: data.drug_package.value,
			currency: data.currency.value,
			start_date: startDate.toISOString(),
			end_date: endDate.toISOString(),
		}

		pricesForm.map((price, i) => handlePricePayload(data, payload, price))

		handleOrganizationPayload(payload, role, organizationId, data.organization)
		hook.create(payload).then((res) => handleResponse(res, handleSuccess))
	}

	const sortByOptions = [
		{ value: "start_date", label: "Start date" },
		{ value: "end_date", label: "End date" },
		{ value: "currency", label: "Currency" },
		{ value: "country", label: "Country" },
		{ value: "drug_package", label: "Drug package" },
		{ value: "mnf", label: "MNF" },
		{ value: "hosp", label: "HOSP" },
		{ value: "ppp", label: "PPP" },
		{ value: "ppe_vat", label: "PPE VAT" },
		{ value: "ppi_vat", label: "PPI VAT" },
		{ value: "listed", label: "LISTED" },
	]

	const handlePackageSelect = (val) => {
		const filterData = {}
		if (!val) {
			setSelectedPackage(null)
		} else {
			setSelectedPackage(val)
			filterData["package"] = val.value
		}

		if (selectedCountry) {
			filterData["country"] = selectedCountry.value
		}
		if (sortBy) {
			filterData["sortBy"] = sortBy.value
		}
		hook.fetch(defaultPageNumber, hook.defaultSize, filterData)
		setFilter(filterData)
	}

	const handleCountrySelect = (val) => {
		const filterData = {}
		if (!val) {
			setSelectedCountry(null)
		} else {
			setSelectedCountry(val)
			filterData["country"] = val.value
		}

		if (selectedPackage) {
			filterData["package"] = selectedPackage.value
		}
		if (sortBy) {
			filterData["sortBy"] = sortBy.value
		}
		hook.fetch(defaultPageNumber, hook.defaultSize, filterData)
		setFilter(filterData)
	}

	const handleSortBySelect = (val) => {
		const filterData = {}

		if (!val) {
			setSortBy(null)
		} else {
			setSortBy(val)
			filterData["sortBy"] = val.value
		}

		if (selectedPackage) {
			filterData["package"] = selectedPackage.value
		}
		if (selectedCountry) {
			filterData["country"] = selectedCountry.value
		}
		hook.fetch(defaultPageNumber, hook.defaultSize, filterData)
		setFilter(filterData)
	}

	useEffect(() => {
		if (hook.message) {
			generateToast(hook.message)
		}
	}, [hook.message])

	const submitPrices = (file) => {
		hook.importFile(file, drugExceptionValue).then((res) => {
			if (res !== undefined) {
				setFiles([])
			}
		})
	}

	const selectData = {
		packages: packages,
		countries: countries,
		currencies: currencies,
		brandTypes: brandTypes,
		organizations: organizations,
	}

	return (
		<>
			<Layout>
				<PageContainer>
					<FiltersDiv>
						<div>
							<Select
								name="package_filter"
								placeholder="Select package filter..."
								className="react-select-container"
								classNamePrefix="react-select"
								styles={customStylesSelect}
								options={packages}
								onChange={(val) => handlePackageSelect(val)}
								isClearable={true}
								value={selectedPackage}
							/>
						</div>
						<div>
							<Select
								name="country_fitler"
								placeholder="Select country filter..."
								className="react-select-container"
								classNamePrefix="react-select"
								styles={customStylesSelect}
								options={countries}
								onChange={(val) => handleCountrySelect(val)}
								isClearable={true}
								value={selectedCountry}
							/>
						</div>
						<div>
							<Select
								name="sort_by_filter"
								placeholder="Sort by..."
								className="react-select-container"
								classNamePrefix="react-select"
								styles={customStylesSelect}
								options={sortByOptions}
								onChange={(val) => handleSortBySelect(val)}
								isClearable={true}
								value={sortBy}
							/>
						</div>
						{!parentData && (
							<>
								<div>
									<UploadFile
										onChange={submitPrices}
										format={[".xlsx"]}
										files={files}
										setFiles={setFiles}
										text="Import prices"
									/>
								</div>
								<div className="checkbox-exeption-group">
									<Checkbox
										id="checkbox"
										name="listed_checkbox"
										checked={drugExceptionValue}
										onChange={(e) => setDrugExceptionValue(e.target.checked)}
									/>
									<label>Raise no drug exception</label>
								</div>
							</>
						)}
					</FiltersDiv>
					<PriceTableFrame>
						<PriceTable selectData={selectData} role={role} parentData={parentData} organizationId={organizationId} />
						{hook.loading && <Loading />}
					</PriceTableFrame>
					{checkWriteCondition(role, parentData) && <AddButton className="addBtn" onClick={() => onOpen()} />}

					{hook.pagination && (
						<Pagination
							totalCount={hook.pagination.total_count}
							totalPages={hook.pagination.total_pages}
							currentPage={hook.pagination.current_page}
							pageSize={hook.defaultSize}
							fetchPage={hook.fetch}
							setSize={hook.changeSize}
							pageSizesArray={[50, 100, 500, 1000, 2000]}
							filter={filter}
						/>
					)}
				</PageContainer>
				<CustomModal isOpen={isOpen} onClose={onClose} size="6xl">
					<PriceForm selectData={selectData} onSubmit={submit} cancel={onClose} role={role} />
				</CustomModal>
			</Layout>
		</>
	)
}

export default PricePage

const PageContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: hidden;
	background: var(--white);
`

const FiltersDiv = styled.div`
	width: 96%;
	display: flex;
	flex-direction: row;
	column-gap: 10px;
	align-items: stretch;
	margin-top: 1%;
	.checkbox-exeption-group {
		display: flex;
		grid-gap: 10px;
		align-items: center;
	}
	.react-select-container {
		width: 283px;
		margin: 0 auto;
		text-align: left;

		.react-select__single-value {
			color: var(--white);
		}

		.react-select__control {
			border-radius: 4px;
			background: var(--blue);
		}

		.react-select__value-container {
			max-height: 70px;
			overflow-y: auto;
		}
		.react-select__multi-value__remove {
			color: var(--blue);
		}
		.react-select__input {
			color: var(--white);
		}
		.react-select__placeholder {
			color: var(--white);
			font-weight: 400;
		}
		.react-select__indicator {
			color: var(--white);
		}
	}

	.import-btn {
		height: 40px;
		color: var(--white);
		background: var(--blue);
	}
`
