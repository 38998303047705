import { useDisclosure } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { ReactComponent as AddButton } from "../assets/add-button.svg"
import ApiKeyForm from "../components/forms/ApiKeyForm"
import { makeDropdownOptions } from "../components/forms/calcHelper"
import Loading from "../components/frames/Loading"
import TableFrame from "../components/frames/TableFrame"
import Layout from "../components/layout/Layout"
import CustomModal from "../components/modals/CustomModal"
import generateToast from "../components/notifications/ToastGenerator"
import ApiKeyTable from "../components/tables/ApiKeyTable"
import useApiKey from "../redux/hooks/useApiKey"
import useUsers from "../redux/hooks/useUsers"
import { handleResponse } from "../utils/functions"

const ApiKeyPage = () => {
	const hook = useApiKey()
	const hookUsers = useUsers()

	const { isOpen, onOpen, onClose } = useDisclosure()

	const [users, setUsers] = useState([])
	const [templateUsers, setTemplateUsers] = useState([])
	const [parentOrganizations, setParentOrganizations] = useState([])

	useEffect(() => {
		hook.fetch()
		hookUsers.fetchDropdown()
		hookUsers.fetchDropdownTemplate()
	}, []) // eslint-disable-line

	useEffect(() => {
		setUsers(makeDropdownOptions(hookUsers.dropdownData, "username"))
		setTemplateUsers(makeDropdownOptions(hookUsers.templateDropdownData, "username"))
		setParentOrganizations(makeDropdownOptions(hook.dropdownData))
	}, [hookUsers.templateDropdownData, hookUsers.dropdownData, hook.dropdownData])

	useEffect(() => {
		if (hook.message) {
			generateToast(hook.message)
		}
	}, [hook.message])

	const submit = (data) => {
		if (data.use_template && !data.template) {
			toast.error("Template is required!")
		} else {
			const payload = {
				name: data.name,
			}

			if (data.template) {
				payload["template"] = data.template.value
			} else {
				payload["template"] = ""
			}

			const handleSuccess = () => {
				hook.fetch()
				onClose()
			}

			hook.create(payload).then((res) => handleResponse(res, handleSuccess))
		}
	}

	const selectData = {
		users: users,
		templateUsers: templateUsers,
		parentOrganizations: parentOrganizations,
	}

	return (
		<Layout>
			<TableFrame>
				<ApiKeyTable selectData={selectData} />
				{hook.loading && <Loading />}
			</TableFrame>
			<AddButton className="addBtn" onClick={() => onOpen()} />
			<CustomModal isOpen={isOpen} onClose={onClose} size="full">
				<ApiKeyForm selectData={selectData} onSubmit={submit} cancel={onClose} />
			</CustomModal>
		</Layout>
	)
}

export default ApiKeyPage
