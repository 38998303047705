import { Button, Flex, FormLabel } from "@chakra-ui/react"
import { NavLink, useHistory } from "react-router-dom"
import { ReactComponent as ConfigIcon } from "../../assets/config-icon.svg"
import { ReactComponent as IndicationIcon } from "../../assets/indication-icon.svg"
import { ReactComponent as PillIcon } from "../../assets/pill-icon.svg"
import { ReactComponent as ProfileIcon } from "../../assets/profile-icon.svg"
import { ReactComponent as StarIcon } from "../../assets/star-icon.svg"
import { ReactComponent as TreatmentsIcon } from "../../assets/treatments-icon.svg"
import { ReactComponent as UserIcon } from "../../assets/user-icon.svg"
import useUser from "../../redux/hooks/useUser"
import { accountTabAccess, rolesOptions, superAdminAccess } from "../../utils/constants"

const AdminSidebar = () => {
	const hook = useUser()
	const history = useHistory()

	// useEffect(() => {
	// 	if (!hook.token) {
	// 		history.push("/login")
	// 	}
	// }, [hook.token])

	const handleLogout = () => {
		hook.signout().then((res) => {
			history.push("/login")
		})
	}

	return (
		<div className="sidebar-container">
			<div className="links-container">
				<div className="linkSections">
					<div className="title-box">
						<StarIcon />
						<h2>Master data</h2>
					</div>
					<NavLink to="/master-data/generic-names">
						<span className="active"></span> Generic name
					</NavLink>
					<NavLink to="/master-data/treatment-line">
						<span className="active"></span> Treatment line
					</NavLink>
					{superAdminAccess.includes(hook.role) && (
						<>
							<NavLink to="/master-data/route-of-administration">
								<span className="active"></span>
								Route of administration
							</NavLink>
							<NavLink to="/master-data/country">
								<span className="active"></span> Country
							</NavLink>
							<NavLink to="/master-data/dosage-pattern">
								<span className="active"></span> Dosage pattern
							</NavLink>
							<NavLink to="/master-data/currency">
								<span className="active"></span> Currency
							</NavLink>
							<NavLink to="/master-data/company">
								<span className="active"></span> Company
							</NavLink>
						</>
					)}
				</div>

				<div className="linkSections">
					<div className="title-box">
						<IndicationIcon />
						<h2>
							<span className="active"></span> Indication
						</h2>
					</div>
					<NavLink to="/master-data/indication">
						<span className="active"></span> Indication
					</NavLink>
					<NavLink to="/indication/drug">
						<span className="active"></span> Drug indication
					</NavLink>
					<NavLink to="/indication/approval">
						<span className="active"></span> Indication approval
					</NavLink>
				</div>

				<div className="linkSections">
					<div className="title-box">
						<PillIcon />
						<h2>
							<span className="active"></span> Drug
						</h2>
					</div>
					<NavLink to="/master-data/product-names">
						<span className="active"></span> Product name
					</NavLink>
					<NavLink to="/drug/package">
						<span className="active"></span> Package
					</NavLink>
					<NavLink to="/drug/price">
						<span className="active"></span> Price
					</NavLink>
					<NavLink to="/drug/reference">
						<span className="active"></span> References
					</NavLink>
				</div>

				<div className="linkSections">
					<div className="title-box">
						<TreatmentsIcon />
						<h2>
							<span className="active"></span> Data
						</h2>
					</div>
					<NavLink to="/posologies">
						<span className="active"></span> Posology
					</NavLink>
				</div>

				{accountTabAccess.includes(hook.role) && (
					<div className="linkSections">
						<div className="title-box">
							<UserIcon />
							<h2>
								<span className="active"></span> Accounts
							</h2>
						</div>
						<NavLink to="/users">
							<span className="active"></span> Users
						</NavLink>
						{superAdminAccess.includes(hook.role) && (
							<NavLink to="/organizations">
								<span className="active"></span> Organizations
							</NavLink>
						)}
						{superAdminAccess.includes(hook.role) && (
							<NavLink to="/api-keys">
								<span className="active"></span> Api keys
							</NavLink>
						)}
					</div>
				)}
				{superAdminAccess.includes(hook.role) && (
					<div className="linkSections">
						<div className="title-box">
							<ConfigIcon />
							<h2>
								<span className="active"></span> Configuration
							</h2>
						</div>

						<NavLink to="/config/bsa-weight">
							<span className="active"></span> BSA & Weight
						</NavLink>

						<NavLink to="/config/currency-rates/dynamic">
							<span className="active"></span>
							Currency rates - dynamic
						</NavLink>
						<NavLink to="/config/currency-rates/static">
							<span className="active"></span>
							Currency rates - static
						</NavLink>
					</div>
				)}
			</div>
			<Flex w={"100%"} direction="column" rowGap={"20px"} marginTop={2}>
				<Flex direction="column">
					{hook.organizationName && superAdminAccess.includes(hook.role) && (
						<FormLabel color="white">Organization: {hook.organizationName}</FormLabel>
					)}
					{hook.role && <FormLabel color="white">Role: {rolesOptions.find((opt) => opt.value === hook.role).label}</FormLabel>}
				</Flex>
				<Flex w={"100%"} gap={[1, 1, 1, 2]}>
					<Button variant={"white"} onClick={() => history.push("/profile")} leftIcon={<ProfileIcon />} w="100px" minW="90px">
						Profile
					</Button>
					<Button variant={"red"} onClick={() => handleLogout()} w="100px" minW="90px">
						Logout
					</Button>
				</Flex>
			</Flex>
		</div>
	)
}

export default AdminSidebar
