import { useDisclosure } from "@chakra-ui/hooks"
import React, { useEffect, useState } from "react"
import { ReactComponent as AddButton } from "../assets/add-button.svg"
import { makeDropdownOptions } from "../components/forms/calcHelper"
import MasterDataForm from "../components/forms/MasterDataForm"
import Loading from "../components/frames/Loading"
import TableFrame from "../components/frames/TableFrame"
import Layout from "../components/layout/Layout"
import CustomModal from "../components/modals/CustomModal"
import generateToast from "../components/notifications/ToastGenerator"
import MasterDataTable from "../components/tables/MasterdataTable"
import useMasterData from "../redux/hooks/useMasterData"
import useOrganization from "../redux/hooks/useOrganization"
import useUser from "../redux/hooks/useUser"
import { masterDataWithOrg, superAdminAccess } from "../utils/constants"
import { checkWriteCondition, handleOrganizationPayload, handleResponse } from "../utils/functions"

const MasterDataPage = ({ type }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()

	const hook = useMasterData()
	const hookOrg = useOrganization()
	const role = useUser().role
	const parentData = useUser().useParentData
	const organizationId = useUser().organizationId
	const [organizations, setOrganization] = useState([])

	useEffect(() => {
		hook.fetch(type)
		if (masterDataWithOrg.includes(type) && superAdminAccess.includes(role)) {
			hookOrg.fetchDropdown()
		}
	}, []) // eslint-disable-line

	useEffect(() => {
		if (hook.message) {
			generateToast(hook.message)
		}
	}, [hook.message])

	useEffect(() => {
		setOrganization(makeDropdownOptions(hookOrg.dropdownData, "name"))
	}, [hookOrg.dropdownData])

	const submit = (data) => {
		const handleSuccess = () => {
			hook.fetch(type)
			onClose()
		}

		let payload = {
			name: data.name,
			code: data.code,
		}

		if (masterDataWithOrg.includes(type)) {
			handleOrganizationPayload(payload, role, organizationId, data.organization)
		}

		hook.create(type, payload).then((res) => handleResponse(res, handleSuccess))
	}

	const selectData = {
		organizations: organizations,
	}

	return (
		<Layout>
			<TableFrame>
				<MasterDataTable type={type} selectData={selectData} role={role} parentData={parentData} organizationId={organizationId} />
				{hook.loading && <Loading />}
			</TableFrame>
			{checkWriteCondition(role, parentData) && <AddButton className="addBtn" onClick={() => onOpen()} />}
			<CustomModal isOpen={isOpen} onClose={onClose} size="md">
				<MasterDataForm onSubmit={submit} cancel={onClose} selectData={selectData} type={type} role={role} />
			</CustomModal>
		</Layout>
	)
}

export default MasterDataPage
