import { useDispatch, useSelector } from "react-redux"
import { createApiKey, fetchApiKeys, removeApiKey, updateApiKey } from "../reducers/apiKey"
import { cleanMessage } from "../reducers/package"

export default function useApiKey() {
	const dispatch = useDispatch()
	const data = useSelector((state) => state.apiKeys.data)
	const dropdownData = useSelector((state) => state.apiKeys.dropdownData)
	const message = useSelector((state) => state.apiKeys.message)
	const loading = useSelector((state) => state.apiKeys.loading)

	const create = async (data) => {
		return dispatch(createApiKey(data))
	}
	const remove = async (id) => {
		return dispatch(removeApiKey(id))
	}

	const update = async (data) => {
		return dispatch(updateApiKey(data))
	}

	const fetch = () => {
		dispatch(fetchApiKeys())
	}

	const clean = async () => {
		dispatch(cleanMessage())
	}

	return {
		fetch,
		data,
		dropdownData,
		message,
		loading,
		create,
		remove,
		update,
		clean,
	}
}
