export const makeDropdownOptions = (array, type) => {
	const dropdownOptions = []
	if (array !== undefined && array.length > 0 && type) {
		array.map((element) => dropdownOptions.push({ value: element.id, label: element[type] }))
	} else if (array !== undefined && array.length > 0) {
		array.map((element) => dropdownOptions.push({ value: element.id, label: element.name }))
	}

	return dropdownOptions
}

export const makeIndicationDropdownOptions = (array) => {
	const options = []
	array.map((element, i) =>
		options.push({
			id: i,
			value: element.treatment_schema_ids,
			label: element.indication,
			treatment_drugs: element.treatment_drugs,
		})
	)
	return options
}

export const makeIdsArray = (array, type) => {
	const idsArray = []
	if (array && array.length > 0 && type === "id") {
		array.forEach((item) => item && idsArray.push(item.id))
	} else if (array && array.length > 0) {
		array.forEach((item) => item && idsArray.push(item.value))
	}
	return idsArray
}
