import { Button, Flex, useDisclosure } from "@chakra-ui/react"
import React, { useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import useTreatment from "../../redux/hooks/useTreatment"
import { superAdminAccess } from "../../utils/constants"
import { checkWriteCondition, handleOrganizationPayload, routesText, subPosologiesText } from "../../utils/functions"
import { makeDropdownOptions, makeIdsArray } from "../forms/calcHelper"
import TreatmentForm from "../forms/TreatmentForm"
import CustomModal from "../modals/CustomModal"
import { TablesStyles } from "./TableStyles"

const TreatmentTable = ({ data, selectData, doNotExpandRows, role, parentData, organizationId }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [updateData, setUpdateData] = useState(null)

	const hook = useTreatment()

	const indexedData = data.map((d, i) => {
		return {
			num: i + 1,
			id: d.id,
			drug_name: d.drug_name,
			generic_name: d.generic_name,
			generic: d.generic,
			indication: d.indication,
			indication_drugs: d.indication_drugs,
			indication_countries: d.indication_countries,
			weeks_per_cycle: d.weeks_per_cycle,
			doses_count: d.doses_count,
			dosage: d.dosage,
			dosage_per_kg: d.dosage_per_kg,
			dosage_per_bsa: d.dosage_per_bsa,
			sub_posologies: d.sub_posologies,
			helper_code: d.helper_code,
			allowed_routes: d.allowed_routes,
			organization: d.organization,
			organization_name: d.organization_name,
		}
	})

	const columns = [
		{
			dataField: "num",
			text: "No.",
			headerStyle: () => {
				return { width: "2%" }
			},
			formatter: (cell) => {
				return `${cell}.`
			},
		},
		{
			dataField: "drug_name",
			text: "Product name",
			headerStyle: () => {
				return { width: "200px", padding: "4px" }
			},
		},
		{
			dataField: "generic_name",
			text: "Generic name",
			headerStyle: () => {
				return { width: "200px", padding: "4px" }
			},
		},
		{
			dataField: "indication",
			text: "Indication",
			headerStyle: () => {
				return { width: "800px", padding: "4px" }
			},
		},
		{
			dataField: "indication_drugs",
			text: "Indication drugs",
			headerStyle: () => {
				return { width: "180px" }
			},
		},
		{
			dataField: "indication_countries",
			text: "Approval countries",
			headerStyle: () => {
				return { width: "280px" }
			},
		},
		{
			dataField: "weeks_per_cycle",
			text: "Weeks per cycle",
			headerStyle: () => {
				return { width: "4%" }
			},
		},
		{
			dataField: "doses_count",
			text: "Doses count",
			headerStyle: () => {
				return { width: "4%" }
			},
		},
		{
			dataField: "dosage",
			text: "Dosage",
			headerStyle: () => {
				return { width: "4%" }
			},
		},
		{
			dataField: "dosage_per_kg",
			text: "Dosage per kg",
			headerStyle: () => {
				return { width: "4%" }
			},
		},
		{
			dataField: "dosage_per_bsa",
			text: "Dosage per bsa",
			headerStyle: () => {
				return { width: "4%" }
			},
		},
		{
			dataField: "sub_posologies",
			text: "Sub posologies",
			headerStyle: () => {
				return { width: "200px" }
			},
			formatter: (cell) => {
				return subPosologiesText(cell)
			},
		},
		{
			dataField: "helper_code",
			text: "Helper code",
			headerStyle: () => {
				return { width: "7%" }
			},
		},
		{
			dataField: "allowed_routes",
			text: "Allowed routes",
			headerStyle: () => {
				return { width: "200px" }
			},
			formatter: (cell) => {
				return routesText(cell)
			},
		},
	]

	if (superAdminAccess.includes(role)) {
		columns.splice(1, 0, {
			dataField: "organization_name",
			text: "Organization",
			headerStyle: () => {
				return { width: "200px", padding: "4px" }
			},
		})
	}

	const expandRow = {
		renderer: (row) =>
			doNotExpandRows ? (
				""
			) : (
				<Flex gridGap="10px">
					<Button variant="orange" onClick={() => handleOpenUpdate(row)}>
						Edit
					</Button>
					<Button variant="red" onClick={() => hook.remove(row.id)}>
						Delete
					</Button>
				</Flex>
			),
		className: "expandedRow",
		parentClassName: "parentExpandedRow",
	}

	const handleOpenUpdate = (row) => {
		hook.fetchTables(row.id).then((res) => {
			const row = res.data.treatment
			let defaultValues = {
				id: row.id,
				defaultGenericName: selectData.genericNames.find((option) => option.value === row.generic),
				defaultIndication: selectData.drugIndications.find((option) => option.value === row.indication),
				defaultProductName: selectData.productNames.find((option) => option.value === row.drug),
				defaultRoutes: makeDropdownOptions(row.allowed_routes),
				defaultSubPosologies: makeDropdownOptions(row.sub_posologies, "helper_code"),
				dosage: row.dosage,
				dosage_per_kg: row.dosage_per_kg,
				dosage_per_bsa: row.dosage_per_bsa,
				weeks_per_cycle: row.weeks_per_cycle,
				helper_code: row.helper_code,
			}

			if (superAdminAccess.includes(role)) {
				defaultValues["organization"] = selectData.organizations.find((ele) => ele.value === Number(row.organization))
			}

			setUpdateData(defaultValues)
			onOpen()
		})
	}

	const submit = (data) => {
		let payload = {
			id: updateData.id,
			weeks_per_cycle: data.weeks_per_cycle,
			drug: data.product_name.value,
			indication: data.indication.value,
		}

		if (data.dosage === undefined) {
			payload["dosage"] = ""
		} else {
			payload["dosage"] = parseFloat(data.dosage)
		}

		if (data.dosage_per_kg === undefined) {
			payload["dosage_per_kg"] = ""
		} else {
			payload["dosage_per_kg"] = parseFloat(data.dosage_per_kg)
		}

		if (data.dosage_per_bsa === undefined) {
			payload["dosage_per_bsa"] = ""
		} else {
			payload["dosage_per_bsa"] = parseFloat(data.dosage_per_bsa)
		}

		if (data.generic_name) {
			payload["generic"] = data.generic_name.value
		} else {
			payload["generic"] = ""
		}

		if (data.sub_posologies) {
			payload["sub_posologies"] = makeIdsArray(data.sub_posologies)
		} else {
			payload["sub_posologies"] = []
		}

		if (data.allowed_routes) {
			payload["allowed_routes"] = makeIdsArray(data.allowed_routes)
		} else {
			payload["allowed_routes"] = []
		}

		if (data.helper_code) {
			payload["helper_code"] = data.helper_code
		} else {
			payload["helper_code"] = ""
		}

		handleOrganizationPayload(payload, role, organizationId, data.organization)

		hook.updateScheme(payload).then((res) => {
			if (res !== undefined && res.status === 200) {
				onClose()
				hook.fetch(hook.pagination.current_page, hook.defaultSize)
				hook.fetchSubPosologiesData()
			}
		})
	}

	return (
		<>
			<TablesStyles minWidth="3000px">
				<BootstrapTable
					data={indexedData}
					columns={columns}
					keyField="id"
					classes="TablesStyles"
					expandRow={checkWriteCondition(role, parentData) && expandRow}
					bordered
					bootstrap4
				/>
			</TablesStyles>
			<CustomModal isOpen={isOpen} onClose={onClose} size="full">
				<TreatmentForm defaultValues={updateData} selectData={selectData} submit={submit} cancel={onClose} role={role} />
			</CustomModal>
		</>
	)
}

export default TreatmentTable
