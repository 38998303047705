import { createAction, createReducer } from "@reduxjs/toolkit"
import axios from "axios"
import { serverErrorMessage } from "../../utils/constants"
import {
	indicationApprovalURL,
	indicationApprovalRemoveURL,
	indicationApprovalPagesURL,
	indicationApprovalFilterByDrugIndicationURL,
} from "../api_urls"

const START_REQUEST = createAction("INDICATION/APPROVAL/START_REQUEST")
const FETCH_SUCCESS = createAction("INDICATION/APPROVAL/FETCH_SUCCESS")
const FETCH_FILTERED_SUCCESS = createAction("INDICATION/APPROVAL/FETCH_FILTERED_SUCCESS")
const REQUEST_SUCCESS = createAction("INDICATION/APPROVAL/REQUEST_SUCCESS")
const REQUEST_FAIL = createAction("INDICATION/APPROVAL/REQUEST_FAIL")
const CLEAN_MESSAGE = createAction("INDICATION/APPROVAL/CLEAN_MESSAGE")
const CLEAN_DATA_BY_DI = createAction("INDICATION/APPROVAL/CLEAN_DATA_BY_DI")
const CHANGE_SIZE = createAction("INDICATION/APPROVAL/CHANGE_SIZE")

export const fetchPage = (pageNumber, pageSize, filterData) => async (dispatch) => {
	let sort = "id"
	if (filterData !== undefined && filterData && filterData.sortBy) {
		sort = filterData.sortBy
	}
	let filterUrl = indicationApprovalPagesURL(pageNumber, pageSize, sort)
	if (filterData) {
		if (filterData.drugIndication) {
			filterUrl += "&drug_indication=" + filterData.drugIndication
		}
		if (filterData.country) {
			filterUrl += "&country=" + filterData.country
		}
	}
	dispatch(START_REQUEST())
	return axios.get(filterUrl).then((res) => handleResponse(res, dispatch, FETCH_SUCCESS, REQUEST_FAIL))
}

export const fetchFilteredPage = (pageNumber, pageSize, drugIndication) => async (dispatch) => {
	dispatch({ type: "INDICATION/APPROVAL/START_REQUEST" })

	dispatch(START_REQUEST())
	return axios
		.get(indicationApprovalFilterByDrugIndicationURL(pageNumber, pageSize, drugIndication))
		.then((res) => handleResponse(res, dispatch, FETCH_FILTERED_SUCCESS, REQUEST_FAIL))
}

export const setDefaultSize = (size) => async (dispatch) => {
	dispatch(CHANGE_SIZE(size))
}

export const createIndicationApproval = (data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(indicationApprovalURL, data).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const removeIndicationApproval = (id) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.delete(indicationApprovalRemoveURL(id)).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const updateIndicationApproval = (data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.put(indicationApprovalURL, data).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const cleanDataByDrugIndication = () => async (dispatch) => {
	dispatch(CLEAN_DATA_BY_DI())
}

const handleResponse = (res, dispatch, success, fail) => {
	if (res !== undefined) {
		if (res.status >= 200 && res.status <= 299) {
			if (success === REQUEST_SUCCESS) {
				dispatch(success(res.data.message))
				dispatch(CLEAN_MESSAGE())
			} else {
				dispatch(success(res.data))
			}
			return res
		} else if (res.response !== undefined && res.response.status === 400) {
			dispatch(fail(res.response.data.message))
			dispatch(CLEAN_MESSAGE())
		} else {
			dispatch(fail(serverErrorMessage))
			dispatch(CLEAN_MESSAGE())
		}
	} else {
		dispatch(fail(serverErrorMessage))
		dispatch(CLEAN_MESSAGE())
	}
}

const initState = {
	data: [],
	dataByDrugIndication: [],
	loading: false,
	pagination: null,
	defaultSize: 100,
	message: null,
}

export const indicationApprovalReducer = createReducer(initState, (builder) => {
	builder
		.addCase(START_REQUEST, (state, action) => {
			state.loading = true
			state.message = null
		})
		.addCase(FETCH_SUCCESS, (state, action) => {
			state.loading = false
			state.data = action.payload.approvals
			state.pagination = action.payload.pagination
		})
		.addCase(FETCH_FILTERED_SUCCESS, (state, action) => {
			state.loading = false
			state.dataByDrugIndication = action.payload.approvals
			state.pagination = action.payload.pagination
		})
		.addCase(REQUEST_SUCCESS, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(REQUEST_FAIL, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(CLEAN_MESSAGE, (state, action) => {
			state.message = null
		})
		.addCase(CLEAN_DATA_BY_DI, (state, action) => {
			state.dataByDrugIndication = []
		})
		.addCase(CHANGE_SIZE, (state, action) => {
			state.defaultSize = action.payload
		})
})
