import { Controller, useForm } from "react-hook-form"
import Select from "react-select"
import styled from "styled-components"
import { superAdminAccess } from "../../utils/constants"
import SaveBtn from "../buttons/SaveBtn"
import { customStylesSelect } from "../CustomSelectStyles"

const CurrencyRateForm = ({ defaultValues, onSubmit, cancel, selectData, role }) => {
	const { register, handleSubmit, control } = useForm()

	return (
		<FormStyles>
			<form onSubmit={handleSubmit(onSubmit)}>
				{superAdminAccess.includes(role) && (
					<>
						<label>Organization</label>
						<Controller
							control={control}
							options={selectData.organizations}
							as={Select}
							defaultValue={defaultValues && defaultValues.defaultOrganization}
							name="organization"
							placeholder="Select organization..."
							className="react-select-container"
							classNamePrefix="react-select"
							styles={customStylesSelect}
							ref={register({ required: true })}
							rules={{ required: true, message: "This field is required" }}
						/>
					</>
				)}
				<label>Currency</label>
				<Controller
					control={control}
					options={selectData.currencies}
					as={Select}
					defaultValue={defaultValues && defaultValues.defaultCurrency}
					name="currency_code"
					placeholder="Select currency..."
					className="react-select-container"
					classNamePrefix="react-select"
					styles={customStylesSelect}
					ref={register({ required: true })}
					rules={{ required: true, message: "This field is required" }}
				/>
				<label>Rate</label>
				<input
					type="number"
					name="rate"
					ref={register({ required: true })}
					defaultValue={defaultValues && defaultValues.rate}
					step="0.0000001"
				/>
				<div className="btns-wrapper">
					<SaveBtn />
					<button className="cancelBtn" type="button" onClick={() => cancel()}>
						Close
					</button>
				</div>
			</form>
		</FormStyles>
	)
}
const FormStyles = styled.div`
	text-align: center;
	width: 100%;
	min-height: 305px;

	input {
		width: 383px;
		height: 40px;
		padding-left: 10px;
		margin-bottom: 0;
	}
	label {
		display: block;
		padding: 20px 0 10px 0;
		color: var(--white);
	}

	textarea {
		width: 383px;
		height: 170px;
		overflow-y: scroll;
		border-radius: 4px;
		resize: none;
		font-size: 16px;
		color: var(--blue);
		font-weight: 700;
		padding: 15px;
		display: block;
		margin: 0 auto;
	}
	.cancelBtn {
		&:hover {
			color: var(--red);
			transition: 0.3 ease;
		}
	}
	.btns-wrapper {
		display: flex;
		justify-content: center;
		width: 100%;
		column-gap: 20px;
		margin-top: 60px;
		button {
			padding: 4px 10px;
			min-width: 130px;
		}
	}
	.react-select-container {
		width: 383px;
		height: 40px;
		margin: 0 auto;
		text-align: left;

		.react-select__control {
			border-radius: 4px;
			background: var(--white);
		}

		.react-select__value-container {
			max-height: 70px;
			overflow-y: auto;
		}
		.react-select__menu-list {
			max-height: 200px;
		}
		.react-select__multi-value__remove {
			color: var(--blue);
		}
		.react-select__input {
			height: 40px;
		}
		.react-select__placeholder {
			color: var(--black);
			font-weight: 400;
		}
		.react-select__single-value {
			color: var(--black);
		}
	}
`
export default CurrencyRateForm
