import { Button, Flex, Heading, IconButton, Stack, useDisclosure } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import Select from "react-select"
import Stepper from "react-stepper-horizontal"
import { toast } from "react-toastify"
import styled from "styled-components"
import { AddIcon } from "../assets/AddIcon"
import { customStylesSelect } from "../components/CustomSelectStyles"
import { makeDropdownOptions, makeIdsArray } from "../components/forms/calcHelper"
import GenericNameForm from "../components/forms/GenericNameForm"
import DrugIndicationForm from "../components/forms/indications/DrugIndicationForm"
import MasterDataForm from "../components/forms/MasterDataForm"
import ProductNameForm from "../components/forms/ProductNameForm"
// import ProductNameForm from "../components/forms/ProductNameForm"
import Layout from "../components/layout/Layout"
import CustomModal from "../components/modals/CustomModal"
import generateToast from "../components/notifications/ToastGenerator"
import { dosagePattern, genericName, indicationMd, productName, route, treatmentLineMd } from "../redux/api_urls"
import useDrugIndication from "../redux/hooks/useDrugIndication"
import useMasterData from "../redux/hooks/useMasterData"
import useOrganization from "../redux/hooks/useOrganization"
import useTreatment from "../redux/hooks/useTreatment"
import useUser from "../redux/hooks/useUser"
import { masterDataWithOrg, superAdminAccess } from "../utils/constants"
import {
	drugIndicationSubmit,
	genericNameSubmit,
	getSelectData,
	makeSubPosologiesDropdownOptions,
	masterDataSubmit,
	productNameSubmit,
} from "../utils/functions"

const CreateTreatmentPage = () => {
	const { register, handleSubmit, errors, reset, control } = useForm() // eslint-disable-line
	const hook = useTreatment()
	const hookIndicationDrug = useDrugIndication()
	const hookMasterData = useMasterData()
	const hookUser = useUser()
	const hookOrg = useOrganization()
	const { isOpen: isOpenProductName, onOpen: onOpenProductName, onClose: onCloseProductName } = useDisclosure()
	const { isOpen: isOpenGenericName, onOpen: onOpenGenericName, onClose: onCloseGenericName } = useDisclosure()
	const { isOpen: isOpenIndications, onOpen: onOpenIndications, onClose: onCloseIndications } = useDisclosure()
	const { isOpen: isOpenRoutes, onOpen: onOpenRoutes, onClose: onCloseRoutes } = useDisclosure()

	const [subPosologies, setSubPosologies] = useState([])
	const [productNames, setProductNames] = useState([])
	const [indications, setIndications] = useState([])
	const [genericNames, setGenericNames] = useState([])
	const [organizations, setOrganizations] = useState([])
	const [treatmentLines, setTreatmentLines] = useState([])
	const [indicationsMd, setIndicationsMd] = useState([])

	useEffect(() => {
		handleReset()
		hook.fetchSubPosologiesData()
		hookMasterData.fetch(productName)
		hookMasterData.fetch(dosagePattern)
		hookMasterData.fetch(route, true)
		hookMasterData.fetch(genericName)
		hookIndicationDrug.fetchDropdownData()
	}, []) // eslint-disable-line

	useEffect(() => {
		setSubPosologies(makeSubPosologiesDropdownOptions(hook.subPosologies))
		setProductNames(makeDropdownOptions(hookMasterData.productNames))
		setIndications(makeDropdownOptions(hookIndicationDrug.dropdownData, "indication_value"))
		setGenericNames(makeDropdownOptions(hookMasterData.genericNames))
		setOrganizations(makeDropdownOptions(hookOrg.dropdownData))
		setTreatmentLines(makeDropdownOptions(hookMasterData.treatmentLines))
		setIndicationsMd(makeDropdownOptions(hookMasterData.indications))
	}, [
		hook.subPosologies,
		hookMasterData.productNames,
		hookIndicationDrug.dropdownData,
		hookMasterData.genericNames,
		hookOrg.dropdownData,
		hookMasterData.treatmentLines,
		hookMasterData.indications,
	])

	useEffect(() => {
		if (hookIndicationDrug.message) {
			generateToast(hookIndicationDrug.message)
		}
	}, [hookIndicationDrug.message])

	useEffect(() => {
		if (hookMasterData.message) {
			generateToast(hookMasterData.message)
		}
	}, [hookMasterData.message])

	useEffect(() => {
		if (hook.message) {
			generateToast(hook.message)
			if (hook.message.type === "success") {
				handleReset()
			}
		}
	}, [hook.message]) // eslint-disable-line

	const cycleDosingPattern = []
	hookMasterData.dosagePatterns.map((element) => cycleDosingPattern.push({ value: element.id, label: element.name }))

	const routes = []
	hookMasterData.routes.forEach((ele) => routes.push({ value: ele.id, label: ele.name }))

	const [steps, setSteps] = useState([{}])
	const [rerenderTrick, setRerenderTrick] = useState(0) // eslint-disable-line

	const handleWeeksPerCycleSubmit = (e) => {
		e.preventDefault()
		const weeksPerCycle = hook.weeksPerCycle
		if (weeksPerCycle === "") {
			toast.warning("Please enter weeks per cycle.")
		} else if (weeksPerCycle > 52) {
			toast.warning("Maximum number exceeded!")
		} else {
			const forms = [...hook.forms]
			forms.forEach((element, formIndex) => {
				const currentWeekDoses = element.weekDoses
				const lastForm = currentWeekDoses[currentWeekDoses.length - 1]
				if (currentWeekDoses.length === 0) {
					const weekDoses = []
					for (let i = 0; i < weeksPerCycle; i++) {
						weekDoses.push({
							id: i,
							value: 0,
						})
					}
					hook.setWeekDoses(formIndex, weekDoses)
				} else if (currentWeekDoses.length > weeksPerCycle) {
					const numberToCut = currentWeekDoses.length - weeksPerCycle
					currentWeekDoses.splice(currentWeekDoses.length - numberToCut)
					hook.setWeekDoses(formIndex, currentWeekDoses)
				} else if (currentWeekDoses.length < weeksPerCycle) {
					const numberToAdd = weeksPerCycle - currentWeekDoses.length
					if (numberToAdd === 1) {
						currentWeekDoses.push({
							id: parseInt(currentWeekDoses.length),
							value: 0,
						})
						hook.setWeekDoses(formIndex, currentWeekDoses)
					} else {
						for (let index = 0; index < numberToAdd; index++) {
							currentWeekDoses.push({
								id: lastForm.id + 1 + index,
								value: 0,
							})
						}
						hook.setWeekDoses(formIndex, currentWeekDoses)
					}
				}
			})
		}
		setRerenderTrick(hook.weeksPerCycle)
	}

	const fillAllWeeks = (e) => {
		e.preventDefault()
		const weekDoses = []
		for (let i = 0; i < hook.weeksPerCycle; i++) {
			weekDoses.push({
				id: i,
				value: e.target.value,
			})
		}
		hook.setWeekDoses(hook.currentId, weekDoses)
	}

	const decrement = () => {
		hook.setId(hook.currentId - 1)
	}

	const handleNext = () => {
		const forms = [...hook.forms]
		const currentId = hook.currentId
		const currentForm = forms[currentId]

		if (currentForm && currentForm.dosagePattern === "") {
			toast.warning("Dosage pattern is missing!")
		} else {
			if (currentId === forms.length - 1) {
				addNextForm(forms)
				hook.setId(currentId + 1)
			} else {
				hook.setId(currentId + 1)
			}
		}
	}

	const addNextForm = (forms) => {
		const newStep = {}
		setSteps(steps.concat(newStep))
		const storeForms = [...hook.forms]
		const lastFormId = storeForms.pop().id
		const weekDoses = []
		for (let i = 0; i < hook.weeksPerCycle; i++) {
			weekDoses.push({
				id: i,
				value: 0,
			})
		}

		const lastForm = forms.slice(-1).pop()

		forms.push({
			id: lastFormId + 1,
			weekDoses: weekDoses,
			isInfinite: lastForm.isInfinite,
			dosagePattern: "",
		})

		hook.addForm(forms)
	}

	const handleDosagePattern = (val, formIndex) => {
		hook.updateDosagePattern(formIndex, val.value)
	}

	const onSubmit = (data) => {
		const forms = [...hook.forms]
		const currentForm = forms[hook.currentId]

		function exists(value) {
			return currentForm.weekDoses.some(function (el) {
				return el.value === value
			})
		}

		if (currentForm.dosagePattern === "" || exists("")) {
			toast.warning("All fields are required!")
		} else {
			const treatment_cycles = []

			forms.forEach((element, i) => {
				if (element.dosagePattern !== "") {
					const doses_schema = {}
					element.weekDoses.forEach((element, i) => {
						doses_schema[`week${element.id + 1}`] = element.value
					})

					treatment_cycles.push({
						cycle_serial_num: element.id + 1,
						doses_schema: doses_schema,
						is_infinite: element.isInfinite,
						dosage_pattern: element.dosagePattern,
					})
				}
			})

			const payload = {
				treatment_schema: {
					dosage: data.dosage,
					dosage_per_kg: data.dosage_per_kg,
					dosage_per_bsa: data.dosage_per_bsa,
					drug: data.product_name.value,
					generic: data.generic_name.value,
					indication: data.indication.value,
					weeks_per_cycle: hook.weeksPerCycle,
				},
				treatment_cycles: treatment_cycles,
			}

			if (data.sub_posologies) {
				payload.treatment_schema.sub_posologies = makeIdsArray(data.sub_posologies)
			} else {
				payload.treatment_schema.sub_posologies = []
			}

			if (data.allowed_routes) {
				payload.treatment_schema.allowed_routes = makeIdsArray(data.allowed_routes)
			} else {
				payload.treatment_schema.allowed_routes = []
			}

			if (data.helper_code) {
				payload.treatment_schema.helper_code = data.helper_code
			} else {
				payload.treatment_schema.helper_code = ""
			}

			hook.create(payload)
		}
	}

	const handleReset = () => {
		reset()
		hook.setId(0)
		hook.resetForms()
		setSteps([{}])
	}

	const checkRadioDisplay = () => {
		const currentId = hook.currentId
		const forms = [...hook.forms]
		const previousForm = forms[currentId - 1]

		if (currentId === 0) {
			return "block"
		} else if (previousForm.isInfinite) {
			return "none"
		} else {
			return "block"
		}
	}

	const checkDefaultCheckedFixed = () => {
		const currentId = hook.currentId
		const forms = [...hook.forms]
		const currentForm = forms[currentId]

		if (currentForm.isInfinite) {
			return false
		} else {
			return true
		}
	}

	const checkDefaultCheckedInfinite = () => {
		const currentId = hook.currentId
		const forms = [...hook.forms]
		const currentForm = forms[currentId]

		if (currentForm.isInfinite) {
			return true
		} else {
			return false
		}
	}

	const checkDisabledDelete = () => {
		const currentId = hook.currentId
		if (currentId === 0) {
			return true
		} else {
			return false
		}
	}

	const handleDeleteForm = (e) => {
		e.preventDefault()
		const currentId = hook.currentId
		setSteps(steps.slice(0, -1))
		hook.setId(currentId - 1)
		hook.removeForm(currentId)
	}

	//PRODUCT NAME MODAL

	const handleOpenProductNames = () => {
		if (superAdminAccess.includes(hookUser.role)) {
			hookOrg.fetchDropdown()
		}
		onOpenProductName()
	}

	const handleProductNameSubmit = (data) => {
		productNameSubmit(data, hookMasterData.fetch, hookMasterData.create, onCloseProductName, hookUser.role, hookUser.organizationId)
	}
	///

	//GENERIC MODAL

	const handleOpenGenerics = () => {
		if (superAdminAccess.includes(hookUser.role)) {
			hookOrg.fetchDropdown()
		}
		onOpenGenericName()
	}

	const handleGenericSubmit = (data) => {
		genericNameSubmit(data, hookMasterData.fetch, hookMasterData.create, onCloseGenericName, hookUser.role, hookUser.organizationId)
	}

	///

	//INDICATION MODAL

	const handleOpenIndications = () => {
		if (superAdminAccess.includes(hookUser.role)) {
			hookOrg.fetchDropdown()
		}
		hookMasterData.fetch(treatmentLineMd)
		hookMasterData.fetch(indicationMd)
		onOpenIndications()
	}

	const handleDrugIndicationSubmit = (data) => {
		drugIndicationSubmit(
			data,
			hookIndicationDrug.fetchDropdownData,
			onCloseIndications,
			hookIndicationDrug.create,
			hookUser.role,
			hookUser.organizationId
		)
	}
	///

	//ROUTE MODAL

	const handleOpenRoutes = () => {
		if (superAdminAccess.includes(hookUser.role) && masterDataWithOrg.includes(route)) {
			hookOrg.fetchDropdown()
		}
		onOpenRoutes()
	}

	const handleMasterDataSubmit = (data) => {
		masterDataSubmit(data, hookMasterData.fetch, hookMasterData.create, onCloseRoutes, route, hookUser.role, hookUser.organizationId)
	}
	///

	return (
		<Layout>
			<Container>
				<FormTreatment onSubmit={handleSubmit(onSubmit)}>
					<div className="scheme-side">
						<Heading as="h1" fontWeight={500} mt="42px">
							Treatment scheme
						</Heading>
						<div className="scheme-side-fields">
							<div>
								<h4>Weeks per cycle</h4>
								<div className="inline-group-weeks">
									<input
										type="number"
										name="weeks_per_cycle"
										onChange={(e) => hook.setWeeks(e.target.value)}
										placeholder="Insert weeks"
										ref={register({ required: true })}
									/>
									<button type="button" onClick={(e) => handleWeeksPerCycleSubmit(e)}>
										Submit
									</button>
								</div>
							</div>
							<Stack spacing={[2, 2, 4]} my={4} align="center">
								<div className="inline-group-dosage">
									<h4>Dosage</h4>
									<Controller as={<input />} control={control} name={"dosage"} placeholder={"Insert dosage"} />
								</div>
								<div className="inline-group-dosage">
									<h4>Dosage per kilogram</h4>
									<Controller as={<input />} control={control} name={"dosage_per_kg"} placeholder={"Insert dosage"} />
								</div>
								<div className="inline-group-dosage">
									<h4>Dosage per BSA</h4>
									<Controller as={<input />} control={control} name={"dosage_per_bsa"} placeholder={"Insert dosage"} />
								</div>
							</Stack>

							<Stack spacing={2}>
								<Flex w="100%" align="flex-end" gap={2}>
									<div className="scheme-select-group">
										<h4>Product name</h4>
										<Controller
											control={control}
											as={Select}
											name="product_name"
											ref={register({ required: true })}
											defaultValue={null}
											options={productNames}
											placeholder="Select product name..."
											className="react-select-container"
											classNamePrefix="react-select"
											styles={customStylesSelect}
										/>
									</div>
									<IconButton
										aria-label="add"
										icon={<AddIcon color="green" />}
										minW="40px"
										onClick={() => handleOpenProductNames(hookUser.role, hookOrg.fetchDropdown, onOpenProductName)}
									/>
								</Flex>
								<Flex w="100%" align="flex-end" gap={2}>
									<div className="scheme-select-group">
										<h4>Generic name</h4>
										<Controller
											control={control}
											as={Select}
											name="generic_name"
											ref={register({ required: true })}
											defaultValue={null}
											options={genericNames}
											placeholder="Select generic name..."
											className="react-select-container"
											classNamePrefix="react-select"
											styles={customStylesSelect}
										/>
									</div>
									<IconButton
										aria-label="add"
										icon={<AddIcon color="green" />}
										minW="40px"
										onClick={() => handleOpenGenerics()}
									/>
								</Flex>
								<Flex w="100%" align="flex-end" gap={2}>
									<div className="scheme-select-group">
										<h4>Indication</h4>
										<Controller
											control={control}
											as={Select}
											name="indication"
											defaultValue={null}
											options={indications}
											placeholder="Select indication..."
											className="react-select-container"
											classNamePrefix="react-select"
											styles={customStylesSelect}
										/>
									</div>
									<IconButton
										aria-label="add"
										icon={<AddIcon color="green" />}
										minW="40px"
										onClick={() => handleOpenIndications()}
									/>
								</Flex>
								<div className="scheme-select-group">
									<h4>Sub posologies</h4>
									<Controller
										isMulti
										control={control}
										as={Select}
										name="sub_posologies"
										options={subPosologies}
										placeholder="Select sub posologies..."
										className="react-select-container"
										classNamePrefix="react-select"
										styles={customStylesSelect}
										defaultValue={null}
									/>
								</div>
								<div>
									<h4>Helper code</h4>
									<input type="text" name="helper_code" ref={register} />
								</div>
								<Flex w="100%" align="flex-end" gap={2}>
									<div className="scheme-select-group">
										<h4>Routes of administration</h4>
										<Controller
											isMulti
											control={control}
											as={Select}
											name="allowed_routes"
											options={routes}
											placeholder="Select routes of administration..."
											className="react-select-container"
											classNamePrefix="react-select"
											styles={customStylesSelect}
											defaultValue={null}
										/>
									</div>
									<IconButton
										aria-label="add"
										icon={<AddIcon color="green" />}
										minW="40px"
										onClick={() => handleOpenRoutes()}
									/>
								</Flex>
							</Stack>
						</div>
						<div>
							<Button type="button" onClick={handleReset} my="36px">
								Reset
							</Button>
						</div>
					</div>
					<div className="cycles-side">
						<div className="cycles-header">
							<h1>Treatment cycles</h1>
							<div className="stepperContainer">
								<Stepper
									steps={steps}
									activeStep={hook.currentId}
									// circleTop={2}
									completeColor={"var(--blue)"}
									completeBorderStyle={"solid"}
									completeBorderColor={"var(--white)"}
									activeColor={"rgba(var(--white_rgb),0.3)"}
									activeBorderStyle={"solid"}
									activeBorderColor={"var(--white)"}
									defaultColor={"var(--blue)"}
									defaultBorderStyle={"solid"}
									defaultBorderColor={"var(--white)"}
									defaultBorderWidth={1}
								/>
							</div>
						</div>

						<div className="cycles-side-fields">
							{hook.forms.length > 0
								? hook.forms.map((item, formIndex) => (
										<div
											key={item.id}
											style={{
												display: formIndex === hook.currentId ? "block" : "none",
											}}
										>
											<div className="dose-inputs">
												{item.weekDoses.map((element, weekInputIndex) => (
													<div key={element.id}>
														<h4>Week {element.id + 1}</h4>
														<input
															className="dose-input"
															name={`weekInput${element.id}`}
															type="number"
															placeholder="Dose..."
															value={element.value}
															onChange={(e) =>
																hook.updateOneWeekInput(formIndex, weekInputIndex, e.target.value)
															}
														/>
													</div>
												))}
											</div>
											<div className="inline-inputs">
												<div className="fill-weeks">
													<h4>Fill all weeks</h4>
													<input type="number" placeholder="Dose..." onChange={(e) => fillAllWeeks(e)} />
												</div>
												<div className="cycles-select-group">
													<h4>Cycle dosage pattern</h4>
													<Select
														name={`dosagePattern${formIndex}`}
														placeholder="Select cycle dosage pattern"
														options={cycleDosingPattern}
														defaultValue={null}
														className="react-select-container"
														classNamePrefix="react-select"
														styles={customStylesSelect}
														onChange={(val) => handleDosagePattern(val, formIndex)}
													/>
												</div>
											</div>
										</div>
								  ))
								: null}
							{hook.forms.length > 0 && (
								<>
									<h4>Cycle type</h4>
									<div className="radio-buttons-group">
										<label
											className="radio-button"
											style={{
												display: checkRadioDisplay(),
											}}
										>
											Fixed cycle
											<input
												type="radio"
												checked={checkDefaultCheckedFixed()}
												value={false}
												onChange={(e) => hook.updateInfinite(hook.currentId, false)}
											/>
											<span className="checkmark"></span>
										</label>
										<label className="radio-button">
											Infinite cycle
											<input
												type="radio"
												checked={checkDefaultCheckedInfinite()}
												value={true}
												onChange={(e) => hook.updateInfinite(hook.currentId, true)}
											/>
											<span className="checkmark"></span>
										</label>
									</div>
								</>
							)}
						</div>
						<Flex justify="space-between" w="100%" px="20px" mb="36px">
							<Button disabled={checkDisabledDelete()} onClick={(e) => handleDeleteForm(e)} type="button" variant="red">
								Delete
							</Button>
							<Button onClick={decrement} type="button" disabled={hook.currentId === 0 ? true : false} w="90px" color="black">
								Back
							</Button>
							<Button type="button" onClick={handleNext} w="90px" color="black">
								Next
							</Button>
							<Button variant="green" type="submit">
								Save
							</Button>
						</Flex>
					</div>
				</FormTreatment>
			</Container>
			<CustomModal isOpen={isOpenIndications} onClose={onCloseIndications} size="5xl">
				<DrugIndicationForm
					onSubmit={handleDrugIndicationSubmit}
					selectData={getSelectData(
						["organizations", "treatmentLines", "productNames", "indications"],
						[organizations, treatmentLines, productNames, indicationsMd]
					)}
					cancel={onCloseIndications}
					role={hookUser.role}
				/>
			</CustomModal>
			<CustomModal isOpen={isOpenProductName} onClose={onCloseProductName} size="5xl">
				<ProductNameForm
					selectData={getSelectData(["productNames", "organizations"], [productNames, organizations])}
					onSubmit={handleProductNameSubmit}
					cancel={onCloseProductName}
					role={hookUser.role}
				/>
			</CustomModal>
			<CustomModal isOpen={isOpenGenericName} onClose={onCloseGenericName} size="3xl">
				<GenericNameForm
					selectData={getSelectData(["genericNames", "organizations"], [genericNames, organizations])}
					onSubmit={handleGenericSubmit}
					cancel={onCloseGenericName}
					role={hookUser.role}
				/>
			</CustomModal>
			<CustomModal isOpen={isOpenRoutes} onClose={onCloseRoutes} size="md">
				<MasterDataForm
					onSubmit={handleMasterDataSubmit}
					cancel={onCloseRoutes}
					selectData={getSelectData(["organizations"], [organizations])}
					type={route}
					role={hookUser.role}
				/>
			</CustomModal>
		</Layout>
	)
}

const Container = styled.div`
	display: flex;
	flex: 1;
	width: 100%;
	height: 100vh;
	overflow: auto;
	button {
		padding: 4px 10px;
	}
	input {
		min-height: 40px;
	}
`
const FormTreatment = styled.form`
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
	text-align: center;

	h1 {
		font-size: 25px;
	}
	h4 {
		margin-bottom: 5px;
	}
	/* .scheme-side, */
	.cycles-side {
		width: 50%;
		min-height: 100vh;
		height: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
		/* justify-content: space-around; */
	}
	.scheme-side {
		background: var(--white);
		color: var(--blue);
		width: 50%;
		height: 100vh;
		display: flex;
		align-items: center;
		flex-direction: column;
	}
	.cycles-side {
		color: var(--white);
		background: var(--blue);
	}
	.scheme-side-fields {
		width: 70%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-top: 30px;
		row-gap: 24px;
		height: 100%;
		input,
		select {
			width: 100%;
			padding-left: 10px;
			background: var(--blue);
			color: var(--white);
			border: none;
			&::placeholder {
				color: var(--white);
				font-size: 14px;
			}
		}
	}

	.stepperContainer {
		width: 100%;
	}
	.cycles-side-fields {
		padding-bottom: 180px;
		width: 90%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.inline-inputs {
		display: flex;
		column-gap: 10px;
		width: 100%;
		margin-bottom: 20px;
		.react-select-container {
			width: 100%;
			text-align: left;
			border-radius: 4px;

			.react-select__input {
				color: var(--black);
				font-weight: 400;
				height: 40px;
			}
			.react-select__control {
				border-radius: 4px;
				background: var(--white);
				color: var(--black);
			}
			.react-select__placeholder {
				color: var(--black);
				font-size: 14px;
			}
			.react-select__menu {
				color: var(--black);
			}
			.react-select__single-value {
				color: var(--black);
			}
		}
		.cycles-select-group {
			display: flex;
			flex-direction: column;
			width: 75%;
			select {
				width: 100%;
			}
		}
		.fill-weeks {
			width: 25%;
			h4 {
				min-width: 92px;
			}
			input {
				width: 100%;
				padding-left: 10px;
				margin-bottom: 0;
				color: var(--black);
				font-weight: 400;
				::placeholder {
					color: var(--black);
				}
			}
		}
	}
	.cycles-header {
		margin-top: 42px;
		width: 100%;
	}
	.inline-group {
		display: flex;
		width: 100%;
		&-schema {
			width: 100%;
		}
		&-cycles {
			width: 100%;
			align-items: center;
			justify-content: center;
			margin-top: 20px;
		}
		&-weeks-row {
			display: flex;
			align-items: baseline;
			justify-content: center;
			input {
				width: 40%;
				margin-left: 20px;
			}
		}
		&-weeks {
			display: flex;
			width: 50%;
			column-gap: 10px;
			margin: 0 auto;
			input {
				width: 130px;
			}
			button {
				width: 70px;
				height: 40px;
				padding: 0 3px;
				background: var(--green);
				color: var(--white);
			}
		}
		&-dosage {
			display: flex;
			column-gap: 10px;
			width: 100%;
			/* margin: 0 auto; */
			justify-content: flex-end;
			align-items: baseline;
			input {
				width: 130px;
			}
		}
	}
	.scheme-select-group {
		width: 100%;
		.react-select-container {
			width: 100%;
			text-align: left;
			border-radius: 4px;

			.react-select__input {
				color: var(--white);
				font-weight: 400;
				height: 40px;
			}
			.react-select__control {
				border-radius: 4px;
				background: var(--blue);
				color: var(--white);
			}
			.react-select__placeholder {
				color: var(--white);
				font-size: 14px;
			}
			.react-select__single-value {
				color: var(--white);
				font-size: 14px;
			}
			.react-select__multi-value__remove {
				color: var(--blue);
			}
		}
	}
	.dose-inputs {
		height: 245px;
		width: 100%;
		margin-bottom: 15px;
		display: flex;
		flex-wrap: wrap;
		overflow-y: auto;
		border: 0.2px solid grey;
		border-radius: 4px;

		div {
			flex-shrink: initial;
			flex-basis: 25%;
		}
		.dose-input {
			width: 100px;
			height: 37px;
			padding: 0 10px;
			color: var(--black);
			::placeholder {
				font-size: 14px;
				color: var(--black);
			}
		}
	}
	.radio-buttons-group {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 80%;
		margin: 10px auto 0 auto;
		.radio-button {
			display: block;
			position: relative;
			padding-left: 35px;

			:first-child {
				margin-right: 20px;
			}

			cursor: pointer;
			font-size: 16px;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		.radio-button input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			display: none;
		}
		.checkmark {
			position: absolute;
			top: 0;
			left: 0;
			height: 25px;
			width: 25px;
			background-color: var(--blue);
			border: 2px solid var(--white);
			border-radius: 50%;
		}
		.radio-button:hover input ~ .checkmark {
			background-color: var(--blue);
		}
		.radio-button input:checked ~ .checkmark {
			background-color: var(--blue);
		}
		.checkmark:after {
			content: "";
			position: absolute;
			display: none;
		}
		.radio-button input:checked ~ .checkmark:after {
			display: block;
		}

		.radio-button .checkmark:after {
			width: 13px;
			height: 13px;
			border-radius: 50%;
			background: var(--white);
			transform: translate(4px, 4px);
		}
	}
	.btn-section {
		width: 100%;
		display: flex;
		justify-content: space-around;
		padding: 0 15px;
		.btn-publish {
			background: var(--green);
			color: var(--white);
		}
	}
	.red-btn {
		background: var(--red);
		color: var(--white);
	}
	@media (max-width: 1400px) {
		/* h1 {
			margin-top: 0px;
		} */

		select {
			font-size: 14px;
		}
		.scheme-side-fields {
			row-gap: 5px;
		}
		.dose-inputs {
			height: 190px;
			margin-bottom: 20px;
			.dose-input {
				width: 80px;
				height: 25px;
				padding-right: 10px;
			}
		}
		.btn-section {
			justify-content: space-between;
		}
	}
	@media (max-width: 1200px) {
		h4 {
			font-size: 14px;
		}
		.btn-section {
			button {
				width: 75px;
			}
		}
		.reset-btn {
			width: 75px;
		}
		.inline-group-weeks {
			width: 70%;
		}
		.inline-group-dosage {
			width: 100%;
		}
	}
`

export default CreateTreatmentPage
