import { useDisclosure } from "@chakra-ui/hooks"
import { Button } from "@chakra-ui/react"
import { useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import { toast } from "react-toastify"
import useUsers from "../../redux/hooks/useUsers"
import {
	cheapestAll,
	cheapestPerStrength,
	chooseAnyPackage,
	countryGraph,
	evolution,
	hosp,
	invalidateAction,
	listed,
	mnf,
	ppeVat,
	ppiVat,
	ppp,
	rolesOptions,
	stacked,
	superAdminAccess,
	tables,
} from "../../utils/constants"
import { formatDate, handleResponse, makeStringOutOfArray, makeStringOutOfStringArray } from "../../utils/functions"
import { makeDropdownOptions, makeIdsArray } from "../forms/calcHelper"
import ConfirmDialogForm from "../forms/ConfirmDialogForm"
import UserForm from "../forms/UserForm"
import CustomModal from "../modals/CustomModal"
import DeleteModal from "../modals/DeleteModal"
import { header, trueOrFalse } from "./tableHelper"
import { TablesStyles } from "./TableStyles"

const UsersTable = ({ data, selectData, role, organizationId }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const { isOpen: isOpenDialog, onOpen: onOpenDialog, onClose: onCloseDialog } = useDisclosure()

	const hook = useUsers()

	const [updateData, setUpdateData] = useState(null)

	const columns = [
		{
			dataField: "username",
			text: "Username",
			headerStyle: () => header(),
		},
		{
			dataField: "first_name",
			text: "First name",
			headerStyle: () => header(),
		},
		{
			dataField: "last_name",
			text: "Last name",
			headerStyle: () => header(),
		},
		{
			dataField: "email",
			text: "Email",
			headerStyle: () => header("2%"),
		},
		{
			dataField: "date_joined",
			text: "Date joined",
			headerStyle: () => header(),
			formatter: (cell) => (cell ? formatDate(cell) : ""),
		},
		{
			dataField: "last_login",
			text: "Last login",
			headerStyle: () => header(),
			formatter: (cell) => (cell ? formatDate(cell) : ""),
		},
		{
			dataField: "role",
			text: "Role",
			headerStyle: () => header(),
			formatter: (cell) => rolesOptions.find((option) => option.value === cell).label,
		},
		{
			dataField: "is_active",
			text: "Active",
			headerStyle: () => header(),
			formatter: (cell) => trueOrFalse(cell),
		},
		{
			dataField: "wastage_calculation",
			text: "Wastage calculation",
			headerStyle: () => header(),
			formatter: (cell) => trueOrFalse(cell),
		},
		{
			dataField: "default_wastage_calc_option",
			text: "Default waste calculation",
			headerStyle: () => header(),
			formatter: (cell) => trueOrFalse(cell),
		},
		{
			dataField: "indication_text",
			text: "Indication text",
			headerStyle: () => header(),
			formatter: (cell) => (cell ? "Full text indication" : "Combination codes"),
		},
		{
			dataField: "allowed_graphs",
			text: "Allowed graphs",
			headerStyle: () => header("2%"),
			formatter: (cell) => makeStringOutOfStringArray(cell),
		},
		{
			dataField: "package_option",
			text: "Package options",
			headerStyle: () => header("2%"),
			formatter: (cell) => makeStringOutOfStringArray(cell),
		},
		{
			dataField: "allowed_prices",
			text: "Allowed prices",
			headerStyle: () => header("2%"),
			formatter: (cell) => makeStringOutOfStringArray(cell, true),
		},
		{
			dataField: "hta_reimbursement_access",
			text: "Hta/reimbursement access",
			headerStyle: () => header(),
			formatter: (cell) => trueOrFalse(cell),
		},
		{
			dataField: "show_bsa_weight_page",
			text: "Bsa weight page",
			headerStyle: () => header(),
			formatter: (cell) => trueOrFalse(cell),
		},
		{
			dataField: "show_currency_rate_page",
			text: "Currency rate page",
			headerStyle: () => header(),
			formatter: (cell) => trueOrFalse(cell),
		},
		{
			dataField: "show_dosage_pattern_page",
			text: "Dosage pattern page",
			headerStyle: () => header(),
			formatter: (cell) => trueOrFalse(cell),
		},
		{
			dataField: "default_drugs",
			text: "Default drugs",
			headerStyle: () => header("8%"),
			formatter: (cell) => makeStringOutOfArray(cell),
		},
		{
			dataField: "default_countries",
			text: "Default countries",
			headerStyle: () => header("4%"),
			formatter: (cell) => makeStringOutOfArray(cell),
		},
		{
			dataField: "auto_calculate",
			text: "Auto calculate",
			headerStyle: () => header(),
			formatter: (cell) => trueOrFalse(cell),
		},
		{
			dataField: "adv_mode_drug_holders",
			text: "Default drug holders",
			headerStyle: () => header(),
		},
		{
			dataField: "select_all_indications",
			text: "Select all indications",
			headerStyle: () => header(),
			formatter: (cell) => trueOrFalse(cell),
		},
		{
			dataField: "default_interval",
			text: "Default interval",
			headerStyle: () => header(),
			formatter: (cell) => (cell ? "Annual" : "28 days"),
		},
		{
			dataField: "default_package_option",
			text: "Default package option",
			headerStyle: () => header(),
		},
		{
			dataField: "cycle_rounding",
			text: "Cycle rounding",
			headerStyle: () => header(),
		},
		{
			dataField: "show_single_year_radio",
			text: "Annual",
			headerStyle: () => header(),
			formatter: (cell) => trueOrFalse(cell),
		},
		{
			dataField: "show_single_month_radio",
			text: "28 days",
			headerStyle: () => header(),
			formatter: (cell) => trueOrFalse(cell),
		},
		{
			dataField: "reference_dosing",
			text: "Reference dosing",
			headerStyle: () => header(),
			formatter: (cell) => trueOrFalse(cell),
		},
		{
			dataField: "show_pipeline",
			text: "Pipeline",
			headerStyle: () => header(),
			formatter: (cell) => trueOrFalse(cell),
		},
		{
			dataField: "default_indications_exists",
			text: "Default indications",
			headerStyle: () => header(),
			formatter: (cell) => trueOrFalse(cell),
		},
		{
			dataField: "default_packages_exists",
			text: "Default packages",
			headerStyle: () => header(),
			formatter: (cell) => trueOrFalse(cell),
		},
		{
			dataField: "eu_market_access",
			text: "EU Market access",
			headerStyle: () => header(),
			formatter: (cell) => trueOrFalse(cell),
		},
		{
			dataField: "allow_protocol",
			text: "Protocol",
			headerStyle: () => header(),
			formatter: (cell) => trueOrFalse(cell),
		},
		{
			dataField: "allow_transplant_eligibility",
			text: "Transplant eligibility",
			headerStyle: () => header(),
			formatter: (cell) => trueOrFalse(cell),
		},
		{
			dataField: "default_extended_treatment_line",
			text: "Default extended treatment line",
			headerStyle: () => header(),
			formatter: (cell) => trueOrFalse(cell),
		},
		{
			dataField: "flexible_drug_order",
			text: "Flexible drug order",
			headerStyle: () => header(),
			formatter: (cell) => trueOrFalse(cell),
		},
		{
			dataField: "cluster_bar_coloring_type",
			text: "Cluster bar coloring type",
			headerStyle: () => header(),
		},
	]

	if (superAdminAccess.includes(role)) {
		columns.splice(0, 0, {
			dataField: "organization_name",
			text: "Organization",
			headerStyle: () => header(),
		})
	}

	const handleSuccess = () => {
		onClose()
		onCloseDialog()
		setTimeout(() => {
			hook.fetch()
		}, 1000)
	}

	const handleRemove = (id) => {
		hook.remove(id).then((res) => handleResponse(res, handleSuccess))
	}

	const expandRow = {
		renderer: (row) => (
			<div className="btns-container">
				<Button variant="orange" onClick={() => handleOpenUpdate(row)}>
					Update
				</Button>
				<DeleteModal user={true} remove={() => handleRemove(row.id)} />
			</div>
		),
		className: "expandedRow",
		parentClassName: "parentExpandedRow",
	}

	const handleOpenUpdate = (row) => {
		let defaultValues = {}
		Object.keys(row).map((key, i) => {
			return (defaultValues[`${key}`] = row[key])
		})

		defaultValues["allowed_countries"] = makeDropdownOptions(defaultValues["allowed_countries"])
		defaultValues["default_countries"] = makeDropdownOptions(defaultValues["default_countries"])
		defaultValues["allowed_drugs"] = makeDropdownOptions(defaultValues["allowed_drugs"])
		defaultValues["default_drugs"] = makeDropdownOptions(defaultValues["default_drugs"])
		defaultValues["allowed_generics"] = makeDropdownOptions(defaultValues["allowed_generics"])
		defaultValues["default_generics"] = makeDropdownOptions(defaultValues["default_generics"])
		defaultValues["allowed_brand_types"] = makeDropdownOptions(defaultValues["allowed_brand_types"])
		defaultValues["default_brand_types"] = makeDropdownOptions(defaultValues["default_brand_types"])
		defaultValues["default_treatment_lines"] = makeDropdownOptions(defaultValues["default_treatment_lines"])
		defaultValues["hosp_checkbox"] = row.default_prices.includes(hosp) ? true : false
		defaultValues["mnf_checkbox"] = row.default_prices.includes(mnf) ? true : false
		defaultValues["ppp_checkbox"] = row.default_prices.includes(ppp) ? true : false
		defaultValues["ppiVat_checkbox"] = row.default_prices.includes(ppiVat) ? true : false
		defaultValues["ppeVat_checkbox"] = row.default_prices.includes(ppeVat) ? true : false
		defaultValues["listed_checkbox"] = row.default_prices.includes(listed) ? true : false
		defaultValues["defaultRole"] = selectData.roles.find((option) => option.value === row.role)
		defaultValues["defaultOrganization"] = selectData.organizations.find((option) => option.value === row.organization)

		setUpdateData(defaultValues)
		onOpen()
	}

	const handleUpdate = (data) => {
		const checkBoxRadioArray = (checkboxRadioValue, array, value) => checkboxRadioValue && array.push(value)

		const allowedGraphs = []
		checkBoxRadioArray(data.tables, allowedGraphs, tables)
		checkBoxRadioArray(data.country, allowedGraphs, countryGraph)
		checkBoxRadioArray(data.stacked, allowedGraphs, stacked)
		checkBoxRadioArray(data.evolution, allowedGraphs, evolution)

		const packagePermissions = []

		checkBoxRadioArray(data.cheapest_all, packagePermissions, cheapestAll)
		checkBoxRadioArray(data.cheapest_per_strength, packagePermissions, cheapestPerStrength)
		checkBoxRadioArray(data.choose_any_package, packagePermissions, chooseAnyPackage)

		const defaultPrices = []

		checkBoxRadioArray(data.hosp_checkbox, defaultPrices, hosp)
		checkBoxRadioArray(data.mnf_checkbox, defaultPrices, mnf)
		checkBoxRadioArray(data.ppp_checkbox, defaultPrices, ppp)
		checkBoxRadioArray(data.ppeVat_checkbox, defaultPrices, ppeVat)
		checkBoxRadioArray(data.ppiVat_checkbox, defaultPrices, ppiVat)
		checkBoxRadioArray(data.listed_checkbox, defaultPrices, listed)

		let defaultInterval = null
		if (data.singleYear) {
			defaultInterval = true
		} else if (data.option28days) {
			defaultInterval = false
		}

		const pricePermissions = {}

		const priceFunction = (permissionObject, priceValue, priceName, keyName) => {
			if (priceValue) {
				permissionObject[`${keyName}`] = ""
				if (priceName) {
					permissionObject[`${keyName}`] = priceName
				}
			}
		}

		priceFunction(pricePermissions, data.hosp, data.hospName, hosp)
		priceFunction(pricePermissions, data.mnf, data.mnfName, mnf)
		priceFunction(pricePermissions, data.ppeVat, data.ppeVatName, ppeVat)
		priceFunction(pricePermissions, data.ppiVat, data.ppiVatName, ppiVat)
		priceFunction(pricePermissions, data.ppp, data.pppName, ppp)
		priceFunction(pricePermissions, data.listed, data.listedName, listed)

		let indicationText
		if (data.indication_text === "false") {
			indicationText = false
		} else {
			indicationText = true
		}

		if (data.password !== data.repeated_password) {
			toast.error("Passwords do not match")
		} else if (!packagePermissions.includes(data.default_package_option)) {
			toast.error("Default package must be allowed.")
		} else if (defaultInterval !== null && data.default_calculation_type === "true") {
			toast.error("Default calculation type cycles is not allowed when you have default interval!")
		} else {
			const excludeFields = [
				"hosp",
				"hospName",
				"hosp_checkbox",
				"mnf",
				"mnfName",
				"mnf_checkbox",
				"listed",
				"listedName",
				"listed_checkbox",
				"ppeVat",
				"ppeVatName",
				"ppeVat_checkbox",
				"ppiVat",
				"ppiVatName",
				"ppiVat_checkbox",
				"ppp",
				"pppName",
				"ppp_checkbox",
				"tables",
				"stacked",
				"evolution",
				"country",
				"cheapest_all",
				"cheapest_per_strength",
				"choose_any_package",
				"singleYear",
				"option28days",
			]

			let autoPayload = {}

			Object.keys(data).map((key, i) => {
				//eslint-disable-line
				if (!excludeFields.includes(key)) {
					return (autoPayload[`${key}`] = data[key])
				}
			})

			autoPayload["id"] = updateData.id
			autoPayload["allowed_countries"] = makeIdsArray(autoPayload["allowed_countries"])
			autoPayload["default_countries"] = makeIdsArray(autoPayload["default_countries"])
			autoPayload["allowed_drugs"] = makeIdsArray(autoPayload["allowed_drugs"])
			autoPayload["default_drugs"] = makeIdsArray(autoPayload["default_drugs"])
			autoPayload["default_generics"] = makeIdsArray(autoPayload["default_generics"])
			autoPayload["allowed_generics"] = makeIdsArray(autoPayload["allowed_generics"])
			autoPayload["allowed_brand_types"] = makeIdsArray(autoPayload["allowed_brand_types"])
			autoPayload["default_brand_types"] = makeIdsArray(autoPayload["default_brand_types"])
			autoPayload["default_treatment_lines"] = makeIdsArray(autoPayload["default_treatment_lines"])
			autoPayload["allowed_graphs"] = allowedGraphs
			autoPayload["allowed_prices"] = pricePermissions
			autoPayload["package_option"] = packagePermissions
			autoPayload["default_prices"] = defaultPrices
			autoPayload["indication_text"] = indicationText
			autoPayload["default_interval"] = defaultInterval
			autoPayload["role"] = autoPayload["role"].value

			if (superAdminAccess.includes(role)) {
				autoPayload["organization"] = autoPayload["organization"].value
			} else {
				autoPayload["organization"] = organizationId
			}

			if (!autoPayload["allow_calc_type_weeks"] && autoPayload["default_calculation_type"] === "false") {
				autoPayload["allow_calc_type_cycles"] = false
				autoPayload["week_start"] = "1"
				autoPayload["week_end"] = "52"
			}

			if (autoPayload["default_interval"] === false) {
				autoPayload["week_start"] = "1"
				autoPayload["week_end"] = "4"
			} else if (autoPayload["default_interval"] === true) {
				autoPayload["week_start"] = "1"
				autoPayload["week_end"] = "52"
			}

			const handleSuccess = () => {
				hook.fetch()
				onClose()
			}

			hook.update(autoPayload).then((res) => handleResponse(res, handleSuccess))
		}
	}

	const closeConfirmDialog = () => {
		onCloseDialog()
	}

	const handleInvalidate = () => {
		hook.cleanCache({ action: invalidateAction, username: updateData.username }).then((res) => handleResponse(res, handleSuccess))
	}

	return (
		<>
			<TablesStyles minWidth={"10000px"}>
				<BootstrapTable
					data={data}
					columns={columns}
					keyField="id"
					classes="TablesStyles"
					expandRow={expandRow}
					bordered
					bootstrap4
				/>
			</TablesStyles>
			<CustomModal isOpen={isOpen} onClose={onClose} size="full">
				<UserForm
					onSubmit={handleUpdate}
					defaultValues={updateData}
					selectData={selectData}
					cancel={onClose}
					update={true}
					showConfirmDialog={onOpenDialog}
					role={role}
				/>
			</CustomModal>
			<CustomModal isOpen={isOpenDialog} onClose={onCloseDialog}>
				<ConfirmDialogForm cancel={closeConfirmDialog} confirm={handleInvalidate}>
					Are you sure you want to invalidate cache?
				</ConfirmDialogForm>
			</CustomModal>
		</>
	)
}

export default UsersTable
