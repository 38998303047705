import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
	fetchPage,
	fetchDropdownIndication,
	createDrugIndication,
	removeDrugIndication,
	updateDrugIndication,
	setDefaultSize,
} from "../reducers/drug_indication"

export default function useDrugIndication() {
	const dispatch = useDispatch()
	const data = useSelector((state) => state.indicationDrugs.data)
	const loading = useSelector((state) => state.indicationDrugs.loading)
	const pagination = useSelector((state) => state.indicationDrugs.pagination)
	const dropdownData = useSelector((state) => state.indicationDrugs.dropdownData)
	const defaultSize = useSelector((state) => state.indicationDrugs.defaultSize)
	const combinationCode = useSelector((state) => state.indicationDrugs.combinationCode)
	const message = useSelector((state) => state.indicationDrugs.message)
	const [modal, setModal] = useState(false)

	const create = async (data) => {
		return dispatch(createDrugIndication(data))
	}
	const remove = async (id) => {
		return dispatch(removeDrugIndication(id))
	}

	const update = async (data) => {
		return dispatch(updateDrugIndication(data))
	}

	const fetch = (pageNumber, pageSize, filterData) => {
		dispatch(fetchPage(pageNumber, pageSize, filterData))
	}

	const fetchDropdownData = () => {
		dispatch(fetchDropdownIndication())
	}

	const changeSize = async (size) => {
		dispatch(setDefaultSize(size))
	}

	return {
		fetch,
		fetchDropdownData,
		changeSize,
		combinationCode,
		pagination,
		defaultSize,
		data,
		loading,
		dropdownData,
		message,
		modal,
		create,
		remove,
		update,
		setModal,
	}
}
