import React from "react"
import { Controller, useForm } from "react-hook-form"
import Select from "react-select"
import styled from "styled-components"
import { superAdminAccess } from "../../utils/constants"
import CustomButton from "../buttons/CustomButton"
import CustomSelectAll from "../CustomSelectAll"
import { customStylesSelect } from "../CustomSelectStyles"
import PositiveDecimalInput from "../inputs/PositiveDecimalInput"

const ReferenceForm = ({ defaultValues, onSubmit, cancel, selectData, role }) => {
	const { handleSubmit, control } = useForm()

	return (
		<FormStyles>
			<form onSubmit={handleSubmit(onSubmit)}>
				<label>Product name</label>
				<Controller
					control={control}
					options={selectData.productNames}
					as={CustomSelectAll}
					defaultValue={defaultValues && defaultValues.defaultProductName}
					name="drug"
					placeholder="Select product name..."
					className="react-select-container"
					classNamePrefix="react-select"
					styles={customStylesSelect}
					rules={{ required: true }}
				/>

				<label>Pack strength</label>
				<Controller
					control={control}
					as={PositiveDecimalInput}
					name={"pack_strength"}
					defaultValue={defaultValues && defaultValues.packStrength}
					placeholder={"Insert pack strength..."}
				/>

				{superAdminAccess.includes(role) && (
					<>
						<label>Organization</label>
						<Controller
							control={control}
							options={selectData.organizations}
							as={Select}
							defaultValue={defaultValues && defaultValues.organization}
							name="organization"
							placeholder="Select organization..."
							className="react-select-container"
							classNamePrefix="react-select"
							styles={customStylesSelect}
							isClearable={true}
						/>
					</>
				)}

				<div className="btns-wrapper">
					<CustomButton type="save" />
					<CustomButton type="cancel" onClick={() => cancel()} />
				</div>
			</form>
		</FormStyles>
	)
}
const FormStyles = styled.div`
	text-align: center;
	height: 535px;
	width: 535px;
	form {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		row-gap: 20px;
	}

	label {
		display: block;
		max-width: 383px;
		padding: 20px 0 10px 0;
		color: var(--white);
	}

	.btns-wrapper {
		margin-top: 50px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		column-gap: 20px;
	}

	.react-select-container {
		width: 100%;
		height: 40px;
		margin: 0 auto;
		text-align: left;
		background: var(--white);

		.react-select__control {
			border-radius: 4px;
			background: var(--white);
		}

		.react-select__value-container {
			color: var(--blue);
			max-height: 70px;
			overflow-y: auto;
		}
		.react-select__multi-value__remove {
			color: var(--blue);
		}
		.react-select__input {
			height: 40px;
		}
		.react-select__placeholder {
			color: var(--blue);
			font-weight: 400;
		}
		.react-select__menu-list {
			color: var(--black);
			height: 160px;
		}
		.react-select__single-value {
			color: var(--black);
		}
	}
`
export default ReferenceForm
