import { Box, Button, Flex, FormLabel, Stack, useBreakpointValue } from "@chakra-ui/react"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import Select from "react-select"
import styled from "styled-components"
import { superAdminAccess } from "../../utils/constants"
import { customStylesSelect } from "../CustomSelectStyles"
import FormCheckbox from "./formComponents/FormCheckbox"

const GenericNameForm = ({ defaultValues, onSubmit, cancel, selectData, role }) => {
	const { register, handleSubmit, control } = useForm()
	return (
		<FormStyles>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Stack spacing={8}>
					<Flex gap={4} className="AllInputsWrapper" direction={useBreakpointValue({ base: "column", md: "row" })}>
						<Box className="inputWrapper">
							<FormLabel>Code</FormLabel>
							<input
								type="text"
								name="code"
								ref={register({ required: true })}
								defaultValue={defaultValues && defaultValues.code}
							/>
						</Box>
						<Box className="inputWrapper">
							<FormLabel>Name</FormLabel>
							<input
								type="text"
								name="name"
								ref={register({ required: true })}
								defaultValue={defaultValues && defaultValues.name}
							/>
						</Box>
						<Box className="inputWrapper">
							<FormLabel>Color</FormLabel>
							<input
								type="text"
								name="color"
								ref={register({ required: true })}
								defaultValue={defaultValues && defaultValues.color}
							/>
						</Box>
					</Flex>

					<Box className="inputWrapper">
						<FormLabel textAlign="center">Cluster generics</FormLabel>
						<Controller
							isMulti
							control={control}
							options={selectData.genericNames}
							as={Select}
							defaultValue={defaultValues && defaultValues.cluster_generics}
							name="cluster_generics"
							ref={register({ required: true })}
							placeholder="Select cluster generics..."
							className="react-select-container"
							classNamePrefix="react-select"
							styles={customStylesSelect}
						/>
					</Box>
					{superAdminAccess.includes(role) && (
						<Box className="inputWrapper">
							<FormLabel textAlign="center">Organization</FormLabel>
							<Controller
								control={control}
								options={selectData.organizations}
								as={Select}
								defaultValue={defaultValues && defaultValues.organization}
								name="organization"
								placeholder="Select organization..."
								className="react-select-container"
								classNamePrefix="react-select"
								styles={customStylesSelect}
								isClearable={true}
							/>
						</Box>
					)}
					<Flex w="100%" justify="center" align="center" gap={6} direction={useBreakpointValue({ base: "column", md: "row" })}>
						<FormCheckbox className="inputWrapper checkboxWrapper">
							<FormLabel textAlign="center" mr={0}>
								Cluster
							</FormLabel>
							<div className="checkbox">
								<input
									id="checkbox"
									name="is_cluster"
									type="checkbox"
									ref={register}
									defaultChecked={defaultValues && defaultValues.is_cluster}
								/>
								<FormLabel htmlFor="checkbox"></FormLabel>
							</div>
						</FormCheckbox>
						<FormCheckbox className="inputWrapper checkboxWrapper">
							<FormLabel textAlign="center" mr={0}>
								Monotherapy
							</FormLabel>
							<div className="checkbox">
								<input
									id="checkboxMono"
									name="is_monotherapy"
									type="checkbox"
									ref={register}
									defaultChecked={defaultValues && defaultValues.is_monotherapy}
								/>
								<FormLabel htmlFor="checkboxMono"></FormLabel>
							</div>
						</FormCheckbox>
						<FormCheckbox className="inputWrapper checkboxWrapper">
							<FormLabel textAlign="center" mr={0}>
								Monotherapy exists
							</FormLabel>
							<div className="checkbox">
								<input
									id="checkboxMonoExists"
									name="monotherapy_exists"
									type="checkbox"
									ref={register}
									defaultChecked={defaultValues && defaultValues.monotherapy_exists}
								/>
								<FormLabel htmlFor="checkboxMonoExists"></FormLabel>
							</div>
						</FormCheckbox>
					</Flex>
				</Stack>
				<Flex w="100%" gap={4} justify="center" pt={20}>
					<Button type="submit" variant="green">
						Save
					</Button>
					<Button type="button" variant="cancel" onClick={() => cancel()}>
						Cancel
					</Button>
				</Flex>
			</form>
		</FormStyles>
	)
}
const FormStyles = styled.div`
	text-align: center;
	width: 100%;
	min-height: 450px;
	padding: 20px;
	label {
		margin-right: 0;
	}
	.AllInputsWrapper {
		input {
			color: var(--black);
			width: 100%;
			height: 40px;
			padding: 0 10px;
		}
		label {
			text-align: center;
		}
	}
	.checkboxWrapper {
		white-space: nowrap;
		width: 150px;
	}
	.inputWrapper {
		color: var(--white);
	}
	.checkbox {
		display: flex;
		width: 100%;
		justify-content: center;

		label {
			position: relative;
			transform: translate(-7px, 0);
			margin-bottom: 0 !important;
		}
	}
	.react-select-container {
		width: 100%;
		height: 40px;
		margin: 0 auto;
		text-align: left;

		.react-select__control {
			border-radius: 4px;
			background: var(--white);
		}

		.react-select__value-container {
			color: var(--blue);
			max-height: 70px;
			overflow-y: auto;
		}
		.react-select__multi-value__remove {
			color: var(--blue);
		}
		.react-select__input {
			height: 40px;
		}
		.react-select__placeholder {
			color: var(--blue);
			font-weight: 400;
		}
		.react-select__menu-list {
			color: var(--black);
			height: 160px;
		}
		.react-select__single-value {
			color: var(--black);
		}
	}
`
export default GenericNameForm
