import { Button } from "@chakra-ui/react"
import { Controller, useForm } from "react-hook-form"
import styled from "styled-components"
import PositiveDecimalInput from "../inputs/PositiveDecimalInput"
import Select from "react-select"
import { customStylesSelect } from "../CustomSelectStyles"

const BsaForm = ({ cancel, submit, selectData, defaultValues }) => {
	const { handleSubmit, control } = useForm()

	return (
		<FormAddStyles onSubmit={handleSubmit(submit)}>
			<label>Country</label>
			<Controller
				control={control}
				options={selectData.countries}
				as={Select}
				defaultValue={defaultValues && defaultValues.defaultCountry}
				name="country"
				placeholder="Select country..."
				className="react-select-container"
				classNamePrefix="react-select"
				styles={customStylesSelect}
				rules={{ required: true, message: "This field is required" }}
			/>
			<label>Weight</label>
			<Controller control={control} as={PositiveDecimalInput} defaultValue={defaultValues && defaultValues.weight} name="weight" />
			<label>BSA</label>
			<Controller control={control} as={PositiveDecimalInput} defaultValue={defaultValues && defaultValues.bsa} name="bsa" />
			<div className="btns-wrapper">
				<Button variant="green" type="submit">
					Save
				</Button>
				<Button className="cancelBtn" onClick={() => cancel()}>
					Close
				</Button>
			</div>
		</FormAddStyles>
	)
}

const FormAddStyles = styled.form`
	text-align: center;
	width: 443px;
	padding: 20px;

	.react-select-container {
		width: 383px;
		height: 40px;
		margin: 0 auto;
		text-align: left;

		.react-select__control {
			border-radius: 4px;
			background: var(--white);
		}

		.react-select__value-container {
			max-height: 70px;
			overflow-y: auto;
		}
		.react-select__menu-list {
			max-height: 200px;
		}
		.react-select__multi-value__remove {
			color: var(--blue);
		}
		.react-select__input {
			height: 40px;
		}
		.react-select__placeholder {
			color: var(--black);
			font-weight: 400;
		}
		.react-select__single-value {
			color: var(--black);
		}
	}

	label {
		display: block;
		padding: 3.5vh 0 1vh 0;
		color: var(--white);
		font-size: 16px;
	}
	.btns-wrapper {
		margin-top: 50px;
		display: flex;
		width: 100%;
		justify-content: center;
		column-gap: 20px;
		button {
			padding: 4px 10px;
		}
	}
`
export default BsaForm
