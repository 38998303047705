import { Button } from "@chakra-ui/button"
import { useDisclosure } from "@chakra-ui/hooks"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import styled from "styled-components"
import { ReactComponent as AddButton } from "../assets/add-button.svg"
import { makeDropdownOptions, makeIdsArray } from "../components/forms/calcHelper"
import ConfirmDialogForm from "../components/forms/ConfirmDialogForm"
import UserForm from "../components/forms/UserForm"
import Loading from "../components/frames/Loading"
import TableFrame from "../components/frames/TableFrame"
import Layout from "../components/layout/Layout"
import CustomModal from "../components/modals/CustomModal"
import generateToast from "../components/notifications/ToastGenerator"
import UsersTable from "../components/tables/UsersTable"
import { brandType, country, genericName, productName, treatmentLineMd } from "../redux/api_urls"
import useMasterData from "../redux/hooks/useMasterData"
import useOrganization from "../redux/hooks/useOrganization"
import useUser from "../redux/hooks/useUser"
import useUsers from "../redux/hooks/useUsers"
import {
	cheapestAll,
	cheapestPerStrength,
	chooseAnyPackage,
	countryGraph,
	evolution,
	hosp,
	invalidaAllAction,
	listed,
	mnf,
	ppeVat,
	ppiVat,
	ppp,
	rolesOptions,
	stacked,
	superAdminAccess,
	tables,
} from "../utils/constants"
import { handleResponse } from "../utils/functions"

const UserPage = () => {
	const hook = useUsers()
	const hookMd = useMasterData()
	const hookOrg = useOrganization()
	const hookUser = useUser()
	const role = hookUser.role
	const organizationId = hookUser.organizationId

	const { isOpen, onOpen, onClose } = useDisclosure()
	const { isOpen: isOpenDialog, onOpen: onOpenDialog, onClose: onCloseDialog } = useDisclosure()

	const [countries, setCountries] = useState([])
	const [productNames, setProductNames] = useState([])
	const [treatmentLines, setTreatmentLines] = useState([])
	const [generics, setGenerics] = useState([])
	const [brandTypes, setBrandTypes] = useState([])
	const [users, setUsers] = useState([])
	const [organizations, setOrganizations] = useState([])

	let roles = rolesOptions
	if (!superAdminAccess.includes(role)) {
		roles = rolesOptions.filter((option) => option.value !== 4)
	}

	useEffect(() => {
		hookUser.refresh()
		hook.fetch()
		hook.fetchDropdownTemplate()
		hookMd.fetch(country)
		hookMd.fetch(productName)
		hookMd.fetch(treatmentLineMd)
		hookMd.fetch(genericName)
		hookMd.fetch(brandType)
		if (superAdminAccess.includes(role)) {
			hookOrg.fetchDropdown()
		}
	}, []) // eslint-disable-line

	useEffect(() => {
		if (hook.message) {
			generateToast(hook.message)
		}
	}, [hook.message])

	useEffect(() => {
		setCountries(makeDropdownOptions(hookMd.countries))
		setProductNames(makeDropdownOptions(hookMd.productNames))
		setTreatmentLines(makeDropdownOptions(hookMd.treatmentLines))
		setGenerics(makeDropdownOptions(hookMd.genericNames))
		setBrandTypes(makeDropdownOptions(hookMd.brandTypes))
		setUsers(makeDropdownOptions(hook.templateDropdownData, "username"))
		setOrganizations(makeDropdownOptions(hookOrg.dropdownData, "name"))
	}, [
		hookMd.countries,
		hookMd.productNames,
		hookMd.treatmentLines,
		hookMd.genericNames,
		hookMd.brandTypes,
		hook.templateDropdownData,
		hookOrg.dropdownData,
	])

	const onSubmit = (data) => {
		const checkBoxRadioArray = (checkboxRadioValue, array, value) => checkboxRadioValue && array.push(value)

		const allowedGraphs = []
		checkBoxRadioArray(data.tables, allowedGraphs, tables)
		checkBoxRadioArray(data.country, allowedGraphs, countryGraph)
		checkBoxRadioArray(data.stacked, allowedGraphs, stacked)
		checkBoxRadioArray(data.evolution, allowedGraphs, evolution)

		const packagePermissions = []

		checkBoxRadioArray(data.cheapest_all, packagePermissions, cheapestAll)
		checkBoxRadioArray(data.cheapest_per_strength, packagePermissions, cheapestPerStrength)
		checkBoxRadioArray(data.choose_any_package, packagePermissions, chooseAnyPackage)

		const defaultPrices = []

		checkBoxRadioArray(data.hosp_checkbox, defaultPrices, hosp)
		checkBoxRadioArray(data.mnf_checkbox, defaultPrices, mnf)
		checkBoxRadioArray(data.ppp_checkbox, defaultPrices, ppp)
		checkBoxRadioArray(data.ppeVat_checkbox, defaultPrices, ppeVat)
		checkBoxRadioArray(data.ppiVat_checkbox, defaultPrices, ppiVat)
		checkBoxRadioArray(data.listed_checkbox, defaultPrices, listed)

		let defaultInterval = null
		if (data.singleYear) {
			defaultInterval = true
		} else if (data.option28days) {
			defaultInterval = false
		}

		const pricePermissions = {}

		const priceFunction = (permissionObject, priceValue, priceName, keyName) => {
			if (priceValue) {
				permissionObject[`${keyName}`] = ""
				if (priceName) {
					permissionObject[`${keyName}`] = priceName
				}
			}
		}

		priceFunction(pricePermissions, data.hosp, data.hospName, hosp)
		priceFunction(pricePermissions, data.mnf, data.mnfName, mnf)
		priceFunction(pricePermissions, data.ppeVat, data.ppeVatName, ppeVat)
		priceFunction(pricePermissions, data.ppiVat, data.ppiVatName, ppiVat)
		priceFunction(pricePermissions, data.ppp, data.pppName, ppp)
		priceFunction(pricePermissions, data.listed, data.listedName, listed)

		let indicationText
		if (data.indication_text === "false") {
			indicationText = false
		} else {
			indicationText = true
		}

		if (data.password !== data.repeated_password) {
			toast.error("Passwords do not match")
		} else if (!packagePermissions.includes(data.default_package_option)) {
			toast.error("Default package must be allowed.")
		} else if (defaultInterval !== null && data.default_calculation_type === "true") {
			toast.error("Default calculation type cycles is not allowed when you have default interval!")
		} else {
			const excludeFields = [
				"hosp",
				"hospName",
				"hosp_checkbox",
				"mnf",
				"mnfName",
				"mnf_checkbox",
				"listed",
				"listedName",
				"listed_checkbox",
				"ppeVat",
				"ppeVatName",
				"ppeVat_checkbox",
				"ppiVat",
				"ppiVatName",
				"ppiVat_checkbox",
				"ppp",
				"pppName",
				"ppp_checkbox",
				"tables",
				"stacked",
				"evolution",
				"country",
				"cheapest_all",
				"cheapest_per_strength",
				"choose_any_package",
				"singleYear",
				"option28days",
			]

			let autoPayload = {}

			Object.keys(data).map((key, i) => {
				//eslint-disable-line
				if (!excludeFields.includes(key)) {
					return (autoPayload[`${key}`] = data[key])
				}
			})

			autoPayload["allowed_countries"] = makeIdsArray(autoPayload["allowed_countries"])
			autoPayload["default_countries"] = makeIdsArray(autoPayload["default_countries"])
			autoPayload["allowed_drugs"] = makeIdsArray(autoPayload["allowed_drugs"])
			autoPayload["default_drugs"] = makeIdsArray(autoPayload["default_drugs"])
			autoPayload["allowed_generics"] = makeIdsArray(autoPayload["allowed_generics"])
			autoPayload["default_generics"] = makeIdsArray(autoPayload["default_generics"])
			autoPayload["allowed_brand_types"] = makeIdsArray(autoPayload["allowed_brand_types"])
			autoPayload["default_brand_types"] = makeIdsArray(autoPayload["default_brand_types"])
			autoPayload["default_treatment_lines"] = makeIdsArray(autoPayload["default_treatment_lines"])
			autoPayload["allowed_graphs"] = allowedGraphs
			autoPayload["allowed_prices"] = pricePermissions
			autoPayload["package_option"] = packagePermissions
			autoPayload["default_prices"] = defaultPrices
			autoPayload["indication_text"] = indicationText
			autoPayload["default_interval"] = defaultInterval
			autoPayload["role"] = autoPayload["role"].value

			if (superAdminAccess.includes(role)) {
				autoPayload["organization"] = autoPayload["organization"].value
			} else {
				autoPayload["organization"] = organizationId
			}

			if (!autoPayload["allow_calc_type_weeks"] && autoPayload["default_calculation_type"] === "false") {
				autoPayload["allow_calc_type_cycles"] = false
				autoPayload["week_start"] = "1"
				autoPayload["week_end"] = "52"
			}

			const handleSuccess = () => {
				hook.fetch()
				onClose()
			}

			hook.create(autoPayload).then((res) => handleResponse(res, handleSuccess))
		}
	}

	const selectData = {
		countries: countries,
		drugs: productNames,
		treatmentLines: treatmentLines,
		generics: generics,
		brandTypes: brandTypes,
		users: users,
		roles: roles,
		organizations: organizations,
	}

	const handleCancel = () => {
		onClose()
	}

	const closeConfirmDialog = () => {
		onCloseDialog()
	}

	const handleCleanCacheAll = () => {
		hook.cleanCache({ action: invalidaAllAction }).then((res) => handleResponse(res, closeConfirmDialog))
	}

	return (
		<Layout>
			<PageContainer>
				<TableFrame>
					<UsersTable data={hook.data} selectData={selectData} role={role} organizationId={organizationId} />
					{hook.loading && <Loading />}
				</TableFrame>
				<Button className="cashe-btn" variant="white" onClick={() => onOpenDialog()}>
					Invalidate all cache
				</Button>
				<CustomModal isOpen={isOpenDialog} onClose={onCloseDialog}>
					<ConfirmDialogForm cancel={closeConfirmDialog} confirm={handleCleanCacheAll}>
						Are you sure you want to invalidate cache?
					</ConfirmDialogForm>
				</CustomModal>
				<AddButton className="addBtn" onClick={() => onOpen()} />
				<CustomModal isOpen={isOpen} onClose={onClose} size="full">
					<UserForm defaultValues={null} onSubmit={onSubmit} cancel={handleCancel} selectData={selectData} />
				</CustomModal>
			</PageContainer>
		</Layout>
	)
}
const PageContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow-y: hidden;
	background: var(--white);
	.cashe-btn {
		font-size: 14px;

		margin-left: 30px;
		width: 135px;
		height: 40px;
		/* color: var(--white);
        background: var(--blue); */
	}
`
export default UserPage
