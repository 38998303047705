import { Icon } from "@chakra-ui/react"

export const InfoIcon = (props) => (
	<Icon viewBox="0 0 36 36" {...props}>
		<path
			d="M18 6C15.6266 6 13.3066 6.70379 11.3332 8.02236C9.35977 9.34094 7.8217 11.2151 6.91345 13.4078C6.0052 15.6005 5.76756 18.0133 6.23058 20.3411C6.6936 22.6689 7.83649 24.8071 9.51472 26.4853C11.193 28.1635 13.3312 29.3064 15.6589 29.7694C17.9867 30.2324 20.3995 29.9948 22.5922 29.0866C24.7849 28.1783 26.6591 26.6402 27.9776 24.6668C29.2962 22.6935 30 20.3734 30 18C30 14.8174 28.7357 11.7652 26.4853 9.51472C24.2349 7.26428 21.1826 6 18 6V6ZM16 11.15C16 10.7544 16.1173 10.3678 16.3371 10.0389C16.5568 9.70996 16.8692 9.45362 17.2346 9.30224C17.6001 9.15087 18.0022 9.11126 18.3902 9.18843C18.7781 9.2656 19.1345 9.45608 19.4142 9.73579C19.6939 10.0155 19.8844 10.3719 19.9616 10.7598C20.0387 11.1478 19.9991 11.5499 19.8478 11.9154C19.6964 12.2808 19.44 12.5932 19.1111 12.8129C18.7822 13.0327 18.3956 13.15 18 13.15C17.7291 13.1636 17.4582 13.1219 17.2039 13.0274C16.9496 12.933 16.7171 12.7879 16.5207 12.6008C16.3243 12.4137 16.1679 12.1886 16.0612 11.9392C15.9545 11.6898 15.8997 11.4213 15.9 11.15H16ZM23 24C23 24.2652 22.8946 24.5196 22.7071 24.7071C22.5196 24.8946 22.2652 25 22 25H15C14.7348 25 14.4804 24.8946 14.2929 24.7071C14.1054 24.5196 14 24.2652 14 24C14 23.7348 14.1054 23.4804 14.2929 23.2929C14.4804 23.1054 14.7348 23 15 23H17V17H16C15.7348 17 15.4804 16.8946 15.2929 16.7071C15.1054 16.5196 15 16.2652 15 16C15 15.7348 15.1054 15.4804 15.2929 15.2929C15.4804 15.1054 15.7348 15 16 15H20V23H22C22.2652 23 22.5196 23.1054 22.7071 23.2929C22.8946 23.4804 23 23.7348 23 24Z"
			fill="currentColor"
		/>
	</Icon>
)
