import { useDisclosure } from "@chakra-ui/react"
import { useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import { toast } from "react-toastify"
import useApiKey from "../../redux/hooks/useApiKey"
import { handleResponse } from "../../utils/functions"
import CustomButton from "../buttons/CustomButton"
import ApiKeyForm from "../forms/ApiKeyForm"
import CustomModal from "../modals/CustomModal"
import DeleteModal from "../modals/DeleteModal"
import { TablesStyles } from "./TableStyles"

const ApiKeyTable = ({ selectData }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()

	const hook = useApiKey()

	const [updateData, setUpdateData] = useState(null)

	let data = hook.data

	const columns = [
		{
			dataField: "name",
			text: "Name",
			headerStyle: () => {
				return { width: "400px" }
			},
		},
		{
			dataField: "token",
			text: "Token",
			headerStyle: () => {
				return { width: "500px" }
			},
		},
		{
			dataField: "template_username",
			text: "Template",
			headerStyle: () => {
				return { width: "250px" }
			},
		},
	]

	const handleSuccess = () => {
		hook.fetch()
		onClose()
	}

	const handleDelete = (id) => {
		hook.remove(id).then((res) => handleResponse(res, handleSuccess))
	}

	const expandRow = {
		renderer: (row) => (
			<div className="btns-container">
				<CustomButton type="update" onClick={() => handleOpenUpdate(row)} />
				<DeleteModal remove={() => handleDelete(row.id)} />
			</div>
		),
		className: "expandedRow",
		parentClassName: "parentExpandedRow",
	}

	const handleOpenUpdate = (row) => {
		const defaultValues = {
			id: row.id,
			name: row.name,
			defaultTemplate: selectData.templateUsers.find((option) => option.value === row.template),
			use_template: row.template && true,
			apiToken: row.token,
		}

		setUpdateData(defaultValues)
		onOpen()
	}

	const submit = (data) => {
		if (data.use_template && !data.template) {
			toast.error("Template is required!")
		} else {
			const payload = {
				id: updateData.id,
				name: data.name,
				change_api_token: data.change_api_token,
			}

			if (data.template) {
				payload["template"] = data.template.value
			} else {
				payload["template"] = ""
			}

			hook.update(payload).then((res) => handleResponse(res, handleSuccess))
		}
	}

	const handleCancel = () => {
		onClose()
	}

	return (
		<>
			<TablesStyles minWidth={"2000px"}>
				<BootstrapTable
					data={data}
					columns={columns}
					keyField="id"
					classes="TablesStyles"
					expandRow={expandRow}
					bordered
					bootstrap4
				/>
			</TablesStyles>

			<CustomModal isOpen={isOpen} onClose={onClose} size="full">
				<ApiKeyForm defaultValues={updateData} selectData={selectData} onSubmit={submit} cancel={handleCancel} />
			</CustomModal>
		</>
	)
}

export default ApiKeyTable
